import {
	ChevronDownIcon,
	ChevronUpDownIcon,
	ChevronUpIcon,
	PlusCircleIcon,
} from "@heroicons/react/24/outline";
import { generateKey } from "../../../redux/functions/function";
import PaginationComponent from "../PaginationComponent/PaginationComponent";
import { useState } from "react";
import EmptyStateComp from "../EmptyStateComponent/EmptyStateComp";
import { TableModel } from "../../../model/interface/TableModel";

export default function TableComponent(props: TableModel) {
	// DC: declare states here...
	const [sort, setSort] = useState("");

	// DC: create a function for sorting the icon
	const sortingIconComp = (column: string) =>
		sort === "asc" ? (
			<ChevronUpIcon
				className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
				onClick={() => manageSorting("desc", column)}
			/>
		) : sort === "desc" ? (
			<ChevronDownIcon
				className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
				onClick={() => manageSorting("", column)}
			/>
		) : (
			<ChevronUpDownIcon
				className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
				onClick={() => manageSorting("asc", column)}
			/>
		);

	// console.log("pagi", paginatedData);
	// // DC: create a function for returning a default sorting icon
	// const defaultSortIconComp = (column: string) =>
	//   sortBy === column && (
	//     <ChevronUpDownIcon
	//       className="cursor-pointer md:h-4 md:w-4 translate-y-1 h-3 w-3 inline float-right"
	//       onClick={() => manageSorting("asc", column)}
	//     />
	//   );

	// DC: created a function for sorting the two states here...
	const manageSorting = (sort: string, sortBy: string) => {
		setSort(sort);
	};

	return (
		<div className="px-4 sm:px-6 lg:px-8">
			<div className="flow-root">
				<div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
					<div className="inline-block min-w-full align-middle py-2 sm:py-0">
						{/* Display table data if available */}
						{props.tableData.length > 0 ? (
							<>
								<table className="min-w-full divide-y divide-gray-200">
									<thead className="bg-gray-50">
										<tr className="divide-x divide-gray-200">
											{props.tableHeader.map((t) => (
												<th
													key={generateKey(t.name)}
													scope="col"
													className="py-3.5 pl-4 pr-3 w-80 text-left text-xs font-semibold text-gray-400"
												>
													{/* DC: icons here... */}
													{t.icon && (
														<t.icon className="-ml-0.5 mr-1 md:mr-2 md:h-5 md:w-5 h-3 w-3 inline" />
													)}
													{/* DC: label here... */}
													<span className="align-middle">{t.value}</span>
													{/* DC: sorting icons here... */}
													{sortingIconComp(t.name)}
												</th>
											))}
										</tr>
									</thead>
									<tbody className="divide-y divide-gray-200 bg-white">
										{/* Display data here... */}
										{props.tableData.map((row: any) => (
											<tr
												key={generateKey(row.name)}
												className={`divide-x divide-gray-200 hover:bg-gray-50 ${
													props.hasRowClick ? "cursor-pointer" : ""
												}`}
												onClick={() => {
													props.handleRowClick &&
														props.handleRowClick!(row[props.idStringProperty!]);
												}}
											>
												{props.tableHeader.map((i, index) => (
													<td
														className={`whitespace-nowrap py-3 md:py-4 pl-4 pr-3 md:text-base text-sm`}
														key={generateKey(i.name)}
													>
														<div
															className={`${
																i.isTruncated ? "w-80 truncate" : ""
															}`}
														>
															{/* {row[i.name]} */}
															{i.customRender
																? i.customRender(row)
																: row[i.name]}
															{index === props.tableHeader.length - 1 &&
																props.offerLength && (
																	<span className="ml-2 text-green-500">
																		( {props.offerLength[row.offer_id] || 0} )
																	</span>
																)}
														</div>
													</td>
												))}
											</tr>
										))}
									</tbody>
								</table>
								{/* Render PaginationComponent only if there is data */}
								<PaginationComponent
									rowsPerPage={props.rowsPerPage}
									currentPage={props.currentPage}
									totalRecords={props.totalRecords}
									totalRows={props.tableData.length}
									onPageChange={props.handlePageChange}
								/>
							</>
						) : (
							// Display empty state if there is no data
							props.emptyState && (
								<EmptyStateComp
									hasCallToAction={props.emptyState?.hasCallToAction}
									icon={
										<PlusCircleIcon
											className="h-10 w-10 mx-auto text-gray-300 mt-16"
											aria-hidden="true"
										/>
									}
									primary_message={props.emptyState?.primaryMessage}
									secondary_message={props.emptyState?.secondaryMessage}
									button_text={props.emptyState?.buttonText}
									button_onclick_handler={props.emptyState?.buttonOnClick}
								/>
							)
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
