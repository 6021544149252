import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

interface WebSocketContextType {
  socket: WebSocket | null;
  isConn: boolean;
  sendWebSocketMessage: (action: string, body: any) => void;
  socketMessage: any;
}

interface WebSocketProviderProps {
  url: string;
  children: ReactNode;
}

const WebSocketContext = createContext<WebSocketContextType | null>(null);

export const useWebSocket = () => useContext(WebSocketContext)!;

const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  url,
  children,
}) => {
  const socketRef = useRef<WebSocket | null>(null);
  const [isConn, setIsConn] = useState(false);
  const [shouldConnect, setShouldConnect] = useState(false);
  const [socketMessage, setSocketMessage] = useState<any>(null);

  const onSocketOpen = useCallback(() => {
    setIsConn(true);
  }, []);

  const onSocketMessage = useCallback((data: MessageEvent<any>) => {
    setSocketMessage(data);
  }, []);

  const onSocketClose = useCallback(() => {
    setIsConn(false);

    // cja: reconnect websocket here
    if (socketRef.current) {
      // Set a timeout before reconnecting to avoid immediate reconnection attempts
      setTimeout(() => {
        // console.log("Reconnecting to webSocket connection...");
        socketRef.current = new WebSocket(url);

        if (socketRef.current) {
          socketRef.current.addEventListener("open", onSocketOpen);
          socketRef.current.addEventListener("close", onSocketClose);
          socketRef.current.addEventListener(
            "message",
            (event: MessageEvent<any>) => {
              onSocketMessage(event.data);
            }
          );
        }
      }, 1000); // Reconnect after 1 seconds
    }
  }, [url, onSocketOpen, onSocketMessage]);

  useEffect(() => {
    if (shouldConnect) {
      // console.log("Creating WebSocket connection...");
      socketRef.current = new WebSocket(url);

      socketRef.current.addEventListener("open", onSocketOpen);
      socketRef.current.addEventListener("close", onSocketClose);
      socketRef.current.addEventListener(
        "message",
        (event: MessageEvent<any>) => {
          onSocketMessage(event.data);
        }
      );

      //   // trace socket connection error
      //   socket.current.addEventListener("error", (error) => {
      //     console.error("WebSocket error:", error);
      //   });

      return () => {
        // trace socket connection closing
        // console.log("Closing WebSocket connection...");
        // Cleanup function
        if (socketRef.current) {
          // Close the WebSocket connection
          socketRef.current.close();
        }
      };
    }
  }, [url, onSocketOpen, onSocketClose, onSocketMessage, shouldConnect]);

  useEffect(() => {
    setShouldConnect(true);

    // Cleanup function
    return () => {
      setShouldConnect(false);
    };
  }, []);

  const sendWebSocketMessage = useCallback((action: string, body: any) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      try {
        socketRef.current?.send(JSON.stringify({ action, body }));
      } catch (error) {
        console.error("Something went wrong in sending socket message.", error);
      }
    } else {
      console.error("WebSocket connection not established or closed.");
    }
  }, []);

  const value: WebSocketContextType = {
    socket: socketRef.current,
    isConn,
    sendWebSocketMessage,
    socketMessage,
  };

  return (
    <WebSocketContext.Provider value={value}>
      {children}
    </WebSocketContext.Provider>
  );
};

export default WebSocketProvider;
