import {
	ClipboardDocumentIcon,
	Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import {
	classNames,
	getFetch,
	getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import { useNavigate } from "react-router-dom";
import { SideBarModel } from "../../../../model/interface/SideBarModel";
import { useUser } from "../../../../context/authContext";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setNextAuctionTemplateName } from "../../../../redux/functions/storageSlice";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../../../model/interface/TokenModel";

const SideBarComponent = (props: SideBarModel) => {
	// declare navigation
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);

	// Assuming userRole is passed as props
	const { userRole } = useUser();

	const [auctionTemplateList, setAuctionTemplateList] = useState([]);
	const [isAuctionTemplateListLoading, setIsAuctionTemplateListLoading] =
		useState(true);

	useEffect(() => {
		if (auctionTemplateList.length === 0 && isAuctionTemplateListLoading && userData.role === 1) {
			getFetch(
				`${process.env.REACT_APP_API_URL}/reverse_auction_template/list/get?buyer_id=${userData._id}`,
				_token
			).then((response) => {
				if (response.statusCode === 200) {
					setAuctionTemplateList(response.data.items);
					setIsAuctionTemplateListLoading(false);
					dispatch(
						setNextAuctionTemplateName(
							`Quotation Template ${response.data.items.length + 1}`
						)
					);
				}
			});
		}
	}, [
		dispatch,
		auctionTemplateList,
		_token,
		isAuctionTemplateListLoading,
		userData._id,
		userData.role
	]);

	const handleGetQuoteClick = () => {
		if (auctionTemplateList.length > 0) {
			dispatch(
				setNextAuctionTemplateName(
					`Quotation Template ${auctionTemplateList.length + 1}`
				)
			);
		}
		navigate("/b/select_commodity");
	};

	return (
		<>
			<div className="flex h-16 shrink-0 items-center">
				<img
					className="h-9 w-auto"
					src="https://res.cloudinary.com/doehyebmw/image/upload/v1681709063/dealogikal_2.0/resources/images/logo/app-logo-dark_wpiwgj.png"
					alt="Dealogikal Logo"
				/>
			</div>
			{userRole === 1 && (
				<button
					onClick={handleGetQuoteClick}
					className="flex items-center w-50 shrink-0 justify-center bg-dealogikal-200 p-2 text-white rounded-lg gap-2 translate-y-[-25%] cursor-pointer hover:bg-dealogikal-40 hover:text-gray-500"
				>
					<span>
						<ClipboardDocumentIcon className="h-5 w-5" />
					</span>
					<span>Get Qoute</span>
				</button>
			)}
			<nav className="flex flex-1 flex-col">
				<ul className="flex flex-1 flex-col gap-y-7">
					<li>
						<ul className="-mx-2 space-y-1">
							{props.mainMenu.map((item) => (
								<li key={item.name}>
									<span
										onClick={() => props.menuClickHandler!(item.menu_id, item.href)}
										className={classNames(
											item.menu_id === localStorage.getItem("activeMenu")?.toString()
												? "bg-gray-100 text-dealogikal-100"
												: "text-gray-700 sm:hover:text-dealogikal-100 sm:hover:bg-gray-50",
											"group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold cursor-pointer"
										)}
									>
										<item.icon
											className={classNames(
												localStorage.getItem("activeMenu")?.toString() === item.menu_id
													? "text-dealogikal-100"
													: "text-gray-400 group-sm:hover:text-dealogikal-100",
												"h-6 w-6 shrink-0"
											)}
											aria-hidden="true"
										/>
										{item.name}
									</span>
								</li>
							))}
						</ul>
					</li>
					<li className="mt-auto">
						<a
							href="/"
							className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 sm:hover:bg-gray-50 sm:hover:text-dealogikal-100"
						>
							<Cog6ToothIcon
								className="h-6 w-6 shrink-0 text-gray-400 group-sm:hover:text-dealogikal-100"
								aria-hidden="true"
							/>
							Settings
						</a>
					</li>
				</ul>
			</nav>
		</>
	);
};

export default SideBarComponent;
