import {
  BuildingOffice2Icon,
  CubeIcon,
  Square3Stack3DIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
  getUtilities,
  setUtilityMyQuotationList,
} from "../../../redux/functions/storageSlice";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import TableComponent from "../../templates/TableComponent/TableComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import { MyQuotationModel } from "../../../model/interface/MyQuotationModel";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { getQuotationsFromBuyer } from "../../../redux/functions/API";

const MyQuotationComp = () => {
  const navigate = useNavigate();
  const utilities = useAppSelector(getUtilities);
  const dispatch = useAppDispatch();

  const [quotationList, setQuotationList] = useState<MyQuotationModel[]>(
    utilities.quotationList
  );
  const [isQuotationListLoading, setIsQuotationListLoading] = useState(true);

  // DC: setup table header columns
  const tableHeader = [
    {
      name: "commodities",
      value: "Commodities",
      icon: CubeIcon,
    },
    {
      name: "volume",
      value: "Volume",
      icon: Square3Stack3DIcon,
    },
    {
      name: "delivery_location",
      value: "Delivery Location",
      icon: BuildingOffice2Icon,
    },
    {
      name: "shipping_method",
      value: "Shipping Method",
      icon: TruckIcon,
    },
  ];

  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (quotationList.length === 0 && isQuotationListLoading) {
      // get and decode the token from local storage
      const _token = getTokenFromLocalStorage() ?? "";
      const userData: TokenModel = jwt_decode(_token);
      const buyer_id: string = userData._id.toString();
      getQuotationsFromBuyer(_token, buyer_id).then((data: any) => {
        setIsQuotationListLoading(false);
        setQuotationList(data);
        dispatch(setUtilityMyQuotationList(data));
      });
    } else {
      // removed the loading
      setIsQuotationListLoading(false);
    }
  }, [dispatch, quotationList, isQuotationListLoading]);

  // created a function for handling the table row click
  const handleRowClick = (quotation_id: number | string) => {
    navigate(`/quotation/${quotation_id}`);
  };

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <BreadcrumbContainerComponent
            key={Math.random()}
            subtitle="My Quotations"
          />

          <div className="px-2">
            {isQuotationListLoading ? (
              <div className="-my-2">
                <Skeleton height={40} count={1} />
                <Skeleton height={40} count={5} />
              </div>
            ) : (
              <TableComponent
                tableHeader={tableHeader}
                tableData={quotationList}
                currentPage={1} // DC: static for now, lets make it dynamic soon
                rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                hasRowClick={true}
                idStringProperty="quotation_id"
                handleRowClick={handleRowClick}
                emptyState={{
                  primaryMessage: "No quotations available.",
                  secondaryMessage: "Get started by creating a new quotation.",
                  buttonText: "Add New Quotation",
                  buttonOnClick() {
                    navigate("/select_commodity");
                  },
                }}
              />
            )}
          </div>
        </>
      }
    />
  );
};
export default MyQuotationComp;
