import { DashboardTitleModel } from "../../../model/interface/DashboardTitleModel";

const DashboardTitleComponent = (props: DashboardTitleModel) => {
  return (
    <>
      <h2 className="text-2xl md:text-3xl font-bold tracking-tight text-gray-900 text-left "> {/** text-2xl md:text-3xl font-bold tracking-tight text-gray-900 text-left mb-12 md:mb-20 */}
        {props.mainTitle}
      </h2>

      {/* <h2 className="text-xl md:text-2xl font-bold tracking-tight text-black text-left">
        {props.subTitle}
      </h2> */}
    </>
  );
};

export default DashboardTitleComponent;
