import DashboardCardComponent2 from "../../../templates/CardComponent/DashboardCardComponent2";
import OrderStatusData from "./OrderStatusData";

const OrderStatusComponent = () => {
  return (
    <div className="py-8 md:py-24">
      <div className="mx-auto max-w-full px-6 lg:px-8">
        <div className="max-w-2xl lg:max-w-none">
          <h2 className="text-xl md:text-2xl font-bold tracking-tight text-black text-left">
            Order Status
          </h2>
          <DashboardCardComponent2 data={OrderStatusData} />
        </div>
      </div>
    </div>
  );
};

export default OrderStatusComponent;
