import VariationFieldModel from "../../../model/interface/VariationFieldModel";

const VariationFieldCOmponent: React.FC<VariationFieldModel> = (props) => {
  return (
    <div
      className={`flex flex-col w-full ${props.padding_alignment} ${props.width}`}
    >
      <input
        type={props.input_type}
        value={props.input_value}
        name={props.input_name}
        id={props.input_id}
        autoFocus={props.isFocused}
        placeholder={props.placeholder_text}
        className={`block w-full rounded-xl text-center border-0 py-4 px-4 md:mt-0 mt-2 shadow-sm ring-1 ring-inset ${
          props.isInvalid === true ? "ring-red-600" : "ring-gray-300"
        } placeholder:text-gray-400 focus:ring-1 focus:ring-inset ${
          props.isInvalid === true
            ? "focus:ring-red-600"
            : "focus:ring-gray-300"
        } text-sm sm:leading-6 ${
          props.isDisabled !== undefined && props.isDisabled
            ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200"
            : ""
        }`}
        onChange={(e) => props.OnchangeHandler(e, 0, 0)}
        disabled={props.isDisabled !== undefined && props.isDisabled}
      />
    </div>
  );
};
export default VariationFieldCOmponent;
