import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TableComponent from "../../../../templates/TableComponent/TableComponent";
import { ClipboardIcon } from "@heroicons/react/24/outline";
import LogRateListType from "../../../../../model/types/LogRateListType";
import { getRateDetailsAPI } from "../../../../../redux/functions/API";
import { getTokenFromLocalStorage } from "../../../../../redux/functions/function";

const CVDetailsComponent = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const [rateDetails, setRateDetails] = useState<LogRateListType[]>([]);
  const [tableHeader, setTableHeader] = useState<any[]>([]);
  const navigate = useNavigate();

  const { rate_details_id } = useParams<string>();

  useEffect(() => {
    getRateDetailsAPI(_token, rate_details_id ?? "").then((response: any) => {
      const data = response.data.items;
      setRateDetails(data);

      // Dynamically generate the table headers based on the response keys
      if (data.length > 0) {
        const keys = Object.keys(data[0]);
        const headers = keys.map((key) => ({
          name: key,
          value: key.replace(/_/g, " ").toUpperCase(), // Format key names for display
          icon: ClipboardIcon, // Use a default icon or map specific icons if needed
        }));
        setTableHeader(headers);
      }
    });
  }, [_token, rate_details_id]);

  return (
    <div className="mt-2">
      <TableComponent
        tableHeader={tableHeader}
        tableData={rateDetails}
        currentPage={1}
        rowsPerPage={10}
        hasRowClick={true}
        idStringProperty="rate_id" // TODO: change to reverse_auction_id
        emptyState={{
          primaryMessage: "No Data",
          secondaryMessage: "Get started",
          buttonText: "Add Data",
          buttonOnClick() {
            navigate("/l/rates");
          },
        }}
      />
    </div>
  );
};


export default CVDetailsComponent;
