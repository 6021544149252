const buyersNearby = [
  {
    id: 1,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Buyer 0",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 2,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Buyer 1",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 3,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Buyer 2",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 4,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Buyer 3",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 5,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Buyer 4",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
];

export default buyersNearby;
