import { useEffect, useState } from "react";
import MainConsoleLayoutComponent from "../MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import {
  classNames,
  generateKey,
  manageSteps,
  pesoSign,
} from "../../../redux/functions/function";
import StepType from "../../../model/types/StepType";
import BreadcrumbContainerComponent from "../BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import StepsComponent from "../StepsComponent/StepsComponent";
import BreadcrumbComponent from "../../templates/BreadcrumbComponent/BreadcrumbComp";
import TabComponent from "../TabComponent/TabComponent";
import DisclosureComponent from "../DisclosureComponent/DisclosureComponent";
import CardComponent from "../CardComponent/CardComponent";
import StaticTableComponent from "../TableComponent/StaticTableComponent";
import { PeopleCardModel } from "../../../model/interface/PeopleCardModel";
import { TokenModel } from "../../../model/interface/TokenModel";
import MetaType from "../../../model/types/MetaType";
import Lottie from "lottie-react";
import Refreshing from "../../../lottie/refresh_animation.json";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

import {
  CalendarIcon,
  MapPinIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";
import MetaComponent from "../MetaComponent/MetaComponent";
import ModalComponent from "../ModalComponent/ModalComponent";
import ChatBoxComp from "../ChatBoxComponent/ChatBoxComp";

interface QuotationCompModel {
  user_data: TokenModel;
  card_data: PeopleCardModel[];
  quotation_details: any;
  refreshHandler: () => void;
  isRefreshing: boolean;
}

const QuotationComp = (props: QuotationCompModel) => {
  // declare states here...
  const [otherSuppliersModalIsOpen, setOtherSuppliersModalIsOpen] =
    useState(false);
  const [selectedSupplierIndex, setSelectedSupplierIndex] = useState<number>(0);
  const [supplierOffer, setSupplierOffer] = useState<any>([]);

  // cja: get the steps saved in session, to be used in updating the steps component
  const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);
  useEffect(() => {
    const str_steps = sessionStorage.getItem("steps");
    str_steps != null && setStepsFromSession(JSON.parse(str_steps));
  }, []);

  // cja: update the step component
  const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
  useEffect(() => {
    if (stepsFromSession.length !== 0) {
      manageSteps(
        { name: "Quotation", href: "/quotation", status: "current" },
        stepsFromSession
      ).then((updated_steps: any) => {
        setUpdatedSteps(updated_steps);
      });
    }
  }, [stepsFromSession]);

  // this meta data component will be extracted from the dummy quotation details
  const MetaDataComponent: MetaType[] = [
    {
      text: props.quotation_details.quotation_details?.shipping_method,
      icon: (
        <TruckIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
    {
      text: props.quotation_details.quotation_details?.delivery_date,
      icon: (
        <CalendarIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
    {
      text: props.quotation_details.quotation_details?.delivery_location,
      icon: (
        <MapPinIcon
          className="mr-1.5 h-5 w-5 flex-shrink-0"
          aria-hidden="true"
        />
      ),
    },
  ];

  const cardActionClickHandler = (
    account_type: string,
    action_type: string
  ) => {
    //alert(`Account: ${account_type} \n Action: ${action_type}`);
    if (account_type === "Supplier" && action_type === "others") {
      viewOtherSuppliers();
    }
  };

  const viewOtherSuppliers = () => setOtherSuppliersModalIsOpen(true);

  const isItemEditable = () => {
    if (props.user_data.role === 1) {
      return false;
    } else if (props.user_data.role === 2) {
      return (
        props.user_data.role === 2 &&
        props.user_data._id === props.quotation_details.suppliers[0].supplier_id
      );
    } else {
      return false;
    }
  };

  // extracting of current supplier's account offer
  useEffect(() => {
    props.quotation_details.suppliers !== undefined &&
      props.quotation_details.suppliers.forEach((supplier: any) => {
        if (supplier.supplier_id === props.user_data._id) {
          setSupplierOffer(supplier.offers);
        }
      });
  }, [
    props.quotation_details?.suppliers,
    props.user_data._id,
    props.user_data.role,
  ]);

  // cja: this will be displayed in seller pov only
  const renderOtherSuppliers = () => (
    <>
      <div className="flex flex-wrap justify-center my-4 mx-4">
        {/* single card (start) */}
        <div className="flex flex-col w-full md:w-2/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              {props.quotation_details.suppliers !== undefined &&
                props.quotation_details.suppliers.map(
                  (supplier: any, index: number) => (
                    <li
                      key={supplier.name}
                      className={classNames(
                        index === selectedSupplierIndex
                          ? "bg-gray-100 text-dealogikal-100"
                          : "text-gray-700 hover:text-dealogikal-100 hover:bg-gray-100",
                        "group flex gap-x-3 rounded-md p-6 py-5 text-base leading-6 font-normal cursor-pointer"
                      )}
                      onClick={() => setSelectedSupplierIndex(index)}
                    >
                      <span className="mt-2 font-extrabold">{`${
                        index + 1
                      }.`}</span>
                      <img
                        className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-50"
                        src={supplier.logo_url}
                        alt=""
                      />
                      <p className="mt-2 w-full">{`${supplier.name} ${
                        supplier.supplier_id === props.user_data._id
                          ? "(You)"
                          : ""
                      }`}</p>
                    </li>
                  )
                )}
            </ul>
          </nav>
        </div>
        {/* single card (end) */}
        {/* single card (start) */}
        <div className="flex flex-col w-full md:w-3/5">
          <nav className="flex flex-1 flex-col" aria-label="Sidebar">
            <ul>
              <li
                className={classNames(
                  "text-gray-400 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between border-b"
                )}
              >
                <p className="mt-2 text-sm">Items</p>
                <p className="mt-2 text-sm">Price</p>
              </li>
              {props.quotation_details.suppliers !== undefined &&
                props.quotation_details.suppliers.map(
                  (supplier: any, index: number) => {
                    return (
                      index === selectedSupplierIndex &&
                      supplier.offers.map((offer: any) => {
                        return (
                          <li
                            key={offer.offer_id}
                            className={classNames(
                              "text-gray-900 group flex gap-x-3 rounded-md px-6 py-1 text-base leading-6 font-normal cursor-pointer justify-between"
                            )}
                          >
                            <p className="mt-2">
                              {offer.commodity_name} <br />
                              <small>{`${offer.min_volume} - ${offer.max_volume}`}</small>
                            </p>
                            <p className="mt-2 text-sm font-bold">
                              {`${pesoSign()} ${offer.price}`}
                            </p>
                          </li>
                        );
                      })
                    );
                  }
                )}
            </ul>
          </nav>
        </div>
        {/* single card (end) */}
      </div>
      {props.user_data.role === 1 && (
        <div className="p-2 mx-2">
          <button
            type="button"
            className="w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm sm:hover:bg-dealogikal-50 focus-visible:outline-none sm:w-full"
          >
            Select
          </button>
        </div>
      )}
    </>
  );

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={generateKey(Math.random().toString())}
              breadcrumb={<BreadcrumbComponent />}
              subtitle="Quotation"
              steps={<StepsComponent steps={updatedSteps} />}
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <div className="flex flex-wrap md:-mt-5 mt-0 mb-3 ml-1 justify-between">
              {/* single card (start) */}
              {props.isRefreshing === true ? (
                <Skeleton className="ml-2" height={50} width={400} count={1} />
              ) : (
                <div className="flex flex-col justify-start text-black bg-gray-100 px-8 py-3 pt-4 rounded-3xl">
                  <MetaComponent
                    key={generateKey(Math.random().toString())}
                    meta_data={MetaDataComponent}
                    placement="start"
                  />
                </div>
              )}

              {/* single card (end) */}
              {/* single card (start) */}
              <div className="flex flex-col justify-start px-4 py-2 pt-2.5 rounded-3xl hover:text-dealogikal-200">
                <Lottie
                  className="w-9 h-auto cursor-pointer"
                  animationData={Refreshing} // animation JSON data
                  loop={props.isRefreshing} // controls whether the animation loops
                  autoplay={props.isRefreshing} // controls whether the animation starts automatically
                  onClick={props.refreshHandler}
                />
              </div>
              {/* single card (end) */}
            </div>

            <div className="mt-4 md:mt-6 sm:hidden">
              <TabComponent />
            </div>
            <div className="">
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div className="p-2">
                {/* display this on mobile while hide it on desktop */}
                <div className="sm:hidden">
                  <DisclosureComponent
                    headerText="Supplier, Logistic and Finance details"
                    body={
                      <CardComponent
                        people={props.card_data}
                        cardActionClickHandler={cardActionClickHandler}
                        userData={props.user_data}
                        isRefreshing={props.isRefreshing}
                        otherCount={props.quotation_details.suppliers?.length}
                      />
                    }
                  />
                </div>
                {/* display this on desktop while hide it on mobile */}
                <div className="hidden sm:block">
                  <CardComponent
                    people={props.card_data}
                    cardActionClickHandler={cardActionClickHandler}
                    userData={props.user_data}
                    isRefreshing={props.isRefreshing}
                    otherCount={props.quotation_details.suppliers?.length}
                  />
                </div>
              </div>

              {props.isRefreshing === true ? (
                <>
                  <Skeleton
                    className="ml-2 mt-2"
                    height={40}
                    width={300}
                    count={1}
                  />
                </>
              ) : (
                <>
                  {props.user_data.role === 1 &&
                    props.card_data[0]?.role === "" && (
                      <div className="ml-4 mt-6 mb-1">
                        <span className="text-sm md:text-base font-medium text-gray-400 self-center">
                          -- No supplier offer available --
                        </span>
                      </div>
                    )}
                  {props.user_data.role === 1 &&
                    props.card_data[0]?.role !== "" && (
                      <div className="ml-4 mt-6 mb-1">
                        <span className="ml-2 text-black font-bold">
                          Supplier's Offer
                        </span>
                      </div>
                    )}
                  {props.user_data.role === 2 && (
                    <div className="ml-4 mt-6 mb-1">
                      <span className="text-gray-400">Best Deal:</span>
                      <span className="ml-2 text-black">
                        {props.quotation_details.suppliers !== undefined &&
                        props.quotation_details.suppliers[0].supplier_id ===
                          props.user_data._id ? (
                          <span className="font-bold">Your Offer</span>
                        ) : (
                          <span className="font-bold">
                            {`${
                              props.quotation_details.suppliers !== undefined &&
                              props.quotation_details?.suppliers[0].name
                            }'s Offer`}
                          </span>
                        )}
                      </span>
                    </div>
                  )}
                </>
              )}

              <div className="p-2">
                {props.isRefreshing === true ? (
                  <>
                    <Skeleton height={200} width={1035} count={1} />
                  </>
                ) : (
                  <>
                    {props.quotation_details.suppliers !== undefined &&
                      props.card_data[0].role !== "" && (
                        <StaticTableComponent
                          isItemEditable={isItemEditable()}
                          user_data={props.user_data}
                          commodity_table={props.quotation_details.suppliers[0]}
                          current_offer={supplierOffer}
                        />
                      )}
                  </>
                )}
              </div>
              {props.isRefreshing === true && props.user_data.role === 1 ? (
                <>
                  <Skeleton
                    className="ml-2"
                    height={50}
                    width={1040}
                    count={1}
                  />
                </>
              ) : (
                <>
                  {props.user_data.role === 1 &&
                    props.card_data[0]?.role !== "" && (
                      <div className="p-2">
                        <button
                          type="button"
                          className="w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm sm:hover:bg-dealogikal-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-2/3"
                        >
                          Get Quote
                        </button>
                      </div>
                    )}
                </>
              )}
            </div>

            <ChatBoxComp />
          </>
        }
      />

      <ModalComponent
        isOpen={otherSuppliersModalIsOpen}
        onCloseHandler={() => {
          setOtherSuppliersModalIsOpen(false);
          setSelectedSupplierIndex(0);
        }}
        header={
          <div className="bg-dealogikal-100 md:py-5 md:px-4 pt-3 pb-3 px-4 text-center">
            <span className="text-white inline md:text-base text-xs">
              Supplier Details
            </span>
          </div>
        }
        body={
          <>
            {/* {props.user_data.role === 2 && renderOtherSuppliers()} */}
            {renderOtherSuppliers()}
          </>
        }
      />
    </>
  );
};

export default QuotationComp;
