import { ChevronRightIcon, HomeIcon } from "@heroicons/react/20/solid";

import { useNavigate } from "react-router-dom";
import { BreadcrumbModel } from "../../../model/interface/BreadcrumbModel";
import { manageBreadcrumb } from "../../../redux/functions/function";

const BreadcrumbComp = () => {
  // declare navigation
  const navigate = useNavigate();

  // get the data from session storage fro the breadcrumb
  const breadcrumb = sessionStorage.getItem("breadcrumb") ?? "";

  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="flex items-center space-x-4">
        <li>
          <div>
            <span
              onClick={() =>
                manageBreadcrumb(
                  {
                    name: "Dashboard",
                    href: "/b/dashboard",
                  },
                  () => navigate("/b/dashboard")
                )
              }
              className="text-gray-400 sm:hover:text-gray-500 cursor-pointer "
            >
              <HomeIcon
                className="w-4 md:h-5 h-4 md:w-5 flex-shrink-0 mt-2"
                aria-hidden="true"
              />
              <span className="sr-only">Dashboard</span>
            </span>
          </div>
        </li>
        {breadcrumb.length !== 0 &&
          JSON.parse(breadcrumb).map((page: BreadcrumbModel, i: number) => (
            <li key={page.name + i} className="ml-0 md:ml-4">
              <div className="flex items-center">
                <ChevronRightIcon
                  className="w-4 h-4 md:h-5 md:w-5 flex-shrink-0 text-gray-400"
                  aria-hidden="true"
                />
                <span
                  onClick={() =>
                    manageBreadcrumb(page, () => navigate(page.href))
                  }
                  className={`ml-4 text-xxs md:text-sm text-gray-500 sm:hover:text-gray-700 cursor-pointer ${
                    i === JSON.parse(breadcrumb).length - 1
                      ? "font-bold"
                      : "font-medium"
                  }`}
                  aria-current={page.current ? "page" : undefined}
                >
                  {page.name}
                </span>
              </div>
            </li>
          ))}
      </ol>
    </nav>
  );
};

export default BreadcrumbComp;
