import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
	ClipboardIcon,
	MagnifyingGlassIcon,
	RectangleStackIcon,
	TruckIcon,
} from "@heroicons/react/24/outline";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import DropdownComponent from "../../../templates/DropdownComponent/DropdownComponent";
import DropdownItemType from "../../../../model/types/DropdownItemType";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import VehicleListType from "../../../../model/types/VehicleListType";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
	getVehiclesDetails,
	setNextRateTemplateName,
	setSelected_Vehicles,
} from "../../../../redux/functions/storageSlice";
import {
	generateKey,
	getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import SelectedVehicleType from "../../../../model/types/SelectedVehicleType";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import {
	getRateConfirmationAPI,
	getRateListAPI,
	getVehicleNames,
} from "../../../../redux/functions/API";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import RateListType from "../../../../model/types/RateType";
import TableComponentwithbutton from "../../../templates/TableComponent/TableComponentwithbutton";
import ConfirmationDetailsType from "../../../../model/types/ConfirmationDetailsType";

const RateListComp: React.FC = () => {
	//get and decode the token from local storage
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);
	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const vehicleListDetail = useAppSelector(getVehiclesDetails);
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [isVehicleListLoading, setIsVehicleListLoading] = useState(true);
	const [vehicleList, setVehicleList] = useState<VehicleListType[]>([]);
	const [isRatesListLoading, setIsRatesListLoading] = useState<boolean>(true);
	const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicleType[]>(
		[]
	);
	const [selectedVehicleID, setSelectedVehicleID] = useState<number[]>([]);
	const [isDisplaySelectionError, setIsDisplaySelectionError] =
		useState<boolean>(false);

	const [confirmTableDetails, setConfirmTableDetails] = useState<
		ConfirmationDetailsType[]
	>(vehicleListDetail.confirmTableDetails);
	// janm: useEffect hook to check the vehicle list from redux storage,
	// if empty, then call from api
	useEffect(() => {
		if (
			vehicleListDetail.selectedVehicleList.length === 0 &&
			isVehicleListLoading
		) {
			// get and decode the token from local storage
			const user_id = userData._id.toString();

			getVehicleNames(_token, user_id).then((response: any) => {
				setVehicleList(response.data.items);
				setIsVehicleListLoading(false);
			});
		} else if (vehicleListDetail.selectedVehicleList.length !== 0) {
			let updatedListofVehicles: VehicleListType[] = [];
			vehicleListDetail.selectedVehicleList.forEach((vehicle: any) => {
				const newVehicle: VehicleListType = {
					vehicle_id: vehicle.vehicle_id,
					vehicle: vehicle.vehicle,
					plate_number: vehicle.plate_number,
					vehicle_type: vehicle.vehicle_type,
					vehicle_type_id: vehicle.vehicle_type_id,
					volume: vehicle.volume,
					commodity: vehicle.commodity,
				};
				updatedListofVehicles = [...updatedListofVehicles, newVehicle];
			});
			setVehicleList(updatedListofVehicles);
			setIsVehicleListLoading(false);
		}
	}, [
		_token,
		userData._id,
		isVehicleListLoading,
		vehicleListDetail.selectedVehicleList,
	]);

	const [rateList, setRateList] = useState<RateListType[]>(
		vehicleListDetail.rateList
	);

	useEffect(() => {
		if (rateList.length === 0 && isRatesListLoading) {
			const user_id: string = userData._id.toString();
			getRateListAPI(_token, user_id)
				.then((response: any) => {
					setRateList(response.data.items);
					setIsRatesListLoading(false);
					let nextDefaultRateName = `Rate No ${response.data.items.length + 1}`;
					// set the next vehicle template if logistic creates new rates
					dispatch(setNextRateTemplateName(nextDefaultRateName));
				})
				.catch(() => {
					setIsRatesListLoading(false);
				});
		} else {
			setIsRatesListLoading(false);
		}
	}, [_token, isRatesListLoading, rateList, dispatch, userData._id]);

	// janm: vehicle list inside modal checkbox handler
	const selectionHandler = (vehicle_id: number) => {
		// get the entire vehicle list
		let toSelectVehicle: any = [];
		vehicleList.forEach((vehi: VehicleListType) => {
			if (vehi.vehicle_id === vehicle_id) {
				toSelectVehicle = [...toSelectVehicle, vehi];
			}
		});

		// check if vehicle_id exist in the selectedVehicle
		checkVehicleExist(vehicle_id).then((res) => {
			if (!res && selectedVehicle.length < 3) {
				// if not selected, then add product name to selected array
				let newSelectedVehicle: SelectedVehicleType[] = [];
				newSelectedVehicle = [
					...selectedVehicle,
					{
						vehicle: toSelectVehicle[0],
						fields: "",
					},
				];
				setSelectedVehicle(newSelectedVehicle);
			} else if (res && selectedVehicle.length <= 3) {
				let newSelectedVehicle: SelectedVehicleType[] = [];
				selectedVehicle.forEach((selected_vehicle: SelectedVehicleType) => {
					if (selected_vehicle.vehicle.vehicle_id !== vehicle_id) {
						newSelectedVehicle = [...newSelectedVehicle, selected_vehicle];
					}
				});
				setSelectedVehicle(newSelectedVehicle);
			} else {
				selectionErrorHandler();
			}
		});
	};

	// janm: function that handles the display of the error message in
	// in selecting products beyond 3
	const selectionErrorHandler = () => {
		setIsDisplaySelectionError(true);
		setTimeout(() => {
			setIsDisplaySelectionError(false);
		}, 3000);
	};

	// janm: check if to_select vehicle already exist
	const checkVehicleExist = (vehicle_id: number) => {
		return new Promise((resolve, reject) => {
			resolve(selectedVehicleID.includes(vehicle_id));
		});
	};

	// janm: useEffect hook that extracts the product ids
	// and store at an array if changes detected
	useEffect(() => {
		let vehicleIDArray: number[] = [];
		selectedVehicle.forEach((selected_vehicle: SelectedVehicleType) => {
			vehicleIDArray = [...vehicleIDArray, selected_vehicle.vehicle.vehicle_id];
		});
		setSelectedVehicleID(vehicleIDArray);
	}, [selectedVehicle]);

	//janm: flag for the active nav vehicle
	const [activeSelectVehicle, setActiveSelectVehicle] =
		useState<string>("Item");
	// janm: this array of objects will be passed in the
	// NavTab component for populating the nav items
	const selectVehicleItems = [
		{
			text: "Item",
		},
		{
			text: "Unlisted(0)",
		},
	];
	// janm: flag for the active nav item
	const [activeNav, setActiveNav] = useState<string>("Active");
	// const [isCreateRateLoading, setIsCreateRateLoading] = useState(true);

	const tableHeader = [
		{
			name: "rate_name",
			value: "Rate No.",
			icon: ClipboardIcon,
		},
		{
			name: "vehicle_names",
			value: "Vehicle",
			icon: ClipboardIcon,
		},
		{
			name: "plate_numbers",
			value: "Plate Number",
			icon: ClipboardIcon,
		},
		{
			name: "commodities",
			value: "Commodity",
			icon: ClipboardIcon,
		},
	];

	// For Confirmation table header
	const confirmTableHeader = [
		{
			name: "customer_name",
			value: "Customer Name",
			icon: ClipboardIcon,
		},
		{
			name: "rate_name",
			value: "Rate Name",
			icon: ClipboardIcon,
		},
		{
			name: "vehicle_name",
			value: "Vehicle Name",
			icon: ClipboardIcon,
		},
		{
			name: "plate_number",
			value: "Plate Number",
			icon: ClipboardIcon,
		},
		{
			name: "commodity_name",
			value: "Commodity Name",
			icon: ClipboardIcon,
		},
		{
			name: "delivery_date",
			value: "Delivery Date",
			icon: ClipboardIcon,
		},
	];

	useEffect(() => {
		const user_id = userData._id.toLocaleString();
		getRateConfirmationAPI(_token, user_id).then((response: any) => {
			setConfirmTableDetails(response.data.items);
		});
	}, [_token, userData._id]);

	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	// janm: this array of objects will be passed in the
	// NavTab component for populating the nav items
	const nav_items = [
		{
			text: "Active",
		},
		{
			text: "Inactive",
		},
		{
			text: "Delisted",
		},
		{
			text: "For Confirmation",
		},
	];

	// janm: confirm selection handler function
	const confirmSelectionHandler = () => {
		// declare the base property which will be the basis in sorting the array in alphabet order
		sortSelectedVehicles(selectedVehicle, "alphabet");
		dispatch(setSelected_Vehicles(JSON.stringify(selectedVehicle)));
		navigate("/l/set_details");
	};

	const sortSelectedVehicles = (
		arr: SelectedVehicleType[],
		base_type: string
	) => {
		arr.sort(function (a: any, b: any) {
			var result = 0;
			if (base_type === "alphabet") {
				var textA = a.vehicle.vehicle.toString().toLowerCase();
				var textB = b.vehicle.vehicle.toString().toLowerCase();
				result = textA < textB ? -1 : textA > textB ? 1 : 0;
			} else if (base_type === "numeric") {
				// to be enhance
			}
			return result;
		});
	};

	const dropdown_items: DropdownItemType[] = [
		{
			item_text: "Select Vehicle",
			icon: (
				<TruckIcon
					className="mr-3 h-5 w-5 text-dealogikal-200 group-hover:text-dealogikal-200"
					aria-hidden="true"
				/>
			),
			onClick: () => setIsModalOpen(true),
		},
	];

	const handleRowClick = (rate_details_id: number | string) => {
		navigate(`/l/logistic_rate/${rate_details_id}`);
	};

	const handleConfirmationRowClick = (reverse_auction_id: number | string) => {
		navigate(`/l/reverse_auction/${reverse_auction_id}`);
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Set Rate"
						/>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<NavTabComponent
							key={Math.random()}
							nav_type="primary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
							right_content={
								<>
									{rateList.length > 0 && (
										<DropdownComponent
											items={dropdown_items}
											main_text="Create Rate"
										/>
									)}
								</>
							}
						/>
						<div className="flex flex-wrap justify-center">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "Active" && (
									<>
										{isRatesListLoading ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={1} />
											</div>
										) : (
											<TableComponent
												tableHeader={tableHeader}
												tableData={rateList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="rate_details_id"
												handleRowClick={handleRowClick}
												emptyState={{
													primaryMessage: "No rates available.",
													secondaryMessage:
														"Get started by creating a new rate.",
													buttonText: "Create Rate",
													buttonOnClick() {
														setIsModalOpen(true);
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Inactive" && (
									<>
										{isRatesListLoading ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={1} />
											</div>
										) : (
											<></>
										)}
									</>
								)}
								{activeNav === "Delisted" && (
									<>
										{isRatesListLoading ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={1} />
											</div>
										) : (
											<></>
										)}
									</>
								)}
								{activeNav === "For Confirmation" && (
									<>
										{isRatesListLoading ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={1} />
											</div>
										) : (
											<TableComponentwithbutton
												tableHeader={confirmTableHeader}
												tableData={confirmTableDetails}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="reverse_auction_id"
												handleRowClick={handleConfirmationRowClick}
												handleButtonRowClick={() => navigate("/l/my_orders")}
												emptyState={{
													primaryMessage: "No rates available.",
													secondaryMessage:
														"Get started by creating a new rate.",
													buttonText: "Create Rate",
													buttonOnClick() {
														setIsModalOpen(true);
													},
												}}
											/>
										)}
									</>
								)}
							</div>
						</div>
					</>
				}
			/>

			<ModalComponent
				isOpen={isModalOpen}
				onCloseHandler={() => setIsModalOpen(false)}
				header={
					<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
						<RectangleStackIcon
							className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
							aria-hidden="true"
						/>
						<span className="text-white inline md:text-base text-xs">
							Select Vehicle Type
						</span>
					</div>
				}
				body={
					<>
						<div className="relative rounded-md md:py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
							<input
								type="text"
								name="account-number"
								id="account-number"
								className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
								placeholder="Search product"
							/>
							<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
								<MagnifyingGlassIcon
									className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
									aria-hidden="true"
								/>
							</div>
						</div>
						<div className="md:mx-0 mx-3.5">
							<NavTabComponent
								key={Math.random()}
								nav_type="secondary"
								active_tab={activeSelectVehicle}
								nav_items={selectVehicleItems}
								setActiveNavHandler={setActiveSelectVehicle}
							/>
						</div>
						{isDisplaySelectionError && (
							<div className="md:px-6 px-5 md:mt-5">
								<span className="text-sm text-red-600">
									You can only select up to 3 vehicles
								</span>
							</div>
						)}
						<div className="flex flex-wrap justify-center">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
								{activeSelectVehicle.includes("Item") && (
									<>
										{vehicleList.length !== 0 && (
											<>
												<div className="md:px-6 px-5 justify-center">
													<div className="table w-full pt-6">
														<div className="table-row-group">
															{vehicleList.map((vehicle: VehicleListType) => {
																return (
																	<div
																		key={generateKey(
																			vehicle.vehicle_id.toString() +
																				Math.random()
																		)}
																	>
																		<div className="table-row">
																			<div className="table-cell text-center md:w-1/12">
																				<input
																					id={`cb` + vehicle.vehicle_id}
																					name={`cb` + vehicle.vehicle_id}
																					type="checkbox"
																					checked={selectedVehicleID.includes(
																						vehicle.vehicle_id
																					)}
																					className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
																					value={vehicle.vehicle_id}
																					onChange={(event) =>
																						selectionHandler(
																							parseInt(event.target.value)
																						)
																					}
																				/>
																			</div>
																			<div className="table-cell md:w-10/12">
																				<span className="font-normal  md:text-base text-xs ml-4">
																					<span className="font-extrabold text">
																						{`${vehicle.vehicle}`}
																					</span>
																					<span className="text-gray-500">
																						{` [ ${vehicle.plate_number} ]`}
																					</span>
																				</span>
																			</div>
																		</div>
																		<br />
																	</div>
																);
															})}
														</div>
													</div>
												</div>
												<div className="flex justify-center gap-1">
													<p className="font-extrabold">Note:</p>
													<span className="text-gray-500">
														For vehicles that are not accredited,
														<a
															href="/l/accreditation/all"
															className="text-blue-500 "
														>
															{" "}
															click here
														</a>
													</span>
												</div>
												<div className="text-center md:mt-4 md:mx-0 mx-5">
													<ButtonComponent
														text="Confirm"
														utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 ${
															selectedVehicle.length === 0
																? "disabled:opacity-50 cursor-arrow"
																: "hover:bg-dealogikal-200 cursor-pointer"
														} duration-500 rounded-full font-normal md:w-11/12 w-full`}
														onClick={confirmSelectionHandler}
														disabled={
															selectedVehicle.length === 0 ? true : false
														}
													/>
												</div>
											</>
										)}
										{vehicleList.length === 0 && (
											<>
												<div className="md:px-6 px-5 justify-center">
													<div className="table w-full pt-6">
														<div className="table-row-group">
															<p className="text-gray-500 text-center py-10">You don't have accredited truck. <a href="/l/accreditation/all" className="text-blue-500 cursor-pointer underline-offset-1">Click here to accredit !</a></p>
														</div>
													</div>
												</div>
											</>
										)}
									</>
								)}
							</div>
						</div>
					</>
				}
			/>
		</>
	);
};

export default RateListComp;
