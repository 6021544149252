import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
	generateKey,
	manageBreadcrumb,
} from "../../../../../redux/functions/function";
import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import BreadcrumbComponent from "../../../../templates/BreadcrumbComponent/BreadcrumbComp";
import CircleImageComponent from "../../../../templates/CircleImageComponent/CircleImageComponent";
import OptionsType from "../../../../../model/types/OptionsType";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
  getNextAuctionTemplateName,
	getUtilities,
	setUtilityCommodityTypes,
} from "../../../../../redux/functions/storageSlice";
import { getCommodityTypes } from "../../../../../redux/functions/API";

const SelectCommodityComp = () => {
	const navigate = useNavigate();

	// declare redux utilities
	const dispatch = useAppDispatch();
	const utilities = useAppSelector(getUtilities);

	// declare states
	const [commodityTypes, setCommodityTypes] = useState(
		utilities.commodityTypes
	);

	// check if utilities did not exist from redux
	useEffect(() => {
		// if commodity types is empty
		if (commodityTypes.length === 0)
			getCommodityTypes().then((data: any) => {
				dispatch(setUtilityCommodityTypes(data));
				setCommodityTypes(data);
			});
	}, [dispatch, commodityTypes]);

	// declare the options type
	const options: OptionsType[] = utilities.commodityTypes.map((i) => ({
		...i,
		href: "/b/order_type",
  }));
  
  const nextAuctionTemplateName = useAppSelector(getNextAuctionTemplateName);
	// const [newSubTitle, setNewSubTitle] = useState<string>(
	// 	nextAuctionTemplateName
  // );
  
  	// check if next template name is not empty
	useEffect(() => {
		if (nextAuctionTemplateName === "") {
			navigate("/b/my_auction_templates");
		}
  }, [navigate, nextAuctionTemplateName]);
  
	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							breadcrumb={<BreadcrumbComponent />}
							subtitle="Select Commodities"
						/>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<div className="flex flex-wrap justify-center md:px-2 md:m-6 md:mt-6 mt-8">
							{options.map((opt: OptionsType) => (
								<CircleImageComponent
									key={generateKey(opt.id.toString())}
									content={{ id: opt.id, name: opt.name, icon: opt.icon }}
									event={{
										onClick: () =>
											manageBreadcrumb(opt, () => navigate(opt.href)),
									}}
								/>
							))}
						</div>
					</>
				}
			/>
		</>
	);
};

export default SelectCommodityComp;
