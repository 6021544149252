import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import {
  BeakerIcon,
  CubeIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import ComboboxComponent from "../../../../templates/ComboboxComponent/ComboboxComponent";
import ButtonComponent from "../../../../templates/ButtonComponent/ButtonComponent";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
  getUtilities,
  setUtilityCommodities,
  setUtilityUnits,
  getProductDetails,
  getProductPageAction,
  setUtilityProductList,
} from "../../../../../redux/functions/storageSlice";
import {
  addProduct,
  getCommodities,
  getProductList,
  getUnits,
  updateProduct,
} from "../../../../../redux/functions/API";
import {
  formatNumberWithComma,
  formatNumberWithoutComma,
  getTokenFromLocalStorage,
} from "../../../../../redux/functions/function";
import { TokenModel } from "../../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import Lottie from "lottie-react";
import CircularLoading from "../../../../../lottie/circular_loading_theme_2.json";
import ProductType from "../../../../../model/types/ProductType";
import ToasterComponent from "../../../../templates/ToasterComponent/ToasterComponent";

const AddSingleProductComp: React.FC = () => {
  // declare navigation
  const navigate = useNavigate();

  // declare redux utilities
  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  const productDetails: ProductType = useAppSelector(getProductDetails);
  const productPageAction: string = useAppSelector(getProductPageAction);

  // declare states here...
  const [commodities, setCommodities] = useState(utilities.commodities);
  const [units, setUnits] = useState(utilities.units);
  const [selectedCommodity, setSelectedCommodity]: any = useState(null);
  const [selectedUnit, setSelectedUnit]: any = useState(null);
  const [stock, setStock] = useState(productDetails.stocks);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [isLoadingAPI, setIsLoadingAPI] = useState(false);
  const [errorState, setErrorState] = useState({
    commodity_id: false,
    unit_id: false,
    stock: false,
  });
  const [isEdit, setIsEdit] = useState(false);
  const [isUpdatingStocks, setIsUpdatingStocks] = useState<boolean>(false);
  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("");

  // useEffect hook that will check the redux state productDetails if empty or not
  // if empty, ---> add product
  // else, ----> edit product
  useEffect(() => {
    if (productDetails.product_id === 0 && productPageAction === "add") {
      setIsEdit(false);
    } else if (
      productDetails.product_id === 0 &&
      productPageAction !== "edit"
    ) {
      navigate("/my_products");
    } else {
      setIsEdit(true);
    }
  }, [productDetails, productPageAction, navigate]);

  // decode the token
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (commodities.length === 0)
      getCommodities().then((data: any) => {
        dispatch(setUtilityCommodities(data));
        setCommodities(data);
      });
  }, [dispatch, commodities]);

  // check if utilities did not exist from redux
  useEffect(() => {
    // if units is empty
    if (units.length === 0)
      getUnits().then((data: any) => {
        dispatch(setUtilityUnits(data));
        setUnits(data);
      });
  }, [dispatch, units]);

  // when commodity dropbox is changed
  useEffect(() => {
    if (selectedCommodity !== null)
      setErrorState((prev) => ({ ...prev, commodity_id: false }));
    if (selectedUnit !== null)
      setErrorState((prev) => ({ ...prev, unit_id: false }));
    if (stock !== "0") setErrorState((prev) => ({ ...prev, stock: false }));
  }, [selectedCommodity, selectedUnit, stock]);

  // create a handler for adding product
  const handlerAddProduct = () => {
    setIsUpdatingStocks(true);
    if (isEdit === false) {
      // do the validation
      if (selectedCommodity === null)
        setErrorState((prev) => ({ ...prev, commodity_id: true }));
      if (selectedUnit === null)
        setErrorState((prev) => ({ ...prev, unit_id: true }));
      if (stock === "0") setErrorState((prev) => ({ ...prev, stock: true }));

      // if all fields are valid
      if (
        selectedCommodity !== null &&
        selectedUnit !== null &&
        stock !== "0"
      ) {
        // declare payload
        const payload = {
          commodity_id: selectedCommodity.id,
          unit_id: selectedUnit.id,
          supplier_id: userData._id,
          stocks: parseInt(stock),
        };
        setIsUpdatingStocks(true);
        // disable the button
        setIsButtonDisabled(true);
        // load the API
        setIsLoadingAPI(true);

        // then call the add product function
        addProduct(payload).then((success) => {
          if (success) {
            // refetch the data to get the updated list of products
            const supplier_id: string = userData._id.toString();
            getProductList(_token, 2, supplier_id).then((data: any) => {
              // add stocksWithUnits field to an array
              const finalData = data.map((i: ProductType) => ({
                ...i,
                stocksWithUnits: `${formatNumberWithComma(i.stocks)} ${
                  i.unit_name
                }`,
              }));
              dispatch(setUtilityProductList(finalData));

              setToastMessage(`Successfully added Product`);
              setToastType("success");
              setIsToastOpen(true);
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000);
              setTimeout(() => {
                setIsLoadingAPI(false);
                navigate("/my_products");
              }, 4000);
            });
          }
        });
      }
    } else {
      // Edit product/stocks scenario
      if (productDetails.product_id !== 0 && isEdit) {
        const updatedStock = parseInt(stock);
        // Convert productDetails.stocks to a number for comparison
        const currentStock = parseInt(productDetails.stocks);

        // Ensure udpated stock value is not less than the current stock
        if (updatedStock < currentStock) {
          // Handle the error scenario here (e.g., show an error message)
          setErrorState((prevState) => ({
            ...prevState,
            stock: true,
          }));
          setIsUpdatingStocks(false);
          setToastMessage(
            "Updated stocks should not be less than current stocks"
          );
          setToastType("error");
          setIsToastOpen(true);
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
          return;
        }
        // if there is no changes disabled the button
        if (updatedStock === currentStock) {
          // Handle the error scenario here (e.g., show an error message)
          setIsUpdatingStocks(false);
          setToastMessage("You did not change any product");
          setToastType("error");
          setIsToastOpen(true);
          setTimeout(() => {
            setIsToastOpen(false);
          }, 3000);
          return;
        }
        // Declare payload for updating the product
        const updatePayload = {
          product_id: productDetails.product_id.toString(),
          supplier_id: userData._id.toString(),
          stocks: parseInt(stock),
        };
        setIsUpdatingStocks(true);
        // disable the button
        setIsButtonDisabled(true);
        // load the API
        setIsLoadingAPI(true);
        // Call the updateProduct function with the updatePayload
        updateProduct(_token, updatePayload)
          .then((response: any) => {
            if (response) {
              setToastMessage(
                `${productDetails.commodity_name} was successfully updated.`
              );
              setToastType("success");
              setIsToastOpen(true);
              setTimeout(() => {
                setIsToastOpen(false);
              }, 3000);
              setTimeout(() => {
                setIsLoadingAPI(false);
                navigate("/my_products");
              }, 4000);
            }
            return;
          })
          .catch((error) => {
            // Handle error if the update fails
          });
      }
    }
  };

  const navigateToShippingDetails = () => {};

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <ToasterComponent
              isOpen={isToastOpen}
              label={toastMessage}
              onClose={setIsToastOpen}
              type={toastType}
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle={isEdit ? "Update Single Product" : "Add Single Product"}
            />

            <div className="flex flex-wrap justify-center mt-2">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-4 md:pl-6 md:w-2/5">
                <label className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6">
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Commodity</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={errorState.commodity_id ? true : false}
                    items={commodities}
                    placeholder={
                      isEdit && productDetails.commodity_name
                        ? `${productDetails.commodity_name}`
                        : "Select product"
                    }
                    selectedItem={selectedCommodity}
                    setSelectedItem={setSelectedCommodity}
                    isDisabled={isEdit}
                  />
                </div>
                {errorState.commodity_id && (
                  <div className="m-2 ml-6 flex">
                    <p className="text-red-600 text-xs md:text-sm">
                      Please select a commodity.
                    </p>
                  </div>
                )}

                <label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
                  <div className="flex gap-2">
                    <BeakerIcon className="-ml-0.5 h-5 w-5" />
                    <span>Units</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={errorState.unit_id ? true : false}
                    items={units}
                    placeholder={
                      isEdit && productDetails.unit_name
                        ? `${productDetails.unit_name}`
                        : "Select unit measurement"
                    }
                    selectedItem={selectedUnit}
                    setSelectedItem={setSelectedUnit}
                    isDisabled={isEdit}
                  />
                </div>
                {errorState.unit_id && (
                  <div className="m-2 ml-6 flex">
                    <p className="text-red-600 text-xs md:text-sm">
                      Please select a unit.
                    </p>
                  </div>
                )}

                <label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
                  <div className="flex gap-2">
                    <Square3Stack3DIcon className="-ml-0.5 h-5 w-5" />
                    <span>Stock</span>
                  </div>
                </label>
                <div className="mt-2">
                  <input
                    type="text"
                    name="txtlocation"
                    id="txtlocation"
                    placeholder="Set range of stock"
                    value={formatNumberWithComma(stock)}
                    onChange={(val: any) =>
                      setStock(
                        formatNumberWithoutComma(val.target.value.toString())
                      )
                    }
                    className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6 ${
                      errorState.stock
                        ? "ring-1 ring-inset ring-red-600"
                        : "ring-1 ring-inset ring-gray-300"
                    }`}
                  />
                </div>
                {errorState.stock && (
                  <div className="m-2 ml-6 flex">
                    <p className="text-red-600 text-xs md:text-sm">
                      {isEdit
                        ? "Updated stocks should not be less than current stocks."
                        : "Please put a number of stocks."}
                    </p>
                  </div>
                )}

                <ButtonComponent
                  text={`${
                    isUpdatingStocks
                      ? "Updating Product "
                      : isEdit
                      ? "Update Product"
                      : isButtonDisabled
                      ? "Saving Product"
                      : "Save Product"
                  }`}
                  icon={
                    isLoadingAPI && (
                      <Lottie
                        className="md:w-5 w-5 h-auto"
                        animationData={CircularLoading}
                        loop={true}
                      />
                    )
                  }
                  disabled={isButtonDisabled}
                  utils={`bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-4 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-10 ${
                    isUpdatingStocks ? "cursor-not-allowed opacity-50" : ""
                  }`}
                  onClick={handlerAddProduct}
                />
                <ButtonComponent
                  text="Proceed to Offer Info"
                  utils="bg-dealogikal-100 text-white text-sm shadow-sm py-4 sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full mt-4"
                  onClick={navigateToShippingDetails}
                />
              </div>
              <div className="flex flex-col w-full md:pt-10 md:pl-8 md:w-3/5"></div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};

export default AddSingleProductComp;
