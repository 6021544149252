import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";

export interface SlideOverModel {
  slideOver: boolean;
  setSlideOver: any;
  hasOverlay?: boolean;
  header?: React.ReactNode;
  bodyContent: React.ReactNode;
  footer?: React.ReactNode;
}

export default function SlideOverComponent(props: SlideOverModel) {
  return (
    <Transition.Root show={props.slideOver} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => props.setSlideOver(false)}
      >
        {props.hasOverlay && (
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
        )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-2xl">
                  <form className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                    <div className="flex-1">
                      {/* Header */}
                      {props.header}
                      {/* Divider container */}
                      <div className="space-y-6 py-6 sm:space-y-0 sm:divide-y sm:divide-gray-200 sm:py-0">
                        {/* slideover body content */}
                        {props.bodyContent}
                      </div>
                    </div>

                    {/* Action buttons / Footer */}
                    {props.footer}
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
