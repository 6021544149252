import { useNavigate } from "react-router-dom";
import { useState, useRef, useEffect } from "react";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import RemarksComp from "../../../templates/RemarkComponent/RemarksComp";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import NearbyPartyDetailsComp from "../../../templates/NearbyPartyDetailsComponent/NearbyPartyDetailsComp";
import ChipsComponent from "../../../templates/ChipsComponent/ChipsComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import {
	CubeIcon,
	RectangleStackIcon,
	TruckIcon,
	MapPinIcon,
	ArrowLeftIcon,
} from "@heroicons/react/24/outline";
import { generateKey } from "../../../../redux/functions/function";
import buyersNearby from "./NearbyBuyersData";
import { useAppSelector, useAppDispatch } from "../../../../redux/app/hooks";
import {
	getBuyCommodityDetails,
	getCreateOfferFields,
	getUtilities,
	setBuyerShippingDetails,
	setDeliveryLocation,
	setDeliveryLocationList,
	setMunicipalDeliveryLocation,
	setMunicipalityDeliveryLocationList,
	setOfferName,
	setSelected_Products,
	setShippingMethodID,
	setShippingMethods,
} from "../../../../redux/functions/storageSlice";
import SelectedProductsType from "../../../../model/types/SelectedProductsType";
import {
	getMunicipalitiesAPI,
	getPhilippineIslandsAPI,
	getShippingMethods,
} from "../../../../redux/functions/API";
import ShippingMethodModel from "../../../../model/interface/ShippingMethodModel";
import ComboboxComponent from "../../../templates/ComboboxComponent/ComboboxComponent";
import DeliveryLocationModel from "../../../../model/interface/DeliveryLocationModel";

const AddProductOrOfferComp: React.FC = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const createOfferFields = useAppSelector(getCreateOfferFields);

	const utilities = useAppSelector(getUtilities);
	const buyCommodityDetails = useAppSelector(getBuyCommodityDetails);
	const shippingDetails = buyCommodityDetails.shippingDetails;
	const _token = getTokenFromLocalStorage() ?? "";
	const [isloading, setIsLoading] = useState<boolean>(false);
	const [isBuyerFound, setIsBuyerFound] = useState<boolean>(false);
	const [isRemarkModalOpen, setIsRemarkModalOpen] = useState<boolean>(false);

	const [listOfShippingMethods, setListOfShippingMethods] = useState<
		ShippingMethodModel[]
	>([]);
	const [listOfDeliveryLocations, setListOfDeliveryLocations] = useState<
		DeliveryLocationModel[]
	>(utilities.deliveryLocations);
	const [isCompleteAddressEmpty, setIsCompleteAddressEmpty] =
		useState<boolean>(false);
	const [listOfMunicipalityLocations, setListOfMunicipalityLocations] =
		useState<DeliveryLocationModel[]>(utilities.municipalityDeliveryLocations);
	const [isOfferNameEmpty, setIsOfferNameEmpty] = useState<boolean>(false);
	const [isShippingMethodEmpty, setIsShippingMethodEmpty] =
		useState<boolean>(false);
	const [isDeliveryLocationEmpty, setIsDeliveryLocationEmpty] =
		useState<boolean>(false);
	const [isShippingDetailsEmpty, setIsShippingDetailsEmpty] =
		useState<boolean>(false);
	const [isEffectivityDateEmpty, setIsEffectivityDateEmpty] =
		useState<boolean>(false);
	const [isEffectivityDateInvalid, setIsEffectivityDateInvalid] =
		useState<boolean>(false);

	// cja: useEffect hook Purpose: to check the data from redux storage
	// if no data, it means, page was being refresh,
	// then navigate to offer list(previous page)
	useEffect(() => {
		createOfferFields.selectedProducts === "" && navigate("/my_offers");
	}, [navigate, createOfferFields.selectedProducts]);

	const foundSupplierHandler = () => {
		setIsLoading(false);
		setIsBuyerFound(true);
	};

	const findingBuyer = () => {
		setIsLoading(true);
		setIsBuyerFound(false);
		setTimeout(() => {
			foundSupplierHandler();
		}, 3000);
	};

	// cja: handle enter key and remove focus to fetch nearby suppliers
	const inputDeliveryLocation = useRef<HTMLInputElement>(null);
	const handleEnterKey = (e: any) => {
		if (e.keyCode === 13) {
			inputDeliveryLocation.current?.blur();
			findingBuyer();
		}
	};

	const removeProductChipHandler = (product_id: number) => {
		removeProduct(product_id).then((new_selected_arr) => {
			dispatch(setSelected_Products(JSON.stringify(new_selected_arr)));
		});
	};

	// cja: remove chips function
	const removeProduct = (product_id: number) => {
		return new Promise((resolve, reject) => {
			let updatedSelectedItemsArray: SelectedProductsType[] = [];
			createOfferFields.selectedProducts !== "" &&
				JSON.parse(createOfferFields.selectedProducts).forEach(
					(selected_product: SelectedProductsType) => {
						if (selected_product.product.product_id !== product_id) {
							updatedSelectedItemsArray = [
								...updatedSelectedItemsArray,
								selected_product,
							];
						}
					}
				);
			resolve(updatedSelectedItemsArray);
		});
	};

	useEffect(() => {
		if (utilities.shippingMethods.length === 0) {
			getShippingMethods(_token).then((data: any) => {
				dispatch(setShippingMethods(data));
			});
		} else {
			let updatedShippingMethods: ShippingMethodModel[] = [];
			utilities.shippingMethods.length !== 0 &&
				utilities.shippingMethods.forEach((shipping_method: any) => {
					updatedShippingMethods = [...updatedShippingMethods, shipping_method];
				});
			setListOfShippingMethods(updatedShippingMethods);
		}
	}, [utilities.shippingMethods, dispatch, _token]);

	// cja: this hook will handle in fetching the municipalities from utilities and
	// store to local state to be populated in the municipality dropdown selection field
	useEffect(() => {
		if (utilities.deliveryLocations.length !== 0) {
			let newDeliveryLocations: DeliveryLocationModel[] = [];
			utilities.deliveryLocations.forEach((location: any) => {
				let singleLocation: DeliveryLocationModel = { id: 0, name: "" };
				// commented for now (for enhancement)
				if (createOfferFields.offerFields.shippingMethodID === "1") {
					singleLocation = {
						id: location.id,
						name: location.location,
					};
				} else if (createOfferFields.offerFields.shippingMethodID === "2") {
					singleLocation = {
						id: location.id,
						name: location.island,
					};
				}
				newDeliveryLocations = [...newDeliveryLocations, singleLocation];
			});
			setListOfDeliveryLocations(newDeliveryLocations);
		}

		let newMunicipalityDeliveryLocations: DeliveryLocationModel[] = [];
		utilities.municipalityDeliveryLocations.forEach((location: any) => {
			let singleLocation: DeliveryLocationModel = { id: 0, name: "" };
			singleLocation = {
				id: location.id,
				name: location.location,
			};
			newMunicipalityDeliveryLocations = [
				...newMunicipalityDeliveryLocations,
				singleLocation,
			];
		});
		setListOfMunicipalityLocations(newMunicipalityDeliveryLocations);
	}, [
		utilities.deliveryLocations,
		utilities.municipalityDeliveryLocations,
		createOfferFields.offerFields.shippingMethodID,
	]);

	// cja: onChange handler of the create offer fields
	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtoffername":
				dispatch(setOfferName(value));
				break;
			case "txtshippingmethod":
				dispatch(setShippingMethodID(value));
				break;
			case "txtlocation":
				dispatch(setDeliveryLocation(value));
				break;
			case "txtmunicipallocation":
				dispatch(setMunicipalDeliveryLocation(value));
				break;
			case "txtshippingdetails":
				dispatch(setBuyerShippingDetails(value));
				break;
			default:
				break;
		}
	};

	const renderLocationSubLabel = () => {
		/*
      shippingMethodID Legend:
        1 - Delivery
        2 - Pick-up
    */
		// return "(Cebu Municipality)";
		if (createOfferFields.offerFields.shippingMethodID === "1") {
			return "(Cebu Municipality)";
		} else if (createOfferFields.offerFields.shippingMethodID === "2") {
			return "(Island)";
		} else {
			return "";
		}
	};

	// cja: this hook will check if the shipping method is delivery or pick-up
	useEffect(() => {
		// 1 - delivery; 2 - pick-up
		// if delivery, then switch the delivery location into municipalities
		// if pick-up, then switch the delivery location into philippine islands
		if (createOfferFields.offerFields.shippingMethodID !== "") {
			let shippingMethod = createOfferFields.offerFields.shippingMethodID;

			if (shippingMethod === "2") {
				getPhilippineIslandsAPI(_token)
					.then((data: any) => {
						dispatch(setDeliveryLocationList(data));
					})
					.catch((err: any) => console.log(err));
			} else {
				getMunicipalitiesAPI(_token, 2)
					.then((data: any) => {
						dispatch(setMunicipalityDeliveryLocationList(data));
					})
					.catch((err: any) => console.log(err));
			}
		}
	}, [_token, dispatch, createOfferFields.offerFields.shippingMethodID]);

	// jar: the api municipality will call only after user select island id
	useEffect(() => {
		if (
			createOfferFields.offerFields.shippingMethodID !== "" &&
			createOfferFields.offerFields.deliveryLocation.name !== ""
		) {
			let selectedIslandID: any =
				createOfferFields.offerFields.deliveryLocation.id;

			getMunicipalitiesAPI(_token, selectedIslandID)
				.then((data: any) => {
					dispatch(setMunicipalityDeliveryLocationList(data));
				})
				.catch((err: any) => console.log(err));

			// Clear municipalityLocation when DeliveryLocation changes
			dispatch(setMunicipalDeliveryLocation({ id: 0, name: "" }));
		}
	}, [
		_token,
		dispatch,
		createOfferFields.offerFields.shippingMethodID,
		createOfferFields.offerFields.deliveryLocation.name,
		createOfferFields.offerFields.deliveryLocation.id,
	]);

	// janm: it will clear the selected location everytime user select again another shipping method
	useEffect(() => {
		if (createOfferFields.offerFields.shippingMethodID !== "") {
			let shippingMethodID = createOfferFields.offerFields.shippingMethodID;

			if (shippingMethodID !== null) {
				dispatch(setDeliveryLocation({ id: 0, name: "" }));
				dispatch(setMunicipalDeliveryLocation({ id: 0, name: "" }));
			}
		}
	}, [createOfferFields.offerFields.shippingMethodID, dispatch]);

	// cja: proceed to variation click handler
	const proceedClickHandler = () => {
		clearErrors();
		if (
			checkEmptyFields("offer_name", createOfferFields.offerFields.offerName) ||
			checkEmptyFields(
				"shipping_method",
				createOfferFields.offerFields.shippingMethodID
			) ||
			checkEmptyFields(
				"delivery_location",
				createOfferFields.offerFields.deliveryLocation.id
			)
		) {
		} else {
			navigate("/s/add_product_offer_variation");
		}
	};
	const clearErrors = () => {
		setIsOfferNameEmpty(false);
		setIsShippingMethodEmpty(false);
		setIsDeliveryLocationEmpty(false);
		setIsCompleteAddressEmpty(false);
		setIsShippingDetailsEmpty(false);
		setIsEffectivityDateInvalid(false);
	};

	// cja: offer field validations (start)
	// validation 1: empty field validation
	const checkEmptyFields = (caller: string, value: string | number) => {
		switch (caller) {
			case "offer_name":
				value === "" && setIsOfferNameEmpty(true);
				return value === "" ? true : false;
			case "shipping_method":
				return value === "" ? true : false;
			case "delivery_location":
				value === "" && setIsDeliveryLocationEmpty(true);
				return value === "" ? true : false;
			case "pickup_location":
				value === "" && setIsCompleteAddressEmpty(true);
				return value === "" ? true : false;
			case "pickup_details":
				value === "" && setIsShippingDetailsEmpty(true);
				return value === "" ? true : false;
			case "effectivity_date":
				value === "" && setIsEffectivityDateEmpty(true);
				return value === "" ? true : false;
			default:
				break;
		}
	};

	// cja: offer field validations (end)
	const renderErrorMessage = (errorType: string) => {
		return (
			<div className={`flex flex-wrap justify-center mt-6`}>
				<div className="flex flex-col w-full md:w-full">
					<span className="text-red-600 text-sm text-center">
						{errorType === "empty" && "Please fill in the empty fields."}
						{errorType === "date_invalid" &&
							"Please make sure that effectivity date is ahead of the current date."}
					</span>
				</div>
			</div>
		);
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Create New Offer"
						/>
						{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
						<div className="flex flex-wrap justify-center">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:pl-8 md:pr-10 md:w-1/2">
								<RemarksComp
									customClasses="md:hidden block mt-6"
									main_message="8 buyers nearby."
									isLoading={isloading}
									isFound={isBuyerFound}
									isViewDetailsClicked={() => setIsRemarkModalOpen(true)}
								/>
								<label
									htmlFor="txtoffername"
									className="block text-sm font-medium leading-6 text-gray-900 mt-2"
								>
									<div className="flex gap-2">
										<RectangleStackIcon className="-ml-0.5 h-5 w-5" />
										<span>Offer Name</span>
									</div>
								</label>
								<div className="mt-2">
									<input
										type="text"
										name="txtoffername"
										id="txtoffername"
										value={createOfferFields.offerFields.offerName}
										placeholder="Set offer name"
										className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ${
											!isOfferNameEmpty
												? "ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200"
												: "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
										} placeholder:text-gray-400 text-sm sm:leading-6`}
										onChange={(e) =>
											fieldChangeHandler(e.target.value, "txtoffername")
										}
									/>
								</div>

								<label
									htmlFor="txtshippingmethod"
									className="block text-sm font-medium leading-6 text-gray-900 md:mt-5 mt-6"
								>
									<div className="flex gap-2">
										<TruckIcon className="-ml-0.5 h-5 w-5" />
										<span>Shipping Method</span>
									</div>
								</label>
								<div className="mt-2">
									<select
										id="txtshippingmethod"
										name="txtshippingmethod"
										value={createOfferFields.offerFields.shippingMethodID}
										className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ${
											!isShippingMethodEmpty
												? "ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200"
												: "ring-1 ring-inset ring-red-400 focus:ring-1 focus:ring-inset focus:ring-red-600"
										} text-sm sm:leading-6`}
										onChange={(e) =>
											fieldChangeHandler(e.target.value, "txtshippingmethod")
										}
									>
										<option value="">Select shipping method</option>
										{listOfShippingMethods.map((shipping_method: any) => {
											return (
												<option
													key={shipping_method.id}
													value={shipping_method.id}
												>
													{shipping_method.name}
												</option>
											);
										})}
									</select>
								</div>

								{createOfferFields.offerFields.shippingMethodID !== "2" && (
									<>
										<label
											htmlFor="txtlocation"
											className="block text-sm font-medium leading-6 text-gray-900 mt-6"
										>
											<div className="flex gap-2">
												<MapPinIcon className="-ml-0.5 h-5 w-5" />
												<span>Offer Coverage {renderLocationSubLabel()}</span>
											</div>
										</label>
										<div className="mt-2">
											<ComboboxComponent
												hasError={isDeliveryLocationEmpty}
												items={listOfMunicipalityLocations}
												placeholder="Select Delivery Location"
												selectedItem={
													createOfferFields.offerFields
														.municipalDeliveryLocation
												}
												setSelectedItem={(e: DeliveryLocationModel) =>
													fieldChangeHandler(e, "txtmunicipallocation")
												}
												onBlur={findingBuyer}
												onKeyUp={handleEnterKey}
											/>
											{isDeliveryLocationEmpty && (
												<div className="ml-6 flex mt-3">
													<p className="text-red-600 text-xs md:text-sm">
														Delivery location field is required
													</p>
												</div>
											)}
										</div>
										<label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
											<div className="flex gap-2">
												<CubeIcon className="-ml-0.5 h-5 w-5" />
												<span>Selected Items</span>
											</div>
										</label>
										<div>
											{createOfferFields.selectedProducts !== "" &&
												JSON.parse(createOfferFields.selectedProducts).map(
													(selected_product: SelectedProductsType) => {
														return (
															<ChipsComponent
																key={generateKey(
																	Math.random.toString() +
																		selected_product.product.product_id
																)}
																text={selected_product.product.commodity_name}
																removeHandler={() =>
																	removeProductChipHandler(
																		selected_product.product.product_id
																	)
																}
															/>
														);
													}
												)}
										</div>
									</>
								)}

								{createOfferFields.offerFields.shippingMethodID === "2" && (
									<>
										<label
											htmlFor="txtlocation"
											className="block text-sm font-medium leading-6 text-gray-900 mt-6"
										>
											<div className="flex gap-2">
												<MapPinIcon className="-ml-0.5 h-5 w-5" />
												<span>Offer Coverage {renderLocationSubLabel()}</span>
											</div>
										</label>
										<div className="mt-2">
											<ComboboxComponent
												hasError={isDeliveryLocationEmpty}
												items={listOfDeliveryLocations}
												placeholder="Select Delivery Location"
												selectedItem={
													createOfferFields.offerFields.deliveryLocation
												}
												setSelectedItem={(e: DeliveryLocationModel) =>
													fieldChangeHandler(e, "txtlocation")
												}
												onBlur={findingBuyer}
												onKeyUp={handleEnterKey}
											/>
											{isDeliveryLocationEmpty && (
												<div className="ml-6 flex mt-3">
													<p className="text-red-600 text-xs md:text-sm">
														Delivery location field is required
													</p>
												</div>
											)}
										</div>
										<label
											htmlFor="txtmunicipallocation"
											className="block text-sm font-medium leading-6 text-gray-900 mt-6"
										>
											<div className="flex gap-2">
												<MapPinIcon className="-ml-0.5 h-5 w-5" />
												<span>Depot location (Municipality)</span>
											</div>
										</label>
										<div className="w-full h-auto bg-gray-100 py-2 pb-4 px-2 mt-3 rounded-lg">
											<div className="mt-2">
												<ComboboxComponent
													hasError={isCompleteAddressEmpty}
													items={listOfMunicipalityLocations}
													placeholder="Select Delivery Location"
													selectedItem={
														createOfferFields.offerFields
															.municipalDeliveryLocation
													}
													setSelectedItem={(e: DeliveryLocationModel) =>
														fieldChangeHandler(e, "txtmunicipallocation")
													}
													onBlur={findingBuyer}
													onKeyUp={handleEnterKey}
												/>
											</div>
											{isCompleteAddressEmpty && (
												<div className="ml-6 flex mt-3">
													<p className="text-red-600 text-xs md:text-sm">
														Delivery location field is required
													</p>
												</div>
											)}
											<div className="mt-2">
												<textarea
													id="txtshippingdetails"
													name="txtshippingdetails"
													value={shippingDetails.delivery_location_details}
													placeholder="Indicate delivery location details"
													rows={3}
													className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
														isShippingDetailsEmpty ? "red-600" : "gray-300"
													} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
													onChange={(e) =>
														fieldChangeHandler(
															e.target.value,
															"txtshippingdetails"
														)
													}
												/>
											</div>
											{isShippingDetailsEmpty && (
												<div className="ml-6 flex mt-3">
													<p className="text-red-600 text-xs md:text-sm">
														Pick-up Location Details is required
													</p>
												</div>
											)}
										</div>
									</>
								)}

								{(isOfferNameEmpty === true ||
									isShippingMethodEmpty === true ||
									isDeliveryLocationEmpty === true ||
									isEffectivityDateEmpty === true) &&
									renderErrorMessage("empty")}
								{isEffectivityDateInvalid === true &&
									renderErrorMessage("date_invalid")}

								<ButtonComponent
									text="Proceed to variation"
									utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-7 mt-2 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full"
									onClick={proceedClickHandler}
								/>
							</div>
							<div className="flex flex-col w-full md:pl-8 md:w-1/2">
								<RemarksComp
									customClasses="md:block hidden mt-6"
									main_message="8 buyers nearby."
									isLoading={isloading}
									isFound={isBuyerFound}
									isViewDetailsClicked={() => setIsRemarkModalOpen(true)}
								/>
							</div>
						</div>
					</>
				}
			/>
			<ModalComponent
				isOpen={isRemarkModalOpen}
				onCloseHandler={() => setIsRemarkModalOpen(false)}
				header={
					<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-2 px-4 text-center">
						<ArrowLeftIcon
							className="-ml-0.5 md:h-5 md:w-5 h-4 w-4 text-white inline float-left"
							onClick={() => setIsRemarkModalOpen(false)}
						/>
						<span className="text-white inline md:text-base text-sm">
							Buyers Nearby
						</span>
					</div>
				}
				body={
					<>
						<div className="py-8 px-6">
							{buyersNearby.map((buyer) => (
								<NearbyPartyDetailsComp details={buyer} key={buyer.id} />
							))}
						</div>
					</>
				}
			/>
		</>
	);
};
export default AddProductOrOfferComp;
