import { useEffect, useState } from "react";
// import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
// import { getNextRateTemplateName, getVehiclesDetails, setNextRateTemplateName, setSelected_Vehicles } from "../../../../../redux/functions/storageSlice";
// import SelectedVehicleType from "../../../../../model/types/SelectedVehicleType";
import { TruckIcon } from "@heroicons/react/24/outline";
import { useParams } from "react-router-dom";
import ChipsComponent from "../../../../templates/ChipsComponent/ChipsComponent";
import {
  generateKey,
  getTokenFromLocalStorage,
} from "../../../../../redux/functions/function";
import VehicleListType from "../../../../../model/types/VehicleListType";
import { getAllAddTruckDetailsAPI } from "../../../../../redux/functions/API";

const VehicleDetailsComponent = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";

  // const dispatch = useAppDispatch();
  // const createRateFields = useAppSelector(getVehiclesDetails);

  const { rate_details_id } = useParams<string>();
  const [vehicleList, setVehicleList] = useState<VehicleListType[]>([]);

  useEffect(() => {
    getAllAddTruckDetailsAPI(_token, rate_details_id ?? "").then(
      (response: any) => {
        setVehicleList(response.data.items);
      }
    );
  }, [_token, rate_details_id]);

  const removeProductChipHandler = (vehicle_id: number) => {
    removeVehicle(vehicle_id).then((new_selected_arr) => {
      // dispatch(setSelected_Vehicles(JSON.stringify(new_selected_arr)));
    });
  };

  // remove vehicle
  const removeVehicle = (vehicle_id: number) => {
    return new Promise((resolve, reject) => {
      // let updatedSelectedItemsArray: SelectedVehicleType[] = [];
      // createRateFields.selectedVehicles !== "" &&
      // JSON.parse(createRateFields.selectedVehicles).forEach(
      // (selected_vehicle: SelectedVehicleType) => {
      //     if (selected_vehicle.vehicle.vehicle_id !== vehicle_id) {
      //     updatedSelectedItemsArray = [
      //         ...updatedSelectedItemsArray,
      //         selected_vehicle,
      //     ];
      //     }
      // }
      // );
      // resolve(updatedSelectedItemsArray);
    });
  };

  return (
    <>
      <label className="block text-lg font-medium leading-6 text-gray-900 mt-6">
        <div className="flex gap-2">
          <TruckIcon className="-ml-0.5 h-5 w-5" />
          <span>Selected Vehicles</span>
        </div>
      </label>

      <div className="items-center content-center mt-2 gap-2 w-11/12 md:w-7/12 lg:w-6/12 ">
        <div className="grid md:grid-cols-2">
          {vehicleList !== undefined &&
            vehicleList.map((selected_vehicle: any) => {
              return (
                <ChipsComponent
                  key={generateKey(
                    Math.random.toString() + selected_vehicle.vehicle_id
                  )}
                  text={`${selected_vehicle.vehicle_name} [ ${selected_vehicle.plate_number}]`}
                  removeHandler={() => {
                    removeProductChipHandler(selected_vehicle.vehicle_id);
                  }}
                />
              );
            })}
        </div>
        {/* <span className="flex justify-center mt-2">
            <PlusCircleIcon 
                className="h-12 w-16 text-dealogikal-100"
                onClick={toggleModal}
            />
            </span> */}
      </div>
    </>
  );
};

export default VehicleDetailsComponent;
