import { Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import SnackBarType from "../../../model/types/SnackBarType";
import { useNavigate } from "react-router-dom";

interface SnackBarComponentModel {
  snackbarList: SnackBarType[];
}

const SnackBarComponent: React.FC<SnackBarComponentModel> = (props) => {
  const navigate = useNavigate();

  return (
    <>
      {/* Global notification live region, render this permanently at the end of the document */}
      <div
        aria-live="assertive"
        className="pointer-events-none fixed inset-0 flex items-end px-4 py-6 sm:items-start sm:p-6 z-50"
      >
        <div className="flex w-full flex-col items-center space-y-4 sm:items-end">
          {/* Notification panel, dynamically insert this into the live region when it needs to be displayed */}

          {props.snackbarList.map((snackbar: SnackBarType, index: number) => {
            return (
              <Fragment key={index}>
                <Transition
                  show={snackbar.isVisible}
                  as={Fragment}
                  enter="transform ease-out duration-300 transition"
                  enterFrom="translate-x-2 opacity-0 sm:translate-x-20"
                  enterTo="translate-x-0 opacity-100 sm:translate-x-0"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div
                    className={`pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-xl ring-1 ring-black ring-opacity-5 ${
                      index === 0 ? "mt-12" : ""
                    }`}
                  >
                    <div className="p-4">
                      <div className="flex items-start">
                        <div className="flex-shrink-0">
                          {snackbar.icon && (
                            <snackbar.icon
                              className="h-6 w-6 text-gray-400"
                              aria-hidden="true"
                            />
                          )}
                        </div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                          {/* render snackbar title here */}
                          {snackbar.title}
                          {/* render snackbar main content here */}
                          {snackbar.content}

                          {/* cja: only render this button if snackbar.title value is "Book Confirmed".*/}
                          {snackbar.title === "Book Confirmed" && (
                            <div className="mt-3 text-center space-x-7">
                              <button
                                type="button"
                                className="rounded-md bg-dealogikal-200 py-2 w-11/12 mt-2 text-sm text-center font-medium text-white hover:bg-dealogikal-50 focus:outline-none"
                                onClick={() => navigate(snackbar.href)}
                              >
                                Proceed to Payment
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="ml-4 flex flex-shrink-0">
                          <button
                            type="button"
                            className="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={snackbar.snackbarCloseHandler!}
                          >
                            <span className="sr-only">Close</span>
                            <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </Transition>
              </Fragment>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default SnackBarComponent;
