import { useEffect, useState } from "react";
import DashboardCardComponent2Light from "../../../templates/CardComponent/DashboardCardComponent2Light";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { getOverviewDashboardAPI, getPreOrderAPI } from "../../../../redux/functions/API";
import jwt_decode from "jwt-decode";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import Skeleton from "react-loading-skeleton"; // Import the Skeleton component

const ShipmentStatusComponent = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const [isDasboardOverviewFetching, setIsDasboardOverviewFetching] =
    useState<boolean>(true);
  const [overviewData, setOverviewData] = useState<any[]>([]);

  useEffect(() => {
    const fetchOverviewData = async () => {
      const logistic_id = userData._id;

      // Early return if fetching is not required
      if (!isDasboardOverviewFetching) return;

      try {
        // Fetch data from both APIs concurrently
        const [response, response1]: [any, any] = await Promise.all([
          getOverviewDashboardAPI(_token, logistic_id),
          getPreOrderAPI(_token, logistic_id)
        ]);

        // Ensure both responses have the expected status code
        if (response?.statusCode === 200 && response1?.statusCode === 200) {
          const newOverviewValue = [
            {
              id: 1,
              name: "Auction",
              value: response1?.data?.items?.length?.toString() || "0",
            },
            {
              id: 2,
              name: "Payment",
              value: response?.data?.items?.paymentCount?.toString() || "0",
            },
            {
              id: 3,
              name: "In Transit",
              value: response?.data?.items?.toShipCount?.toString() || "0",
            },
            {
              id: 4,
              name: "Completed",
              value: response?.data?.items?.completedCount?.toString() || "0",
            },
          ];

          // Update state with new data
          setOverviewData(newOverviewValue);
        }
      } catch (error) {
        console.error("Error fetching overview data:", error);
      } finally {
        // Ensure that fetching state is updated no matter what
        setIsDasboardOverviewFetching(false);
      }
    };

    // Fetch overview data when dependencies change
    fetchOverviewData();
    
  }, [_token, isDasboardOverviewFetching, userData._id]);
  
  return (
    <div className="py-4 md:py-8">
      <div className="mx-auto max-w-full px-3 sm:px-5 lg:px-8">
        <div className="max-w-2xl lg:max-w-none">
          <h2 className="text-xl md:text-2xl font-bold tracking-tight text-black text-left">
            Shipment Status
          </h2>
          {isDasboardOverviewFetching ? ( // Show skeletons if loading
            <dl className="ml-1 mt-6 grid grid-cols-2 gap-3 md:gap-x-8 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-4">
              {Array.from({ length: 4 }).map((_, index) => (
                <div key={index} className="flex flex-col bg-gray-100 p-8 rounded-3xl">
                  <dt className="text-sm font-semibold leading-6 text-gray-900">
                    <Skeleton height={20} width={`80%`} />
                  </dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900">
                    <Skeleton height={40} width={`60%`} />
                  </dd>
                </div>
              ))}
            </dl>
          ) : (
            <DashboardCardComponent2Light data={overviewData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default ShipmentStatusComponent;
