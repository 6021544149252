import ChipsComponentModel from "../../../model/interface/ChipsComponentModel";
const ChipsComponent: React.FC<ChipsComponentModel> = (props) => {
  return (
    <span className="inline-flex items-center justify-between gap-x-0.5 rounded-full bg-dealogikal-50/10 px-3.5 py-1 mx-1 mt-2 text-sm font-medium text-dealogikal-200  ring-1 ring-inset ring-dealogikal-50">
      <span className="mr-1">{props.text}</span>
      <button
        type="button"
        className="group relative -mr-1 h-3.5 w-3.5 rounded-sm hover:bg-gray-500/20"
        onClick={() => props.removeHandler("")}
      >
        <span className="sr-only">Remove</span>
        <svg
          viewBox="0 0 14 14"
          className="h-3.5 w-3.5 stroke-dealogikal-50 group-hover:stroke-dealogikal-100"
        >
          <path d="M4 4l6 6m0-6l-6 6" />
        </svg>
        <span className="absolute -inset-1" />
      </button>
    </span>
  );
};
export default ChipsComponent;
