import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames, generateKey } from "../../../redux/functions/function";
import DropdownItemType from "../../../model/types/DropdownItemType";
import DropdownModel from "../../../model/interface/DropdownModel";

const DropdownOptionComponent: React.FC<DropdownModel> = (props) => {
	// State to hold selected item
	const [selectedItem, setSelectedItem] = useState<DropdownItemType | null>(
		null
	);

	// Handle item click and set the selected item
	const handleItemClick = (item: DropdownItemType) => {
		setSelectedItem(item); // Update selectedItem state
		if (item.onClick) {
			item.onClick(); // If the item has an onClick handler, call it
		}
	};

	return (
		<Menu
			as="div"
			className="relative inline-block text-left float-right md:w-48 w-full md:mt-0 mt-4 md:mb-0 mb-4"
		>
			<div>
				{/* The button shows the selected item's text or the default main_text */}
				<Menu.Button className="inline-flex w-full justify-center gap-x-2 rounded-full bg-white md:py-2 py-1 text-sm text-medium text-gray-500 shadow-md hover:bg-gray-100">
					{/* Show selected item or fallback to main_text */}
					{selectedItem ? selectedItem.item_text : props.main_text}
					<ChevronDownIcon
						className="-mr-1 h-5 w-5 text-gray-500"
						aria-hidden="true"
					/>
				</Menu.Button>
			</div>

			<Transition
				as={Fragment}
				enter="transition ease-out duration-100"
				enterFrom="transform opacity-0 scale-95"
				enterTo="transform opacity-100 scale-100"
				leave="transition ease-in duration-75"
				leaveFrom="transform opacity-100 scale-100"
				leaveTo="transform opacity-0 scale-95"
			>
				<Menu.Items className="absolute right-0 z-20 mt-2 md:w-80 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
					<div className="py-1">
						{props.items.map((item: DropdownItemType) => (
							<Menu.Item key={generateKey(item.item_text + Math.random())}>
								{({ active }) => (
									<span
										className={classNames(
											active
												? "bg-gray-100 text-dealogikal-200"
												: "text-dealogikal-200",
											"group flex items-center px-4 py-1 text-sm cursor-pointer"
										)}
										onClick={() => handleItemClick(item)} // Set selected item on click
									>
										{item.icon}
										{item.item_text}
									</span>
								)}
							</Menu.Item>
						))}
					</div>
				</Menu.Items>
			</Transition>
		</Menu>
	);
};

export default DropdownOptionComponent;
