import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbComp from "../../../templates/BreadcrumbComponent/BreadcrumbComp";

const SingleItemComp = () => {
  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <BreadcrumbComp />
          </>
        }
      />
    </>
  );
};

export default SingleItemComp;
