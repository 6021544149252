import { ReactNode } from "react";
import ProgressBarComponent from "../ProgressBarComponent/ProgressBarComponent";

interface Props {
  logo?: ReactNode;
  transition_text?: string;
}

const TransitionComponent: React.FC<Props> = (props) => {
  return (
    <>
      <div className="flex flex-wrap justify-center md:px-4">
        <div className="flex flex-col w-full p-2 md:w-2/5 h-screen">
          <div className="my-auto md:pb-28">
            {props.logo}
            <ProgressBarComponent text={props.transition_text} />
          </div>
        </div>
      </div>
    </>
  );
};

export default TransitionComponent;
