import { ChangeEvent, useEffect, useState } from "react";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import MetaComponent from "../../../templates/MetaComponent/MetaComponent";
import {
	TruckIcon,
	MapPinIcon,
	SquaresPlusIcon,
	InboxStackIcon,
	TrashIcon,
	PlusCircleIcon,
} from "@heroicons/react/24/outline";
import VerticalListComponent from "../../../templates/VerticalListComponent/VerticalListComponent";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
	getCreateOfferFields,
	getUtilities,
	setDeliveryLocation,
	setOfferName,
	setSelected_Products,
	setShippingMethodID,
	setUtilityOfferList,
	setMunicipalDeliveryLocation,
} from "../../../../redux/functions/storageSlice";
import SelectedProductsType from "../../../../model/types/SelectedProductsType";
import MetaType from "../../../../model/types/MetaType";
import {
	countDecimalPoints,
	generateKey,
	getTokenFromLocalStorage,
	postFetch,
} from "../../../../redux/functions/function";
import { useNavigate } from "react-router-dom";
import VariationFieldComponent from "../../../templates/VariationComponent/VariationFieldComponent";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import Lottie from "lottie-react";
import CircularLoading from "../../../../lottie/circular_loading_theme_1.json";
import ToasterComponent from "../../../templates/ToasterComponent/ToasterComponent";
import { getOfferList } from "../../../../redux/functions/API";
import SingleOfferDataType from "../../../../model/types/SingleOfferDataType";
import DropdownLitstComponent from "../../../templates/DropdownLitstComponent/DropdownLitstComponent";

const AddVariationComp = () => {
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const utilities = useAppSelector(getUtilities);
	const createOfferFields = useAppSelector(getCreateOfferFields);
	const [selectedProducts, setSelectedProducts] = useState<
		SelectedProductsType[]
	>([]);
	const [listOfProducts, setListOfProducts] = useState<any>([]);
	const [currentProduct, setCurrentProduct] = useState<number>(0);
	const [stockData, setStockData] = useState<string>("");

	// cja: state data guide-->[product_id,[variation_id, [min_volume, max_volume, price][]]]
	const [mainVariationFieldArray, setMainVariationFieldArray] = useState<
		[number, [number, [string, string, string]][]][]
	>([]);
	// cja: state data guide-->[product_id, variation_id, field_index(min, max, price)]
	const [focusedField, setFocusedField] = useState<[number, number, number]>([
		-1, -1, -1,
	]);

	// cja: publish handler validation flags (start)
	// cja: flag if publish button has been click and there are still errors
	const [isPublishedWithErrors, setIsPublishedWithErrors] =
		useState<boolean>(false);
	// validation flag 1 --> product_id[]
	const [publishEmptyValidation, setPublishEmptyValidation] = useState<
		number[]
	>([]);
	// validation flag 2 --> product_id[]
	const [publishComparisonValidation, setPublishComparisonValidation] =
		useState<number[]>([]);
	// validation flag 3 --> product_id[]
	const [publishStockValidation, setPublishStockValidation] = useState<
		number[]
	>([]);
	const [publishGeneralError, setPublishGeneralError] = useState<string>("");
	// cja: publish handler validation flags (end)

	// cja: local state publis loading indicator if the system is in publishing process
	const [isPublishing, setIsPublishing] = useState<boolean>(false);

	// cja: validation states (start)

	// cja: validation 1
	// flag state if the fields are empty when the add variation has been clicked
	const [hasEmptyFields, setHasEmptyFields] = useState<boolean>(false);
	// local state in storing the empty field
	// state data guide-->[product_id, [variation_id, [min, max, price]], error_message]
	const [emptyVariationField, setEmptyVariationField] = useState<
		[number, [number, [string, string, string]], string]
	>([-1, [-1, ["", "", ""]], ""]);

	// cja: validation 2
	// flag state if variation fields faild to meet the following validaion
	// minimum volume must be less than maximum volume; or vice versa
	// state data guide-->[product_id, [variation_id, [min, max, price]], error_message]
	const [hasComparisonError, setHasComparisonError] = useState<boolean>(false);
	const [faildComparedVariationFields, setFaildComparedVariationFields] =
		useState<[number, [number, [string, string, string]], string]>([
			-1,
			[-1, ["", "", ""]],
			"",
		]);

	// cja: validation 3
	// flag state if minimum or maximum volumes exceeds product stock
	// state data guide-->[product_id, [variation_id, [min, max, price]], error_message, index]
	// index is either minimum or maximum volume field
	const [isStockExceedError, setIsStockExceedError] = useState<boolean>(false);
	const [exceedToStockField, setExceedToStockField] = useState<
		[number, [number, [string, string, string]], string, number]
	>([-1, [-1, ["", "", ""]], "", -1]);
	// cja: validation states (end)

	// cja: local state for the toast
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastType, setToastType] = useState<string>("");

	// cja: this useEffect hook will get selected products from the redux storage
	// if it will return empty string, then it will redirect to the list of offers
	useEffect(() => {
		createOfferFields.selectedProducts !== "" &&
			setSelectedProducts(JSON.parse(createOfferFields.selectedProducts));
		createOfferFields.selectedProducts === "" && navigate("/my_offers");
	}, [createOfferFields.selectedProducts, navigate]);

	// cja: this useEffect hook will extract the list of item to be pass in the vertical list component
	useEffect(() => {
		let updatedListOfProducts: {
			item_id: number;
			name: string;
			current: boolean;
		}[] = [];
		selectedProducts.length !== 0 &&
			selectedProducts.forEach((selected_product: SelectedProductsType) => {
				updatedListOfProducts = [
					...updatedListOfProducts,
					{
						item_id: selected_product.product.product_id,
						name: selected_product.product.commodity_name,
						current:
							selected_product.product.product_id === currentProduct
								? true
								: false,
					},
				];
			});
		setListOfProducts(updatedListOfProducts);

		if (currentProduct === 0) {
			if (selectedProducts.length !== 0) {
				let new_currentProduct = selectedProducts[0].product.product_id;
				setCurrentProduct(new_currentProduct);
			}
		}
	}, [selectedProducts, currentProduct]);

	// cja: create the main array of variation field
	useEffect(() => {
		let updatedMainVariationFieldArray: [
			number,
			[number, [string, string, string]][]
		][] = [];
		selectedProducts.forEach((selected_product: SelectedProductsType) => {
			let product_id = selected_product.product.product_id;
			let new_field: [number, [string, string, string]][] = [];

			if (selected_product.fields === "") {
				new_field = [...new_field, [1, ["", "", ""]]];
			} else {
				JSON.parse(selected_product.fields).forEach(
					(field: [number, [string, string, string]]) => {
						new_field = [...new_field, field];
					}
				);
			}

			updatedMainVariationFieldArray = [
				...updatedMainVariationFieldArray,
				[product_id, new_field],
			];
		});

		setMainVariationFieldArray(updatedMainVariationFieldArray);
	}, [selectedProducts]);

	// cja: function handler if the products are clicked
	const itemOnClickHandler = (item_id: number) => {
		// remove the error first
		// validation 1: empty field validation
		setHasEmptyFields(false);
		setEmptyVariationField([-1, [-1, ["", "", ""]], ""]);
		// validation 2: comparison validation
		setHasComparisonError(false);
		setFaildComparedVariationFields([-1, [-1, ["", "", ""]], ""]);
		// validation 3: stock validation
		setIsStockExceedError(false);
		setExceedToStockField([-1, [-1, ["", "", ""]], "", -1]);

		// set the current product into active
		setCurrentProduct(item_id);
		setStockData(ExtractStock(item_id));

		setHasEmptyFields(false);
	};

	// cja: function that will assign the stock in a specific product
	useEffect(() => {
		if (stockData === "" && selectedProducts.length !== 0) {
			setStockData(selectedProducts[0].product.stocks);
		}
	}, [selectedProducts, stockData]);

	const ExtractStock = (item_id: number) => {
		let itemStock = "";
		selectedProducts.length !== 0 &&
			selectedProducts.forEach((selected_product: SelectedProductsType) => {
				if (selected_product.product.product_id === item_id) {
					itemStock = selected_product.product.stocks;
				}
			});

		return itemStock;
	};

	// cja: dynamic variation field function implementations (start)
	// cja: delete variation field handler function
	const deleteVariationFieldHandler = (
		product_id: number,
		variation_id: number
	) => {
		removeVariationField(variation_id, product_id).then((res: any) => {
			setMainVariationFieldArray(res);
		});
	};

	// cja: validation handler region (start)
	// cja: function handler to check the empty variation fields before adding new field
	const checkEmptyFields = (product_id: number) => {
		return new Promise((resolve, reject) => {
			let hasEmpty = null; // boolean flag variable to be return from the caller
			// locate product variation array through product_id parameter
			mainVariationFieldArray.forEach(
				(
					main_variation_field: [number, [number, [string, string, string]][]]
				) => {
					if (main_variation_field[0].toString() === product_id.toString()) {
						let variation_field_arr: [number, [string, string, string]][] =
							main_variation_field[1]; // extract the variation field of the matched product
						// loop over the product variation array
						variation_field_arr.forEach(
							(variation_field: [number, [string, string, string]]) => {
								// then check if there are empty fields
								if (
									variation_field[1][0] === "" ||
									variation_field[1][1] === "" ||
									variation_field[1][2] === ""
								) {
									// set flag to true if there is empty field
									hasEmpty = true;
									// save the product_id, variation field with empty fields, and error message to local state
									setEmptyVariationField([
										product_id,
										variation_field,
										"Please make sure to fill in the fields completely before adding another variation.",
									]);
								} else {
									hasEmpty = false;
								}
							}
						);
					}
				}
			);
			resolve(hasEmpty);
		});
	};

	// cja: function handler to check if the minimum volume field is always less than or
	// not equal to the maximum volume field before adding new variation field
	const checkComparison = (product_id: number) => {
		return new Promise((resolve, reject) => {
			let isInvalid = null; // boolean flag if variation field is invalid.
			// locate product variation array through product_id parameter
			mainVariationFieldArray.forEach(
				(
					main_variation_field: [number, [number, [string, string, string]][]]
				) => {
					if (main_variation_field[0].toString() === product_id.toString()) {
						let variation_field_arr: [number, [string, string, string]][] =
							main_variation_field[1]; // extract the variation field of the matched product
						// loop over the product variation array
						variation_field_arr.forEach(
							(variation_field: [number, [string, string, string]]) => {
								// then compare fields
								let minimumField = parseInt(variation_field[1][0]);
								let maximumField = parseInt(variation_field[1][1]);
								if (minimumField >= maximumField) {
									// set flag to true if there is comparison error
									isInvalid = true;
									// save the product_id, variation field, and error message to local state
									setFaildComparedVariationFields([
										product_id,
										variation_field,
										"Please make sure that minimum volume is less than or equal to the maximum volume.",
									]);
								} else {
									isInvalid = false;
								}
							}
						);
					}
				}
			);

			resolve(isInvalid);
		});
	};

	// cja: function handler to check if minimum and maximum volume fields will not exceed the product stock
	const checkStockValidation = (product_id: number) => {
		return new Promise((resolve, reject) => {
			let isInvalid = null; // boolean flag if variation field is invalid.
			// locate product variation array through product_id parameter
			mainVariationFieldArray.forEach(
				(
					main_variation_field: [number, [number, [string, string, string]][]]
				) => {
					if (main_variation_field[0].toString() === product_id.toString()) {
						let variationFieldArray: [number, [string, string, string]][] =
							main_variation_field[1]; // extract the variation field of the matched product
						// loop over the product variation array
						variationFieldArray.forEach(
							(variation_field: [number, [string, string, string]]) => {
								let stringStock = ExtractStock(product_id)
									.toString()
									.replaceAll(" ltrs", "")
									.replaceAll(",", "");

								let productStock = parseInt(stringStock);
								let minimumField = parseInt(variation_field[1][0]);
								let maximumField = parseInt(variation_field[1][1]);

								if (
									minimumField >= productStock ||
									maximumField > productStock
								) {
									let index = -1;
									if (minimumField >= productStock) {
										index = 0;
									} else if (maximumField > productStock) {
										index = 1;
									}
									// set flag to true if the minimum or maximum volume fields exceed product stock
									isInvalid = true;
									// save the product_id, variation field, and error message to local state
									setExceedToStockField([
										product_id,
										variation_field,
										`Please make sure that ${
											index === 0 ? "minimum" : "maximum"
										} volume does not exceed ${
											index === 0 ? " or equal" : ""
										} to the product stock.`,
										index,
									]);
								} else {
									isInvalid = false;
								}
							}
						);
					}
				}
			);
			resolve(isInvalid);
		});
	};

	// cja: validation handler region (end)

	const clearErrorFlags = () => {
		// validation 1: empty field states
		setHasEmptyFields(false);
		setEmptyVariationField([-1, [-1, ["", "", ""]], ""]);
		// validation 2: comparing minimum and maximum volume field states
		setHasComparisonError(false);
		setFaildComparedVariationFields([-1, [-1, ["", "", ""]], ""]);
		// validation 3: minimum or maximum field must not exceed to the product stock
		setIsStockExceedError(false);
		setExceedToStockField([-1, [-1, ["", "", ""]], "", -1]);
	};

	// cja: function click add variaiton handler
	const addVariationHandler = (product_id: number) => {
		// remove the existing errors in publishing event
		setPublishEmptyValidation([]);
		// initially, let's clear all the validation states related to this function (adding of new variation functionality)
		clearErrorFlags();
		// validation 1: let's check if there is an empty field
		checkEmptyFields(product_id).then((res: any) => {
			if (res === true) {
				setHasEmptyFields(res);
			} else {
				// validation 2: let's check if minimum is less than maximum volume
				checkComparison(product_id).then((res: any) => {
					if (res === true) {
						setHasComparisonError(res);
					} else {
						// validation 3: let's check if minimum or maximum fields does not exceed the product stock
						checkStockValidation(product_id).then((res: any) => {
							if (res === true) {
								setIsStockExceedError(res);
							} else {
								let updatedMainVariationFieldArray: [
									number,
									[number, [string, string, string]][]
								][] = [];
								mainVariationFieldArray.forEach(
									(
										variation_field: [
											number,
											[number, [string, string, string]][]
										]
									) => {
										if (variation_field[0] === product_id) {
											let productVariationFields: [
												number,
												[string, string, string]
											][] = variation_field[1];
											let productVariationIDFields: number[] = [];
											productVariationFields.forEach(
												(field: [number, [string, string, string]]) => {
													productVariationIDFields = [
														...productVariationIDFields,
														field[0],
													];
												}
											);
											// sort product_variation_id_fields in ascending order
											productVariationIDFields.sort(function (
												a: number,
												b: number
											) {
												return b - a;
											});
											// increment the last variation_id to be the next variation_id
											let newProductVariationID =
												productVariationIDFields[0] + 1;
											const newProductVariationFields: [
												number,
												[string, string, string]
											][] = [
												...productVariationFields,
												[newProductVariationID, ["", "", ""]],
											];
											// update variation_field
											updatedMainVariationFieldArray = [
												...updatedMainVariationFieldArray,
												[product_id, newProductVariationFields],
											];
										} else {
											updatedMainVariationFieldArray = [
												...updatedMainVariationFieldArray,
												variation_field,
											];
										}
										setMainVariationFieldArray(updatedMainVariationFieldArray);
									}
								);
							}
						});
					}
				});
			}
		});
	};

	// cja: create another array of variation field with variation field already deleted
	const removeVariationField = (variation_id: number, product_id: number) => {
		return new Promise((resolve, reject) => {
			let updatedMainVariationFieldArray: [
				number,
				[number, [string, string, string]][]
			][] = [];
			let newProductVariationFields: [number, [string, string, string]][] = [];

			mainVariationFieldArray.forEach(
				(variation_field: [number, [number, [string, string, string]][]]) => {
					if (variation_field[0].toString() === product_id.toString()) {
						let productVariationFields: [number, [string, string, string]][] =
							variation_field[1];
						productVariationFields.forEach(
							(product_variation_field: [number, [string, string, string]]) => {
								if (
									variation_id.toString() !==
									product_variation_field[0].toString()
								) {
									newProductVariationFields = [
										...newProductVariationFields,
										product_variation_field,
									];
								}
							}
						);
						updatedMainVariationFieldArray = [
							...updatedMainVariationFieldArray,
							[product_id, newProductVariationFields],
						];
					} else {
						updatedMainVariationFieldArray = [
							...updatedMainVariationFieldArray,
							variation_field,
						];
					}
				}
			);
			resolve(updatedMainVariationFieldArray);
		});
	};
	// cja: dynamic variation field function implementations (end)

	// cja: This is the handler that will be triggered every
	// onChange event of all the variation fields in different products.
	const handleChange = (
		event: ChangeEvent<HTMLInputElement>,
		product_id: number,
		variation_id: number,
		index: number
	) => {
		setFocusedField([product_id, variation_id, index]); // set the focus field
		// CJA: validate the use of Decimal Point
		/*
        Decimal Validation:

        (1) decimal point MUST ONLY be applicable in the price input
        (2) decimal point must only be one
        (3) no decimal point should be at the first index of the input

      */

		// Decimal Validation 1: Identify the input. Decimal MUST ONLY be applicable in the price input
		let accepted_characters = null;
		/*
      [index] Legend:

      0 - Minimum Volume Input
      1 - Maximum Volume Input
      2 - Price per unit Input

    */
		if (index === 2) {
			// check the value if not blank/alphabet/character
			// include decimal
			accepted_characters = /^[0-9,.\b]+$/;
		} else {
			// check the value if not blank/alphabet/character
			accepted_characters = /^[0-9,\b]+$/;
		}

		if (
			event.target.value === "" ||
			accepted_characters.test(event.target.value)
		) {
			// Decimal Validation 2 & 3: Decimal point must only be one & no decimal point should be at the first index of the input
			if (
				countDecimalPoints(event.target.value) < 2 &&
				event.target.value.charAt(0) !== "."
			) {
				updateField(
					event,
					product_id.toString(),
					variation_id.toString(),
					index
				).then((new_product_variation_fields: any) => {
					let updatedMainVariationFieldArray: [
						number,
						[number, [string, string, string]][]
					][] = [];
					mainVariationFieldArray.forEach(
						(
							main_variation_field: [
								number,
								[number, [string, string, string]][]
							]
						) => {
							let mainVariationFieldID = main_variation_field[0].toString();
							if (product_id.toString() === mainVariationFieldID) {
								updatedMainVariationFieldArray = [
									...updatedMainVariationFieldArray,
									[product_id, new_product_variation_fields],
								];
							} else {
								updatedMainVariationFieldArray = [
									...updatedMainVariationFieldArray,
									main_variation_field,
								];
							}
						}
					);
					setMainVariationFieldArray(updatedMainVariationFieldArray);
				});
			}
		}
	};

	// cja: this is the main function handler in updating the variation field value.
	// This will only return a Promise response containing the array of variation fields on a specific product.
	const updateField = (
		event: ChangeEvent<HTMLInputElement>,
		product_id: string,
		variation_id: string,
		index: number
	) => {
		return new Promise((resolve, reject) => {
			// find the product through product id parameter
			mainVariationFieldArray.forEach(
				(
					main_variation_field: [number, [number, [string, string, string]][]]
				) => {
					let currentProductID = main_variation_field[0].toString(); // get product id from the current field in the loop
					let productVariationFields: [number, [string, string, string]][] =
						main_variation_field[1]; // get the variation field array of the product
					let newProductVariationFields: [number, [string, string, string]][] =
						[]; // initialize new version of product variation field array
					if (currentProductID === product_id) {
						// loop the fields of the current product, then find the specific variation
						// by using the variation id parameter
						productVariationFields.forEach(
							(product_variation_field: [number, [string, string, string]]) => {
								let currentVariationID = product_variation_field[0].toString(); // get the current product variation id
								if (currentVariationID === variation_id) {
									let fields: [string, string, string] =
										product_variation_field[1]; // get the array of fields ([min, max, price]) of the current variation
									fields[index] = event.target.value; // locate and update the field by the use of index parameter

									// add to new array of product variation array
									newProductVariationFields = [
										...newProductVariationFields,
										[parseInt(currentVariationID), fields],
									];
								} else {
									// simply add to new array of product variation array
									newProductVariationFields = [
										...newProductVariationFields,
										[parseInt(currentVariationID), product_variation_field[1]],
									];
								}
							}
						);
						resolve(newProductVariationFields);
					}
				}
			);
		});
	};

	// cja: function that will handle in checking the active product in the list
	// and display its fields
	const populateVariationFields = (
		active_product: string,
		product_variation_field: [number, [string, string, string]][]
	) => {
		// cja: if variation_field[0] === currentProduct
		return active_product === currentProduct.toString() ? (
			<>
				{product_variation_field.map(
					(variation_field: [number, [string, string, string]]) => {
						let variationFieldID: number = variation_field[0];
						// cja: this function will identify if the field is the last focus
						function IsFocused(index: number) {
							return (
								focusedField[0].toString() === active_product.toString() &&
								variation_field[0].toString() === focusedField[1].toString() &&
								focusedField[2] === index
							);
						}

						// cja: this function will identify if the field is valid or invalid to apply border color
						function IsInvalid(index: number, validation: string) {
							if (validation === "empty") {
								return (
									emptyVariationField[0].toString() === active_product &&
									emptyVariationField[1][0].toString() ===
										variationFieldID.toString() &&
									emptyVariationField[1][1][index] === ""
								);
							} else if (validation === "comparison") {
								return (
									variationFieldID.toString() ===
									faildComparedVariationFields[1][0].toString()
								);
							} else if (validation === "stock") {
								return (
									variationFieldID.toString() ===
										exceedToStockField[1][0].toString() &&
									index === exceedToStockField[3]
								);
							} else {
								return false;
							}
						}

						return (
							<div
								className="flex flex-wrap justify-center mt-4"
								key={generateKey(Math.random() + variation_field[0].toString())}
							>
								<div
									className={`flex flex-col ${
										product_variation_field.length === 1
											? "md:w-full"
											: "md:w-11/12"
									}`}
								>
									<div className="flex flex-wrap justify-center rounded-lg bg-gray-100 md:bg-white px-2 md:px-0 pb-2 md:pb-0">
										<VariationFieldComponent
											key={generateKey(
												Math.random() + "minimum" + variationFieldID
											)}
											width="md:w-1/3"
											padding_alignment="md:pr-2"
											input_value={variation_field[1][0].toString()}
											input_type="text"
											input_name="txtminimum_volume"
											input_id="txtminimum_volume"
											placeholder_text="Minimum Volume"
											variation_id={variationFieldID}
											OnchangeHandler={(e) =>
												handleChange(
													e,
													parseInt(active_product),
													variationFieldID,
													0
												)
											}
											isInvalid={
												IsInvalid(0, "empty") ||
												IsInvalid(0, "comparison") ||
												IsInvalid(0, "stock")
											}
											isFocused={IsFocused(0)}
										/>
										<VariationFieldComponent
											key={generateKey(
												Math.random() + "maximum" + variationFieldID
											)}
											width="md:w-1/3"
											padding_alignment="md:px-2"
											input_value={variation_field[1][1].toString()}
											input_type="text"
											input_name="txtmaximum_volume"
											input_id="txtmaximum_volume"
											placeholder_text="Maximum Volume"
											variation_id={variationFieldID}
											OnchangeHandler={(e) =>
												handleChange(
													e,
													parseInt(active_product),
													variationFieldID,
													1
												)
											}
											isInvalid={
												IsInvalid(1, "empty") ||
												IsInvalid(1, "comparison") ||
												IsInvalid(1, "stock")
											}
											isFocused={IsFocused(1)}
										/>
										<VariationFieldComponent
											key={generateKey(
												Math.random() + "price" + variationFieldID
											)}
											width="md:w-1/3"
											padding_alignment="md:pl-2"
											input_value={variation_field[1][2].toString()}
											input_type="text"
											input_name="txtprice_per_unit"
											input_id="txtprice_per_unit"
											placeholder_text="Price per unit"
											variation_id={variationFieldID}
											OnchangeHandler={(e) =>
												handleChange(
													e,
													parseInt(active_product),
													variationFieldID,
													2
												)
											}
											isInvalid={IsInvalid(2, "empty")}
											isFocused={IsFocused(2)}
										/>
										{product_variation_field.length > 1 && (
											<span
												className="block md:hidden text-sm text-gray-900 text-end w-full mt-2"
												onClick={() =>
													deleteVariationFieldHandler(
														parseInt(active_product),
														variationFieldID
													)
												}
											>
												Remove
											</span>
										)}
									</div>
								</div>
								<div
									className={`flex flex-col my-auto ${
										product_variation_field.length === 1
											? "md:w-0"
											: "md:w-1/12"
									}`}
								>
									<div className="flex flex-wrap">
										<TrashIcon
											className="hidden md:block h-6 w-6 mx-auto text-gray-400 cursor-pointer"
											onClick={() =>
												deleteVariationFieldHandler(
													parseInt(active_product),
													variationFieldID
												)
											}
										/>
									</div>
								</div>
							</div>
						);
					}
				)}
			</>
		) : (
			<></>
		);
	};

	const renderSingleValidationErrorMessage = (error_text: string) => {
		return (
			<div className={`flex flex-wrap justify-center mt-5`}>
				<div className="flex flex-col w-full md:w-full">
					<span className="text-red-600 text-sm text-center">{error_text}</span>
				</div>
			</div>
		);
	};

	const displayPublishErrorMessages = () => {
		if (publishEmptyValidation.length !== 0 && isPublishedWithErrors === true) {
			return (
				<div className={`flex flex-wrap justify-center mt-5`}>
					<div className="flex flex-col w-full md:w-full">
						<span className="text-red-600 text-sm text-center">
							{`Please fill in the empty variation fields under ${extractProductName(
								publishEmptyValidation[0].toString()
							)}`}
						</span>
					</div>
				</div>
			);
		} else if (
			publishComparisonValidation.length !== 0 &&
			isPublishedWithErrors === true
		) {
			return (
				<div className={`flex flex-wrap justify-center mt-5`}>
					<div className="flex flex-col w-full md:w-full">
						<span className="text-red-600 text-sm text-center">
							{`Invalid minimum and maximum volume in ${extractProductName(
								publishComparisonValidation[0].toString()
							)} commodity. Ensure minimum is always less than maximum.`}
						</span>
					</div>
				</div>
			);
		} else if (
			publishStockValidation.length !== 0 &&
			isPublishedWithErrors === true
		) {
			return (
				<div className={`flex flex-wrap justify-center mt-5`}>
					<div className="flex flex-col w-full md:w-full">
						<span className="text-red-600 text-sm text-center">
							{`Invalid volume in ${extractProductName(
								publishStockValidation[0].toString()
							)} commodity. Ensure minimum/maximum doesn't exceed the stock.`}
						</span>
					</div>
				</div>
			);
		} else if (publishGeneralError !== "" && isPublishedWithErrors) {
			return (
				<>
					<div className={`flex flex-wrap justify-center mt-5`}>
						<div className="flex flex-col w-full md:w-full">
							<span className="text-red-600 text-sm text-center">
								{publishGeneralError}
							</span>
						</div>
					</div>
				</>
			);
		} else {
			<></>;
		}
	};

	// cja: function to extract the shipping method using the shipping method id param
	const extractShippingMethod = (shipping_method_id: string) => {
		let shippingMethodName = "";
		utilities.shippingMethods.length !== 0 &&
			utilities.shippingMethods.forEach((shipping_method: any) => {
				if (shipping_method.id.toString() === shipping_method_id) {
					shippingMethodName = shipping_method.name;
				}
			});
		return shippingMethodName;
	};

	const MetaDataComponent: MetaType[] = [
		{
			text: extractShippingMethod(
				createOfferFields.offerFields.shippingMethodID
			),
			icon: (
				<TruckIcon
					className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
			),
		},
		{
			text: createOfferFields.offerFields.deliveryLocation.name,
			text2: createOfferFields.offerFields.municipalDeliveryLocation.name,
			icon: (
				<MapPinIcon
					className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
					aria-hidden="true"
				/>
			),
		},
	];

	const extractProductName = (product_id: string) => {
		let productName = "";
		utilities.productList.length !== 0 &&
			utilities.productList.forEach((product: any) => {
				if (product.product_id.toString() === product_id) {
					productName = product.commodity_name;
				}
			});

		return productName;
	};

	const publishClickHandler = () => {
		// cja: as of now, empty validations are implemented
		if (
			publishEmptyValidation.length !== 0 ||
			publishComparisonValidation.length !== 0 ||
			publishStockValidation.length !== 0
		) {
			setIsPublishedWithErrors(true);
		} else {
			// get and decode the token from local storage
			const _token = getTokenFromLocalStorage() ?? "";
			const userData: TokenModel = jwt_decode(_token);
			const supplier_id: string = userData._id.toString();

			// set publish error flag & publish loading indicator into false
			setIsPublishedWithErrors(false);
			setIsPublishing(true);

			publishNewOffer(_token, supplier_id).then((res: any) => {
				if (res.statusCode === 200) {
					// reset the local state publish loading indicator
					setIsPublishing(false);

					// refresh offer list by calling the get offer list API and store to redux
					getOfferList(_token, 1, supplier_id).then((data: any) => {
						dispatch(setUtilityOfferList(data));
					});

					// display toast
					setIsToastOpen(true);
					setToastMessage(res.data.message);
					setToastType("");

					setTimeout(() => {
						clearNewOfferReduxStates();
						navigate("/my_offers");
					}, 3000);

					dispatch(setMunicipalDeliveryLocation({ id: 0, name: "" }));
				} else if (res.statusCode === 409) {
					setIsPublishing(false);
					setIsPublishedWithErrors(true);
					setPublishGeneralError(res.data.message);
				}
			});
		}
	};

	// cja: publish offer api integration
	const publishNewOffer = (_token: string, supplier_id: string) => {
		return new Promise((resolve, reject) => {
			let offer_data: SingleOfferDataType = {
				offer_name: createOfferFields.offerFields.offerName,
				shipping_method_id: createOfferFields.offerFields.shippingMethodID,
				delivery_location_id: createOfferFields.offerFields.deliveryLocation.id,
				delivery_municipality_id:
					createOfferFields.offerFields.municipalDeliveryLocation.id,
				variation_items: mainVariationFieldArray,
				supplier_id: supplier_id,
			};

      postFetch(
        `${process.env.REACT_APP_API_URL}/offer/add`,
        offer_data,
        _token
      )
        .then((response) => {
          resolve(response);
        })
        .catch(() => {
          reject("Error: Something went wrong in adding new offer.");
        });
    });
  };

	// cja: clear redux states in creating new offer
	const clearNewOfferReduxStates = () => {
		dispatch(setSelected_Products(""));
		dispatch(setOfferName(""));
		dispatch(setShippingMethodID(""));
		dispatch(setDeliveryLocation({ id: "", name: "" }));
	};

	// cja: publish main validation -> this useEffect hook will be triggered once there are changes
	// detected in the main_variation_field_arr local state. Then, loop over the array to implement the 3 validaitons
	useEffect(() => {
		// reset the flags associated with publish validations
		// validation 1
		setPublishEmptyValidation([]);
		setPublishComparisonValidation([]);
		setPublishStockValidation([]);
		setIsPublishedWithErrors(false);

		// initialize new publish empty fields
		let newPublishEmptyValidation: number[] = [];
		// initialize the new array of comparison invalid fields
		let newPublishComparisonValidation: number[] = [];
		// initialize the new array of stock validation fields
		let newPublishStockValidation: number[] = [];
		// loop over the main variation field array
		mainVariationFieldArray.forEach(
			(
				main_variation_field: [number, [number, [string, string, string]][]]
			) => {
				const product_id: number = main_variation_field[0];
				let stock: number = 0;
				selectedProducts.forEach((product: SelectedProductsType) => {
					if (product.product.product_id === product_id) {
						stock = Number(
							product.product.stocks
								.toString()
								.replaceAll(" ltrs", "")
								.replaceAll(",", "")
						);
					}
				});

				// loop over the variation field on every product
				main_variation_field[1].forEach(
					(variation_field: [number, [string, string, string]]) => {
						// extract fields
						const min: string = variation_field[1][0];
						const max: string = variation_field[1][1];
						const price: string = variation_field[1][2];

						// empty validation
						if (min === "" || max === "" || price === "") {
							newPublishEmptyValidation = [
								...newPublishEmptyValidation,
								product_id,
							];
						} else if (Number(min) >= Number(max)) {
							newPublishComparisonValidation = [
								...newPublishComparisonValidation,
								product_id,
							];
						} else if (Number(min) >= stock || Number(max) > stock) {
							newPublishStockValidation = [
								...newPublishStockValidation,
								product_id,
							];
						}
					}
				);
			}
		);

		setPublishEmptyValidation(newPublishEmptyValidation);
		setPublishComparisonValidation(newPublishComparisonValidation);
		setPublishStockValidation(newPublishStockValidation);
	}, [mainVariationFieldArray, selectedProducts]);

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<ToasterComponent
							isOpen={isToastOpen}
							label={toastMessage}
							onClose={setIsToastOpen}
							type={toastType}
						/>
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle={createOfferFields.offerFields.offerName}
						/>
						<div className="flex flex-wrap justify-center md:-mt-5 mt-0">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:w-full text-gray-500">
								<MetaComponent
									key={generateKey(Math.random().toString())}
									meta_data={MetaDataComponent}
									placement="end"
								/>
							</div>
							{/* single card (end) */}
						</div>
						<div className="flex flex-wrap justify-center mt-4 sm:mt-0">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:w-full border-b pb-4 sm:pb-0">
								{/* cja: display error messages (start) */}
								{displayPublishErrorMessages()}
								{/* cja: display error messages (end) */}
								<ButtonComponent
									text={`${isPublishing ? "Publishing" : "Publish"}`}
									icon={
										isPublishing && (
											<Lottie
												className="md:w-5 w-5 h-auto"
												animationData={CircularLoading}
												loop={true}
											/>
										)
									}
									utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 mt-4 mb-4 self-end sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-48 w-full"
									onClick={publishClickHandler}
								/>
							</div>
							{/* single card (end) */}
						</div>
						<div className="flex flex-wrap justify-center mt-0 md:mt-8">
							{/* single card (start) */}
							<div className="flex flex-col w-full md:w-2/5 pl-0 md:pl-6 pr-0 md:pr-24">
								{/* cja: show on small upto large screens --> hidden to all screens by default */}
								<div className="hidden md:block">
									<VerticalListComponent
										list={listOfProducts}
										itemOnClickHandler={itemOnClickHandler}
									/>
								</div>
								{/* cja: show on all screens --> hidden to small upto larger screens by default */}
								<div className="block md:hidden">
									<DropdownLitstComponent
										list={listOfProducts}
										itemOnClickHandler={itemOnClickHandler}
									/>
								</div>
							</div>
							<div className="flex flex-col w-full md:w-3/5 mt-2 md:mt-1">
								<div className="flex flex-wrap justify-center">
									<div className="flex flex-col w-full md:w-2/3">
										<label
											htmlFor="txtlocation"
											className="text-sm font-medium leading-6 text-gray-900"
										>
											<div className="flex gap-2">
												<SquaresPlusIcon className="-ml-0.5 h-5 w-5" />
												<span>Variation(s)</span>
											</div>
										</label>
									</div>
									<div className="flex flex-col w-full md:w-1/3">
										<div className="selft-start md:self-end mt-2 md:mt-0">
											<span className="text-center rounded-full bg-red-100 px-6 py-2 text-xs font-medium text-red-700 flex-wrap">
												<InboxStackIcon className="-ml-0.5 h-5 w-5 inline" />
												<span className="inline ml-2">{stockData}</span>
											</span>
										</div>
									</div>
								</div>
								<div className="flex flex-wrap justify-center mt-4">
									<div className="flex flex-col w-full md:w-full">
										{mainVariationFieldArray.map(
											(main_variation_field: any) => {
												// cja: guide
												// main_variation_field[0] = product_id
												// main_variation_field[1] = variation_field_array

												return (
													<div
														key={
															Math.random().toString() +
															main_variation_field[0].toString()
														}
													>
														{populateVariationFields(
															main_variation_field[0].toString(),
															main_variation_field[1]
														)}
													</div>
												);
											}
										)}
									</div>
									{hasEmptyFields === true &&
										renderSingleValidationErrorMessage(emptyVariationField[2])}
									{hasComparisonError === true &&
										renderSingleValidationErrorMessage(
											faildComparedVariationFields[2]
										)}
									{isStockExceedError === true &&
										renderSingleValidationErrorMessage(exceedToStockField[2])}
									<ButtonComponent
										icon={
											<PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
										}
										text="Add Variation"
										utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-6 mt-6 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full"
										onClick={() => addVariationHandler(currentProduct)}
									/>
								</div>
							</div>
							{/* single card (end) */}
						</div>
					</>
				}
			/>
		</>
	);
};
export default AddVariationComp;
