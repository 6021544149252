import { Square3Stack3DIcon } from "@heroicons/react/20/solid";
import { PencilIcon } from "@heroicons/react/24/outline";
import { generateKey } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import { Fragment } from "react";
import EnabledPriceEditorType from "../../../model/types/EnabledPriceEditorType";

interface StaticTableComponentModel {
  isItemEditable: boolean;
  user_data: TokenModel;
  commodity_table: any;
  current_order?: any;
  current_offer?: any;
  logistic?: any;
  editPriceClickHandler?: (enabledPriceEditor: EnabledPriceEditorType) => void;
}

const StaticTableComponent: React.FC<StaticTableComponentModel> = (props) => {

  const getPrice = (commodityId: number, volume: number) => {
    const matchingOffer = props.commodity_table.find(
      ( offer: { commodity_id: number; minimum_quantity: number; maximum_quantity: number; }) => offer.commodity_id === commodityId &&
               volume >= offer.minimum_quantity &&
               volume <= offer.maximum_quantity
    );

    return matchingOffer ? matchingOffer.price : 'No matching offer found';
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8 w-full sm:w-2/3">
      <div className="flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle pt-3 px-1">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-xs text-xxs md:text-xs font-semibold text-gray-900 sm:pl-6"
                    >
                      Items
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-xs text-xxs md:text-xs font-semibold text-gray-900"
                    >
                      Top Price
                    </th>
                    {props.user_data.role === 2 && (
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-xs text-xxs md:text-xs font-semibold text-gray-900"
                      >
                        Your Price
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {props.current_order.commodities &&
                    props.current_order.commodities.map((commodity: any, index: number) => (
                      <tr key={generateKey(commodity.commodity_id)}>
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0">
                              <img
                                className="h-11 w-11 rounded-full"
                                src={commodity.commodity_icon}
                                alt=""
                              />
                            </div>
                            <div className="ml-4">
                              <div className="text-xs md:text-base font-bold text-gray-700">
                                {commodity.commodity_name}
                              </div>
                             
                                <div className="mt-1 text-gray-500" key={index}>
                                  <div className="inline-block">
                                    {<Square3Stack3DIcon className="h-3 w-3" />}
                                  </div>
                                  <div className="inline-block ml-2 text-xs md:text-base">
                                    {commodity.volume}
                                    {/* at &#8369; {offer.variants[variantKey].price} */}
                                  </div>
                                </div>
                          
                              
                            </div>
                          </div>
                        </td>
    
                        <td className="whitespace-nowrap py-4 text-sm md:text-base font-bold text-gray-700" key={index}>
                          <p className="inline-block mr-3 float-left">
                            {/* &#8369; {o?.price} &nbsp; */}
                            &#8369; {getPrice(commodity.commodity_id, commodity.volume)} &nbsp;
                            {props.logistic && (
                              <span className="text-green-500 text-sm">
                                {props.logistic.toFixed(2)}
                              </span>
                            )}
                          </p>
                        </td>
                  
                        {props.user_data.role === 2 && (
                          <td className="whitespace-nowrap py-4 text-sm md:text-base font-bold text-gray-700">
                            {props.current_offer
                            .filter((offer: any) => offer.commodity_name === commodity.commodity_name &&
                                                    commodity.volume >= offer.minimum_quantity &&
                                                    commodity.volume <= offer.maximum_quantity)
                            .map((o: any) => {
                                return (
                                  <Fragment key={Math.random().toString()}>
                                    <p className="inline-block mr-3 float-left">
                                      {o.price} 
                                    </p>
                                    <PencilIcon
                                      className={`h-5 text-gray-600 inline-block float-left cursor-pointer hover:text-dealogikal-200 ${!(props.user_data && props.user_data.isUserSupplier) ? 'hidden' : ''}`} 
                                      aria-hidden="true"
                                      title="Edit your price"
                                      onClick={() =>
                                        props.editPriceClickHandler!({
                                          offer_id: o.offer_id,
                                          offer_variation_id: o.offer_variation_id,
                                          commodity_name: o.commodity_name,
                                          current_price: o.price,
                                          updated_price: "",
                                        })
                                      }
                                    />
                                  </Fragment>
                                );
                            })}
                          </td>
                        )}
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StaticTableComponent;
