import React, { useEffect } from "react";
import ShippingDetailsComp from "../../../../components/buyer/buy_commodity/shipping_details/ShippingDetailsComp";
import { useValidateAuth } from "../../../../hooks/useValidateAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckWebSocketConnection } from "../../../../hooks/useCheckWebSocketConnection";

const ShippingDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const validateAuth = useValidateAuth(location.pathname);

  /*
    cja: [Hook Purpose]
    - if not refresh, every navigate of the pages does not resend the message by checking the redux state
    - if refresh, reestablish the connection
  */
  useCheckWebSocketConnection(!validateAuth);

  /*
    cja: hook that will handle in validating
    the expiration of authentication token
  */
  useEffect(() => {
    if (validateAuth) {
      navigate("/login");
    }
  }, [navigate, validateAuth]);

  return <ShippingDetailsComp />;
};

export default ShippingDetails;
