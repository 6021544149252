import {
  ChatBubbleBottomCenterTextIcon,
  CheckCircleIcon,
} from "@heroicons/react/20/solid";
import { generateKey } from "../../../redux/functions/function";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'
import CardComponentModel from "../../../model/interface/CardComponentModel";
import Lottie from "lottie-react";
import loading from "../../../lottie/loading.json";

export default function CardComponent(props: CardComponentModel) {
  return (
    <ul className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 xl:grid-cols-3">
      {props.isRefreshing === true ? (
        <>
          <Skeleton height={140} width={500} count={1} />
          <Skeleton height={140} width={500} count={1} />
          {props.userData.role === 2 && (
            <Skeleton height={140} width={500} count={1} />
          )}
        </>
      ) : (
        <>
          {props.people.map((person: any, index: number) => (
            <li
              key={generateKey(person.name)}
              className="col-span-1 divide-y divide-gray-200 rounded-lg border bg-white shadow-sm"
            >
              {/* cja: header part of the card (start) */}
              <div className="flex w-full items-center justify-between space-x-6 p-4 md:p-6">
                <div className="flex-1 truncate">
                  <div className="flex items-center space-x-3">
                    {/* cja: this is the name of the participants */}
                    <h3
                      className={`truncate text-sm ${
                        person.role === "" || person.name === ""
                          ? " md:text-lg font-medium text-gray-400"
                          : " md:text-xl font-bold text-gray-900"
                      }`}
                    >
                      {/* cja: if avatar array is provided by the parent component */}
                      {props.avatars !== undefined &&
                        person.role === "Supplier" && (
                          <div className="flex -space-x-2 overflow-hidden">
                            {props.isFetchingAvatars ? (
                              <Lottie
                                className="md:w-10 w-14 h-auto mx-auto"
                                animationData={loading}
                                loop={true}
                              />
                            ) : (
                              <>
                                {props.avatars !== undefined && (
                                  <>
                                    {props.avatars.length === 0 ? (
                                      <span className="text-gray-400 font-normal text-base">
                                        No available suppliers
                                      </span>
                                    ) : (
                                      <>
                                        {props.avatars?.map((avatar) => (
                                          <img
                                            key={generateKey(
                                              Math.random().toString() +
                                                avatar.supplier_id
                                            )}
                                            className="inline-block h-10 w-10 rounded-full"
                                            src={
                                              avatar.company_logo === "-"
                                                ? "https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png"
                                                : avatar.company_logo
                                            }
                                            alt={`${avatar.company_logo} Logo`}
                                          />
                                        ))}
                                        {props.avatars?.length > 3 && (
                                          <span className="inline-flex flex-shrink-0 items-center rounded-full bg-dealogikal-100 px-3.5 py-1 text-xxs md:text-sm font-medium text-white">
                                            +{props.avatars?.length - 3}
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        )}
                      {/* cja: if avatar array is not provided by the parent component */}
                      {props.avatars !== undefined &&
                        person.role !== "Supplier" && <>{person.name}</>}
                      {props.avatars === undefined && (
                        <>
                          {person.role === "Supplier" ? (
                            <>
                              {person.name === ""
                                ? "No suppliers available"
                                : person.name}
                            </>
                          ) : (
                            <>{person.name}</>
                          )}
                        </>
                      )}
                    </h3>

                    {/* cja: this is the role of the participants */}
                    {person.role !== "" && (
                      <>
                        {/* cja: if avatar array is provided by the parent component */}
                        {props.avatars !== undefined &&
                          person.role !== "Supplier" && (
                            <span className="inline-flex flex-shrink-0 items-center rounded-full bg-dealogikal-100 px-3.5 py-0.5 text-xxs md:text-sm font-medium text-white">
                              {person.role}
                            </span>
                          )}
                      </>
                    )}
                  </div>
                </div>
                {props.avatars !== undefined ? (
                  <>
                    <div className="h-10 w-10"></div>
                  </>
                ) : (
                  // if avatars is undefined/not provided by the parent component
                  <>
                    {person.role === "" ? (
                      <span className="truncate text-sm md:text-lg font-medium text-gray-500">
                        -
                      </span>
                    ) : (
                      <>
                        {person.imageUrl !== "" ? (
                          // render person logo if any
                          <img
                            className="h-10 w-10 flex-shrink-0 rounded-full bg-gray-50"
                            src={person.imageUrl}
                            alt=""
                          />
                        ) : (
                          // no logo
                          <div className="h-10 w-10"></div>
                        )}
                      </>
                    )}
                  </>
                )}
              </div>
              {/* cja: header part of the card (end) */}

              {/* cja: footer part of the card (start) */}
              {/* show on mobile */}
              <div className="sm:hidden">
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    {index === 0 && props.userData.role === 2 ? (
                      <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-2 font-semibold text-gray-900 cursor-pointer"></span>
                    ) : (
                      <>
                        {person.count !== undefined && (
                          <>
                            {person.count === 0 ? (
                              <>
                                <span className="md:text-lg font-medium text-gray-400 pl-6 pt-4">
                                  -
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="relative inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-4  font-semibold text-gray-900 cursor-pointer"
                                  onClick={() =>
                                    props.cardActionClickHandler!(
                                      person.role,
                                      "others"
                                    )
                                  }
                                >
                                  <person.icon className="h-5 w-5 text-dealogikal-200" />
                                  <span className="text-dealogikal-200 text-xs ">{`${
                                    person.role
                                  } (${
                                    person.count !== undefined &&
                                    person.count
                                  })`}</span>
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              </div>
              {/* end show on mobile */}

              {/* show on desktop */}
              <div className="hidden sm:block">
                <div className="-mt-px flex divide-x divide-gray-200">
                  <div className="flex w-0 flex-1">
                    {index === 0 && props.userData.role === 2 ? (
                      <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-center gap-x-3 rounded-bl-lg border border-transparent py-2 md:py-4 text-xs md:text-sm font-semibold text-gray-900"></span>
                    ) : (
                      <>
                        {/* otherCount is related to supplier list overview */}
                        {person.count !== undefined && (
                          <>
                            {person.count === 0 ? (
                              <>
                                <span className="md:text-lg font-medium text-gray-400 pl-6 pt-4">
                                  -
                                </span>
                              </>
                            ) : (
                              <>
                                {/* if booking status is greater that 0, the booking process begin */}
                                {props.bookingStatus! > 0 && (
                                  <>
                                    {/* Booking view for buyers' terminal */}
                                    {props.userData.role === 1 && (
                                      <>
                                        {/* 1. For confirmation status */}
                                        {props.bookingStatus === 1 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-7 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Waiting for confirmation
                                            </span>
                                          </span>
                                        )}
                                        {/* 2. For unconfirmation status */}
                                        {props.bookingStatus === 2 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-7 md:text-sm font-semibold text-gray-900">
                                            <span className="text-red-700 text-xs md:text-sm">
                                              Unconfirmed Order
                                            </span>
                                          </span>
                                        )}
                                        {/* 3. For payment status */}
                                        {props.bookingStatus === 3 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-7 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Confirmed
                                            </span>
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {/* Booking confirmation for suppliers' terminal */}
                                    {props.userData.role === 2 && (
                                      <>
                                        {/* 1. For confirmation status */}
                                        {props.bookingStatus === 1 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Waiting for confirmation
                                            </span>
                                          </span>
                                        )}
                                        {/* 2. For unconfirmed status */}
                                        {props.bookingStatus === 2 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-red-700 text-xs md:text-sm">
                                              Unconfirmed Order
                                            </span>
                                          </span>
                                        )}
                                        {/* 3. For payment status */}
                                        {props.bookingStatus === 3 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Confirmed
                                            </span>
                                          </span>
                                        )}
                                      </>
                                    )}
                                    {/* Booking confirmation for logistics' terminal */}
                                    {props.userData.role === 6 && (
                                      <>
                                        {/* 1. For confirmation status */}
                                        {props.bookingStatus === 1 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Waiting for confirmation
                                            </span>
                                          </span>
                                        )}
                                        {/* 2. For unconfirmed status */}
                                        {props.bookingStatus === 2 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-red-700 text-xs md:text-sm">
                                              Unconfirmed Order
                                            </span>
                                          </span>
                                        )}
                                        {/* 3. For payment status */}
                                        {props.bookingStatus === 3 && (
                                          <span className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 md:text-sm font-semibold text-gray-900">
                                            <span className="text-dealogikal-200 text-xs md:text-sm">
                                              Confirmed
                                            </span>
                                          </span>
                                        )}
                                      </>
                                    )}
                                  </>
                                )}
                                {/* render the role with counts if booking status is undefined or it's value is 0 */}
                                {props.bookingStatus === undefined ||
                                  (props.bookingStatus === 0 && (
                                    <span
                                      className="relative -mr-px inline-flex w-0 flex-1 items-center justify-left gap-x-3 rounded-bl-lg border border-transparent py-2 sm:pl-3 md:py-4 md:pl-6 text-xs md:text-sm font-semibold text-gray-900 cursor-pointer"
                                      onClick={() =>
                                        props.cardActionClickHandler!(
                                          person.role,
                                          "others"
                                        )
                                      }
                                    >
                                      <person.icon className="h-5 w-5 text-dealogikal-200" />
                                      <span className="text-dealogikal-200 text-xs md:text-sm">{`${
                                        person.role
                                      } (${
                                        person.count !== undefined &&
                                        (person.count)
                                      })`}</span>
                                    </span>
                                  ))}
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}

                    {/* card chat button section (start) */}
                    <span
                      className="relative inline-flex w-0 flex-1 items-center justify-end gap-x-3 rounded-br-lg border border-transparent py-2 sm:pr-3 md:py-4 md:pr-6 text-xs md:text-sm font-semibold text-gray-900"
                      // onClick={() =>
                      //   props.cardActionClickHandler!(person.role, "chat")
                      // }
                    >
                      {person.count !== undefined && (
                        <>
                          {person.count === 0 ? (
                            <>
                              <span className="md:text-lg font-medium text-gray-400">
                                -
                              </span>
                            </>
                          ) : (
                            <>
                              {/* if booking status is greater that 0, the booking process begin */}
                              {props.bookingStatus! > 0 && (
                                <>
                                  {/* Booking view for buyers' terminal */}
                                  {props.userData.role === 1 && (
                                    <>
                                      {/* 1. For confirmation status */}
                                      {props.bookingStatus === 1 && (
                                        <span className="relative -mr-px inline-flex w-0 flex-1 gap-x-3 justify-end rounded-bl-lg border border-transparent py-2 sm:pl-3 md:pl-6 text-xs md:text-sm font-semibold text-gray-900">
                                          <div className="w-5 h-5 border-t-2 border-l-2 rounded-full border-dealogikal-50 animate-spin"></div>
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {/* Booking confirmation for suppliers' terminal */}
                                  {props.userData.role === 2 && index !== 0 && (
                                    <>
                                      {/* 1. For confirmation status */}
                                      {props.bookingStatus === 1 && (
                                        <span className="relative -mr-px inline-flex w-0 flex-1 gap-x-3 justify-end rounded-bl-lg border border-transparent py-2 sm:pl-3 md:pl-6 text-xs md:text-sm font-semibold text-gray-900">
                                          <div className="w-5 h-5 border-t-2 border-l-2 rounded-full border-dealogikal-50 animate-spin"></div>
                                        </span>
                                      )}
                                    </>
                                  )}
                                  {/* Booking confirmation for logistics' terminal */}
                                  {props.userData.role === 6 && index !== 0 && (
                                    <>
                                      {/* 1. For confirmation status */}
                                      {props.bookingStatus === 1 && (
                                        <span className="relative -mr-px inline-flex w-0 flex-1 gap-x-3 justify-end rounded-bl-lg border border-transparent py-2 sm:pl-3 md:pl-6 text-xs md:text-sm font-semibold text-gray-900">
                                          <div className="w-5 h-5 border-t-2 border-l-2 rounded-full border-dealogikal-50 animate-spin"></div>
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              )}
                              {/* render the chat icons if booking status is undefined or it's value is 0 */}
                              {props.bookingStatus === undefined ||
                                (props.bookingStatus === 0 && (
                                  <>
                                    <ChatBubbleBottomCenterTextIcon
                                      className="h-6 w-6 text-dealogikal-200 cursor-pointer"
                                      aria-hidden="true"
                                    />
                                    <span className="text-dealogikal-200 text-xs md:text-base cursor-pointer">
                                      <p className="inline-block">Chat</p>
                                    </span>
                                    <CheckCircleIcon
                                      className="h-6 w-6 text-dealogikal-200 self-end"
                                      aria-hidden="true"
                                    />
                                  </>
                                ))}
                            </>
                          )}
                        </>
                      )}
                    </span>
                    {/* card chat button section (end) */}
                  </div>
                </div>
              </div>
              {/* end show on desktop */}
              {/* cja: footer part of the card (end) */}
            </li>
          ))}
        </>
      )}
    </ul>
  );
}
