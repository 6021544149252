import { PlusIcon } from "@heroicons/react/20/solid";
import EmptyStateModel from "../../../model/interface/EmptyStateModel";

const EmptyStateComp: React.FC<EmptyStateModel> = (props) => {
  return (
    <div className="text-center mt-40">
      {props.hasCallToAction !== undefined && !props.hasCallToAction
        ? ""
        : props.icon}
      <h3 className="mt-2 text-base font-semibold text-gray-900">
        {props.primary_message}
      </h3>
      <p className="mt-1 text-sm text-gray-500">{props.secondary_message}</p>
      {props.hasCallToAction !== undefined && !props.hasCallToAction ? (
        <></>
      ) : (
        <div className="mt-6">
          <button
            type="button"
            className="inline-flex items-center rounded-full bg-dealogikal-200 p-4 text-sm font-semibold text-white shadow-sm hover:bg-dealogikal-100 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dealogikal-100"
            onClick={props.button_onclick_handler}
          >
            <PlusIcon className="-ml-0.5 mr-1.5 h-5 w-5" aria-hidden="true" />
            {props.button_text}
          </button>
        </div>
      )}
    </div>
  );
};

export default EmptyStateComp;
