import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import FeaturesComponent from "./features/FeaturesComponent";
import OrderStatusComponent from "./order-status/OrderStatusComponent";
import GettingStartedComponent from "./getting-started/GettingStartedComponent";

const DashboardComponent = () => {

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* Features component */}
            <FeaturesComponent />

            {/* Order status component */}
            <OrderStatusComponent />

            {/* Getting started component */}
            <GettingStartedComponent />
          </>
        }
      />
    </>
  );
};

export default DashboardComponent;
