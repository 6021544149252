import React, { useEffect, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import Skeleton from "react-loading-skeleton";
import TableComponent from "../../templates/TableComponent/TableComponent";
import { UserIcon } from "@heroicons/react/24/outline";
import { getDriverListAPI } from "../../../redux/functions/API";
import {
  generateKey,
  getTokenFromLocalStorage,
  isValidUrl,
} from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import { useAppSelector } from "../../../redux/app/hooks";
import { getDriverList } from "../../../redux/functions/storageSlice";
import DriverListType from "../../../model/types/DriverListType";

const DriversComp = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const navigate = useNavigate();
  const driverlist = useAppSelector(getDriverList);

  // useState
  const [isDriverListFetching, setIsDriverListFetching] =
    useState<Boolean>(true);
  const [driverListTableData, setDriverListTableData] = useState(driverlist);
  const [selectedDriver, setSelectedDriver] = useState<DriverListType[] | null>(
    driverlist
  );
  const [imageValid, setImageValid] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPhotoClick, setIsPhotoClick] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);

  // for Tab Navigation
  const [activeNav, setActiveNav] = useState<string>("Drivers List");

  // for Navigation Item
  const navItems = [{ text: "Drivers List" }, { text: "Upcoming ..." }];
  const setActiveNavTab = (activeItem: string) => {
    setActiveNav(activeItem);
  };

  // for Table Component Header
  const driversListTableHeader = [
    {
      name: "firstname",
      value: "Firstname",
      icon: UserIcon,
    },
    {
      name: "middlename",
      value: "Middlename",
      icon: UserIcon,
    },
    {
      name: "lastname",
      value: "Lastname",
      icon: UserIcon,
    },
  ];

  // for API Integration
  useEffect(() => {
    const logistic_id = userData._id;
    if (isDriverListFetching) {
      getDriverListAPI(_token, logistic_id).then((response: any) => {
        if (response.statusCode === 200) {
          const driverList = response.data.items.map(
            (item: any, index: number) => {
              return {
                ...item,
                row_id: item.id ?? index,
              };
            }
          );
          setDriverListTableData(driverList);
          setIsDriverListFetching(false);
        }
      });
    }
  }, [_token, isDriverListFetching, userData._id]);

  const handleImageClick = () => {
    setIsModalOpen(true);
    setIsPhotoClick(true);
  };

  const toggleZoom = () => {
    setIsZoomed((prevZoom) => !prevZoom);
  };

  const handleImageError = () => {
    setImageValid(false);
  };

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          <BreadcrumbContainerComponent subtitle="Drivers Management" />
          <NavTabComponent
            key={Math.random()}
            nav_type="secondary"
            active_tab={activeNav}
            nav_items={navItems}
            setActiveNavHandler={setActiveNavTab}
            left_content={null}
            right_content={
              <>
                {driverListTableData.length > 0 && (
                  <ButtonComponent
                    text="Add driver"
                    utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:my-0 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-1/3 w-full"
                    onClick={() => navigate("/l/drivers/add_driver")}
                  />
                )}
              </>
            }
          />
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
              {activeNav === "Drivers List" && (
                <>
                  {isDriverListFetching ? (
                    <div className="-my-2">
                      <Skeleton height={40} count={1} />
                      <Skeleton height={40} count={5} />
                    </div>
                  ) : (
                    <TableComponent
                      tableHeader={driversListTableHeader}
                      tableData={driverListTableData}
                      currentPage={1}
                      rowsPerPage={10}
                      hasRowClick={true}
                      idStringProperty="driver_id"
                      handleRowClick={(driver_id: string) => {
                        const selected = driverListTableData.find(
                          (driver) => driver.driver_id === parseInt(driver_id)
                        );
                        setSelectedDriver(selected ? [selected] : null);
                        setIsModalOpen(true);
                      }}
                      emptyState={{
                        primaryMessage: "No Drivers Available",
                        secondaryMessage: "Start uploading License",
                        buttonText: "Create Order",
                        buttonOnClick() {
                          navigate("/l/drivers/add_driver");
                        },
                      }}
                    />
                  )}
                </>
              )}
            </div>
          </div>
          <ModalComponent
            isOpen={isModalOpen}
            onCloseHandler={() => {
              setIsModalOpen(false);
            }}
            header={
              <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                <span className="text-white inline md:text-base text-xs">
                  Verifying Payment
                </span>
              </div>
            }
            body={
              selectedDriver ? (
                <div className="py-5 mx-5 text-gray-400">
                  {selectedDriver.map((item) => (
                      <div
                        //key={Math.random()}
                        className="grid grid-cols-1 xl:grid-cols-2 gap-4"
                        key={generateKey(
                          item.driver_id.toString() +
                            Math.random()
                        )}
                      >
                        {/**------------------------------------------------------------------- */}
                        <div className="flex flex-col">
                          <span className="text-ts">Driver's Name</span>
                          <div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
                            <p className="pl-3 text-gray-700 sm">
                              {item.firstname}
                            </p>
                          </div>
                          <span className="text-ts">Driver's Middle Name</span>
                          <div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
                            <p className="pl-3 text-gray-700 sm">
                              {item.middlename}
                            </p>
                          </div>
                          <span className="text-ts">Driver's Last Name</span>
                          <div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
                            <p className="pl-3 text-gray-700 sm">
                              {item.lastname}
                            </p>
                          </div>
                          <span className="text-ts">Driver's License Number</span>
                          <div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
                            <p className="pl-3 text-gray-700 sm">
                              {item.license_number}
                            </p>
                          </div>
                        </div>

                        <div className="flex flex-col">
                          <span className="text-ts">Driver's License</span>
                          <div className="bg-gray-100 rounded-md py-3 px-1 mt-2 xl:w-64 w-full">
                            <div className="flex justify-center items-center">
                              {isValidUrl(item.license_url) ? (
                                imageValid ? (
                                  <img
                                    src={item.license_url}
                                    alt="Drivers Information"
                                    className="mt-5 h-28 w-full px-1.5 cursor-pointer"
                                    onClick={handleImageClick}
                                    onError={handleImageError}
                                  />
                                ) : (
                                  <p className="text-center">
                                    No valid no valid driver's license image
                                    available.
                                  </p>
                                )
                              ) : (
                                <p className="text-center">
                                  No valid driver's license image URL provided.
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                  ))}
                  {isPhotoClick && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                      <div className="overflow-auto max-h-screen">
                        <img
                          src={
                            selectedDriver.find((item) => item.license_url)
                              ?.license_url
                          }
                          alt="Enlarged Deposit Slip"
                          className={`max-w-full h-auto transition-transform duration-300 ${
                            isZoomed
                              ? "transform scale-150"
                              : "transform scale-100"
                          }`}
                          onClick={toggleZoom}
                        />
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="flex flex-wrap justify-center">
                  <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                    <div className="md:px-6 px-5 justify-center">
                      <p>No deposit slip available.</p>
                    </div>
                  </div>
                </div>
              )
            }
          />
        </>
      }
    />
  );
};

export default DriversComp;
