import { useCallback, useEffect, useMemo, useState } from "react";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import { getReverseAuctionDetailsAPI } from "../../../../redux/functions/API";
import { useParams } from "react-router-dom";
import ReverseAuctionComponent from "../../../templates/ReverseAuctionComponent/ReverseAuctionComponent";
import { TruckIcon, UserIcon } from "@heroicons/react/24/outline";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
	getReverseAuctionDetails,
	setReverseAuctionDetails,
} from "../../../../redux/functions/storageSlice";

const BuyerReverseAuctionComp = () => {
	// get and decode the token from local storage
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);

	const [cardData, setCardData] = useState<any>([]);
	const dispatch = useAppDispatch();
	const reverseAuctionDetails = useAppSelector(getReverseAuctionDetails);
	const [isRefreshing, setisRefreshing] = useState<boolean>(true);
	const [supplierData, setSupplierData] = useState<any>([]); // Store supplier data here
	const [logisticData, setLogisticData] = useState<any>([]);
	const { reverse_auction_id } = useParams();

	const supplierDetails = useMemo(() => {
		return Array.isArray(supplierData) ? supplierData : [];
	}, [supplierData]);

	const selectedSupplier = useMemo(() => {
		return (
			supplierDetails.find((supplier: any) => supplier.is_selected) ||
			reverseAuctionDetails?.supplier_data?.cheapestSupplier
		);
	}, [supplierDetails, reverseAuctionDetails]);

	const accreditedLogistics = useMemo(() => {
		if (!logisticData || logisticData.length === 0 || !selectedSupplier) {
			return [];
		}
		return logisticData.filter(
			(logistic: any) =>
				logistic.accreditated_by.includes(selectedSupplier?.supplier_id) // Check if the supplier_id is accredited
		);
	}, [logisticData, selectedSupplier]);

	useEffect(() => {
		if (isRefreshing) {
			getReverseAuctionDetailsAPI(
				_token,
				reverse_auction_id ?? "",
				userData.role
			)
				.then((data: any) => {
					const {
						reverse_auction_data,
						buyer_data,
						supplier_data,
						logistic_data,
					} = data;

					// Check if all suppliers are not selected
					let selectedSupplier: any;
					// let accreditedLogistics = [];

					const allSuppliersNotSelected =
						supplier_data?.supplier_details?.every(
							(supplier: any) => !supplier.is_selected
						);

					// Check if all suppliers are not selected, fallback to cheapestSupplier
					if (allSuppliersNotSelected) {
						selectedSupplier = supplier_data?.cheapestSupplier;
					} else {
						selectedSupplier = supplier_data?.supplier_details?.find(
							(supplier: any) => supplier.is_selected
						);
					}

					// Check if logistic data has logistics accredited by the selected supplier
					const accredited = logistic_data?.logistic_details?.filter(
						(logistic: any) =>
							logistic?.accreditated_by[0] === selectedSupplier?.supplier_id
					) || [];

					dispatch(
						setReverseAuctionDetails({
							reverse_auction_data: reverse_auction_data || {},
							buyer_data: buyer_data || {},
							supplier_data: supplier_data || {},
							logistic_data: {
								...logistic_data,
								selected_logistic: accredited || {}, // Update with the selected logistic
							}, // Ensure logistic_data is passed
						})
					);

					const buyerDetailsOfferCard = {
						name:
							buyer_data?.buyer_details?.company_name || "No buyer available",
						icon: UserIcon,
						role: "Buyer",
						imageUrl:
							buyer_data?.buyer_details?.company_logo || "default-image-url",
						count: buyer_data?.buyer_details ? 1 : 0,
					};

					const supplierDetailsOfferCard = {
						name:
							selectedSupplier?.supplier_details?.company_name ||
							"No supplier available",
						icon: TruckIcon,
						role: "Supplier",
						imageUrl:
							selectedSupplier?.supplier_details?.company_logo ||
							"default-image-url",
						count: supplier_data?.supplier_details?.length || 0,
					};

					// Conditional logic for logistic card
					let logisticDetailsOfferCard;
					if (accredited.length > 0) {
						logisticDetailsOfferCard = {
							name:
								accredited[0]?.logistic_details?.company_name ||
								"Logistic available",
							icon: TruckIcon,
							role: "Logistic",
							imageUrl:
								accredited[0]?.logistic_details?.company_logo ||
								"default-image-url",
							count: accredited.length || 0, // Count based on accredited logistics
						};
					} else {
						logisticDetailsOfferCard = {
							name: "No logistic available",
							icon: TruckIcon,
							role: "Logistic",
							imageUrl: "default-image-url",
							count: 0,
						};
					}

					setCardData([
						buyerDetailsOfferCard,
						supplierDetailsOfferCard,
						logisticDetailsOfferCard,
					]);
					setSupplierData(supplier_data?.supplier_details || []);
					setLogisticData(logistic_data?.logistic_details || []);
					setisRefreshing(false);
				})
				.catch((error: any) => {
					console.error("Error fetching data:", error);
					setisRefreshing(false);
				});
		}
	}, [
		dispatch,
		_token,
		userData.role,
		isRefreshing,
		selectedSupplier?.supplier_id,
		accreditedLogistics,
		reverseAuctionDetails,
		selectedSupplier?.supplier_details?.company_logo,
		selectedSupplier?.supplier_details?.company_name,
		reverse_auction_id,
	]);

	const updateCardData = useCallback((updatedCardData: any) => {
		setCardData(updatedCardData);
	}, []);

	const refreshHandler = useCallback(() => {
		setisRefreshing(true);
	}, []);

	return (
		<>
			<ReverseAuctionComponent
				user_data={userData}
				card_data={cardData}
				reverse_auction_id={reverse_auction_id ?? ""}
				reverse_auction_details={reverseAuctionDetails}
				refreshHandler={refreshHandler}
				isRefreshing={isRefreshing}
				updateCardData={updateCardData}
			/>
		</>
	);
};

export default BuyerReverseAuctionComp;
