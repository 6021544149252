import { BreadcrumbModel } from "../../model/interface/BreadcrumbModel";
import StepType from "../../model/types/StepType";

export function generateKey(id: string) {
  return `${id}_${new Date().getTime()}_${Math.random()}`;
}

// DC: POST method implementation
export async function getFetch(url = "", token: string) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "GET", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: {
      "Content-Type": "application/json",
      Authorization: token ?? "",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

// DC: POST method implementation
export async function postFetch(url = "", data = {}, token?: string) {
  const headersParams: any = token
    ? {
        "Content-Type": "application/json",
        Authorization: token ?? "",
      }
    : {
        "Content-Type": "application/json",
      };

  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headersParams,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

// CJA: PUT method implementation
export async function putFetch(url = "", data = {}, token?: string) {
  const headersParams: any = token
    ? {
        "Content-Type": "application/json",
        Authorization: token ?? "",
      }
    : {
        "Content-Type": "application/json",
      };

  // Default options are marked with *
  const response = await fetch(url, {
    method: "PUT", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headersParams,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

// CJA: PUT method implementation
export async function patchFetch(url = "", data = {}, token?: string) {
  const headersParams: any = token
    ? {
        "Content-Type": "application/json",
        Authorization: token ?? "",
      }
    : {
        "Content-Type": "application/json",
      };

  // Default options are marked with *
  const response = await fetch(url, {
    method: "PATCH", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headersParams,
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

// DC: POST upload cloudinary method implementation
export async function postFetchUpload(url = "", data: any) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    redirect: "follow", // manual, *follow, error
    referrerPolicy: "no-referrer", // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    body: data, // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
}

//set our default timeout for modals
export function defaultModalTimeout() {
  return 2000;
}

//set our default timeout for modals
export function customModalTimeout() {
  return 500;
}

//set our default timeout for search
export function defaultSearchTimeout() {
  return 1000;
}

//set our default timeout for search
export function defaultSkeletonTimeout() {
  return 1000;
}
//set our default timeout for search
export function defaultCustomSkeletonTimeout() {
  return 500;
}

// created a function for manipulating our classess
export function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

// created a function here for removing an item from an array
export function arrayRemove(arr: any, value: any) {
  return arr.filter(function (ele: any) {
    return ele !== value;
  });
}

// DC: created a function for saving the token to local storage
export function saveTokenToLocalStorage(token: string) {
  localStorage.setItem("_token", token);
}

// DC: created a function for getting the token from local storage
export function getTokenFromLocalStorage() {
  return localStorage.getItem("_token");
}

// DC: created a function for getting the token from local storage
export function destoryTokenFromLocalStorage() {
  return localStorage.removeItem("_token");
}

// DC: created a redirection for features
export function manageBreadcrumb(item: BreadcrumbModel, callback: () => void) {
  // declare flag
  let isExists = false;

  // get our session for breadcrumb
  const breadcrumb = sessionStorage.getItem("breadcrumb") ?? "";

  // manipulate the breadcrumb data
  let newBreadcrumbArr =
    breadcrumb.length !== 0 ? [...JSON.parse(breadcrumb)] : [];

  // let's loop the bread crumb array
  for (const breadcrumbArray of newBreadcrumbArr) {
    // then lets check if it exists from the array
    if (breadcrumbArray.name === item.name) {
      isExists = true; //then return true
    }
  }

  // check if exists
  if (isExists) {
    // get the index of the item
    const itemIndex = newBreadcrumbArr.findIndex((x) => x.name === item.name);
    // then remove the children arrays
    const finalBreadCrumbArr = newBreadcrumbArr.filter(
      (val, index) => index <= itemIndex
    );
    sessionStorage.setItem("breadcrumb", JSON.stringify(finalBreadCrumbArr));
    callback();
  } else {
    // if not exists then just simply add it to array
    newBreadcrumbArr = [
      ...newBreadcrumbArr,
      {
        id: item.id,
        name: item.name,
        href: item.href,
      },
    ];
    sessionStorage.setItem("breadcrumb", JSON.stringify(newBreadcrumbArr));
    callback();
  }
}

// breadcrumb for buyer vehicle
export function manageBuyerBreadcrumb(item: BreadcrumbModel, callback: () => void) {
  // declare flag
  let isExists = false;

  // get our session for breadcrumb
  const breadcrumb = sessionStorage.getItem("buyerbreadcrumb") ?? "";

  // manipulate the breadcrumb data
  let newBreadcrumbArr =
    breadcrumb.length !== 0 ? [...JSON.parse(breadcrumb)] : [];

  // let's loop the bread crumb array
  for (const breadcrumbArray of newBreadcrumbArr) {
    // then lets check if it exists from the array
    if (breadcrumbArray.name === item.name) {
      isExists = true; //then return true
    }
  }

  // check if exists
  if (isExists) {
    // get the index of the item
    const itemIndex = newBreadcrumbArr.findIndex((x) => x.name === item.name);
    // then remove the children arrays
    const finalBreadCrumbArr = newBreadcrumbArr.filter(
      (val, index) => index <= itemIndex
    );
    sessionStorage.setItem("buyerbreadcrumb", JSON.stringify(finalBreadCrumbArr));
    callback();
  } else {
    // if not exists then just simply add it to array
    newBreadcrumbArr = [
      ...newBreadcrumbArr,
      {
        id: item.id,
        name: item.name,
        href: item.href,
      },
    ];
    sessionStorage.setItem("buyerbreadcrumb", JSON.stringify(newBreadcrumbArr));
    callback();
  }
}

// cja: function in managing steps
export function manageSteps(next_step: StepType, steps: StepType[]) {
  // return a promise to trigger the caller that the step update is done
  return new Promise((resolve, reject) => {
    // process start
    let new_steps: StepType[] = [];
    let param_step_idx: number;

    // locate & get the index of the ste parameter
    param_step_idx = steps.findIndex((s: StepType) => {
      return s.name === next_step.name;
    });

    // loop the starting step
    steps.forEach((step: StepType) => {
      // check if the looped current step equals to the step parameter
      if (step.name === next_step.name) {
        new_steps = [...new_steps, next_step];
      } else {
        // get the indexof the looped current step
        let step_idx = steps.findIndex((s: StepType) => {
          return s.name === step.name;
        });

        // to save step status
        let status: string = "";
        if (param_step_idx < step_idx) {
          status = "upcoming";
        } else if (param_step_idx > step_idx) {
          status = "complete";
        }
        new_steps = [
          ...new_steps,
          {
            name: step.name,
            href: step.href,
            status: status,
          },
        ];
      }
    });

    // cja: after the process, remove the existing steps in the session storage
    // and replace with the updated
    sessionStorage.removeItem("steps");
    sessionStorage.setItem("steps", JSON.stringify(new_steps));

    resolve(new_steps);
  });
}

// DC: created a function for removing the localstorage sessions
export function removeLocalStorageData() {
  sessionStorage.removeItem("breadcrumb");
  sessionStorage.removeItem("steps");
}

// DC: created a function for returning a peso sign in a string
export function pesoSign() {
  return "\u20B1";
}

// DC: created a function for formatting the number to add comma when it reach 4 digits
export function formatNumberWithComma(number: number | string) {
  // unformat parameter by removing the existing comma & spaces
  let to_format_value = "";
  to_format_value = number.toString().replaceAll(",", "");

  // reformat the value
  let formatted_value = "";
  // Check if the number has reached four digits
  if (to_format_value.length > 3) {
    // Insert commas every three digits from the end
    formatted_value = to_format_value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    formatted_value = to_format_value;
  }

  // Return the modified number string
  return formatted_value;
}

// create a function for removing all the comma
export function formatNumberWithoutComma(value: string) {
  return value.replaceAll(",", "");
}

// DC: created a function for generating a random integers with minimum and maximum requirement
export function generateRandomNumber(min: number, max: number) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

// CJA: function for comparing 2 values for validation
// maximum mas
export function compareValues(maximum: string, minimum: string) {
  const max = maximum.replaceAll(",", "");
  const min = minimum.replaceAll(",", "");
  return parseInt(max) > parseInt(min) ? true : false;
}

// cja: function that will sort an array of objects
// parameters:
// arr ---> the array to be sort
// base_property ---> the property of the objects inside the array to be the basis in sorting
// base_type ---> (e.g., alphabet, numeric, etc.)
export const sortArrayOfObjects = (
  arr: any,
  base_property: keyof any,
  base_type: string
) => {
  arr.sort(function (a: any, b: any) {
    var result = 0;
    if (base_type === "alphabet") {
      var textA = a[base_property].toString().toLowerCase();
      var textB = b[base_property].toString().toLowerCase();
      result = textA < textB ? -1 : textA > textB ? 1 : 0;
    } else if (base_type === "numeric") {
      // to be enchance
    }
    return result;
  });
};

// cja: function that will format decimal in numeric values
export const formatDecimalPlace = (value: number, place: number) => {
  const formattedValue = value.toFixed(place);
  return formattedValue;
};

// cja: function that will format date string into readable value
export const formatToReadableDate = (date_string: string) => {
  const date = new Date(date_string);

  // initialize date options
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  // convert date string into readable date with the options initialized above
  const formattedDate = date.toLocaleDateString("en-US", options);
  return formattedDate;
};
// DC: created a function for converting the seconds to Day, Hour and Minutes
export function convertSecondsToDHMS(seconds: number) {
  seconds = Number(seconds);
  var d = Math.floor(seconds / (3600 * 24));
  var h = Math.floor((seconds % (3600 * 24)) / 3600);
  var m = Math.floor((seconds % 3600) / 60);
  var s = Math.floor(seconds % 60);

  var dDisplay = d > 0 ? d + (d === 1 ? " day, " : " days, ") : "";
  var hDisplay = h > 0 ? h + (h === 1 ? " hour, " : " hours, ") : "";
  var mDisplay = m > 0 ? m + (m === 1 ? " minute, " : " minutes, ") : "";
  var sDisplay = s > 0 ? s + (s === 1 ? " second" : " seconds") : "";

  const output = dDisplay + hDisplay + mDisplay + sDisplay;
  const parsedOutput = output.split(", ");

  return `${parsedOutput[0]} remaining`;
}

// create a function for converting seconds to HH:MM:SS
export function convertSecondsToHHMMSS(seconds: number) {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  const hh = String(hours).padStart(2, "0");
  const mm = String(minutes).padStart(2, "0");
  const ss = String(remainingSeconds).padStart(2, "0").substr(0, 2);

  return `${hh}:${mm}:${ss}`;
}

export function getTotalNumberOfDaysInMonth(
  currentMonth: number,
  currentYear: number
) {
  return new Date(currentYear, currentMonth, 0).getDate();
}

// set our default operation lead time
export function getOperationLeadTime() {
  return 1;
}

// set our default text for the Bank Cut-Off
export function getDefaultBankCutOffMessage(cut_off: string) {
  return `Bank Payment Cut-off is ${cut_off}`;
}

// set our default text for the Operation Cut-Off
export function getDefaultOperationCutOffMessage(cut_off: string) {
  return `Active Quotation Cut-off time is ${cut_off}`;
}

// count the decimal points inside a string value
export function countDecimalPoints(val: string) {
  // Match all occurrences of the decimal point in the string
  const decimalMatches = val.match(/\./g);

  // Return the count of decimal points or 0 if there are none
  return decimalMatches ? decimalMatches.length : 0;
}


// add by Rey: Regex Validation for URL (for image fetching in modal)
export function isValidUrl(url: string): boolean {
  const urlPattern = new RegExp(
    "^(https?:\\/\\/)?" + // protocol
    "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
    "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
    "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
    "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
    "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i" // case-insensitive
  );
  return urlPattern.test(url);
}