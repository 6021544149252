import { ChangeEvent, useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import {
	ArrowDownCircleIcon,
	ArrowUpTrayIcon,
	CircleStackIcon,
	CloudArrowUpIcon,
	PhotoIcon,
	TruckIcon,
	UserIcon,
	XCircleIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import CircularLoading from "../../../lottie/circular_loading_theme_1.json";
import TableComponent from "../../templates/TableComponent/TableComponent";
import {
	//generateKey,
	getTokenFromLocalStorage,
} from "../../../redux/functions/function";
import {
	//getATL_list_API,
	//getATLDetailsAPI,
	getATW_API,
	getCloseOrdersAPI,
	uploadImageSalesOrder,
	verificationAPI,
} from "../../../redux/functions/API";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import DropdownItemType from "../../../model/types/DropdownItemType";
import DropdownOptionComponent from "../../templates/DropdownOptionComponent/DropdownOptioncomponent";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
	getCloseOrders,
	getCommentDetails,
	setCloseOrdersList,
	setForATWTableList,
	getforATWTablelist,
	setCommentDetails,
	//setForATLTAbleList,
	setForATLTableDetails,
	getForATLTableDetails,
} from "../../../redux/functions/storageSlice";
import { saveAs } from "file-saver";
import html2canvas from "html2canvas";
import { PDFDocument } from "pdf-lib";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import CloseOrdersListType from "../../../model/types/CloseOrdersListType";
import ToasterComponent from "../../templates/ToasterComponent/ToasterComponent";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import {
	ATLTableDetailsType,
	//ATLTablelistType,
	ATWTablelistType,
} from "../../../model/types/Authorization";
import Lottie from "lottie-react";

const SalesOrderManagementComp = () => {
	const dispatch = useAppDispatch();
	const commentsDetails = useAppSelector(getCommentDetails);
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);
	const mySales = useAppSelector(getCloseOrders);
	const ATWList = useAppSelector(getforATWTablelist);
	const ATLDetails = useAppSelector(getForATLTableDetails);

	const cloudName = "doehyebmw";
	const uploadPreset = "qny9eay8";

	const contentRef = useRef<HTMLDivElement>(null);

	const [activeNav, setActiveNav] = useState<string>("Close Order");
	const [salesOrderLists, setSalesOrderLists] = useState<CloseOrdersListType[]>(
		mySales.closeOrdersList
	);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isLoadingAPI, setIsLoadingAPI] = useState(false);
	const [isDownloadingPDF, setIsDownloadingPDF] = useState<boolean>(false);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isModalImage, setIsModalImage] = useState<boolean>(false);
	const [isModalUploadOpen, setIsModalUploadOpen] = useState(false);
	const [isATW_ModalOpen, setIsATW_ModalOpen] = useState(false);
	const [selectedOption, setSelectedOption] = useState<string>("");
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const { socket, sendWebSocketMessage } = useWebSocket();

	const [isCommentDetailsEmpty, setIsCommentDetailsEmpty] =
		useState<boolean>(false);
	const [isCloseOrderFetching, setIsCloseOrderFetching] =
		useState<boolean>(true);
	const [isATWFetching, setIsATWFetching] = useState<boolean>(true);
	const [closeOrderListDetails, setCloseOrderListDetails] = useState<
		CloseOrdersListType[]
	>(mySales.closeOrdersList);
	const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
	const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [error, setError] = useState<string>("");
	const [soNumber, setSONumber] = useState<string>("");
	const [soNumberIsError] = useState<boolean>(false);
	const [uploadSuccess, setUploadSuccess] = useState<string>("");

	const [toastMessage, setToastMessage] = useState<string>("");
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastType, setToastType] = useState<string>("");
	const [isUploadDone, setIsUploadDone] = useState<boolean>(true);
	const [salesOrderURL, setSalesOrderURL] = useState<string>("");
	const [forATWTablePageList, setForATWTablePageList] =
		useState<ATWTablelistType[]>(ATWList);
	const [forATLTablePageDetails, setForATLTablePageDetails] =
		useState<ATLTableDetailsType[]>(ATLDetails);

	const selectedCloseOrderDetails = mySales.closeOrdersList.find(
		(list: any) => list.disbursement_details_id === selectedRowId
	);

	const [selectedSupplierFiles, setSelectedSupplierFiles] = useState<File[]>(
		[]
	);

	const clearErrors = () => {
		setIsCommentDetailsEmpty(false);
	};

	const [selectedOrder, setSelectedOrder] =
		useState<CloseOrdersListType | null>(null);

	const [selectedATL, setSelectedATL] = useState<ATLTableDetailsType | null>(
		null
	);
	const nav_items = [
		{
			text: "Close Order",
		},
		{
			text: "For Uploading SO",
		},
		{
			text: "ATW",
		},
	];

	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	// Table Headers
	const salesOrderHeader = [
		{
			name: "so_number",
			value: "SO Number",
			icon: UserIcon,
		},
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "calculate_amount",
			value: "Amount",
			icon: CircleStackIcon,
		},
		{
			name: "is_disbursement_verified",
			value: "Status",
			icon: ArrowDownCircleIcon,
			customRender: (item: any) => (
				<span
					className={
						item.is_disbursement_verified === "Upload SO"
							? "text-dealogikal-50"
							: item.is_disbursement_verified === "Verified"
							? "text-green-500"
							: ""
					}
				>
					{item.is_disbursement_verified}
				</span>
			),
		},
	];

	// Table Header
	const closeOrderHeader = [
		{
			name: "buyer_name",
			value: "Buyer",
			icon: UserIcon,
		},
		{
			name: "logistic_name",
			value: "Logistic",
			icon: TruckIcon,
		},
		{
			name: "plate_number",
			value: "Plate #",
			icon: TruckIcon,
		},
		{
			name: "calculate_amount",
			value: "Amount",
			icon: TruckIcon,
		},
		{
			name: "is_disbursement_verified",
			value: "Status",
			icon: ArrowDownCircleIcon,
			customRender: (item: any) => (
				<span
					className={
						item.is_disbursement_verified === "For Verifying"
							? "text-orange-500"
							: item.is_disbursement_verified === "Verified"
							? "text-green-500"
							: ""
					}
				>
					{item.is_disbursement_verified}
				</span>
			),
		},
	];

	const ATWHeader = [
		{
			name: "logistic_name",
			value: "Logistic Name:",
			icon: UserIcon,
		},
		{
			name: "delivery_location_details",
			value: "Ship to:",
			icon: UserIcon,
		},
		{
			name: "delivery_date",
			value: "Delivery Date",
			icon: UserIcon,
			customRender: (item: any) => {
				const formatDate = (dateString: string) => {
					const date = new Date(dateString);

					// Check if the date is valid
					if (isNaN(date.getTime())) {
						console.error(`Invalid date string: ${dateString}`);
						return "--/--/----"; // Placeholder for invalid dates
					}

					const day = date.getDate().toString().padStart(2, "0");
					const month = (date.getMonth() + 1).toString().padStart(2, "0");
					const year = date.getFullYear();

					return `${day}/${month}/${year}`;
				};

				return (
					<span
						className={
							new Date(item.delivery_date) < new Date()
								? "text-red-500" // For past dates
								: "text-blue-500" // For future dates
						}
					>
						{formatDate(item.delivery_date)}
					</span>
				);
			},
		},
	];

	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtcommentsdetails":
				dispatch(setCommentDetails(value));
				break;
			default:
				break;
		}
	};

	const dropdown_items: DropdownItemType[] = [
		{
			item_text: "Verify",
			onClick: () => setSelectedOption("Verify"),
		},
		{
			item_text: "Reconcile",
			onClick: () => setSelectedOption("Reconcile"),
		},
	];

	// fetching close order
	useEffect(() => {
		if (isCloseOrderFetching && mySales.closeOrdersList.length === 0) {
			getCloseOrdersAPI(_token, 2, userData._id).then((response: any) => {
				if (response.statusCode === 200) {
					setIsCloseOrderFetching(false);
					const disbursementId = response.data.items.map(
						(item: any, index: number) => {
							const formattedAmount = new Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "PHP", // Change currency as per your requirement
							}).format(parseFloat(item.calculate_amount));
							return {
								...item,
								row_id: item.id ?? index,
								calculate_amount: formattedAmount,
								shipping_method_id:
									item.shipping_method_id === 2 ? "Pick-up" : "Delivery",
								is_disbursement_verified:
									item.is_disbursement_verified === 0
										? "For Verifying"
										: "Verified",	
							};
						}
					);
					dispatch(setCloseOrdersList(disbursementId));
				} else {
					console.log("failed to fetch disbursement list");
				}
			});
		} else if (mySales.closeOrdersList.length !== 0) {
			//type CloseOrdersListTypeWithRowId = CloseOrdersListType & {row_id: number;};

			let updatedListOfCloseOrders: CloseOrdersListType[] = [];
			let updatedListOfSalesOrders: CloseOrdersListType[] = [];
            
			//let salesOrdersRowIdCounter = 1;
			// if (Array.isArray(paymentList.disbursementList)) {
			mySales.closeOrdersList.forEach((orders: any, index: number) => {
				const newCloseOrder = {
					buyer_id: orders.buyer_id,
					buyer_name: orders.buyer_name,
					calculate_amount: orders.calculate_amount,
					delivery_date: orders.delivery_date,
					deposit_slip_url: orders.deposit_slip_url,
					disbursement_details_id: orders.disbursement_details_id,
					disbursement_slip_url: orders.disbursement_slip_url,
					is_disbursement_verified:
						orders.is_disbursement_verified === "Verified"
							? "SO Uploaded"//"Upload SO"
							: orders.is_disbursement_verified,
					is_payment_verified: orders.is_payment_verified,
					logistic_name: orders.logistic_name,
					payment_details_id: orders.payment_details_id,
					plate_number: orders.plate_number,
					reverse_auction_id: orders.reverse_auction_id,
					shipping_method_id: orders.shipping_method_id,
					user_id: orders.user_id,
					order_details_id: orders.order_details_id,
					offer_bookings_id: orders.offer_bookings_id,
					is_SoUpload:orders.sales_order_number || orders.sales_order_url || orders.so_is_verified
										? "SO Uploaded"
										: "For Upload SO"
				} as CloseOrdersListType; // Type assertion

				if (newCloseOrder.is_disbursement_verified === "Upload SO") {
					updatedListOfSalesOrders.push(newCloseOrder);
				} else {
					updatedListOfCloseOrders.push(newCloseOrder);
				}
			});

			//  }
			setSalesOrderLists(updatedListOfSalesOrders);
			setCloseOrderListDetails(updatedListOfCloseOrders);
			setIsCloseOrderFetching(false);
		}
	}, [
		isCloseOrderFetching,
		sendWebSocketMessage,
		socket,
		userData._id,
		_token,
		mySales.closeOrdersList.length,
		dispatch,
		mySales.closeOrdersList,
	]);

	//fetching ATW
	useEffect(() => {
		const updatedATWList: ATWTablelistType[] = [];

		if (isATWFetching && ATWList.length === 0) {
			getATW_API(_token).then((response: any) => {
				if (response.statusCode === 200) {
					setIsATWFetching(false);
					const newATWList = response.data.items.map((item: any) => {
						return {
							...item,
						};
					});
					dispatch(setForATWTableList(newATWList));
				}
			});
		} else if (ATWList.length !== 0) {
			ATWList.forEach((list: any) => {
				const newATWList = {
					...list,
				};
				updatedATWList.push(newATWList);
			});
			setForATWTablePageList(updatedATWList);
			setIsATWFetching(false);
		}
	}, [isATWFetching, ATWList, _token, dispatch]); // Add dependencies to avoid stale closures

	// download pdf ATW
	const handlePDFDownload = async () => {
		const content: any = contentRef.current;
		try {
			setIsLoadingAPI(true);
			setIsDownloadingPDF(true);
			setIsButtonDisabled(true);
			// Capture the HTML content as an image
			const canvas = await html2canvas(content, { scale: 3 });

			const imgData = canvas.toDataURL("image/png");
			// Create a PDF document
			const pdfDoc = await PDFDocument.create();
			const page = pdfDoc.addPage([canvas.width, canvas.height]);

			// Embed the image in the PDF document
			const img = await pdfDoc.embedPng(imgData);
			page.drawImage(img, {
				x: 0,
				y: 0,
				width: canvas.width,
				height: canvas.height,
			});

			// Save the PDF and download it
			const pdfBytes = await pdfDoc.save();
			const blob = new Blob([pdfBytes], { type: "application/pdf" });
			saveAs(blob, "ATW.pdf");

			// Reset states after download is complete
			setIsLoadingAPI(false);
			setIsDownloadingPDF(false);
			setIsButtonDisabled(false);
		} catch {
			console.log("error");

			// Reset states if an error occurs
			setIsLoadingAPI(false);
			setIsDownloadingPDF(false);
			setIsButtonDisabled(false);
		}
	};

	//fetching ATL Doc
	// useEffect(() => {
	//   if (ATWList.length === 0) return; // Handle empty list

	//   // Select the logistic_id from the first item in ATWList
	//   const logisticID = ATWList[0].logistic_id;

	//   if (isATL_Fetching) {
	//     getATL_list_API(_token, logisticID).then((response: any) => {
	//       setIsATL_Fetching(false);
	//       if (response.statusCode === 200) {
	//         const newATLlist = response.data.items
	//           .filter((item: any) => item.atl_details.length > 0)
	//           .map((item: any) => ({
	//             ...item,
	//             buyer_shipping_method:
	//               item.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
	//           }));
	//         dispatch(setForATLTAbleList(newATLlist));
	//       }
	//     });
	//   } else if (ATLlist.length !== 0) {
	//     const updatedATLlist: ATLTablelistType[] = [];
	//     ATLlist.forEach((list: any) => {
	//       const newATLlist = {
	//         ...list,
	//         buyer_shipping_method_label:
	//           list.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
	//       };
	//       updatedATLlist.push(newATLlist);
	//     });
	//     setForATLTablePageList(updatedATLlist);
	//     setIsATL_Fetching(false);
	//   }
	// }, [isATL_Fetching, ATLlist, ATWList, _token, dispatch, userData._id]);

	const showImageImportHandler = (index: number) => {
		//setIsVendorModalOpen(false);
		setIsModalUploadOpen(false);
		setIsModalImage(true);

		const file = selectedSupplierFiles[index];
		const reader = new FileReader();
		reader.onloadend = () => {
			setSelectedImage(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	const removeChipHandler = (index: number) => {
		const updatedFiles = [...selectedSupplierFiles];
		updatedFiles.splice(index, 1);
		setSelectedSupplierFiles(updatedFiles);
	};

	const importImageHandler = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);

		const files = Array.from(event.dataTransfer.files);
		handleFiles(files);
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);
	};

	const handleFiles = (files: File[]) => {
		let totalSize = 0;
		files.forEach((file) => {
			totalSize += file.size;
		});

		const maxSize = 5 * 1024 * 1024; // 5MB in bytes
		if (totalSize > maxSize) {
			setError("Total file size exceeds 5MB. Please upload smaller files.");
			return;
		}

		setSelectedSupplierFiles((prevFiles) => [...prevFiles, ...files]);
		setError(""); // Reset error if the file size is within the limit
	};

	const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const files = Array.from(event.target.files || []);
		handleFiles(files);
	};

	const closeSelectedImage = () => {
		setIsModalUploadOpen(true);
		setSelectedImage(null);
		setIsModalImage(false);
	};

	const uploadImageHandler = async () => {
		if (selectedSupplierFiles.length > 1) {
			setToastMessage("Please upload one receipt only");
			setToastType("error");
			setIsToastOpen(true);
			setTimeout(() => {
				setIsToastOpen(false);
			}, 3000);
			return;
		}

		const formData = new FormData();
		formData.append("file", selectedSupplierFiles[0]);
		formData.append("upload_preset", uploadPreset); // Your upload preset from Cloudinary

		try {
			const response = await fetch(
				`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
				{
					method: "POST",
					body: formData,
				}
			);
			const dataImageUpload = await response.json();

			if (dataImageUpload.error) {
				setError(dataImageUpload.error.message);
			} else {
				setIsUploadDone(true);
				setSalesOrderURL(dataImageUpload.url);
				setSelectedSupplierFiles([]); // Clear the selected files after upload
			}
		} catch (error) {
			setError("Error uploading to Cloudinary");
		}
	};

	useEffect(() => {
		if (isUploadDone && salesOrderURL !== "") {
			const data = {
				order_details_id: selectedOrder?.order_details_id ?? 0,
				offer_bookings_id: selectedOrder?.offer_bookings_id ?? 0,
				sales_order_number: soNumber,
				sales_order_url: salesOrderURL,
			};

			uploadImageSalesOrder(_token, data).then((response: any) => {
				if (response.statusCode === 200) {
					setUploadSuccess("Successfully upload to Supplier");
					setIsModalUploadOpen(false);
					setToastMessage("Successfully Uploaded Sales Order");
					setIsToastOpen(true);
					setTimeout(() => {
						setIsToastOpen(false);
					}, 3000);
				} else if (response.statusCode === 400) {
					setIsModalUploadOpen(false);
					setToastMessage("Unsuccessful Upload Sales Order");
					setToastType("error");
					setIsToastOpen(true);
					setTimeout(() => {
						setIsToastOpen(false);
					}, 3000);
				}
			});
		}
	}, [
		isUploadDone,
		_token,
		soNumber,
		salesOrderURL,
		selectedOrder?.order_details_id,
		selectedOrder?.offer_bookings_id,
	]);

	// handler for updating status
	const verificationHandler = () => {
		let statusId: number;

		// Convert the string status back to a number
		switch (selectedCloseOrderDetails?.is_disbursement_verified) {
			case "For Verifying":
				statusId = 1;
				break;
			default:
				statusId = 0; // Default to 0 if status is unknown
		}

		const data: any = {
			status_id: statusId,
			disbursement_details_id:
				selectedCloseOrderDetails?.disbursement_details_id,
			note: commentsDetails,
		};

		verificationAPI(_token, data).then((response: any) => {
			if (response.statusCode === 200) {
				setCloseOrderListDetails((prevList) =>
					prevList.filter(
						(item) =>
							item.disbursement_details_id !==
							selectedCloseOrderDetails?.disbursement_details_id
					)
				);

				if (socket && socket.readyState === WebSocket.OPEN) {
					const socketPayload = {
						title: "Payment Verification",
						message: `${userData.company} has approved your payment.`,
						user_id: selectedCloseOrderDetails?.buyer_id,
						notification_type_id: 1,
						source_id: statusId,
					};
					sendWebSocketMessage("sendNotification", socketPayload);
				}

				if (socket && socket.readyState === WebSocket.OPEN) {
					const socketPayload = {
						title: "Payment Disbursement",
						message: `${userData.company} has verified your payment.`,
						user_id: 1,
						notification_type_id: 1,
						source_id: statusId,
					};
					sendWebSocketMessage("sendNotification", socketPayload);
				}

				clearErrors();
				dispatch(setCommentDetails(""));
				setIsModalOpen(false);
				setToastMessage("Successfully Verified");
				setToastType("success");
				setTimeout(() => {
					setIsToastOpen(false);
				}, 3000);
			}
		});
	};

	const handleRowClickUploadSO = (disbursement_details_id: string) => {
		const selected = salesOrderLists.find(
			(order) =>
				order.disbursement_details_id === parseInt(disbursement_details_id)
		);
		setSelectedRowId(parseInt(disbursement_details_id));
		setSelectedOrder(selected || null);
		setIsModalUploadOpen(true);
	};

	useEffect(() => {
		// Synchronize state with ATLDetails if ATLDetails is updated
		if (ATLDetails.length > 0) {
			// Create a new array reference to trigger React re-render
			const updatedDetails = ATLDetails.map((details) => ({
				...details,
				buyer_shipping_method_label:
					details.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
			}));
			setForATLTablePageDetails(updatedDetails);
		}
	}, [ATLDetails]); // Dependency array ensures this runs when ATLDetails changes

	// Assuming you have these state variables defined in your component
	const [prevLogisticId, setPrevLogisticId] = useState<string | null>(null);
	const [prevAtwId, setPrevAtwId] = useState<string | null>(null);

	const handleATWRowClick = async (atw_id: string) => {
		try {
			// Convert `atw_id` to a number for internal use
			const atwIdNum = Number(atw_id);

			let selected = forATLTablePageDetails.find(
				(list: ATLTableDetailsType) =>
					list.atl_details && // Ensure atl_details is defined
					//list.atl_details.length > 0 && // Ensure atl_details is not empty
					list.atl_details.atl_id === atwIdNum // Access atl_id safely
			);

			const selectedATW = ATWList.find((item) => item.atw_id === atwIdNum);

			if (!selected) {
				if (!selectedATW) {
					setToastMessage("ATL with the specified ID not found.");
					setToastType("error");
					setIsToastOpen(true);
					return;
				}

				const { logistic_id } = selectedATW;

				// Convert logistic_id and atw_id to strings for comparison
				const logisticIdStr = String(logistic_id);
				const atwIdStr = atw_id;

				const isLogisticIdChanged = logisticIdStr !== prevLogisticId;
				const isAtwIdChanged = atwIdStr !== prevAtwId;
				const shouldFetch = isLogisticIdChanged || isAtwIdChanged;

				if (shouldFetch) {
					const response: any = await getATW_API(_token, parseInt(atwIdStr));
					if (response.statusCode === 200) {
						const newATLDetails = response.data.items.map((item: any) => ({
							...item,
							buyer_shipping_method:
								item.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
						}));
						dispatch(setForATLTableDetails(newATLDetails));
						setForATLTablePageDetails(newATLDetails);

						selected = newATLDetails.find(
							(list: ATLTableDetailsType) =>
								list.atl_details && // Ensure atl_details is defined
								//list.atl_details.length > 0 && // Ensure atl_details is not empty
								list.atl_details.atl_id === atwIdNum
						);
					}

					// Update previous values
					setPrevLogisticId(logisticIdStr);
					setPrevAtwId(atwIdStr);
				} else {
					if (ATLDetails.length === 0) {
						const updatedATLDetails: ATLTableDetailsType[] = ATLDetails.map(
							(detailslist: any) => ({
								...detailslist,
								buyer_shipping_method_label:
									detailslist.buyer_shipping_method === 1
										? "Delivery"
										: "Pick-up",
							})
						);

						setForATLTablePageDetails(updatedATLDetails);

						selected = updatedATLDetails.find(
							(list: ATLTableDetailsType) =>
								list.atl_details.atl_id === atwIdNum
						);
					}
				}
			}

			if (selected) {
				setSelectedRowId(atwIdNum);
				setSelectedATL(selected);
				setIsATW_ModalOpen(true);
			} else {
				setToastMessage("ATL with this specific Logistics not found.");
				setToastType("error");
				setIsToastOpen(true);
			}
		} catch (error) {
			console.error("Error in handleATWRowClick:", error);
		}
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<ToasterComponent
							isOpen={isToastOpen}
							label={toastMessage}
							onClose={setIsToastOpen}
							type={toastType}
						/>
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="My Sales"
						/>
						<NavTabComponent
							key={Math.random()}
							nav_type="secondary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
							left_content={
								<></>
								// <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full -z-10">
								//   <input
								//     type="text"
								//     name="account-number"
								//     id="account-number"
								//     className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
								//     placeholder="Search template"
								//   />
								//   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5 ">
								//     <MagnifyingGlassIcon
								//       className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
								//       aria-hidden="true"
								//     />
								//   </div>
								// </div>
							}
						/>
						{/**Start here*/}
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "For Uploading SO" && (
									<>
										{isCloseOrderFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={salesOrderHeader}
												tableData={salesOrderLists}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={handleRowClickUploadSO}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													//buttonOnClick() {createTemplateClickHandler();},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Close Order" && (
									<>
										{isCloseOrderFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={closeOrderHeader}
												tableData={closeOrderListDetails}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="disbursement_details_id"
												handleRowClick={(disbursement_details_id: string) => {
													setSelectedRowId(parseInt(disbursement_details_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No Orders available.",
													secondaryMessage: "",
													buttonText: "Create Order",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
								{activeNav === "ATW" && (
									<>
										{isATWFetching ? (
											<>
												<div className="-my-2">
													<Skeleton height={40} count={1} />
													<Skeleton height={40} count={5} />
												</div>
											</>
										) : (
											<TableComponent
												tableHeader={ATWHeader}
												tableData={forATWTablePageList}
												currentPage={1}
												rowsPerPage={10}
												hasRowClick={true}
												idStringProperty="atw_id"
												handleRowClick={handleATWRowClick}
												emptyState={{
													primaryMessage: "No ATW available.",
													secondaryMessage: "",
													buttonText: "Create ATW",
													buttonOnClick() {
														//createTemplateClickHandler();
													},
												}}
											/>
										)}
									</>
								)}
							</div>
						</div>

						{/** Modal Component */}
						<ModalComponent
							isOpen={isModalOpen}
							onCloseHandler={() => {
								setIsModalOpen(false);
								setSelectedOption("");
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Payment Verification
									</span>
								</div>
							}
							body={
								<>
									{selectedCloseOrderDetails !== undefined && (
										<>
											<div className="mx-5 items-center py-16">
												<div className="flex flex-col justify-between gap-8 md:flex-row -translate-y-9">
													<div className="flex flex-col gap-5 w-full">
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Buyer Name
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedCloseOrderDetails.buyer_name}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Shipment Type
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedCloseOrderDetails.shipping_method_id}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Delivery Date
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedCloseOrderDetails.delivery_date}
																</p>
															</div>
														</div>
														<div className="flex flex-col gap-1">
															<span className="text-sm text-gray-400">
																Logistic{" "}
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md">
																<p className="text-base text-gray-700">
																	{selectedCloseOrderDetails.logistic_name ===
																	null
																		? "No Logistic"
																		: selectedCloseOrderDetails.logistic_name}
																</p>
															</div>
														</div>
													</div>

													<div className="md:ml-10 md:w-full">
														<div className="flex flex-col gap-1 mb-5">
															<span className="text-sm text-gray-400">
																Amount
															</span>
															<div className="bg-gray-200 py-1 px-4 rounded-md w-full">
																<p className="text-base text-gray-700">
																	{selectedCloseOrderDetails.calculate_amount}
																</p>
															</div>
														</div>
														<div className="items-center">
															<img
																className="w-full h-52 md:h-56"
																src={
																	selectedCloseOrderDetails.disbursement_slip_url
																}
																alt="uploaded"
															/>
														</div>
														<div className="mt-4 mr-5">
															<DropdownOptionComponent
																items={dropdown_items}
																main_text="Options"
															/>
														</div>
														<div className="pt-16 ml-6">
															{selectedOption === "Verify" && (
																<ButtonComponent
																	text="Confirm"
																	utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal md:w-11/12 w-full`}
																	onClick={verificationHandler}
																/>
															)}
														</div>
													</div>
												</div>
												{selectedOption === "Reconcile" && (
													<>
														<textarea
															id="txtcommentsdetails"
															name="txtcommentsdetails"
															value={commentsDetails}
															placeholder="Please input reason"
															rows={3}
															className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																isCommentDetailsEmpty ? "red-600" : "gray-300"
															} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
															onChange={(e) =>
																fieldChangeHandler(
																	e.target.value,
																	"txtcommentsdetails"
																)
															}
														/>
														<div className="mt-5">
															<ButtonComponent
																text="Send"
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal w-full`}
																onClick={verificationHandler}
															/>
														</div>
													</>
												)}
											</div>
										</>
									)}
								</>
							}
						/>

						<ModalComponent
							isOpen={isModalUploadOpen}
							onCloseHandler={() => {
								setIsModalUploadOpen(false);
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Payment Verification
									</span>
								</div>
							}
							body={
								selectedOrder ? (
									<div className="md:mx-0 mx-3.5">
										<NavTabComponent
											key={Math.random()}
											nav_type="secondary"
											active_tab={""}
											nav_items={[]}
											setActiveNavHandler={() => {}}
										/>
										<div className="py-5 mx-5 text-gray-400">
											<div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
												<div className="flex flex-col">
													<span className="text-ts">Buyer Name</span>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
														<p className="pl-3 text-gray-700 sm">
															{selectedOrder.buyer_name}
														</p>
													</div>
													<button
														className="mt-3 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200"
														onClick={() => {}}
													>
														View Order details
													</button>
													<div className="mt-10"></div>
													<span>Delivery Date</span>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
														<p className="pl-3 text-gray-700 sm">
															{selectedOrder.delivery_date}
														</p>
													</div>
													<span>Logistic</span>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
														<p className="pl-3 text-gray-700 sm">
															{selectedOrder.logistic_name}
														</p>
													</div>
													<button
														className="mt-10 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200"
														onClick={() => {}}
													>
														View Truck Details
													</button>
												</div>
												<div className="flex flex-col">
													<label
														htmlFor="txtDriverName"
														className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
													>
														<div className="flex gap-2">
															<span className="text-ts">Input SO Number</span>
														</div>
													</label>
													<div className="mt-2 mb-2">
														<input
															value={soNumber}
															type="text"
															name="txtDriverName"
															id="txtDriverName"
															placeholder="Please input your First Name"
															className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																soNumberIsError ? "red-600" : "gray-300"
															} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
															onChange={(e: ChangeEvent<HTMLInputElement>) =>
																setSONumber(e.target.value)
															}
														/>
													</div>
													<div className="mt-8">
														<div
															className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5${
																isDraggingOver ? "bg-gray-400" : ""
															}`}
															onDrop={handleDrop}
															onDragOver={handleDragOver}
															onDragLeave={handleDragLeave}
														>
															<CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
															<h3 className="text-sm">Drag and Drop</h3>
															<p className="text-xs">
																your images here or{" "}
																<button
																	className="text-blue-400"
																	onClick={importImageHandler}
																>
																	upload image...
																</button>
															</p>
															{selectedSupplierFiles.length === 0 ? (
																<span className="text-gray-400 md:text-xs text-xxs mt-5">
																	-- No file selected --
																</span>
															) : (
																<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5 ">
																	{selectedSupplierFiles.map((file, index) => (
																		<div key={index}>
																			<div
																				className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
																				onClick={() =>
																					showImageImportHandler(index)
																				}
																			>
																				<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
																				<span className="text-xs text-gray-700 truncate ... w-16">
																					{file.name}
																				</span>
																				<button
																					className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
																					onClick={(e) => {
																						e.stopPropagation();
																						removeChipHandler(index);
																					}}
																				>
																					<XCircleIcon className="h-3 w-3" />
																				</button>
																			</div>
																		</div>
																	))}
																</div>
															)}
															<div className="my-1">
																<input
																	type="file"
																	ref={fileInputRef}
																	style={{ display: "none" }}
																	accept=".jpg, .jpeg, .png"
																	multiple
																	onChange={handleFileChange}
																/>
															</div>
															{selectedSupplierFiles.length !== 0 && (
																<ButtonComponent
																	text="Upload"
																	utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
										                      hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full`}
																	onClick={uploadImageHandler}
																	//disabled={!!salesOrderURL}
																/>
															)}
															<span className="text-xs text-gray-500 mt-5">
																Max size: 5MB
															</span>{" "}
															{error && (
																<span className="text-xs text-red-500 mt-2">
																	{error}
																</span>
															)}
															{uploadSuccess && (
																<span className="text-xs text-green-500 mt-2">
																	{uploadSuccess}
																</span>
															)}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								) : (
									<div className="py-5 mx-5 text-gray-400">
										No order selected.
									</div>
								)
							}
						/>

						<ModalComponent
							isXL={true}
							isOpen={isModalImage}
							onCloseHandler={() => {}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<ArrowUpTrayIcon
										className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
										aria-hidden="true"
									/>
									<span className="text-white inline md:text-base text-xs">
										Import Image
									</span>
								</div>
							}
							body={
								<>
									{selectedImage && (
										<div className="flex justify-center py-5 mx-7 my-4">
											<img
												src={selectedImage}
												alt="Selected"
												className="max-w-full h-96 relative"
											/>
											<button
												onClick={closeSelectedImage}
												className="absolute top-16 right-2 px-3 py-2 text-sm bg-dealogikal-200 text-white rounded-lg cursor-pointer"
											>
												Close
											</button>
										</div>
									)}
								</>
							}
						/>

						<ModalComponent
							isOpen={isATW_ModalOpen}
							onCloseHandler={() => setIsATW_ModalOpen(false)}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										ATW Details
									</span>
								</div>
							}
							body={
								selectedATL ? (
									<>
										<div className="flex justify-center mt-10">
											<ButtonComponent
												text={`${
													isButtonDisabled ? "Downloading" : "Download ATL"
												}`}
												icon={
													isLoadingAPI && (
														<Lottie
															className="md:w-5 w-5 h-auto"
															animationData={CircularLoading}
															loop={true}
														/>
													)
												}
												disabled={isButtonDisabled}
												utils={`border border-dealogikal-200 text-dealogikal-200 md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
                  												hover:bg-dealogikal-200 hover:text-white duration-500 rounded-full font-normal md:w-72 w-full
                  												${
																						isDownloadingPDF
																							? "cursor-not-allowed opacity-50"
																							: "cursor-pointer"
																					}
                  												`}
												onClick={handlePDFDownload}
											/>
										</div>

										<div
											className="max-w-4xl mx-auto p-8 bg-white shadow-lg my-10"
											ref={contentRef}
										>
											<h1 className="text-xl md:text-3xl font-bold text-center mb-8">
												DEALOGICAL CORPORATION
											</h1>
											<div className="mb-8 grid grid-cols-2 gap-4 text-sm lg:text-base w-full">
												<div>
													<p className="flex gap-4 items-center w-80">
														<span className="w-24">DATE</span>
														<span>:</span>
														<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
															{selectedATL.requested_delivery_date}
														</span>
													</p>
													<p className="flex gap-4 items-center w-80">
														<span className="w-24">POD</span>
														<span>:</span>
														<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
															{selectedATL.requested_delivery_date}
														</span>
													</p>

													<p className="flex gap-4 items-center w-80">
														<span className="w-24">SO #</span>
														<span>:</span>
														<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
															{selectedATL.atl_details.so_number || "N/A"}
														</span>
													</p>
												</div>
											</div>
											<h2 className="text-2xl font-bold text-center mb-4">
												AUTHORITY TO LOAD
											</h2>

											<p className="mb-4 text-sm text-black">
												Please authorize our driver{" "}
												<span className="font-bold underline underline-offset-2">
													{selectedATL.atl_details.driver_firstname}{" "}
													{selectedATL.atl_details.driver_lastname}
												</span>{" "}
												to withdraw the following products from your terminal.
											</p>

											<p className="mb-2 font-semibold">
												Details are as follows:
											</p>
											<table className="w-full max-w-3xl mx-auto mb-10 items-center">
												<thead>
													<tr className="border-b text-base">
														<th className="text-left p-2">PRODUCT</th>
														<th className="text-left p-2">QUANTITY (liters)</th>
														<th className="text-left p-2">REMARKS</th>
													</tr>
												</thead>
												<tbody>
													{selectedATL.items.map((item, index) => (
														<tr key={index} className="border-b text-sm">
															<td className="p-2">{item.commodity_name}</td>
															<td className="p-2">{item.ordered_volume}</td>
															<td className="p-2">{item.unit}</td>
														</tr>
													))}
												</tbody>
											</table>
											<div className="grid grid-cols-1 gap-4 mt-5 text-sm md:text-base">
												<p className="flex gap-4">
													<span className="font-semibold w-36">HAULER</span>
													<span>: Dealogical</span>
												</p>

												<>
													<p className="flex gap-4">
														<span className="font-semibold w-36">PLATE #</span>
														<span>
															: {selectedATL.atl_details.plate_number}
														</span>
													</p>
													<p className="flex gap-4">
														<span className="font-semibold w-36">
															LICENSE NO.
														</span>
														<span>
															: {selectedATL.atl_details.license_number}
														</span>
													</p>
													<p className="flex gap-4">
														<span className="font-semibold w-36">WHEELS</span>
														<span>: {selectedATL.atl_details.wheels}</span>
													</p>
													<p className="flex gap-4">
														<span className="font-semibold w-36">CAPACITY</span>
														<span>
															:{" "}
															{parseInt(
																selectedATL.atl_details.total_capacity,
																10
															) / 1000}{" "}
															kl capacity
														</span>
													</p>
												</>
											</div>
											<div className="flex flex-col mt-10 items-start">
												<p className="border-b border-gray-800 text-start inline-block font-bold">
													KLYNEA MARTINI S. YAUN
												</p>
												<span className="text-center text-sm mt-1 ml-5">
													Authorized Signatory
												</span>
											</div>
										</div>
									</>
								) : (
									<div className="flex justify-center items-center h-full">
										<p>No Logistic ATL has been Confirmed.</p>
									</div>
								)
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default SalesOrderManagementComp;
