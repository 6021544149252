const suppliersNearbyData = [
  {
    id: 1,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Astrox",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 2,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Jetti",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 3,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Insular",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 4,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "Seaoil",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
  {
    id: 5,
    logo_url:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1681708430/dealogikal_2.0/resources/images/logo/dealogikal_logo_rgpnll_rzb544.png",
    name: "PTT",
    location: "Mandaue City, Cebu",
    ratings: "5.0",
  },
];

export default suppliersNearbyData;
