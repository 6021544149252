import { useState } from "react";
import { NavBarModel } from "../../../model/interface/NavBarModel";

const NavbarComponent: React.FC<NavBarModel> = (props) => {
  const [menu_open, setMenu_Open] = useState<boolean>(false);
  return (
    <>
      <div className="w-full fixed bg-white z-50 top-0 left-0 md:px-44 md:py-6">
        <div className="md:flex items-center justify-between bg-white py-4">
          {/* cja: company/website app/brand logo (start) */}
          <div>{props.brand_logo}</div>
          {/* cja: company/website app/brand logo (end) */}
          {/* cja: collapsible dropdown (start) */}
          <div
            onClick={() => setMenu_Open(!menu_open)}
            className="text-3xl absolute right-8 top-6 cursor-pointer md:hidden"
          >
            <span>{menu_open ? "x" : "="}</span>
          </div>
          {/* cja: collapsible dropdown (end) */}
          {/* cja: navigations (start) */}
          <ul
            className={`md:flex md:items-center md:pb-0 pb-12 absolute md:static bg-white md:z-auto z-[-1] left-0 w-full md:w-auto md:pl-0 pl-9 transition-all duration-400 ease-in ${
              menu_open
                ? "top-20 opacity-100"
                : "top-[-490px] md:opacity-100 opacity-0"
            }`}
          >
            {props.nav_items.map((item: any) => (
              <li key={Math.random()} className="md:ml-8 text-xl md:my-0 my-7">
                <a
                  href={item.link}
                  className="text-black sm:hover:text-gray-800 text-base font-semibold duration-500"
                >
                  {item.name}
                </a>
              </li>
            ))}
            {/* cja: right action buttons (start) */}
            <div>{props.right_buttons}</div>
            {/* cja: right action buttons (end) */}
          </ul>
          {/* cja: navigations (end) */}
        </div>
      </div>
    </>
  );
};

export default NavbarComponent;
