import { useState } from "react";

interface Props {
  subtitle: string;
  isTitleEditable?: boolean; // if true, title is editable
  isTitleInEditState?: boolean; // isTitleEditable props must be true. Then, this is the indication of showing input text or text only
  titleIconClickHandler?: () => void; // isTitleEditable props must be true.
  subtitleOnBlurHandler?: (new_title: string) => void;
  breadcrumb?: React.ReactNode;
  steps?: React.ReactNode;
  republishButton?: React.ReactNode;
}

const BreadcrumbContainerComponent: React.FC<Props> = (props) => {
  const [inputValue, setInputValue] = useState(props.subtitle);
  const [inputWidth, setInputWidth] = useState(350); // Initial width

  const handleInputChange = (e: any) => {
    const value = e.target.value;
    setInputValue(value);

    // Calculate the width based on the length of the input value
    const textWidth = value.length * 12; // Adjust the multiplier as needed
    setInputWidth(textWidth < 350 ? 350 : textWidth); // Minimum width is 100px
  };

  const renderTitle = () => {
    if (props.isTitleEditable) {
      return (
        <>
          <input
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            onBlur={() => props.subtitleOnBlurHandler?.(inputValue)}
            style={{ width: inputWidth + "px" }}
            className="p-4 border-gray-300 transition-width duration-200 outline-none focus:border-blue-500 py-1 text-gray-900 text-lg md:text-2xl font-bold inline-flex rounded-2xl border-0 ring-1 ring-inset ring-gray-300 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 sm:leading-6"
          />
        </>
      );
    } else {
      return (
        <h2 className="text-gray-900 text-lg md:text-3xl font-bold inline-flex">
          {props.subtitle}
        </h2>
      );
    }
  };

  return (
    <>
      <div className="flex flex-wrap justify-center md:ml-2 md:mr-2 md:mb-4">
        <div className="flex flex-col w-full md:w-2/5">
          <div className="flex flex-col w-full md:w-full">
            {/* cja: this is temporary breadcrumb only */}
            {props.breadcrumb}
          </div>
          <div className="w-full md:w-full sm:mt-6 mt-4">{renderTitle()}</div>
        </div>
        {props.steps !== undefined ? (
          <div className="flex flex-col w-full md:w-3/5">{props.steps}</div>
        ) : (
          <>
            {props.republishButton !== undefined ? (
              <div className="flex flex-wrap w-full md:w-3/5 mt-4 md:mt-0">
                <div className="md:w-5/6"></div>
                <div className="w-full md:w-1/6">{props.republishButton}</div>
              </div>
            ) : (
              <div className="flex flex-col w-full md:w-3/5"></div>
            )}
          </>
        )}
      </div>
    </>
  );
};

export default BreadcrumbContainerComponent;
