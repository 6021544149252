import { ButtonModel } from "../../../model/interface/ButtonModel";

const ButtonComponent: React.FC<ButtonModel> = (props) => {
  return (
    <>
      <button
        className={props.utils}
        onClick={() => props.onClick?.()}
        disabled={props.disabled}
      >
        <div className="flex items-center justify-center gap-2">
          {props.icon}
          <span className={props.textUtils}>{props.text}</span>
        </div>
      </button>
    </>
  );
};

export default ButtonComponent;
