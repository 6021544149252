import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import OptionsType from "../../../../../model/types/OptionsType";
import CircleImageComponent from "../../../../templates/CircleImageComponent/CircleImageComponent";
import { generateKey } from "../../../../../redux/functions/function";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import { manageBreadcrumb } from "../../../../../redux/functions/function";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
  getUtilities,
  setUtilityCommodityTypes,
} from "../../../../../redux/functions/storageSlice";
import { useEffect, useState } from "react";
import { getCommodityTypes } from "../../../../../redux/functions/API";

const ChooseCommodityComp = () => {
  // declare navigation
  const navigate = useNavigate();

  // declare redux utilities
  const utilities = useAppSelector(getUtilities);
  const dispatch = useAppDispatch();

  // declare our states here...
  const [commodityType, setCommodityType] = useState(utilities.commodityTypes);

  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (commodityType.length === 0)
      getCommodityTypes().then((data: any) => {
        dispatch(setUtilityCommodityTypes(data));
        setCommodityType(data);
      });
  }, [dispatch, commodityType]);

  // declare the options type
  const options: OptionsType[] = utilities.commodityTypes.map((i) => ({
    ...i,
    href: "/s/choose_product_type",
  }));

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <BreadcrumbContainerComponent
            key={Math.random()}
            subtitle="Choose Commodity"
          />
          {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
          <div className="flex flex-wrap justify-start md:px-2 md:m-6 md:mt-6 mt-8">
            {options.map((opt: OptionsType) => (
              <CircleImageComponent
                key={generateKey(opt.id.toString())}
                content={{ id: opt.id, name: opt.name, icon: opt.icon }}
                event={{
                  onClick: () =>
                    manageBreadcrumb(opt, () => navigate(opt.href)),
                }}
              />
            ))}
          </div>
        </>
      }
    />
  );
};

export default ChooseCommodityComp;
