import { useRef, useEffect } from "react";
import ChatReplyInputComp from "./ChatReplyInputComp";
import {
  UserIcon,
  TruckIcon,
  BuildingLibraryIcon,
} from "@heroicons/react/24/outline";
import SingleChatComp from "./SingleChatComp";
import OnlineBadgeComponent from "../OnlineBadgeComponent/OnlineBadgeComponent";
import { generateKey } from "../../../redux/functions/function";

const ChatBoxComp = () => {
  //   reciprocate the scroll bar direction
  const scrollContainerRef = useRef(null);
  useEffect(() => {
    const scrollContainer: any = scrollContainerRef.current;
    scrollContainer.scrollTop =
      scrollContainer !== null && scrollContainer.scrollHeight;
  }, []);

  return (
    <>
      <div className="fixed md:w-1/5 mx-auto pt-8 z-40 bottom-0 right-0 mb-4 mr-6">
        {/* chatbox div */}
        <div className="bg-gray-100 border px-4 pt-3 rounded-lg drop-shadow-xl h-128">
          <div className="mt-1">
            <div className="table-row-group">
              <div className="table-row">
                <div className="table-cell align-middle w-1/2 md:px-0 px-2">
                  <span className="mx-3 py-1 text-xs">All</span>
                  <span className="mx-3 py-1 text-xs">
                    <UserIcon className="-ml-0.5 h-5 w-5 inline" />
                    <span className="inline ml-1">Suppliers</span>
                  </span>
                  <span className="mx-3 py-1 text-xs">
                    <TruckIcon className="-ml-0.5 h-5 w-5 inline" />
                    <span className="inline ml-1">Logistics</span>
                  </span>
                  <span className="mx-3 py-1 text-xs">
                    <BuildingLibraryIcon className="-ml-0.5 h-5 w-5 inline" />
                    <span className="inline ml-1">Financer</span>
                  </span>
                </div>
              </div>
            </div>
            <hr className="-mx-4 mt-4" />
            <div className="overflow-y-auto h-72" ref={scrollContainerRef}>
              {/* Single Chat */}
              <SingleChatComp
                owner_name="Knottical Power"
                badge_status={
                  <OnlineBadgeComponent
                    key={generateKey(Math.random().toString())}
                    badge_text="Supplier"
                    isActive={false}
                  />
                }
                chat_type="receive"
                message="Hello, how can I help you?"
              />
              {/* Single Chat */}
              {/* Single Chat */}
              <SingleChatComp
                owner_name="Jane Smith"
                badge_status={
                  <OnlineBadgeComponent
                    key={generateKey(Math.random().toString())}
                    badge_text="Buyer"
                    isActive={true}
                  />
                }
                chat_type="send"
                message="Can I ask more about your offer?"
              />
              {/* Single Chat */}
            </div>
          </div>
          <ChatReplyInputComp />
        </div>
      </div>
    </>
  );
};

export default ChatBoxComp;
