import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  generateKey,
  manageBreadcrumb,
  manageSteps,
} from "../../../../../redux/functions/function";
import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import BreadcrumbComponent from "../../../../templates/BreadcrumbComponent/BreadcrumbComp";
import CircleImageComponent from "../../../../templates/CircleImageComponent/CircleImageComponent";
import OptionsType from "../../../../../model/types/OptionsType";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
  getNextAuctionTemplateName,
  getUtilities,
  setUtilityCommodityTypeMethods,
} from "../../../../../redux/functions/storageSlice";
import { getCommodityTypeMethods } from "../../../../../redux/functions/API";

const OrderTypeComp = () => {
  const navigate = useNavigate();

  // declare redux utilities
  const utilities = useAppSelector(getUtilities);
  const dispatch = useAppDispatch();

  // declare our states here...
  const [commodityTypeMethod, setCommodityTypeMethod] = useState(
    utilities.commodityTypeMethods
  );

  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity type method is empty
    if (commodityTypeMethod.length === 0)
      getCommodityTypeMethods().then((data: any) => {
        dispatch(setUtilityCommodityTypeMethods(data));
        setCommodityTypeMethod(data);
      });
  }, [dispatch, commodityTypeMethod]);

  // declare the options type
  const options: OptionsType[] = utilities.commodityTypeMethods.map((i) => ({
    ...i,
    href: "/b/reverse_auction_template",
  }));

  const steps = [
    {
      name: "Order Type",
      href: "/order_type",
      status: "upcoming",
    },
    { name: "Choose Item", href: "/choose_item", status: "upcoming" },
    { name: "Shipping Details", href: "/shipping_details", status: "upcoming" },
    { name: "Quotation", href: "/quotation", status: "upcoming" },
    { name: "Payment", href: "#", status: "upcoming" },
    { name: "Receive", href: "#", status: "upcoming" },
  ];

  useEffect(() => {
    manageSteps(
      {
        name: "Order Type",
        href: "/order_type",
        status: "current",
      },
      steps
    ); // the manageSteps parameter will be the current step
  });

  const nextAuctionTemplateName = useAppSelector(getNextAuctionTemplateName);

   	// check if next template name is not empty
	useEffect(() => {
		if (nextAuctionTemplateName === "") {
			navigate("/b/my_auction_templates");
		}
  }, [navigate, nextAuctionTemplateName]);
  
  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              breadcrumb={<BreadcrumbComponent />}
              subtitle="Choose Order Type"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <div className="flex flex-wrap justify-center md:px-2 md:m-6 md:mt-6 mt-8">
              {options.map((opt: OptionsType) => (
                <CircleImageComponent
                  key={generateKey(opt.id.toString())}
                  content={{ id: opt.id, name: opt.name, icon: opt.icon }}
                  event={{
                    onClick: () => {
                      manageBreadcrumb(opt, () => navigate(opt.href));
                    },
                  }}
                />
              ))}
            </div>
          </>
        }
      />
    </>
  );
};

export default OrderTypeComp;
