import { ChartPieIcon, CubeIcon, TagIcon } from "@heroicons/react/24/solid";

const exploreDealogikal = [
  {
    name: "My Products",
    icon: CubeIcon,
    description: "Description here...",
    href: "/my_products",
  },
  {
    name: "My Offers",
    icon: TagIcon,
    description: "Description here...",
    href: "/my_offers",
  },
  {
    name: "My Sales",
    icon: ChartPieIcon,
    description: "Description here...",
    href: "/my_sales",
  },
  // {
  //   name: "My Accreditation",
  //   icon: ChartBarIcon,
  //   description: "Description here...",
  //   href: "/s/my_accreditation",
  // },
];

export default exploreDealogikal;
