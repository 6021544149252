import MainConsoleLayoutComponent from "../../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import BreadcrumbComponent from "../../../../templates/BreadcrumbComponent/BreadcrumbComp";
import CardComponent from "../../../../templates/CardComponent/CardComponent";
import {
	generateKey,
	getFetch,
	getOperationLeadTime,
	getTokenFromLocalStorage,
	getTotalNumberOfDaysInMonth,
	postFetch,
} from "../../../../../redux/functions/function";
import { TokenModel } from "../../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import {
	BeakerIcon,
	CalendarDaysIcon,
	CubeIcon,
	ExclamationTriangleIcon,
	MagnifyingGlassIcon,
	MapPinIcon,
	PlusCircleIcon,
	Square3Stack3DIcon,
	TruckIcon,
} from "@heroicons/react/24/outline";
import ComboboxComponent from "../../../../templates/ComboboxComponent/ComboboxComponent";
import { ChangeEvent, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/app/hooks";
import {
	getCreateAuctionTemplateDetails,
	setAuctionSelectedCommodities,
	setAuctionBuyerShippingDetails,
	getUtilities,
	setDeliveryLocationList,
	setShippingMethods,
	setUtilityCommodities,
	setUtilityUnits,
	getNextAuctionTemplateName,
	setNextAuctionTemplateName,
	setAuctionBuyerTemplateID,
	getVehiclesDetails,
	setSelected_Vehicles,
	setIslandDeliveryLocationList,
	setAuctionBuyerDeliveryLocationDetails,
} from "../../../../../redux/functions/storageSlice";
import {
	addNewReverseAuctionTemplateAPI,
	getAllAddTruckAPI,
	getCommodities,
	getMunicipalitiesAPI,
	getPhilippineIslandsAPI,
	getShippingMethods,
	getUnits,
	getUpdatedRemainingReverseAuctionTime,
	updateReverseAuctionTemplateDetails,
} from "../../../../../redux/functions/API";
import { CommodityTypeMethodModel } from "../../../../../model/interface/CommodityTypeMethodModel";
import { UnitsModel } from "../../../../../model/interface/UnitsModel";
import BuyerSelectedItemsType from "../../../../../model/types/BuyerSelectedItemsType";
import ChipsComponent from "../../../../templates/ChipsComponent/ChipsComponent";
import ButtonComponent from "../../../../templates/ButtonComponent/ButtonComponent";
import Lottie from "lottie-react";
import CircularLoading from "../../../../../lottie/circular_loading_theme_1.json";
import ShippingMethodModel from "../../../../../model/interface/ShippingMethodModel";
import DeliveryLocationModel from "../../../../../model/interface/DeliveryLocationModel";
import BuyerShippingDetailsType from "../../../../../model/types/BuyerShippingDetailsType";
import { useNavigate } from "react-router-dom";
import ToasterComponent from "../../../../templates/ToasterComponent/ToasterComponent";
import ModalComponent from "../../../../templates/ModalComponent/ModalComponent";
import CalendarComponent from "../../../../templates/CalendarComponent/CalendarComponent";
import TransitionComponent from "../../../../templates/TransitionComponent/TransitionComponent";
import AvatarModel from "../../../../../model/interface/AvatarModel";
import AuctionTemplateDetailsModel from "../../../../../model/interface/AuctionTemplateDetailsModel";
import CreateAuctionTemplateDetailsModel from "../../../../../model/interface/CreateAuctionTemplateDetailsModel";
import EnabledDatesModel from "../../../../../model/interface/EnabledDatesModel";
import ReverseAuctionBannerComponent from "../../../../templates/ReverseAuctionBannerComponent/ReverseAuctionBannerComponent";
import NavTabComponent from "../../../../templates/NavTabComponent/NavTabComponent";
import VehicleListType from "../../../../../model/types/VehicleListType";
import SelectedVehicleType from "../../../../../model/types/SelectedVehicleType";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const ReverseAuctionTemplateComp = () => {
	// get and decode the token from local storage
	const _token = getTokenFromLocalStorage() ?? "";
	const userData: TokenModel = jwt_decode(_token);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const utilities = useAppSelector(getUtilities);
	const createAuctionTemplateDetails = useAppSelector(
		getCreateAuctionTemplateDetails
	);
	const selectedProducts = createAuctionTemplateDetails.selectedProducts;

	// cja: item details functionality code (start)
	const [listOfCommodities, setListOfCommodities] = useState(
		utilities.commodities
	);
	const [listOfUnits, setListOfUnits] = useState(utilities.units);
	const [selectedCommodity, setSelectedCommodity] =
		useState<CommodityTypeMethodModel>({
			icon: "",
			id: 0,
			name: "",
		});
	const [selectedUnit, setSelectedUnit] = useState<UnitsModel>({
		code: "",
		id: 0,
		name: "",
	});
	const [volume, setVolume] = useState<string>("");
	const [productIsError, setProductIsError] = useState<boolean>(false);
	const [unitIsError, setUnitIsError] = useState<boolean>(false);
	const [volumeIsError, setVolumeIsError] = useState<boolean>(false);
	const [deliveryDaysIsError, setDeliveryDaysIsError] =
		useState<boolean>(false);
	const [noSelectedDateError, setNoSelectedDateError] =
		useState<boolean>(false);

	const [selectedIsMaximumReached, setSelectedIsMaximumReached] =
		useState<boolean>(false);
	const [isTitleInEditState, setIsTitleInEditState] = useState<boolean>(false);
	const nextAuctionTemplateName = useAppSelector(getNextAuctionTemplateName);
	const [newSubTitle, setNewSubTitle] = useState<string>(
		nextAuctionTemplateName
	);
	const [deliveryDateModalOpen, setDeliveryDateModalOpen] =
		useState<boolean>(false);
	const [selectedDate, setSelectedDate] = useState<{
		month: number;
		day: number;
		year: number;
	}>({ month: 0, day: 0, year: 0 });
	const [chosenDaysDelivery, setChosenDaysDelivery] = useState<{
		id: number;
		name: string;
	}>({ id: 0, name: "" });
	//const [datesEnabled, setDatesEnabled] = useState<number[]>([]);
	const [datesEnabled, setDatesEnabled] = useState<EnabledDatesModel[]>([]);
	const [isShowProceedingTransition, setIsShowProceedingTransition] =
		useState<boolean>(false);
	const [supplierAvatars, setSupplierAvatars] = useState<AvatarModel[]>([]);
	const [isFetchingSuppliers, setIsFetchingSuppliers] =
		useState<boolean>(false);
	const [isVehicleEmpty, setIsVehicleEmpty] = useState(false);

	// check if next template name is not empty
	useEffect(() => {
		if (newSubTitle === "") {
			navigate("/b/my_auction_templates");
		}
	}, [navigate, newSubTitle]);

	// check if utilities did not exist from redux
	useEffect(() => {
		// if commodity types is empty
		if (listOfCommodities.length === 0)
			getCommodities().then((data: any) => {
				dispatch(setUtilityCommodities(data));
				setListOfCommodities(data);
			});
	}, [dispatch, listOfCommodities]);

	// check if utilities did not exist from redux
	useEffect(() => {
		// if units is empty
		if (listOfUnits.length === 0)
			getUnits().then((data: any) => {
				dispatch(setUtilityUnits(data));
				setListOfUnits(data);
			});
	}, [dispatch, listOfUnits]);

	const [avgasError, setAvgasError] = useState<string>("");
	// cja: add product button click handler function
	const addProductClickHandler = () => {
		resetErrorStates();
		if (selectedCommodity.id === 0 || selectedUnit.id === 0 || volume === "") {
			setProductIsError(selectedCommodity.id === 0 ? true : false);
			setUnitIsError(selectedUnit.id === 0 ? true : false);
			setVolumeIsError(volume === "" ? true : false);
		} else {
			if (selectedProducts.length === 3) {
				setSelectedIsMaximumReached(true);
				resetFields();
			} else {
				// Check if trying to add AVGAS when other commodities exist
				if (
					selectedCommodity.name === "AVGAS" &&
					selectedProducts.length > 0 &&
					!isAvgasSelected
				) {
					// You can set a new state for this specific error message
					setAvgasError("AVGAS cannot be combined with other commodities");
					setTimeout(() => {
						setAvgasError("");
					}, 3000);
					return;
				}
				saveNewProduct().then(() => {
					resetFields();
				});
			}
		}
	};

	// cja: saving new product in the redux
	const saveNewProduct = () => {
		return new Promise((resolve, reject) => {
			let newSelectedProduct: BuyerSelectedItemsType = {
				product: selectedCommodity,
				unit: selectedUnit,
				volume: parseInt(volume.replace(/,/g, ""), 10),
			};

			dispatch(
				setAuctionSelectedCommodities([...selectedProducts, newSelectedProduct])
			);
			resolve(true);
		});
	};

	const resetErrorStates = () => {
		setProductIsError(false);
		setUnitIsError(false);
		setVolumeIsError(false);
		setSelectedIsMaximumReached(false);
		setIsNoCommodityAdded(false);
		setIsShippingMethodEmpty(false);
		setIsDeliveryLocationEmpty(false);
		setIsDeliveryLocationDetailsEmpty(false);
	};

	// cja: reset the temporary local states for the fields
	const resetFields = () => {
		setSelectedCommodity({ icon: "", id: 0, name: "" });
		setSelectedUnit({ code: "", id: 0, name: "" });
		setVolume("");
	};

	// cja: remove product chips handler
	const removeProductChipHandler = (product_id: number) => {
		let updatedSelectedProducts: BuyerSelectedItemsType[] = [];
		selectedProducts.forEach((selected_product: BuyerSelectedItemsType) => {
			if (selected_product.product.id.toString() !== product_id.toString()) {
				updatedSelectedProducts = [
					...updatedSelectedProducts,
					selected_product,
				];
			}
		});

		dispatch(setAuctionSelectedCommodities(updatedSelectedProducts));
	};

	const cardActionClickHandler = (
		account_type: string,
		action_type: string
	) => {
		alert(`Account: ${account_type} \n Action: ${action_type}`);
	};

	// cja: item details functionality code (end)

	//  cja: shipping details functionality code (start)
	const shippingDetails = createAuctionTemplateDetails.shippingDetails;
	const [shippingMethod, setShippingMethod] = useState(
		shippingDetails.shipping_method
	);

	const [deliveryLocation, setDeliveryLocation] = useState(
		shippingDetails.delivery_location
	);

	const [deliveryIsland, setDeliveryIsland] = useState(
		shippingDetails.delivery_island
	);
	const [listOfShippingMethods, setListOfShippingMethods] = useState<
		ShippingMethodModel[]
	>([]);
	const [listOfDeliveryLocations, setListOfDeliveryLocations] = useState<
		DeliveryLocationModel[]
	>(utilities.deliveryLocations);
	const [listOfIslandLocations, setListOfIslandLocations] = useState<
		DeliveryLocationModel[]
	>(utilities.islandDeliveryLocations);

	const [isShippingMethodEmpty, setIsShippingMethodEmpty] =
		useState<boolean>(false);
	const [isDeliveryLocationEmpty, setIsDeliveryLocationEmpty] =
		useState<boolean>(false);
	// const [isPickupLocationEmpty, setIsPickupLocationEmpty] =
	useState<boolean>(false);
	const [isDeliveryLocationDetailsEmpty, setIsDeliveryLocationDetailsEmpty] =
		useState<boolean>(false);
	const [selectedVehicleID, setSelectedVehicleID] = useState<number[]>([]);
	// janm: flag for the active nav vehicle
	const [activeSelectVehicle, setActiveSelectVehicle] =
		useState<string>("Item");
	// janm: this array of objects will be passed in the
	// NavTab component for populating the nav items
	const selectVehicleItems = [
		{
			text: "Items",
		},
		{
			text: "Unlisted(0)",
		},
	];
	const [isDisplaySelectionError, setIsDisplaySelectionError] =
		useState<boolean>(false);
	const [selectedVehicle, setSelectedVehicle] = useState<SelectedVehicleType[]>(
		[]
	);
	const createRateFields = useAppSelector(getVehiclesDetails);
	const [vehicleList, setVehicleList] = useState<VehicleListType[]>([]);
	const [isVehicleListLoading, setIsVehicleListLoading] =
		useState<boolean>(true);
	const [isNoCommodityAdded, setIsNoCommodityAdded] = useState<boolean>(false);
	const [isSaving, setIsSaving] = useState<boolean>(false);
	const [isProceeding, setIsProceeding] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastIcon, setToastIcon] = useState("success");
	const [showToast, setShowToast] = useState(false);

	const [toastType, setToastType] = useState<string>("");
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);

	// truck API (start) ==========================================================>
	// janm: vehicle list inside modal checkbox handler
	const selectionHandler = (vehicle_id: number) => {
		const isSelected = selectedVehicleID.includes(vehicle_id);
		if (!isSelected && selectedVehicle.length < 3) {
			const selected = vehicleList.find(
				(vehicle) => vehicle.vehicle_id === vehicle_id
			);
			if (selected) {
				setSelectedVehicle([
					...selectedVehicle,
					{ vehicle: selected, fields: "" },
				]);
				setSelectedVehicleID([...selectedVehicleID, vehicle_id]);
			}
		} else if (isSelected) {
			// Deselect if already selected
			const updatedSelectedVehicles = selectedVehicle.filter(
				(item) => item.vehicle.vehicle_id !== vehicle_id
			);
			const updatedSelectedVehicleIDs = selectedVehicleID.filter(
				(id) => id !== vehicle_id
			);
			setSelectedVehicle(updatedSelectedVehicles);
			setSelectedVehicleID(updatedSelectedVehicleIDs);
		} else {
			selectionErrorHandler();
		}
	};

	const [isDeliveryIslandEmpty, setIsDeliveryIslandEmpty] = useState(false);

	// janm: list on vehicle list
	useEffect(() => {
		if (createRateFields.selectedVehicles === "") {
			// do something here for improvement
		} else {
			let newVehicleIDArray: number[] = [];
			JSON.parse(createRateFields.selectedVehicles).forEach(
				(selected_vehicle: SelectedVehicleType) => {
					newVehicleIDArray = [
						...newVehicleIDArray,
						selected_vehicle.vehicle.vehicle_id,
					];
				}
			);
			setSelectedVehicleID(newVehicleIDArray);
		}
	}, [createRateFields.selectedVehicles, navigate]);

	// janm: useEffect hook to check the vehicle list from redux storage,
	// if empty, then call from api
	useEffect(() => {
		if (
			createRateFields.selectedVehicleList.length === 0 &&
			isVehicleListLoading
		) {
			// get and decode the token from local storage
			const supplier_id: string = userData._id.toString();

			getAllAddTruckAPI(_token, supplier_id).then((response: any) => {
				// dispatch(setSelectedVehicleList(data));
				setVehicleList(response.data.items);
				setIsVehicleListLoading(false);
			});
		} else if (createRateFields.selectedVehicleList.length !== 0) {
			let updatedListofVehicles: VehicleListType[] = [];
			createRateFields.selectedVehicleList.forEach((vehicle: any) => {
				const newVehicle: VehicleListType = {
					vehicle_id: vehicle.vehicle_id,
					vehicle: vehicle.vehicle,
					plate_number: vehicle.plate_number,
					vehicle_type: vehicle.vehicle_type,
					volume: vehicle.volume,
					commodity: vehicle.commodity,
				};
				updatedListofVehicles = [...updatedListofVehicles, newVehicle];
			});
			setVehicleList(updatedListofVehicles);
			setIsVehicleListLoading(false);
		}
	}, [
		_token,
		userData._id,
		isVehicleListLoading,
		createRateFields.selectedVehicleList,
	]);

	const removeTruckChipHandler = (vehicle_id: number) => {
		removeVehicle(vehicle_id).then((new_selected_arr) => {
			dispatch(setSelected_Vehicles(JSON.stringify(new_selected_arr)));
		});
	};

	// this functionality to check if user select AVGAS
	// Add this function near the top of the component
	const handleCommoditySelection = (commodity: CommodityTypeMethodModel) => {
		setSelectedCommodity(commodity);

		if (commodity.name === "AVGAS") {
			const drumUnit = listOfUnits.find((unit) => unit.name === "Drum");
			if (drumUnit) {
				setSelectedUnit(drumUnit);
			}
		} else {
			setSelectedUnit({ code: "", id: 0, name: "" });
		}
	};

	const [isAvgasSelected, setIsAvgasSelected] = useState<boolean>(false);
	// Add this function to check if AVGAS is selected
	useEffect(() => {
		const isAvgas = selectedProducts.some(
			(product) => product.product.name === "AVGAS"
		);
		setIsAvgasSelected(isAvgas);
	}, [selectedProducts]);

	// janm: remove chips function
	const removeVehicle = (vehicle_id: number) => {
		return new Promise((resolve, reject) => {
			let updatedSelectedItemsArray: SelectedVehicleType[] = [];
			createRateFields.selectedVehicles !== "" &&
				JSON.parse(createRateFields.selectedVehicles).forEach(
					(selected_vehicle: SelectedVehicleType) => {
						if (selected_vehicle.vehicle.vehicle_id !== vehicle_id) {
							updatedSelectedItemsArray = [
								...updatedSelectedItemsArray,
								selected_vehicle,
							];
						}
					}
				);
			resolve(updatedSelectedItemsArray);
		});
	};

	const sortSelectedVehicles = (
		arr: SelectedVehicleType[],
		base_type: string
	) => {
		arr.sort(function (a: any, b: any) {
			var result = 0;
			if (base_type === "alphabet") {
				var textA = a.vehicle.vehicle.toString().toLowerCase();
				var textB = b.vehicle.vehicle.toString().toLowerCase();
				result = textA < textB ? -1 : textA > textB ? 1 : 0;
			} else if (base_type === "numeric") {
				// to be enhance
			}
			return result;
		});
	};

	// janm: function that handles the display of the error message in
	// in selecting products beyond 3
	const selectionErrorHandler = () => {
		setIsDisplaySelectionError(true);
		setTimeout(() => {
			setIsDisplaySelectionError(false);
		}, 3000);
	};

	// truck API (end) ==========================================================>

	// cja: fetch the delivery location data from redux
	// then store to local state to be displayed on the field
	useEffect(() => {
		if (shippingDetails.delivery_location.id !== 0) {
			setDeliveryLocation(shippingDetails.delivery_location);
		}
		if (shippingDetails.delivery_island?.id !== 0) {
			setDeliveryIsland(shippingDetails.delivery_island);
		}
	}, [shippingDetails]);

	// cja: update the redux storage by fetching the local states
	useEffect(() => {
		let updatedShippingDetails: BuyerShippingDetailsType = {
			shipping_method: shippingMethod,
			delivery_island: deliveryIsland,
			delivery_location: deliveryLocation,
			delivery_location_details: shippingDetails.delivery_location_details,
			delivery_date: "",
		};
		dispatch(setAuctionBuyerShippingDetails(updatedShippingDetails));
	}, [
		dispatch,
		shippingMethod,
		deliveryLocation,
		shippingDetails.delivery_location_details,
		deliveryIsland,
	]);

	const renderLocationSubLabel = () => {
		/*
      shippingMethodID Legend:
        1 - Delivery
        2 - Pick-up
    */
		if (createAuctionTemplateDetails.shippingDetails.shipping_method.id === 1) {
			return "(Cebu Municipality)";
		} else if (
			createAuctionTemplateDetails.shippingDetails.shipping_method.id === 2
		) {
			return "(Island)";
		} else {
			return "";
		}
	};

	// cja: this hook will be triggered to fetch the shipping methods from API
	useEffect(() => {
		//  if redux is empty, then call api and store to redux
		if (utilities.shippingMethods.length === 0) {
			getShippingMethods(_token).then((data: any) => {
				dispatch(setShippingMethods(data));
			});
		} else {
			// if not empty, get from redux and store in the local state
			let updatedShippingMethods: ShippingMethodModel[] = [];
			utilities.shippingMethods.length !== 0 &&
				utilities.shippingMethods.forEach((shipping_method: any) => {
					updatedShippingMethods = [...updatedShippingMethods, shipping_method];
				});
			setListOfShippingMethods(updatedShippingMethods);
		}
	}, [utilities.shippingMethods, dispatch, _token]);

	// this hook will handle in fetching the municipalities from utilities and
	// store to local state to be populated in the municipality dropdown selection field
	useEffect(() => {
		let newDeliveryLocations: DeliveryLocationModel[] = [];
		utilities.deliveryLocations.forEach((location: any) => {
			let singleLocation: DeliveryLocationModel = { id: 0, name: "" };
			// commented for now (for enhancement)
			if (
				createAuctionTemplateDetails.shippingDetails.shipping_method.id === 1
			) {
				singleLocation = {
					id: location.id,
					name: location.location,
				};
			} else if (
				createAuctionTemplateDetails.shippingDetails.shipping_method.id === 2
			) {
				singleLocation = {
					id: location.id,
					name: location.island,
				};
			}
			newDeliveryLocations = [...newDeliveryLocations, singleLocation];
		});
		setListOfDeliveryLocations(newDeliveryLocations);

		if (utilities.islandDeliveryLocations.length !== 0) {
			let newIslandDeliveryLocations: DeliveryLocationModel[] = [];
			utilities.islandDeliveryLocations.forEach((location: any) => {
				let singleLocation: DeliveryLocationModel = { id: 0, name: "" };
				singleLocation = {
					id: location.id,
					name: location.island,
				};
				newIslandDeliveryLocations = [
					...newIslandDeliveryLocations,
					singleLocation,
				];
			});
			setListOfIslandLocations(newIslandDeliveryLocations);
		}
	}, [
		createAuctionTemplateDetails.shippingDetails.shipping_method.id,
		utilities.deliveryLocations,
		utilities.islandDeliveryLocations,
	]);

	// cja: get delivery location (municipalities) according to the selected shipping method
	useEffect(() => {
		if (createAuctionTemplateDetails.shippingDetails.shipping_method.id !== 0) {
			let shippingMethodID =
				createAuctionTemplateDetails.shippingDetails.shipping_method.id;

			let munisipalityID: any =
				createAuctionTemplateDetails.shippingDetails.delivery_island?.id;
			getPhilippineIslandsAPI(_token)
				.then((data: any) => {
					dispatch(setIslandDeliveryLocationList(data));
				})
				.catch((err) => console.log(err));

			if (shippingMethodID === 1) {
				if (
					createAuctionTemplateDetails.shippingDetails.delivery_island?.name !==
					""
				) {
					getMunicipalitiesAPI(_token, munisipalityID)
						.then((data: any) => {
							dispatch(setDeliveryLocationList(data));
						})
						.catch((err) => console.log(err));
				}
			}
			setDeliveryLocation({
				id: 0,
				name: "",
			});
		}
	}, [
		createAuctionTemplateDetails.shippingDetails.shipping_method,
		createAuctionTemplateDetails.shippingDetails.delivery_island?.id,
		createAuctionTemplateDetails.shippingDetails.delivery_island?.name,
		_token,
		dispatch,
	]);

	// janm: it will clear the selected location everytime user select again another shipping method
	useEffect(() => {
		if (createAuctionTemplateDetails.shippingDetails.shipping_method.id !== 0) {
			let shippingMethodID =
				createAuctionTemplateDetails.shippingDetails.shipping_method.id;

			if (shippingMethodID !== null) {
				setDeliveryIsland({
					id: 0,
					name: "",
				});
			}
		}
	}, [createAuctionTemplateDetails.shippingDetails.shipping_method.id]);

	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

	const toggleModal = () => {
		setIsModalOpen(!isModalOpen);
	};

	// janm: it will clear the selected location everytime user select again another shipping method
	useEffect(() => {
		if (createAuctionTemplateDetails.shippingDetails.shipping_method.id === 0) {
			let shippingMethodID =
				createAuctionTemplateDetails.shippingDetails.shipping_method.id;

			if (shippingMethodID !== null) {
				setDeliveryLocation({
					id: 0,
					name: "",
				});
				setDeliveryIsland({
					id: 0,
					name: "",
				});
				dispatch(setAuctionBuyerDeliveryLocationDetails(""));
			}
		}
	}, [createAuctionTemplateDetails.shippingDetails.shipping_method, dispatch]);

	const clearCreateAuctionTemplateData = () => {
		// clear redux state createAuctionTemplateDetails
		let updatedShippingDetails: BuyerShippingDetailsType = {
			shipping_method: {
				id: 0,
				name: "",
			},
			delivery_island: {
				id: 0,
				name: "",
			},
			delivery_location: {
				id: 0,
				name: "",
			},
			delivery_location_details: "",
			delivery_date: "",
		};

		dispatch(setAuctionBuyerTemplateID(0));
		dispatch(setAuctionSelectedCommodities([]));
		dispatch(setAuctionBuyerShippingDetails(updatedShippingDetails));

		// clear local states
		setShippingMethod({
			id: 0,
			name: "",
		});
		setDeliveryLocation({
			id: 0,
			name: "",
		});
		setDeliveryIsland({
			id: 0,
			name: "",
		});
		dispatch(setAuctionBuyerDeliveryLocationDetails(""));
		setDatesEnabled([]);
		setChosenDaysDelivery({
			id: 0,
			name: "",
		});
		setSelectedDate({ month: 0, day: 0, year: 0 });
	};

	// handler for proceed to open modal calendar
	const proceedHandler = (operation: string) => {
		resetErrorStates();
		operation === "proceed" ? setIsSaving(true) : setIsProceeding(true);
		if (selectedProducts.length === 0) {
			setProductIsError(selectedCommodity.id === 0 ? true : false);
			setUnitIsError(selectedUnit.id === 0 ? true : false);
			setVolumeIsError(volume === "" ? true : false);
		}
		setIsShippingMethodEmpty(shippingMethod.id === 0 ? true : false);
		setIsDeliveryLocationEmpty(deliveryLocation.id === 0 ? true : false);
		if (shippingMethod.id === 1) {
			if (
				selectedProducts.length === 0 ||
				deliveryIsland?.id === 0 ||
				deliveryLocation.id === 0 ||
				shippingDetails.delivery_location_details === ""
			) {
				setIsDeliveryLocationEmpty(
					deliveryLocation.id === null || deliveryLocation.id === 0
						? true
						: false
				);
				setIsDeliveryIslandEmpty(
					deliveryIsland?.id == null || deliveryIsland?.id === 0 ? true : false
				);
				setIsDeliveryLocationDetailsEmpty(
					shippingDetails.delivery_location_details.trim() === "" ? true : false
				);
				setIsNoCommodityAdded(selectedProducts.length === 0 ? true : false);
				// disable saving/proceeding progress if there are errors
				operation === "proceed" ? setIsSaving(false) : setIsProceeding(false);
			} else {
				dispatch(setNextAuctionTemplateName(newSubTitle));
				setIsProceeding(false);
				setDeliveryDateModalOpen(true);
				setAllowTimerRendering(true);
			}
		} else if (shippingMethod.id === 2) {
			if (
				selectedProducts.length === 0 ||
				deliveryIsland?.id === 0 ||
				createRateFields.selectedVehicles === ""
			) {
				setIsDeliveryIslandEmpty(
					deliveryIsland?.id == null || deliveryIsland?.id === 0 ? true : false
				);
				setIsVehicleEmpty(
					createRateFields.selectedVehicles === "" ? true : false
				);
				setIsNoCommodityAdded(selectedProducts.length === 0 ? true : false);
				operation === "proceed" ? setIsSaving(false) : setIsProceeding(false);
			} else {
				dispatch(setNextAuctionTemplateName(newSubTitle));
				setIsProceeding(false);
				setDeliveryDateModalOpen(true);
				setAllowTimerRendering(true);
			}
		}
	};

	const saveOrProceedHandler = (operation: string) => {
		resetErrorStates();
		operation === "save" ? setIsSaving(true) : setIsProceeding(true);
		if (createAuctionTemplateDetails.auctionTemplateID === 0) {
			let selectedCommodities: {
				commodity_id: number;
				unit_id: number;
				volume: number;
			}[] = selectedProducts.map((product: BuyerSelectedItemsType) => ({
				commodity_id: product.product.id,
				unit_id: product.unit.id,
				volume: product.volume,
			}));
			setIsShippingMethodEmpty(shippingMethod.id === 0 ? true : false);
			if (shippingMethod.id === 1) {
				if (
					selectedProducts.length === 0 ||
					deliveryIsland?.id === 0 ||
					deliveryLocation.id === 0 ||
					shippingDetails.delivery_location_details === ""
				) {
					setIsDeliveryLocationEmpty(
						deliveryLocation.id === null || deliveryLocation.id === 0
							? true
							: false
					);
					setIsDeliveryIslandEmpty(
						deliveryIsland?.id == null || deliveryIsland?.id === 0
							? true
							: false
					);
					setIsDeliveryLocationDetailsEmpty(
						shippingDetails.delivery_location_details.trim() === ""
							? true
							: false
					);
					setIsNoCommodityAdded(selectedProducts.length === 0 ? true : false);
					// disable saving/proceeding progress if there are errors
					operation === "save" ? setIsSaving(false) : setIsProceeding(false);
				} else {
					if (operation === "save") {
						let createAuctionTemplateData: CreateAuctionTemplateDetailsModel = {
							commodities: selectedCommodities,
							shipping_method_id: shippingMethod.id,
							island_id: deliveryIsland?.id,
							municipality_id: deliveryLocation.id,
							delivery_location_details:
								shippingDetails.delivery_location_details,
							buyer_id: userData._id,
							template_name: newSubTitle,
						};
						addNewReverseAuctionTemplateAPI(_token, createAuctionTemplateData)
							.then((data: any) => {
								//clear redux createAuctionTemplateDetails
								clearCreateAuctionTemplateData();
								// disable saving
								setIsSaving(false);
								// display toaster message
								displayToastMessage(data.data.message);
								hideToastAndNavigateToList();
							})
							.catch((error) => console.log(error));
					}
					// else if (operation === "proceed") {
					// 	dispatch(setNextAuctionTemplateName(newSubTitle));
					// 	setIsProceeding(false);
					// 	setDeliveryDateModalOpen(true);
					// 	setAllowTimerRendering(true);
					// }
				}
			} else if (shippingMethod.id === 2) {
				if (
					selectedProducts.length === 0 ||
					deliveryIsland?.id === 0 ||
					createRateFields.selectedVehicles === ""
				) {
					setIsDeliveryIslandEmpty(
						deliveryIsland?.id == null || deliveryIsland?.id === 0
							? true
							: false
					);
					setIsVehicleEmpty(
						createRateFields.selectedVehicles === "" ? true : false
					);
					setIsNoCommodityAdded(selectedProducts.length === 0 ? true : false);
					operation === "save" ? setIsSaving(false) : setIsProceeding(false);
				} else {
					if (operation === "save") {
						let createAuctionTemplateData: CreateAuctionTemplateDetailsModel = {
							commodities: selectedCommodities,
							shipping_method_id: shippingMethod.id,
							island_id: deliveryIsland?.id,
							municipality_id: deliveryLocation.id,
							vehicle_id: selectedVehicleID,
							buyer_id: userData._id,
							template_name: newSubTitle,
						};

						// call create reverse auction template API
						addNewReverseAuctionTemplateAPI(_token, createAuctionTemplateData)
							.then((data: any) => {
								// clear redux createAuctionTemplateDetails
								clearCreateAuctionTemplateData();

								// disable saving
								setIsSaving(false);

								// display toaster message
								displayToastMessage(data.data.message);
								hideToastAndNavigateToList();
							})
							.catch((error) => console.log(error));
					}
					// else if (operation === "proceed") {
					// 	dispatch(setNextAuctionTemplateName(newSubTitle));
					// 	setIsProceeding(false);
					// 	setDeliveryDateModalOpen(true);
					// 	setAllowTimerRendering(true);
					// }
				}
			}
		} else {
			let selectedCommodities: {
				commodity_id: number;
				unit_id: number;
				volume: number;
			}[] = selectedProducts.map((product: BuyerSelectedItemsType) => ({
				commodity_id: product.product.id,
				unit_id: product.unit.id,
				volume: product.volume,
			}));
			if (shippingMethod.id === 1) {
				let updatedAuctionTemplateData: AuctionTemplateDetailsModel = {
					commodities: selectedCommodities,
					shipping_method_id: shippingMethod.id,
					island_id: deliveryIsland?.id,
					municipality_id: deliveryLocation.id,
					delivery_location_details: shippingDetails.delivery_location_details,
					buyer_id: userData._id,
					template_name: newSubTitle,
					reverse_auction_template_id:
						createAuctionTemplateDetails.auctionTemplateID,
				};
				updateReverseAuctionTemplateDetails(_token, updatedAuctionTemplateData)
					.then((data: any) => {
						// clear redux createAuctionTemplateDetails
						clearCreateAuctionTemplateData();

						// disable saving
						setIsSaving(false);

						// display toaster message
						displayToastMessage(data.data.message);
						hideToastAndNavigateToList();
					})
					.catch((err) => console.log(err));
			} else if (shippingMethod.id === 2) {
				let updatedAuctionTemplateData: AuctionTemplateDetailsModel = {
					commodities: selectedCommodities,
					shipping_method_id: shippingMethod.id,
					island_id: deliveryLocation.id ?? "",
					buyer_id: userData._id,
					template_name: newSubTitle,
					vehicle_id: selectedVehicleID,
					reverse_auction_template_id:
						createAuctionTemplateDetails.auctionTemplateID,
				};
				updateReverseAuctionTemplateDetails(_token, updatedAuctionTemplateData)
					.then((data: any) => {
						// clear redux createAuctionTemplateDetails
						clearCreateAuctionTemplateData();

						// disable saving
						setIsSaving(false);

						// display toaster message
						displayToastMessage(data.data.message);
						hideToastAndNavigateToList();
					})
					.catch((err) => console.log(err));
			}
		}
	};

	const displayToastMessage = (message: string) => {
		// display toaster message
		setToastMessage(message);
		setToastIcon("success");
		setShowToast(true);
	};

	const hideToastAndNavigateToList = () => {
		// hide toaster
		setTimeout(() => {
			setShowToast(false);
		}, 3000);

		// navigate to auction template list page
		setTimeout(() => {
			navigate("/b/my_auction_templates");
		}, 4000);
	};

	//  cja: shipping details functionality code (end)
	let cardDataa: any[] = [
		{
			name: "Sample",
			icon: TruckIcon,
			role: "Supplier",
			imageUrl:
				"https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
		},
		{
			name: "FTAT Logistics",
			icon: TruckIcon,
			role: "Logistic",
			imageUrl:
				"https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
		},
	];

	useEffect(() => {
		if (
			selectedProducts.length > 0 &&
			shippingMethod.name !== "" &&
			deliveryLocation.name !== ""
		) {
			setIsFetchingSuppliers(true);
			// get the commodity ids selected
			let commodity_ids: string[] = selectedProducts.map((product: any) =>
				product.product.id.toString()
			);

			getFetch(
				`${
					process.env.REACT_APP_API_URL
				}/reverse_auction_template/suppliers/get?commodity_ids=${commodity_ids}&shipping_method_id=${shippingMethod.id.toString()}&delivery_location_id=${deliveryLocation.id.toString()}`,
				_token
			)
				.then((response) => {
					setIsFetchingSuppliers(false);
					if (response.statusCode === 200) {
						setSupplierAvatars(response.data.items);
					}
				})
				.catch(() => {
					setIsFetchingSuppliers(false);
				});
		}
	}, [selectedProducts, shippingMethod, deliveryLocation, _token]);

	const templateNameIconClickHandler = () => {
		setIsTitleInEditState(!isTitleInEditState);
	};

	const subtitleOnBlurHandler = (new_title: string) => {
		if (new_title !== "") {
			setNewSubTitle(new_title);
		} else {
			setNewSubTitle(nextAuctionTemplateName);
		}
	};

	// cja: delivery date modal blocks (start)
	const dateOnClickHandler = (
		month: number,
		day: number,
		year: number,
		enabled: boolean
	) => {
		// can only select on enabled dates
		if (enabled) {
			setSelectedDate({
				month,
				day,
				year,
			});
		}
	};

	const daysOfDelivery = [
		{ id: 2, name: "2 DAYS" },
		{ id: 3, name: "3 DAYS" },
		{ id: 4, name: "4 DAYS" },
		{ id: 5, name: "5 DAYS" },
	];

	// cja: this useEffect hook will identify the dates that needs to be enable and pass to calendar component
	useEffect(() => {
		if (chosenDaysDelivery.name !== "") {
			let chosenDays: number = parseInt(
				chosenDaysDelivery.name.replace(" DAYS", "")
			);

			let newDatesEnabled: EnabledDatesModel[] = [];
			let operationsLeadTime = getOperationLeadTime();

			/**
			 *  janm: use this code below on testing in the last days for choosing next days in month
			 * const currentDate: Date = new Date(2024, 4, 29);
			 */
			const currentDate = new Date();
			// const currentDate: Date = new Date(2024, 4, 29);
			const dayOfMonth = currentDate.getDate();
			// since getMonth() function returns zero-based month, we need to add 1
			const currentMonth = currentDate.getMonth() + 1;
			const currentYear = currentDate.getFullYear();

			// add the operations lead time
			const startingDeliveryDate = dayOfMonth + operationsLeadTime;
			// call the function in identifying the total number of days this current month
			const totalNumberOfDaysInMonth = getTotalNumberOfDaysInMonth(
				currentMonth,
				currentYear
			);
			// calculate the expected transaction duration
			const expectedTransactionDuration = startingDeliveryDate + chosenDays;

			if (expectedTransactionDuration > totalNumberOfDaysInMonth) {
				// Calculate remaining days in the current month
				const daysRemainingInCurrentMonth =
					totalNumberOfDaysInMonth - startingDeliveryDate;

				// Enable remaining days in the current month
				for (let i = 1; i <= daysRemainingInCurrentMonth; i++) {
					newDatesEnabled.push({
						month: currentMonth,
						date: startingDeliveryDate + i,
						year: currentYear,
					});
				}

				// Enable days in the next month
				const daysInNextMonth = chosenDays - daysRemainingInCurrentMonth;
				for (let i = 1; i <= daysInNextMonth; i++) {
					newDatesEnabled.push({
						month: currentMonth + 1,
						date: i,
						year: currentYear,
					});
				}
			} else {
				// Enable days in the current month
				for (let i = 1; i <= chosenDays; i++) {
					newDatesEnabled.push({
						month: currentMonth,
						date: startingDeliveryDate + i,
						year: currentYear,
					});
				}
			}

			setDatesEnabled(newDatesEnabled);
		}
	}, [chosenDaysDelivery]);

	// cja: delivery date modal blocks (end)
	const proceedToReverseAuction = () => {
		setIsProceeding(true);
		setDeliveryDaysIsError(false);
		setNoSelectedDateError(false);
		if (chosenDaysDelivery.id === 0) {
			setDeliveryDaysIsError(true);
			setIsProceeding(false);
		} else {
			setDeliveryDaysIsError(false);
			if (selectedDate.day === 0) {
				setIsProceeding(false);
				setNoSelectedDateError(true);
			} else {
				setNoSelectedDateError(false);
				// extract selected commodities from redux storage according to API requirements
				let selectedCommodities: {
					commodity_id: number;
					unit_id: number;
					volume: number;
				}[] = selectedProducts.map((product: BuyerSelectedItemsType) => ({
					commodity_id: product.product.id,
					unit_id: product.unit.id,
					volume: product.volume,
				}));

				const auctionDetails: any = {
					commodities: selectedCommodities,
					shipping_method_id: shippingMethod.id,
					island_id: deliveryIsland?.id,
					municipality_id: deliveryLocation.id,
					vehicle_id: selectedVehicleID,
					delivery_location_details: shippingDetails.delivery_location_details,
					delivery_date: `${selectedDate.month}-${selectedDate.day}-${selectedDate.year}`,
					buyer_id: userData._id,
				};

				// cja: call add_reverse_auction
				setIsShowProceedingTransition(true);
				postFetch(
					`${process.env.REACT_APP_API_URL}/reverse_auction/add`,
					auctionDetails,
					_token
				)
					.then((response) => {
						setIsProceeding(false);
						setDeliveryDateModalOpen(false);
						clearCreateAuctionTemplateData();

						if (response.data.items[0]?.reverse_auction_id === undefined) {
							setIsShowProceedingTransition(false);
							if (response && response.statusCode === 409) {
								setToastMessage(
									"Your current quotation has in-progress or expired offer. "
								);
								setToastType("error");
								setIsToastOpen(true);
							}
						} else {
							setTimeout(() => {
								navigate(
									`/b/reverse_auction/${response.data.items[0]?.reverse_auction_id}`
								);
								setIsShowProceedingTransition(false);
							}, 2000);
						}
					})
					.catch((error) => {
						setIsProceeding(false);
						console.log(error);
					});
			}
		}
	};

	// cja: remaining time before the 2:00 PM cut-off blocks
	const [remainingTimeInSeconds, setRemainingTimeInSeconds] =
		useState<number>(0);
	const [isRemainingTimeLoading, setIsRemainingTimeLoading] =
		useState<boolean>(true);

	const [allowTimerRendering, setAllowTimerRendering] =
		useState<boolean>(false);

	const modalAndTimerHandler = () => {
		setDeliveryDateModalOpen(false);
		setAllowTimerRendering(false);
	};

	//janm: this hook will be triggered when there is changes in isRemainingTimeLoading
	// then fetch the updated remaining reverse auction time in seconds.
	useEffect(() => {
		// Declaration of intervalId variable to store the ID of the setInterval function
		let intervalId: any;

		// Function to fetch the remaining time from the server
		const fetchRemainingTime = () => {
			// Set loading state to true to indicate that remaining time is being fetched
			setIsRemainingTimeLoading(true);
			// Fetch updated remaining time from the server
			getUpdatedRemainingReverseAuctionTime(_token)
				.then((response: any) => {
					// If response contains remaining auction time, update state with the new value
					if (response.remaining_auction_time !== undefined) {
						setRemainingTimeInSeconds(Number(response.remaining_auction_time));
					}
				})
				.catch((err) => console.log(err))
				// Set loading state to false regardless of success or failure of the fetch operation
				.finally(() => setIsRemainingTimeLoading(false));
		};

		// Function to update the timer every second
		const updateTimer = () => {
			setRemainingTimeInSeconds((prevRemainingTimeInSeconds) => {
				// If remaining time is greater than 0, decrement it by 1
				if (prevRemainingTimeInSeconds > 0) {
					return prevRemainingTimeInSeconds - 1;
				} else {
					// If remaining time is 0 or negative, set it to 0
					return 0;
				}
			});
		};

		// Conditionally execute the timer logic based on the value of allowTimerRendering
		if (allowTimerRendering) {
			// If timer rendering is allowed, fetch the remaining time and start the interval
			fetchRemainingTime();
			intervalId = setInterval(updateTimer, 1000);

			// Cleanup function to clear the interval when component unmounts or when allowTimerRendering becomes false
			return () => {
				clearInterval(intervalId);
			};
		} else {
			// If timer rendering is not allowed, clear the interval immediately
			clearInterval(intervalId);
		}
	}, [allowTimerRendering, _token]);

	const confirmSelectionHandler = () => {
		sortSelectedVehicles(selectedVehicle, "alphabet");
		dispatch(setSelected_Vehicles(JSON.stringify(selectedVehicle)));
		setIsModalOpen(false);

		// every click of confirm, empty the selected local state
		setSelectedVehicle([]);
	};

	// regex for comma separator for thousand value
	const formatNumber = (value: any) => {
		return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const handleVolumeChange = (e: ChangeEvent<HTMLInputElement>) => {
		const inputValue = e.target.value.replace(/,/g, "");
		if (/^\d*$/.test(inputValue)) {
			setVolume(formatNumber(inputValue));
		}
	};

	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtshippingdetails":
				dispatch(setAuctionBuyerDeliveryLocationDetails(value));
				break;
			default:
				break;
		}
	};

	return (
		<>
			{isShowProceedingTransition === true ? (
				<TransitionComponent
					key={Math.random()}
					transition_text="Proceeding to Auction..."
				/>
			) : (
				<>
					<MainConsoleLayoutComponent
						content={
							<>
								<ToasterComponent
									isOpen={isToastOpen}
									label={toastMessage}
									onClose={setIsToastOpen}
									type={toastType}
								/>
								{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
								<BreadcrumbContainerComponent
									key={Math.random()}
									breadcrumb={<BreadcrumbComponent />}
									subtitle={newSubTitle}
									isTitleEditable={true} // indicated when the page title is editable
									isTitleInEditState={isTitleInEditState} // indication if show input textbox or not; by default set to false to hide input textbox
									titleIconClickHandler={templateNameIconClickHandler} // if editable, this is the trigger of enabling editing input
									subtitleOnBlurHandler={subtitleOnBlurHandler}
								/>
								{/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
								<ToasterComponent
									isOpen={showToast}
									label={toastMessage}
									onClose={setShowToast}
									type={toastIcon}
								/>
								<div className="flex flex-wrap justify-center">
									{/* single card (start) */}
									<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
										<CardComponent
											people={cardDataa}
											avatars={supplierAvatars}
											isFetchingAvatars={isFetchingSuppliers}
											cardActionClickHandler={cardActionClickHandler}
											userData={userData}
											isRefreshing={false}
											otherCount={0}
										/>
									</div>
									{/* single card (end) */}
									<div className="flex flex-col w-full md:pt-4 md:pl-2 md:w-full">
										<div className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 ">
											<div
												key={Math.random()}
												className="col-span-1 divide-y divide-gray-200 text-gray-400"
											>
												Item Details
											</div>
											<div
												key={Math.random()}
												className="col-span-1 divide-y divide-gray-200 text-gray-400"
											>
												Shipping Details
											</div>
										</div>
									</div>
									{/* single card (start) */}
									<div className="flex flex-col w-full md:pt-4 md:pl-2 md:w-full">
										<div className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2 ">
											{/* cja: Item details card (start) */}
											<div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
												<label
													htmlFor="txtshippingmethod"
													className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
												>
													<div className="flex gap-2">
														<CubeIcon className="-ml-0.5 h-5 w-5" />
														<span>Product</span>
													</div>
												</label>
												<div className="mt-2">
													<ComboboxComponent
														hasError={productIsError}
														items={listOfCommodities}
														placeholder="Select product"
														selectedItem={selectedCommodity}
														setSelectedItem={handleCommoditySelection}
														isDisabled={isAvgasSelected}
													/>
												</div>
												{productIsError && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															Product field is required
														</p>
													</div>
												)}
												<label
													htmlFor="txtlocation"
													className="block text-sm font-medium leading-6 text-gray-900 mt-10"
												>
													<div className="flex gap-2">
														<BeakerIcon className="-ml-0.5 h-5 w-5" />
														<span>Units</span>
													</div>
												</label>
												<div className="mt-2">
													<ComboboxComponent
														hasError={unitIsError}
														items={listOfUnits}
														placeholder="Select unit measurement"
														selectedItem={selectedUnit}
														setSelectedItem={setSelectedUnit}
														isDisabled={
															selectedCommodity.name === "AVGAS" ||
															isAvgasSelected
														}
													/>
												</div>
												{unitIsError && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															Unit field is required
														</p>
													</div>
												)}
												<label
													htmlFor="txtvolume"
													className="block text-sm font-medium leading-6 text-gray-900 mt-6"
												>
													<div className="flex gap-2">
														<Square3Stack3DIcon className="-ml-0.5 h-5 w-5" />
														<span>Volume</span>
													</div>
												</label>
												<div className="mt-2">
													<input
														value={volume}
														type="text"
														name="txtvolume"
														id="txtvolume"
														placeholder="Set range of volume"
														className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
															volumeIsError ? "red-600" : "gray-300"
														} ${
															isAvgasSelected ? "cursor-not-allowed" : ""
														} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
														// onChange={(e: ChangeEvent<HTMLInputElement>) =>
														//   setVolume(e.target.value)
														// }
														onChange={handleVolumeChange}
														disabled={isAvgasSelected}
													/>
												</div>
												{volumeIsError && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															Volume field is required
														</p>
													</div>
												)}
												<label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
													<div className="flex gap-2">
														<CubeIcon className="-ml-0.5 h-5 w-5" />
														<span>Selected Items</span>
													</div>
												</label>
												<div
													className={`border-0 h-auto ${
														selectedProducts.length === 0 ? "py-2.5" : ""
													} ${
														isNoCommodityAdded
															? "ring-1 ring-inset ring-red-600 rounded-2xl py-4 mt-2"
															: ""
													}`}
												>
													{selectedProducts.length !== 0 &&
														selectedProducts.map(
															(selected_product: BuyerSelectedItemsType) => {
																return (
																	<ChipsComponent
																		key={generateKey(
																			Math.random.toString() +
																				selected_product.product.id
																		)}
																		text={`${selected_product.product.name} (${selected_product.volume} ${selected_product.unit.name})`}
																		removeHandler={() =>
																			removeProductChipHandler(
																				selected_product.product.id
																			)
																		}
																	/>
																);
															}
														)}
													{selectedProducts.length === 0 && (
														<p className="ml-6 text-xs text-gray-400">
															-- No added products --
														</p>
													)}
												</div>

												<div className="ml-6 flex mt-3">
													<p className="text-red-600 text-xs md:text-sm">
														{avgasError}
													</p>
												</div>

												{isNoCommodityAdded && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															Please add product(s) first.
														</p>
													</div>
												)}
												{selectedIsMaximumReached && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															You can only select up to 3 products
														</p>
													</div>
												)}
												<ButtonComponent
													text="Add Product"
													icon={
														false && (
															<Lottie
																className="md:w-5 w-5 h-auto"
																animationData={CircularLoading}
																loop={true}
															/>
														)
													}
													disabled={false}
													utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4"
													onClick={addProductClickHandler}
												/>
											</div>
											{/* cja: Item details card (end) */}
											{/* cja: Shipping Details Card (start) */}
											<div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
												<label
													htmlFor="txtshippingmethod"
													className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
												>
													<div className="flex gap-2">
														<TruckIcon className="-ml-0.5 h-5 w-5" />
														<span>Shipping Method</span>
													</div>
												</label>
												<div className="mt-2">
													<ComboboxComponent
														hasError={isShippingMethodEmpty}
														items={listOfShippingMethods}
														placeholder="Select shipping method"
														selectedItem={shippingMethod}
														setSelectedItem={setShippingMethod}
													/>
												</div>
												{isShippingMethodEmpty && (
													<div className="ml-6 flex mt-3">
														<p className="text-red-600 text-xs md:text-sm">
															Shipping method field is required
														</p>
													</div>
												)}
												{shippingMethod.id === 0 &&
													shippingMethod.name === "" && (
														<>
															<label
																htmlFor="txtlocation"
																className="block text-sm font-medium leading-6 text-gray-900 mt-6"
															>
																<div className="flex gap-2">
																	<MapPinIcon className="-ml-0.5 h-5 w-5" />
																	<span>
																		Delivery Location {renderLocationSubLabel()}
																	</span>
																</div>
															</label>
															<div className="mt-2">
																<ComboboxComponent
																	hasError={isDeliveryLocationEmpty}
																	items={listOfDeliveryLocations}
																	placeholder="Select Delivery Location"
																	selectedItem={deliveryLocation}
																	setSelectedItem={setDeliveryLocation}
																	onBlur={() => alert("finding")}
																/>
															</div>
															{isDeliveryLocationEmpty && (
																<div className="ml-6 flex mt-3">
																	<p className="text-red-600 text-xs md:text-sm">
																		Delivery location field is required
																	</p>
																</div>
															)}
														</>
													)}
												{shippingMethod.id === 1 &&
													shippingMethod.name === "Delivery" && (
														<>
															<div className="w-full h-auto bg-gray-100 py-0.5 pb-4 px-2 mt-3 rounded-lg">
																<label
																	htmlFor="txtlocation"
																	className="block text-sm font-medium leading-6 text-gray-900 mt-6"
																>
																	<div className="flex gap-2">
																		<MapPinIcon className="-ml-0.5 h-5 w-5" />
																		<span>Delivery Location (Island)</span>
																	</div>
																</label>
																<div className="mt-2">
																	<ComboboxComponent
																		hasError={isDeliveryIslandEmpty}
																		items={listOfIslandLocations}
																		placeholder="Select Delivery Location"
																		selectedItem={deliveryIsland}
																		setSelectedItem={setDeliveryIsland}
																		onBlur={() => alert("finding")}
																	/>
																</div>
																{isDeliveryIslandEmpty && (
																	<div className="ml-6 flex mt-3">
																		<p className="text-red-600 text-xs md:text-sm">
																			Delivery location field is required
																		</p>
																	</div>
																)}

																<label
																	htmlFor="txtlocation"
																	className="block text-sm font-medium leading-6 text-gray-900 mt-6"
																>
																	<div className="flex gap-2">
																		<MapPinIcon className="-ml-0.5 h-5 w-5" />
																		<span>
																			Delivery Location{" "}
																			{renderLocationSubLabel()}
																		</span>
																	</div>
																</label>
																<div className="mt-2">
																	<ComboboxComponent
																		hasError={isDeliveryLocationEmpty}
																		items={listOfDeliveryLocations}
																		placeholder="Select Delivery Location"
																		selectedItem={deliveryLocation}
																		setSelectedItem={setDeliveryLocation}
																		onBlur={() => alert("finding")}
																	/>
																</div>
																{isDeliveryLocationEmpty && (
																	<div className="ml-6 flex mt-3">
																		<p className="text-red-600 text-xs md:text-sm">
																			Delivery location field is required
																		</p>
																	</div>
																)}
																<label
																	htmlFor="txtlocation"
																	className="block text-sm font-medium leading-6 text-gray-900 mt-6"
																>
																	<div className="flex gap-2">
																		<MapPinIcon className="-ml-0.5 h-5 w-5" />
																		<span>Delivery Location Details</span>
																	</div>
																</label>
																<div className="mt-2">
																	<textarea
																		id="txtshippingdetails"
																		name="txtshippingdetails"
																		value={
																			shippingDetails.delivery_location_details
																		}
																		placeholder="Indicate delivery location details"
																		rows={3}
																		className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																			isDeliveryLocationDetailsEmpty
																				? "red-600"
																				: "gray-300"
																		} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
																		onChange={(e) =>
																			fieldChangeHandler(
																				e.target.value,
																				"txtshippingdetails"
																			)
																		}
																	/>
																</div>
																{isDeliveryLocationDetailsEmpty && (
																	<div className="ml-6 flex mt-3">
																		<p className="text-red-600 text-xs md:text-sm">
																			Delivery location details field is
																			required
																		</p>
																	</div>
																)}
															</div>
														</>
													)}

												{/* janm: if Shipping method is Pick-up*/}
												{shippingMethod.id === 2 &&
													shippingMethod.name === "Pick-up" && (
														<>
															<label
																htmlFor="txtlocation"
																className="block text-sm font-medium leading-6 text-gray-900 mt-6"
															>
																<div className="flex gap-2">
																	<MapPinIcon className="-ml-0.5 h-5 w-5" />
																	<span>
																		Delivery Location {renderLocationSubLabel()}
																	</span>
																</div>
															</label>
															<div className="mt-2">
																<ComboboxComponent
																	hasError={isDeliveryIslandEmpty}
																	items={listOfIslandLocations}
																	placeholder="Select Delivery Location"
																	selectedItem={deliveryIsland}
																	setSelectedItem={setDeliveryIsland}
																	onBlur={() => alert("finding")}
																/>
															</div>
															{isDeliveryIslandEmpty && (
																<div className="ml-6 flex mt-3">
																	<p className="text-red-600 text-xs md:text-sm">
																		Delivery location field is required
																	</p>
																</div>
															)}
															<label
																htmlFor="txtlocation"
																className="block text-sm font-medium leading-6 text-gray-900 mt-6"
															>
																<div className="flex gap-2">
																	<TruckIcon className="-ml-0.5 h-5 w-5" />
																	<span>Select Truck</span>
																</div>
															</label>
															{createRateFields.selectedVehicles !== "" &&
																JSON.parse(
																	createRateFields.selectedVehicles
																).map(
																	(selected_vehicle: SelectedVehicleType) => {
																		return (
																			<ChipsComponent
																				key={generateKey(
																					Math.random.toString() +
																						selected_vehicle.vehicle.vehicle_id
																				)}
																				text={`${selected_vehicle.vehicle.vehicle} [ ${selected_vehicle.vehicle.plate_number}]`}
																				removeHandler={() => {
																					removeTruckChipHandler(
																						selected_vehicle.vehicle.vehicle_id
																					);
																				}}
																			/>
																		);
																	}
																)}
															{isVehicleEmpty && (
																<>
																	<p className="text-xs text-center text-gray-400 mt-11">
																		-- No added Vehicle --
																	</p>
																</>
															)}
															<span className="flex justify-center cursor-pointer">
																<PlusCircleIcon
																	className="h-12 w-16 text-dealogikal-100 cursor-pointer"
																	onClick={toggleModal}
																/>
															</span>
														</>
													)}

												<div className="flex flex-wrap justify-center mt-7">
													{/* single card (start) */}
													<div className="flex flex-col w-full md:w-1/2 md:pr-1 mt-2 md:mt-6">
														<ButtonComponent
															text={`${isSaving ? "Saving" : "Save"} Template`}
															utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full"
															onClick={() => saveOrProceedHandler("save")}
														/>
													</div>
													<div className="flex flex-col w-full md:w-1/2 md:pl-1 mt-2 md:mt-6">
														<ButtonComponent
															text={`Proceed to Active Quotation`}
															utils="bg-dealogikal-100 text-white text-sm shadow-sm py-3 sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full"
															onClick={() => proceedHandler("proceed")}
														/>
													</div>
													{/* single card (end) */}
												</div>
											</div>
											{/* cja: Shipping Details Card (end) */}
										</div>
									</div>
									{/* single card (end) */}
								</div>
							</>
						}
					/>
					{/* for selecting trucks */}
					<ModalComponent
						isOpen={isModalOpen}
						onCloseHandler={() => setIsModalOpen(false)}
						header={
							<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
								<TruckIcon
									className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
									aria-hidden="true"
								/>
								<span className="text-white inline md:text-base text-xs">
									Select Vehicle Type
								</span>
							</div>
						}
						body={
							<>
								<div className="relative rounded-md md:py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
									<input
										type="text"
										name="account-number"
										id="account-number"
										className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
										placeholder="Search product"
									/>
									<div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
										<MagnifyingGlassIcon
											className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
											aria-hidden="true"
										/>
									</div>
								</div>
								<div className="md:mx-0 mx-3.5">
									<NavTabComponent
										key={Math.random()}
										nav_type="secondary"
										active_tab={activeSelectVehicle}
										nav_items={selectVehicleItems}
										setActiveNavHandler={setActiveSelectVehicle}
									/>
								</div>
								{isDisplaySelectionError && (
									<div className="md:px-6 px-5 md:mt-5">
										<span className="text-sm text-red-600">
											You can only select another vehicle up to 1 vehicle
										</span>
									</div>
								)}

								<div className="flex flex-wrap justify-center">
									{/* single card (start) */}
									<div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
										{activeSelectVehicle.includes("Item") && (
											<>
												{vehicleList.length !== 0 && (
													<>
														<div className="md:px-6 px-5 justify-center">
															<div className="table w-full pt-6">
																<div className="table-row-group">
																	{vehicleList.map(
																		(vehicle: VehicleListType) => {
																			return (
																				<div
																					key={generateKey(
																						vehicle.vehicle_id.toString() +
																							Math.random()
																					)}
																				>
																					<div className="table-row">
																						<div className="table-cell text-center md:w-1/12">
																							<input
																								id={`cb` + vehicle.vehicle_id}
																								name={`cb` + vehicle.vehicle_id}
																								type="checkbox"
																								checked={selectedVehicleID.includes(
																									vehicle.vehicle_id
																								)}
																								className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
																								value={vehicle.vehicle_id}
																								onChange={(event) =>
																									selectionHandler(
																										parseInt(event.target.value)
																									)
																								}
																							/>
																						</div>
																						<div className="table-cell md:w-10/12">
																							<span className="font-normal  md:text-base text-xs ml-4">
																								<span className="font-extrabold text">
																									{`${vehicle.vehicle}`}
																								</span>
																								<span className="text-gray-500">
																									{` [ ${vehicle.plate_number} ]`}
																								</span>
																							</span>
																						</div>
																					</div>
																					<br />
																				</div>
																			);
																		}
																	)}
																</div>
															</div>
														</div>
														<div className="text-center md:mt-4 md:mx-0 mx-5">
															<ButtonComponent
																text="Confirm"
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 ${
																	selectedVehicle.length === 3
																		? "disabled:opacity-50 cursor-arrow"
																		: "hover:bg-dealogikal-200 cursor-pointer"
																} duration-500 rounded-full font-normal md:w-11/12 w-full`}
																onClick={confirmSelectionHandler}
																disabled={
																	selectedVehicle.length === 3 ? true : false
																}
															/>
														</div>
													</>
												)}
												{vehicleList.length === 0 && (
													<>
														<div className="flex gap-2 justify-center text-orange-400 mt-10">
															<MagnifyingGlassIcon
																className="md:h-5 md:w-5 h-4 w-4"
																aria-hidden="true"
															/>
															<span className="">
																You don't have accredited truck! Please accredit
																truck first!
															</span>
														</div>
													</>
												)}
											</>
										)}
									</div>
								</div>
							</>
						}
					/>
					<ModalComponent
						isOpen={deliveryDateModalOpen}
						onCloseHandler={modalAndTimerHandler}
						header={
							<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
								<span className="text-white inline md:text-base text-xs">
									Set Delivery Date
								</span>

								<CalendarDaysIcon
									className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
									aria-hidden="true"
								/>
							</div>
						}
						body={
							<>
								<div className="flex flex-wrap justify-center mt-4">
									{isRemainingTimeLoading ? (
										<div className="items-center content-center flex flex-col gap-5">
											<div className="flex gap-5">
												<Skeleton
													className="ml-2"
													height={40}
													width={300}
													count={1}
												/>
												<Skeleton
													className="ml-2"
													height={40}
													width={300}
													count={1}
												/>
											</div>
											<div className="flex gap-5">
												<Skeleton
													className="ml-2"
													height={40}
													width={100}
													count={1}
												/>
												<Skeleton
													className="ml-2"
													height={40}
													width={100}
													count={1}
												/>
												<Skeleton
													className="ml-2"
													height={40}
													width={100}
													count={1}
												/>
											</div>
										</div>
									) : (
										<ReverseAuctionBannerComponent
											margin_top="-mt-4"
											remaining_time_in_seconds={remainingTimeInSeconds}
											is_remaining_time_loading={isRemainingTimeLoading}
											setDeliveryDateModalOpen={setDeliveryDateModalOpen}
										/>
									)}

									{/* single card (start) */}
									<div className="w-full md:w-full text-yellow-600 inline-flex justify-center mt-4">
										<span>
											<ExclamationTriangleIcon className="h-5 w-5" />
										</span>
										<span className="ml-1">
											Lead time delivery is 2 days after current date
										</span>
									</div>
									{/* single card (end) */}
									{/* single card (start) */}
									<div className="flex flex-col w-full md:w-3/5 px-6">
										<div className="mt-2">
											<ComboboxComponent
												hasError={deliveryDaysIsError}
												items={daysOfDelivery}
												placeholder="Choose days delivery"
												selectedItem={chosenDaysDelivery}
												setSelectedItem={setChosenDaysDelivery}
											/>
										</div>
										{deliveryDaysIsError && (
											<div className="flex justify-center mt-2">
												<p className="text-red-600 text-xs md:text-sm">
													Please set up delivery schedule
												</p>
											</div>
										)}
										{noSelectedDateError && (
											<div className="flex justify-center mt-2">
												<p className="text-red-600 text-xs md:text-sm">
													Please select a delivery date first
												</p>
											</div>
										)}
									</div>
									{/* single card (end) */}
									{/* single card (start) */}
									<div className="flex flex-col w-full pt-4 pb-10 md:w-full">
										<CalendarComponent
											dateOnClickHandler={dateOnClickHandler}
											selectedDate={selectedDate}
											datesEnabled={datesEnabled}
										/>
									</div>
									{/* single card (end) */}
									{/* single card (start) */}
									<div className="inline-flex w-full md:w-full justify-center mb-10">
										<ButtonComponent
											text={`Cancel`}
											utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-1/4"
											onClick={modalAndTimerHandler}
										/>
										<ButtonComponent
											text={`${isProceeding ? "Loading..." : "OK"}`}
											utils="bg-dealogikal-100 text-white ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 mx-3 duration-500 rounded-full font-normal w-1/4"
											onClick={proceedToReverseAuction}
										/>
									</div>
									{/* single card (end) */}
								</div>
							</>
						}
					/>
				</>
			)}
		</>
	);
};

export default ReverseAuctionTemplateComp;
