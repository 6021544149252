import { classNames } from "../../../redux/functions/function";

interface VerticalListComponentModel {
  list: any[];
  itemOnClickHandler: (item_id: number) => void;
}

const VerticalListComponent: React.FC<VerticalListComponentModel> = (props) => {
  return (
    <nav className="flex flex-1 flex-col" aria-label="Sidebar">
      <ul className="-mx-2 space-y-1">
        {props.list.map((item: any) => (
          <li
            key={item.name}
            className={classNames(
              item.current
                ? "bg-gray-50 text-dealogikal-100"
                : "text-gray-700 hover:text-dealogikal-100 hover:bg-gray-50",
              "group flex gap-x-3 rounded-md p-2 pl-3 text-base leading-6 font-normal cursor-pointer"
            )}
            onClick={() => props.itemOnClickHandler(item.item_id)}
          >
            {item.name}
          </li>
        ))}
      </ul>
    </nav>
  );
};
export default VerticalListComponent;
