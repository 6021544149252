interface DashboardCardComponent2LightModel {
  data: {
    id: number;
    name: string;
    value: string;
  }[];
}

const DashboardCardComponent2Light = (
  props: DashboardCardComponent2LightModel
) => {
  return (
    <>
      <dl className="ml-1 mt-6 grid grid-cols-2 gap-3 md:gap-x-8 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-4">
        {props.data.map((stat) => (
          <div
            key={stat.id}
            className="flex flex-col bg-gray-100 group sm:hover:bg-dealogikal-100 p-8 rounded-3xl"
          >
            <dt className="text-sm font-semibold leading-6 text-gray-900 group-hover:text-white">
              {stat.name}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 group-hover:text-white">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
};

export default DashboardCardComponent2Light;
