import { getTokenFromLocalStorage } from "../redux/functions/function";
import { TokenModel } from "../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useAppDispatch } from "../redux/app/hooks";
import { setSessionData } from "../redux/functions/storageSlice";

export const useValidateAuth = (pathname: string) => {
  const dispatch = useAppDispatch();
  // get the authentication token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  // decode the token
  const userData: TokenModel = jwt_decode(_token);

  try {
    // check the expiration date
    const isExpired = Date.now() >= userData.exp * 1000;
    if (isExpired) {
      dispatch(setSessionData({ path: pathname, message: "session expired" }));
      return true;
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
};
