import { Outlet } from "react-router-dom";
import { getTokenFromLocalStorage } from "../redux/functions/function";
import Landing from "../pages/landing/Landing";

// declare a function here for the user login validation
const useAuth = () => {
  // get the token from session storage
  const _tokenFromLS = getTokenFromLocalStorage() ?? "";

  // this line here will introduce the validity
  const user = { loggedIn: _tokenFromLS ? true : false };
  return user && user.loggedIn;
};

// create a route validation here for protected pages
const ProtectedRoutes = () => {
  const isAuth = useAuth();
  return isAuth ? <Outlet /> : <Landing />;
};

export default ProtectedRoutes;
