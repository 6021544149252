import { useNavigate } from "react-router-dom";
import { manageBreadcrumb } from "../../../redux/functions/function";
import { DefaultDataModel } from "../../../model/interface/DefaultDataModel";

const DashboardCardComponent1 = (props: DefaultDataModel) => {
  // declare navigation
  const navigate = useNavigate();

  return (
    // change the grid-cols depend on item
    <div className="mx-auto mt-6 grid justify-items-center grid-cols-2 gap-x-3  md:gap-x-8 gap-y-3 md:gap-y-10 sm:max-w-none lg:grid-cols-3">
      {props.data.map((item) => (
        <div
          key={item.name}
          className="text-center w-full bg-dealogikal-theme-3 sm:flex sm:flex-col  lg:block lg:text-center p-4 rounded-3xl cursor-pointer"
          onClick={() => manageBreadcrumb(item, () => navigate(item.href))}
        >
          <div className="sm:flex-shrink-0">
            <div className="flow-root">
              <item.icon className="mx-auto h-10 w-10 md:h-16 md:w-16  text-white" />
            </div>
          </div>
          {/* delete sm:ml-6 to center the text */}
          <div className="mt-3  sm:mt-3 lg:mt-6">
            <h3 className="text-lg md:text-xl  font-bold text-white">
              {item.name}
            </h3>
            <p className="mt-2 text-xs md:text-sm text-white">
              {item.description}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default DashboardCardComponent1;
