import { useEffect, useState, ChangeEvent } from "react";
import { useNavigate } from "react-router-dom";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import BreadcrumbComponent from "../../../templates/BreadcrumbComponent/BreadcrumbComp";
import ComboboxComponent from "../../../templates/ComboboxComponent/ComboboxComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import StepsComponent from "../../../templates/StepsComponent/StepsComponent";
import {
  CubeIcon,
  BeakerIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import { generateKey, manageSteps } from "../../../../redux/functions/function";
import StepType from "../../../../model/types/StepType";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getBuyCommodityDetails,
  getUtilities,
  setSelectedCommodityProducts,
  setUtilityCommodities,
  setUtilityUnits,
} from "../../../../redux/functions/storageSlice";
import { getCommodities, getUnits } from "../../../../redux/functions/API";
import Lottie from "lottie-react";
import CircularLoading from "../../../../lottie/circular_loading_theme_1.json";
import BuyerSelectedItemsType from "../../../../model/types/BuyerSelectedItemsType";
import ChipsComponent from "../../../templates/ChipsComponent/ChipsComponent";
import { CommodityTypeMethodModel } from "../../../../model/interface/CommodityTypeMethodModel";
import { UnitsModel } from "../../../../model/interface/UnitsModel";

const ChooseItemComp = () => {
  const navigate = useNavigate();
  // declare redux utilities
  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  const buyCommodityDetails = useAppSelector(getBuyCommodityDetails);
  const selectedProducts = buyCommodityDetails.selectedProducts;

  const [listOfCommodities, setListOfCommodities] = useState(
    utilities.commodities
  );
  const [listOfUnits, setListOfUnits] = useState(utilities.units);
  const [selectedCommodity, setSelectedCommodity] =
    useState<CommodityTypeMethodModel>({
      icon: "",
      id: 0,
      name: "",
    });
  const [selectedUnit, setSelectedUnit] = useState<UnitsModel>({
    code: "",
    id: 0,
    name: "",
  });
  const [volume, setVolume] = useState<string>("");
  const [productIsError, setProductIsError] = useState<boolean>(false);
  const [unitIsError, setUnitIsError] = useState<boolean>(false);
  const [volumeIsError, setVolumeIsError] = useState<boolean>(false);

  const [selectedIsError, setSelectedIsError] = useState<boolean>(false);
  const [selectedIsMaximumReached, setSelectedIsMaximumReached] =
    useState<boolean>(false);
  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (listOfCommodities.length === 0)
      getCommodities().then((data: any) => {
        dispatch(setUtilityCommodities(data));
        setListOfCommodities(data);
      });
  }, [dispatch, listOfCommodities]);

  // check if utilities did not exist from redux
  useEffect(() => {
    // if units is empty
    if (listOfUnits.length === 0)
      getUnits().then((data: any) => {
        dispatch(setUtilityUnits(data));
        setListOfUnits(data);
      });
  }, [dispatch, listOfUnits]);

  // cja: get the steps saved in session, to be used in updating the steps component
  const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);
  useEffect(() => {
    const strSteps = sessionStorage.getItem("steps");
    strSteps != null && setStepsFromSession(JSON.parse(strSteps));
  }, []);

  // cja: update the step component
  const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
  useEffect(() => {
    if (stepsFromSession.length !== 0) {
      manageSteps(
        { name: "Choose Item", href: "/choose_item", status: "current" },
        stepsFromSession
      ).then((updated_steps: any) => {
        setUpdatedSteps(updated_steps);
      });
    }
  }, [stepsFromSession]);

  // cja: add product button click handler function
  const addProductClickHandler = () => {
    resetFieldErrorState();
    if (selectedCommodity.id === 0 || selectedUnit.id === 0 || volume === "") {
      setProductIsError(selectedCommodity.id === 0 ? true : false);
      setUnitIsError(selectedUnit.id === 0 ? true : false);
      setVolumeIsError(volume === "" ? true : false);
    } else {
      if (selectedProducts.length === 3) {
        setSelectedIsMaximumReached(true);
        resetFields();
      } else {
        saveNewProduct().then(() => {
          resetFields();
        });
      }
    }
  };

  // cja: saving new product in the redux
  const saveNewProduct = () => {
    return new Promise((resolve, reject) => {
      let newSelectedProduct: BuyerSelectedItemsType = {
        product: selectedCommodity,
        unit: selectedUnit,
        volume: parseInt(volume),
      };

      dispatch(
        setSelectedCommodityProducts([...selectedProducts, newSelectedProduct])
      );
      resolve(true);
    });
  };

  const navigateToShippingDetails = () => {
    resetFieldErrorState();
    if (selectedProducts.length === 0) {
      setSelectedIsError(true);
    } else {
      navigate("/shipping_details");
    }
  };

  const resetFieldErrorState = () => {
    setProductIsError(false);
    setUnitIsError(false);
    setVolumeIsError(false);
    setSelectedIsError(false);
    setSelectedIsMaximumReached(false);
  };

  // cja: reset the temporary local states for the fields
  const resetFields = () => {
    setSelectedCommodity({ icon: "", id: 0, name: "" });
    setSelectedUnit({ code: "", id: 0, name: "" });
    setVolume("");
  };

  // cja: remove product chips handler
  const removeProductChipHandler = (product_id: number) => {
    let updatedSelectedProducts: BuyerSelectedItemsType[] = [];
    selectedProducts.forEach((selected_product: BuyerSelectedItemsType) => {
      if (selected_product.product.id.toString() !== product_id.toString()) {
        updatedSelectedProducts = [
          ...updatedSelectedProducts,
          selected_product,
        ];
      }
    });

    dispatch(setSelectedCommodityProducts(updatedSelectedProducts));
  };

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              breadcrumb={<BreadcrumbComponent />}
              subtitle="Choose Item"
              steps={<StepsComponent steps={updatedSteps} />}
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <div className="flex flex-wrap justify-center mt-2">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-10 md:pl-8 md:w-2/5">
                <label
                  htmlFor="txtshippingmethod"
                  className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                >
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Product</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={productIsError}
                    items={listOfCommodities}
                    placeholder="Select product"
                    selectedItem={selectedCommodity}
                    setSelectedItem={setSelectedCommodity}
                  />
                </div>
                {productIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Product field is required
                    </p>
                  </div>
                )}
                <label
                  htmlFor="txtlocation"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                >
                  <div className="flex gap-2">
                    <BeakerIcon className="-ml-0.5 h-5 w-5" />
                    <span>Units</span>
                  </div>
                </label>
                <div className="mt-2">
                  <ComboboxComponent
                    hasError={unitIsError}
                    items={listOfUnits}
                    placeholder="Select unit measurement"
                    selectedItem={selectedUnit}
                    setSelectedItem={setSelectedUnit}
                  />
                </div>
                {unitIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Unit field is required
                    </p>
                  </div>
                )}
                <label
                  htmlFor="txtlocation"
                  className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                >
                  <div className="flex gap-2">
                    <Square3Stack3DIcon className="-ml-0.5 h-5 w-5" />
                    <span>Volume</span>
                  </div>
                </label>
                <div className="mt-2">
                  <input
                    value={volume}
                    type="text"
                    name="txtlocation"
                    id="txtlocation"
                    placeholder="Set range of volume"
                    className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                      volumeIsError ? "red-600" : "gray-300"
                    } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setVolume(e.target.value)
                    }
                  />
                </div>
                {volumeIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Volume field is required
                    </p>
                  </div>
                )}
                <hr className="mt-8 h-0.5 border-t-1 bg-gray-100 opacity-100 dark:opacity-50" />
                <label className="block text-sm font-medium leading-6 text-gray-900 mt-6">
                  <div className="flex gap-2">
                    <CubeIcon className="-ml-0.5 h-5 w-5" />
                    <span>Selected Items</span>
                  </div>
                </label>
                <div>
                  {selectedProducts.length !== 0 &&
                    selectedProducts.map(
                      (selected_product: BuyerSelectedItemsType) => {
                        return (
                          <ChipsComponent
                            key={generateKey(
                              Math.random.toString() +
                                selected_product.product.id
                            )}
                            text={`${selected_product.product.name} (${selected_product.volume} ${selected_product.unit.name})`}
                            removeHandler={() =>
                              removeProductChipHandler(
                                selected_product.product.id
                              )
                            }
                          />
                        );
                      }
                    )}
                </div>
                {selectedProducts.length === 0 && (
                  <p className="ml-6 mt-3 text-xs text-gray-400">
                    -- No added products --
                  </p>
                )}
                {selectedIsError && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      Please add product(s) first.
                    </p>
                  </div>
                )}
                {selectedIsMaximumReached && (
                  <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                      You can only select up to 3 products
                    </p>
                  </div>
                )}
                <ButtonComponent
                  text="Add Product"
                  icon={
                    false && (
                      <Lottie
                        className="md:w-5 w-5 h-auto"
                        animationData={CircularLoading}
                        loop={true}
                      />
                    )
                  }
                  disabled={false}
                  utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-4 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-10"
                  onClick={addProductClickHandler}
                />
                <ButtonComponent
                  text="Set Delivery / Pick up Location"
                  utils="bg-dealogikal-100 text-white text-sm shadow-sm py-4 sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full mt-4"
                  onClick={navigateToShippingDetails}
                />
              </div>
              <div className="flex flex-col w-full md:pt-10 md:pl-8 md:w-3/5"></div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};

export default ChooseItemComp;
