import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import GettingStartedComponent from "./getting-started/GettingStartedComponent";
import OrderStatusComponent from "./order-status/OrderStatusComponent";
import MyStoreComponent from "./store/MyStoreComponent";

const DashboardComponent = () => {
  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* Features component */}
            <MyStoreComponent />
            {/* Order status component */}
            <OrderStatusComponent />
            {/* Getting started component */}
            <GettingStartedComponent />
          </>
        }
      />
    </>
  );
};

export default DashboardComponent;
