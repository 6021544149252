interface Props {
  content: {
    id: number;
    name: string;
    icon: string;
  };
  behavior?: {
    hover?: boolean;
  };
  event?: {
    onClick?: () => void;
  };
}

const CircleImageComponent: React.FC<Props> = (props) => {
  return (
    <>
      <div
        className="flex flex-col h-20 w-20 mx-1 md:h-48 md:w-48 md:mx-16 md:mt-20 rounded-full overflow-hidden bg-gray-100 cursor-pointer sm:hover:bg-gray-300 duration-500"
        onClick={props.event?.onClick}
      >
        <div className="flex flex-col md:h-3/5 md:w-2/3 mx-auto pt-3">
          <img
            className="h-5 md:h-auto w-auto m-1 md:m-6"
            src={props.content.icon}
            alt={props.content.name + ` logo`}
          />
        </div>
        <div className="flex flex-col md:h-2/5 w-full md:w-full">
          <h2 className="text-center text-gray-900 md:text-base testing text-xs md:mt-4">
            {props.content.name}
          </h2>
        </div>
      </div>
    </>
  );
};

export default CircleImageComponent;
