const ClosedAuctionStateComponent = () => {
  return (
    <div className="flex flex-wrap justify-center mt-12">
      <div className="flex flex-col w-full  md:w-full py-10 items-center">
        <img
          src="https://res.cloudinary.com/doehyebmw/image/upload/v1704350241/dealogikal_2.0/resources/images/big_img/big_img2_ykftx4.png"
          className="h-56 w-96 lg:w-2/5 lg:h-auto"
          alt="Empty State Img"
        />
      </div>
    </div>
  );
};
export default ClosedAuctionStateComponent;
