import { useEffect, useState } from 'react'
import MainConsoleLayoutComponent from '../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent'
import BreadcrumbContainerComponent from '../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent'
import ComboboxComponent from '../../../templates/ComboboxComponent/ComboboxComponent'
import { ComboBoxItemType } from '../../../../model/types/ComboBoxItemType'
import ButtonComponent from '../../../templates/ButtonComponent/ButtonComponent'
import { getPhilippineIslandsAPI } from '../../../../redux/functions/API'
import { useNavigate } from 'react-router-dom'
import { getTokenFromLocalStorage } from '../../../../redux/functions/function'
import DeliveryIslandLocationModel from '../../../../model/interface/DeliveryIslandLocationModel';
import { MapPinIcon } from '@heroicons/react/24/outline'
import { useAppDispatch } from '../../../../redux/app/hooks'
import { setSelectedDeliveryZone } from '../../../../redux/functions/storageSlice'

const SetDeliveryZoneComp = () => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();  
    // get and decode the token from local storage
    const _token = getTokenFromLocalStorage() ?? "";
    const [selectedLocationIsland, setSelectedLocationIsland] = useState<DeliveryIslandLocationModel>({
        id: "",
        island: ""
    });
    const [isDeliveryLocationEmpty, setIsDeliveryLocationEmpty] = useState<boolean>(false);
    const [listOfDeliveryLocation, setlistOfDeliveryLocation] = useState<ComboBoxItemType>([]);
    const [philippineIslands, setPhilippineIslands] = useState([]);

    const handlerAddTruck = async () => {
        if (!selectedLocationIsland.id){
            setIsDeliveryLocationEmpty(true);
        };
        if (selectedLocationIsland.id) {
            dispatch(setSelectedDeliveryZone(selectedLocationIsland.id));
            navigate("/l/set_rate");
        };
    };

    useEffect(() => {
        const fetchData = async () => {
            if (philippineIslands.length === 0)
                try {
                    const islands: any = await getPhilippineIslandsAPI(_token);
                    setPhilippineIslands(islands);
                    // Prepare the list of delivery locations for ComboboxComponent
                    const deliveryLocations = islands.map((island: any) => ({
                        id: island.id,
                        name: island.island,
                    }));
                    // Set the list of delivery locations
                    setlistOfDeliveryLocation(deliveryLocations);
                } catch (error: any) {
                    setIsDeliveryLocationEmpty(error);
                }
        };

        fetchData();
    }, [_token, philippineIslands.length]);

    return (
        <>
            <MainConsoleLayoutComponent
                content={
                    <>
                        {/**-------------------------------------------------------------------------------------------------------------- */}
                        <BreadcrumbContainerComponent
                            key={Math.random()}
                            subtitle="Delivery Zone"
                        />
                        {/**-------------------------------------------------------------------------------------------------------------- */}
                        <label
                            htmlFor='txtlocation'
                            className='block text-sm font-medium leading-6 text-gray-900 mt-16'
                        >
                            <div className="flex gap-2">
                                <MapPinIcon className="-ml-0.5 h-5 w-5" />
                                <span>Set Delivery Location</span>
                            </div>
                        </label>
                        <div className="inline-block mt-2">
                            <ComboboxComponent
                                hasError={isDeliveryLocationEmpty}
                                items={listOfDeliveryLocation}
                                placeholder="Set delivery zone"
                                selectedItem={selectedLocationIsland}
                                setSelectedItem={setSelectedLocationIsland}
                            />
                        </div>
                        {isDeliveryLocationEmpty && (
                            <div className="ml-6 flex mt-3">
                                <p className="text-red-600 text-xs md:text-sm">
                                    Delivery location details field is required
                                </p>
                            </div>
                        )}
                        <div className='flex mt-32'>
                            <ButtonComponent
                                text="Confirm"
                                utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:my-0 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-1/3 w-full"
                                onClick={handlerAddTruck}
                            />
                        </div>
                    </>
                }
            />
        </>
    )
}

export default SetDeliveryZoneComp