import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import { generateKey } from "../../../redux/functions/function";
import { PaginationModel } from "../../../model/interface/PaginationModel";
import { useMemo } from "react";

export default function PaginationComponent(props: PaginationModel) {
	// DC: declare variables here...
	const totalNumOfPages = Math.ceil(props.totalRows / props.rowsPerPage);
	const totalRecords = props.totalRecords ?? 0;
	const overAlls =
		(props.currentPage - 1) * props.rowsPerPage + props.totalRows;

	const totalNumOfPagess = Math.ceil(
		(props.totalRecords ?? 0) / props.rowsPerPage
	);

	// DC: generate paginations here...
	const generatePageNumbers = useMemo(() => {
		const pageNumbers: any = [];
		const maxVisiblePages = 3; // Show 3 pages at a time
		let startPage = Math.max(
			1,
			props.currentPage - Math.floor(maxVisiblePages / 2)
		);
		let endPage = startPage + maxVisiblePages - 1;

		if (overAlls !== totalRecords) {
			if (endPage - startPage + 1 < maxVisiblePages) {
				startPage = Math.max(1, endPage - maxVisiblePages + 1);
			}

			for (let i = startPage; i <= endPage; i++) {
				pageNumbers.push(i);
			}
		} else {
			// If overAlls equals totalNumOfPagess, maintain the current range without incrementing
			const lastPage = totalNumOfPagess;

			// Set the range to show based on the current page
			if (props.currentPage === lastPage) {
				// User is on the last page, show the last three pages
				const startPage = Math.max(1, lastPage - 2); // Start from the last page - 2
				for (let i = startPage; i <= lastPage; i++) {
					pageNumbers.push(i);
				}
			} else {
				// If the user is not on the last page, show the normal range
				const prevPage = Math.max(1, lastPage - 2); // Ensure it doesn't go below 1
				for (let i = prevPage; i <= lastPage; i++) {
					pageNumbers.push(i);
				}
			}
		}

		return pageNumbers;
	}, [props.currentPage, totalNumOfPagess, overAlls, totalRecords]);

	const lastPageNumber = totalNumOfPagess;

	return (
		<div className="flex items-center justify-between border-t border-gray-200 bg-white py-3">
			{/* will only display it to mobile devices */}
			<div className="flex flex-1 justify-between sm:hidden mt-4 mx-2">
				<button
					onClick={(e) => {
						e.preventDefault(); // Prevent the default anchor behavior
						if (props.onPageChange && props.currentPage > 1) {
							props.onPageChange(props.currentPage - 1); // Handle previous click
						}
					}}
					disabled={props.currentPage === 1}
					className={`relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 md:text-sm text-xs font-medium text-gray-700 hover:bg-gray-100 ${
						props.currentPage === 1 && "pointer-events-none opacity-50" // Disable when on the first page
					}`}
				>
					Previous
				</button>
				{/* {pagesComponent} */}
				{generatePageNumbers.map((page: any) => (
					<button
						key={page}
						onClick={(e) => {
							e.preventDefault();
							props.onPageChange?.(page); // Optional chaining to prevent undefined errors
						}}
						className={`px-2 py-1 mx-1 ${
							props.currentPage === page
								? "font-bold text-blue-600"
								: "text-gray-600"
						}`}
					>
						{page}
					</button>
				))}
				<button
					onClick={(e) => {
						e.preventDefault(); // Prevent default anchor behavior
						if (props.currentPage < totalNumOfPages) {
							props.onPageChange?.(props.currentPage + 1); // Handle next click
						}
					}}
					disabled={props.currentPage === 3}
					className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 md:text-sm text-xs font-medium text-gray-700 hover:bg-gray-100"
				>
					Next
				</button>
			</div>

			{/* will only display this from small to large screens */}
			<div className="hidden sm:flex sm:flex-1 sm:items-center sm:justify-between mt-4">
				<div>
					<p className="text-sm text-gray-700">
						Showing{" "}
						<span className="font-bold">
							{/* Calculate the starting row for the current page */}
							{(props.currentPage - 1) * props.rowsPerPage + 1}
						</span>{" "}
						to{" "}
						<span className="font-bold">
							{/* Calculate the ending row for the current page, making sure not to exceed totalRows */}
							{Math.min(props.currentPage * props.rowsPerPage, props.totalRows)}
						</span>{" "}
						of <span className="font-bold">{props.totalRecords}</span> results
					</p>
				</div>
				<div>
					<nav
						className="isolate inline-flex -space-x-px rounded-md shadow-sm"
						aria-label="Pagination"
					>
						<button
							onClick={(e) => {
								e.preventDefault(); // Prevent default anchor behavior
								if (props.currentPage > 1) {
									props.onPageChange?.(props.currentPage - 1); // Handle previous click
								}
							}}
							disabled={props.currentPage === 1}
							className="relative cursor-pointer inline-flex items-center rounded-l-md px-2 py-2 text-black
             ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Previous</span>
							<ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
						</button>
						{/* {pagesComponent} */}
						{/* Pagination numbers */}
						{generatePageNumbers.map((page: any) => (
							<span
								key={generateKey(page.toString())}
								onClick={() => props.onPageChange?.(page)}
								className={`${
									page === props.currentPage
										? "bg-dealogikal-100 text-white hover:bg-dealogikal-200 hover:text-white ring-1 ring-inset ring-dealogikal-100"
										: "hover:bg-gray-100 ring-1 ring-inset ring-gray-300"
								} relative cursor-pointer inline-flex items-center px-4 py-2 text-sm font-semibold text-gray-900 focus:z-20 focus:outline-offset-0`}
							>
								{page}
							</span>
						))}
						<button
							onClick={(e) => {
								e.preventDefault(); // Prevent default anchor behavior
								console.log("current page", props.currentPage);
								if (props.currentPage >= totalNumOfPages) {
									// Proceed to the next page if the current page is less than the total number of pages
									props.onPageChange?.(props.currentPage + 1);
								}
							}}
							disabled={props.currentPage === lastPageNumber}
							className="relative cursor-pointer inline-flex items-center rounded-r-md px-2 py-2 text-black
             ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:z-20 focus:outline-offset-0"
						>
							<span className="sr-only">Next</span>
							<ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
						</button>
					</nav>
				</div>
			</div>
		</div>
	);
}
