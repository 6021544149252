import { Fragment, ReactNode } from "react";
import { Popover, Transition } from "@headlessui/react";

interface Props {
	icon?: ReactNode;
	header?: ReactNode;
	body: ReactNode;
	footer?: ReactNode;
	button?: ReactNode;
}

const NotificationModalComponent: React.FC<Props> = (props) => {
	return (
		<div className="fixed top-2 m-2.5 -ml-10">
			<Popover className="relative">
				<Popover.Button
					className={`position-right text-gray-400 group inline-flex items-center text-base hover:text-dealogikal-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75`}
				>
					{props.button}
				</Popover.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 translate-y-1"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 translate-y-1"
				>
					<Popover.Panel
						className="absolute left-1/2 z-10 mt-3 w-96 max-h-96 overflow-y-auto md:-translate-x-56 -translate-x-48 rounded-lg drop-shadow-lg bg-gray-100
            "
					>
						{props.header}
						{props.body}
						{props.footer}
					</Popover.Panel>
				</Transition>
			</Popover>
		</div>
	);
};

export default NotificationModalComponent;
