const ShippingTimeline = ({ date, status, isCompleted, isLast }) => {
	return (
		<div className="flex items-center">
			<div className="flex flex-col items-center">
				<div
					className={`w-5 h-5 rounded-full border-4 ${
						isCompleted
							? "bg-blue-500 border-blue-500"
							: "bg-white border-gray-300"
					} flex items-center justify-center`}
				>
					{isCompleted && <div className="w-3 h-3 bg-white rounded-full" />}
				</div>
				{!isLast && <div className="w-1 h-10 bg-gray-300" />}
			</div>
			<div className="ml-4 mb-5">
				<p className="text-xs text-gray-500">{date}</p>
				<p className="font-semibold text-sm">{status}</p>
			</div>
		</div>
	);
};

export default ShippingTimeline;
