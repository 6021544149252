import { H2HeaderModel } from "../../../model/interface/H2HeaderModel";

const H2Header = (props: H2HeaderModel) => {
  return (
    <h2 className="text-lg lg:text-2xl font-bold leading-9 tracking-tight text-gray-900 mx-auto text-center">
      {props.label}
    </h2>
  );
};

export default H2Header;
