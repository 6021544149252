import {
  ShoppingBagIcon,
  TagIcon,
  TruckIcon,
} from "@heroicons/react/24/outline";

const exploreDealogikal = [
  {
    name: "Active Quotations",
    icon: TagIcon,
    description: "",
    href: "/b/reverse_auction_list",
  },
  {
    name: "My Orders",
    icon: ShoppingBagIcon,
    description: "",
    href: "/b/my_orders",
  },
  {
    name: "My Vehicle",
    icon: TruckIcon,
    description: "",
    href: "/b/vehicle_manager",
  },

  // {
  //   name: "Buy Commodities",
  //   icon: TruckIcon,
  //   description: "Description here...",
  //   href: "/select_commodity",
  // },
  // {
  //   name: "Browse Marketplace",
  //   icon: BuildingStorefrontIcon,
  //   description: "Description here...",
  //   href: "/browse_marketplace",
  // },
  // {
  //   name: "Trade Finance",
  //   icon: ScaleIcon,
  //   description: "Description here...",
  //   href: "/trade_finance",
  // },
  // {
  //   name: "Explore Community",
  //   icon: ChatBubbleLeftEllipsisIcon,
  //   description: "Description here...",
  //   href: "/explore_community",
  // },
];

export default exploreDealogikal;
