import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./redux/app/store";
import { Provider } from "react-redux";
import { AuthProvider } from "./context/authContext";
import WebSocketProvider from "./services/ws/WebSocketService";
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <Provider store={store}>
        {/* cja: this provider is responsible for opening, closing, and seding of messages for websocket connections */}
        <WebSocketProvider url="wss://zjeh615hm5.execute-api.ap-southeast-1.amazonaws.com/dev/">
          <App />
        </WebSocketProvider>
      </Provider>
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
