import { TokenModel } from "../model/interface/TokenModel";
import { getTokenFromLocalStorage } from "../redux/functions/function";
import jwt_decode from "jwt-decode";
import { useWebSocket } from "../services/ws/WebSocketService";
import { useAppDispatch, useAppSelector } from "../redux/app/hooks";
import {
  getSocketConnection,
  setSocketConnection,
  setSocketResponseData,
} from "../redux/functions/storageSlice";
import { useEffect } from "react";

export const useCheckWebSocketConnection = (isAuthenticated: boolean) => {
  // for ws purposes
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const { socket, sendWebSocketMessage, socketMessage } = useWebSocket();
  const dispatch = useAppDispatch();
  const socketConnection = useAppSelector(getSocketConnection);

  /*
    cja: hook that will establish the web 
    socket connection if refreshed
  */
  useEffect(() => {
    if (socket !== null && isAuthenticated && !socketConnection.isConnected) {
      if (socket?.readyState === WebSocket.OPEN) {
        const socketPayload = {
          user_id: userData._id,
        };

        sendWebSocketMessage("establishSocketConnection", socketPayload);
      }
    }
  }, [
    socket,
    sendWebSocketMessage,
    userData._id,
    isAuthenticated,
    socketConnection.isConnected,
  ]);

  // cja: this hook will handle the socket message
  useEffect(() => {
    if (socketMessage !== null) {
      const strData: string = socketMessage.toString();
      if (strData === "Success") {
        // do something here
      } else {
        const socketData = JSON.parse(strData);
        const { payload } = socketData;

        if (payload.key === "CONNECTION_ESTABLISHED") {
          // save connection details to redux
          dispatch(setSocketResponseData(socketData));
          dispatch(
            setSocketConnection({ isConnected: true, currentConnectionId: "" })
          );
        }
      }
    }
  }, [socketMessage, dispatch]);
};
