import { DashboardOrderStatusModel } from "../../../model/interface/DashboardOrderStatusModel";

const DashboardCardComponent2 = (props: DashboardOrderStatusModel) => {
  return (
    <>
      <dl className="ml-1 mt-6 grid grid-cols-2 gap-3 md:gap-x-8 overflow-hidden text-center sm:grid-cols-2 lg:grid-cols-4">
        {props.data.map((stat) => (
          <div
            key={stat.id}
            className="flex flex-col bg-gray-100 p-8 rounded-3xl"
          >
            <dt className="text-sm font-semibold leading-6 text-white">
              {stat.name}
            </dt>
            <dd className="order-first text-3xl font-semibold tracking-tight text-white">
              {stat.value}
            </dd>
          </div>
        ))}
      </dl>
    </>
  );
};

export default DashboardCardComponent2;
