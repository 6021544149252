import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import PageNotFound from "./pages/page_not_found/PageNotFound";
import ProtectedRoutes from "./routes/ProtectedRoutes";
import PrivateRoutes from "./routes/PrivateRoutes";
import PublicRoutes from "./routes/PublicRoutes";
import { useUser } from "./context/authContext";

const App = () => {
  const { userRole } = useUser();

  return (
    <Router>
      <Routes>
        {PublicRoutes.map((item, key) => (
          <Route key={key} {...item} />
        ))}

        {userRole ? (
          <Route element={<ProtectedRoutes />}>
            {PrivateRoutes.map((item, key) =>
              item && item.allowedRoles && item.allowedRoles.includes(userRole) ? (
                <Route key={key} {...item} />
              ) : (
                <Route key={key} path="/401" element={<PageNotFound />} />
              )
            )}
          </Route>
        ) : (
          <Route key="login" path="/login" element={<Navigate to="/login" />} />
        )}

        <Route key="not-found" path="*" element={<PageNotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
