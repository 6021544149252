import { useState } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../../redux/functions/function";
import { ComboBoxItemModel } from "../../../model/interface/ComboBoxItemModel";

const ComboboxComponent: React.FC<ComboBoxItemModel> = (props) => {
  const [query, setQuery] = useState("");
  const filteredPeople =
    query === ""
      ? props.items
      : props.items.filter((item) => {
          return item.name.toLowerCase().includes(query.toLowerCase());
        });

  return (
    <Combobox
      as="div"
      value={props.selectedItem}
      onChange={props.setSelectedItem}
      disabled={props.isDisabled}
    >
      <div className="relative mt-2">
        <Combobox.Input
          className={`text-sm block w-full rounded-2xl py-3 px-6 shadow-sm border-0 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 sm:text-sm sm:leading-6 ${
            props.hasError
              ? "ring-1 ring-inset ring-red-600"
              : "ring-1 ring-inset ring-gray-300"
          } ${props.isDisabled ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200" : ""}`}
          onChange={(event) => setQuery(event.target.value)}
          displayValue={(item: any) => item?.name}
          placeholder={props.placeholder}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Combobox.Button>

        {filteredPeople.length > 0 && (
          <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm">
            {filteredPeople.map((item) => (
              <Combobox.Option
                key={Math.random().toString() + item.id.toString()}
                value={item}
                className={({ active }) =>
                  classNames(
                    "relative cursor-default select-none py-2 pl-3 pr-9",
                    active ? "bg-dealogikal-100 text-white" : "text-gray-900"
                  )
                }
              >
                {({ active, selected }) => (
                  <>
                    <span
                      className={classNames(
                        "block truncate",
                        selected && "font-semibold"
                      )}
                    >
                      {item.name}
                    </span>

                    {selected && (
                      <span
                        className={classNames(
                          "absolute inset-y-0 right-0 flex items-center pr-4",
                          active ? "text-white" : "text-dealogikal-100"
                        )}
                      >
                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                      </span>
                    )}
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    </Combobox>
  );
};

export default ComboboxComponent;
