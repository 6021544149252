import DashboardCardComponent3 from "../../../templates/CardComponent/DashboardCardComponent3";
import PostData from "./GettingStartedData";

const GettingStartedComponent = () => {
  return (
    <div className="py-8 md:py-24">
      <div className="mx-auto max-w-full px-6 lg:px-8">
        <div className="mx-auto w-full">
          <h2 className="text-xl md:text-2xl font-bold tracking-tight text-black text-left mb-6">
            Getting started
          </h2>
        </div>
        <DashboardCardComponent3 data={PostData} />
      </div>
    </div>
  );
};

export default GettingStartedComponent;
