import QuotationComp from "../../templates/QuotationComponent/QuotationComp";
import { getQuotationDetailsAPI } from "../../../redux/functions/API";
import { useEffect, useState } from "react";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import { TokenModel } from "../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useAppSelector } from "../../../redux/app/hooks";
import { getQuotationDetails } from "../../../redux/functions/storageSlice";
import { TruckIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";

const SellerQuotationComp = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const [cardData, setCardData] = useState<any>([]);
  const [quotationDetails, setQuotationDetails] = useState<any>({});
  const [isRefreshing, setisRefreshing] = useState<boolean>(true);

  const navigate = useNavigate();
  const quoteDetails = useAppSelector(getQuotationDetails);

  // cja: if offerlist in redux is empty, it will automatically redirect to offer list page
  useEffect(() => {
    quoteDetails.quotationID === 0 && navigate("/my_offers");
  }, [navigate, quoteDetails.quotationID]);

  useEffect(() => {
    if (isRefreshing) {
      getQuotationDetailsAPI(
        _token,
        quoteDetails.quotationID,
        userData.role
      ).then((data: any) => {
        // get dominant number of offers
        let dominantOfferCount: number = 0;
        data.suppliers.forEach((supplier: any) => {
          if (dominantOfferCount !== 0) {
            if (dominantOfferCount < supplier.length) {
              dominantOfferCount = supplier.offers.length;
            }
          } else {
            dominantOfferCount = supplier.offers.length;
          }
        });

        // mapping and storing of restructured quotation details to local states
        let quotationDetails = data.quotation_details;
        let supplierDetailsOffer: any = [];
        data.suppliers.forEach((supplier: any) => {
          if (supplier.offers.length === dominantOfferCount) {
            let supplier_id: number = 0;
            let name: string = "";
            let logo_url: string = "";
            let offers: any = [];
            supplier.offers.forEach((supplier_details: any) => {
              supplier_id = supplier.supplier_id;
              name = supplier.company_name;
              logo_url = supplier.company_logo;
              offers = [
                ...offers,
                {
                  commodity_name: supplier_details.commodity_name,
                  commodity_icon: supplier_details.commodity_icon,
                  price: supplier_details.price,
                  min_volume: supplier_details.min_volume,
                  max_volume: supplier_details.max_volume,
                  requested_volume: supplier_details.requested_volume,
                },
              ];
            });
            supplierDetailsOffer = [
              ...supplierDetailsOffer,
              {
                supplier_id,
                name,
                logo_url,
                offers,
              },
            ];
          }
        });

        let updatedCardData: any[] = [
          {
            name: data.buyer_details.company_name,
            icon: TruckIcon,
            role: "Buyer",
            imageUrl: data.buyer_details.company_logo,
          },
          {
            name: "FTAT Logistics",
            icon: TruckIcon,
            role: "Logistic",
            imageUrl:
              "https://res.cloudinary.com/doehyebmw/image/upload/v1692950374/dealogikal_2.0/resources/logo/trucking-logo_qesgia.png",
          },
          {
            name: supplierDetailsOffer[0].name,
            icon: TruckIcon,
            role: "Supplier",
            imageUrl: supplierDetailsOffer[0].logo_url,
          },
        ];

        // final data to be render to buyer quotation page
        setQuotationDetails({
          suppliers: supplierDetailsOffer,
          quotation_details: quotationDetails,
        });
        setCardData(updatedCardData);
        setisRefreshing(false);
      });
    }
  }, [_token, userData.role, isRefreshing, quoteDetails.quotationID]);

  return (
    <QuotationComp
      user_data={userData}
      card_data={cardData}
      quotation_details={quotationDetails}
      refreshHandler={() => setisRefreshing(true)}
      isRefreshing={isRefreshing}
    />
  );
};
export default SellerQuotationComp;
