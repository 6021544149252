import React, { ChangeEvent, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import {
  ArrowUpTrayIcon,
  CloudArrowUpIcon,
  CubeIcon,
  PhotoIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import Lottie from "lottie-react";
import CircularLoading from "../../../../lottie/circular_loading_theme_1.json";
import ToasterComponent from "../../../templates/ToasterComponent/ToasterComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { addDriverInfoAPI } from "../../../../redux/functions/API";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

const AddDriverComp = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const navigate = useNavigate();

  const cloudName = "doehyebmw";
  const uploadPreset = "k3qgtsat";

  const [driverLicenseURL, setDriverLicenseURL] = useState<string>("");
  const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [isModalImage, setIsModalImage] = useState<boolean>(false);
  const [isLoadingAPI, setIsLoadingAPI] = useState<boolean>(false);
  const [isLoadingDriverAddAPI, setIsLoadingDriverAddAPI] = useState<boolean>(false);
  const [isAddingLicense, setIsAddingLicense] = useState<boolean>(false);
  const [selectedDriverLicenseFile, setSelectedDriverLicenseFile] = useState<
    File[]
  >([]);
  const [error, setError] = useState<string>("");
  const [uploadSuccess, setUploadSuccess] = useState<string>("");

  const [isAddingDriver] = useState<boolean>(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
 

  const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [toastType, setToastType] = useState<string>("");

  const [driverNameIsError] = useState<boolean>(false);
  const [driverMiddleNameIsError] = useState<boolean>(false);
  const [driverLastNameIsError] = useState<boolean>(false);
  const [driverLicenseNumberIsError] = useState<boolean>(false);

  const [driverName, setDriverName] = useState<string>("");
  const [driverMiddleName, setDriverMiddleName] = useState<string>("");
  const [driverLastName, setDriverLastName] = useState<string>("");
  const [driverLicenseNumber, setDriverLicenseNumber] = useState<string>("");

  // delete this console when applying the adding driver api

  // upload driver license (start) ====================================================================>

  // handler for uploading license
  const uploadDriverLicenseHandler = async () => {
    if (selectedDriverLicenseFile.length > 1) {
      setToastMessage("Please upload one receipt only");
      setToastType("error");
      setIsToastOpen(true);
      setTimeout(() => setIsToastOpen(false), 3000);
      return;
    }

    //setIsButtonDisabled(true);
    setIsAddingLicense(true);
    setIsLoadingAPI(true);
    const formData = new FormData();
    formData.append("file", selectedDriverLicenseFile[0]);
    formData.append("upload_preset", uploadPreset); // Your upload preset from Cloudinary

    try {
      const response = await fetch(
        `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
        {
          method: "POST",
          body: formData,
        }
      );
      const dataSupplierReceiptupload = await response.json();
      if (dataSupplierReceiptupload.error) {
        setError(dataSupplierReceiptupload.error.message);
      } else {
        setDriverLicenseURL(dataSupplierReceiptupload.url);
        setUploadSuccess("Successfully uploaded driver license");
        setIsAddingLicense(true);
        setIsLoadingAPI(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const showImageImportHandler = (index: number) => {
    setIsModalImage(true);

    const file = selectedDriverLicenseFile[index];
    const reader = new FileReader();
    reader.onloadend = () => {
      setSelectedImage(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const removeChipHandler = (index: number) => {
    const updatedFiles = [...selectedDriverLicenseFile];
    updatedFiles.splice(index, 1);
    setSelectedDriverLicenseFile(updatedFiles);
  };

  const importImageHandler = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);

    const files = Array.from(event.dataTransfer.files);
    handleFiles(files);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(true);
  };

  const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDraggingOver(false);
  };

  const handleFiles = (files: File[]) => {
    let totalSize = 0;
    files.forEach((file) => {
      totalSize += file.size;
    });

    const maxSize = 5 * 1024 * 1024; // 5MB in bytes
    if (totalSize > maxSize) {
      setError("Total file size exceeds 5MB. Please upload smaller files.");
      return;
    }

    setSelectedDriverLicenseFile((prevFiles) => [...prevFiles, ...files]);
    setError(""); // Reset error if the file size is within the limit
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(event.target.files || []);
    handleFiles(files);
  };

  const closeSelectedImage = () => {
    setSelectedImage(null);
    setIsModalImage(false);
  };
  // upload driver license (end) ====================================================================>


  // API Integration
  const handlerAddDriver = async () => {
    try {
      const data = {
        firstname: driverName,
        middlename: driverMiddleName,
        lastname: driverLastName,
        license_number:driverLicenseNumber,
        license_url: driverLicenseURL,
        logistic_id: userData._id,
      };

      return addDriverInfoAPI(_token, data).then((response: any) => {
        
        if (response.statusCode === 200) {
          console.log("Driver added successfully", response);
          setIsButtonDisabled(true);
          setIsLoadingDriverAddAPI(false)
          navigate("/l/drivers");
        } else {
          console.error("Failed to add Driver", response);
          const missingParamsMessage = response.data.message;
          const missingParams = missingParamsMessage.match(
            /firstname|middlename|lastname|license_url/g
          );

          if (missingParams) {
            // Create a custom message based on missing parameters
            // Replace "license_url" with "photo"
          const updatedParams = missingParams.map((param:any) => param === 'license_url' ? 'license photo' : param);
          const customMessage = `Missing parameters: ${updatedParams.join(", ")}`;
          setToastMessage(customMessage);
          } 
          else {
            setToastMessage("An unknown error occurred.");
          }
          setToastType("error");
          setIsToastOpen(true);
        }
      });
    } catch (error) {
      console.error("Error adding Driver", error);
    }
  };


  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            <ToasterComponent
              isOpen={isToastOpen}
              label={toastMessage}
              onClose={setIsToastOpen}
              type={toastType}
            />
            {/**---------------------------------------------------------------------------------------------------- */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="Driver Manager"
            />
            {/**---------------------------------------------------------------------------------------------------- */}
            <ButtonComponent
              text={`${isButtonDisabled ? "Adding Driver" : "Add Driver"}`}
              icon={
                isLoadingDriverAddAPI && (
                  <Lottie
                    className="md:w-5 w-5 h-auto"
                    animationData={CircularLoading}
                    loop={true}
                  />
                )
              }
              disabled={isButtonDisabled}
              utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 -mt-5 py-3.5 mb-5 float-right sm:hover:bg-dealogikal-200
                    duration-500 rounded-full font-normal md:w-56 w-full ${
                      isAddingDriver ? "cursor-not-allowed opacity-50" : ""
                    } `}
              onClick={handlerAddDriver}
            />
            {/**---------------------------------------------------------------------------------------------------- */}
            <div className="flex ml-4 mt-2 md:mt-10">
              <div className="grid">
                <div
                  key={Math.random()}
                  className="col-span-1 divide-y divide-gray-200 text-gray-400"
                >
                  Details
                </div>
              </div>
            </div>
            <div className="mt-1 mb-2">
              <hr className="w-full ml-2 "></hr>
            </div>
            {/** input Driver Details */}
            <div className="flex flex-col w-full md:pt-4 md:pl-2 md:w-full ml-2">
              <div className="grid grid-cols-1 gap-2 md:gap-6 sm:grid-cols-2">
                {/** Drivers First Name  */}
                <div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
                  <label
                    htmlFor="txtDriverName"
                    className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                  >
                    <div className="flex gap-2">
                      <CubeIcon className="-ml-0.5 h-5 w-5" />
                      <span>First Name</span>
                    </div>
                  </label>
                  <div className="mt-2 mb-2">
                    <input
                      value={driverName}
                      type="text"
                      name="txtDriverName"
                      id="txtDriverName"
                      placeholder="Please input your First Name"
                      className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                        driverNameIsError ? "red-600" : "gray-300"
                      } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                      onChange={(e: ChangeEvent<HTMLInputElement>)=>setDriverName(e.target.value)}
                    />
                  </div>
                  {/** Drivers Middle Name  */}
                  <label
                    htmlFor="txtDriverMiddleName"
                    className="block text-sm font-medium leading-6 text-gray-900 md:mt-6 mt-6"
                  >
                    <div className="flex gap-2">
                      <CubeIcon className="-ml-0.5 h-5 w-5" />
                      <span>Middle Name</span>
                    </div>
                  </label>
                  <div className="mt-2">
                    <input
                      value={driverMiddleName}
                      type="text"
                      name="txtDriverMiddleName"
                      id="txtDriverMiddleName"
                      placeholder="Please input your Middle Name"
                      className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                        driverMiddleNameIsError ? "red-600" : "gray-300"
                      } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                      onChange={(e: ChangeEvent<HTMLInputElement>)=>setDriverMiddleName(e.target.value)}
                    />
                  </div>
                  {/** Drivers Last Name  */}
                  <label
                    htmlFor="txtDriverLastName"
                    className="block text-sm font-medium leading-6 text-gray-900 md:mt-6 mt-6"
                  >
                    <div className="flex gap-2">
                      <CubeIcon className="-ml-0.5 h-5 w-5" />
                      <span>Last Name</span>
                    </div>
                  </label>
                  <div className="mt-2">
                    <input
                      value={driverLastName}
                      type="text"
                      name="txtDriverLastName"
                      id="txtDriverLastName"
                      placeholder="Please input your Last Name"
                      className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                        driverLastNameIsError ? "red-600" : "gray-300"
                      } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                      onChange={(e: ChangeEvent<HTMLInputElement>)=>setDriverLastName(e.target.value)}
                    />
                  </div>
                  <div></div>
                </div>
                {/**Another Card */}
                <div className="col-span-1 rounded-lg border bg-white shadow-sm px-4 py-6">
                <label
                    htmlFor="txtDriverName"
                    className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                  >
                    <div className="flex gap-2">
                      <CubeIcon className="-ml-0.5 h-5 w-5" />
                      <span>Driver License Number</span>
                    </div>
                  </label>
                  <div className="mt-2 mb-2">
                    <input
                      value={driverLicenseNumber}
                      type="text"
                      name="txtDriverName"
                      id="txtDriverName"
                      placeholder="Please input your First Name"
                      className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                        driverLicenseNumberIsError ? "red-600" : "gray-300"
                      } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                      onChange={(e: ChangeEvent<HTMLInputElement>)=>setDriverLicenseNumber(e.target.value)}
                    />
                  </div>
                  <label
                    htmlFor="txtLicenseUrl"
                    className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                  >
                    <div className="flex gap-2">
                      <CubeIcon className="-ml-0.5 h-5 w-5" />
                      <span>Upload Driver License</span>
                    </div>
                  </label>
                  <div className="mt-2 mb-2">
                    <div className="flex flex-col">
                      <div className="mt-8">
                        <div
                          className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5${
                            isDraggingOver ? "bg-gray-400" : ""
                          }`}
                          onDrop={handleDrop}
                          onDragOver={handleDragOver}
                          onDragLeave={handleDragLeave}
                        >
                          <CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
                          <h3 className="text-sm">Drag and Drop</h3>
                          <p className="text-xs">
                            your images here or{" "}
                            <button
                              className="text-blue-400"
                              onClick={importImageHandler}
                            >
                              upload image...
                            </button>
                          </p>
                          {selectedDriverLicenseFile.length === 0 ? (
                            <span className="text-gray-400 md:text-xs text-xxs mt-5">
                              -- No file selected --
                            </span>
                          ) : (
                            <div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5 ">
                              {selectedDriverLicenseFile.map((file, index) => (
                                <div key={index}>
                                  <div
                                    className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
                                    onClick={() =>
                                      showImageImportHandler(index)
                                    }
                                  >
                                    <PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
                                    <span className="text-xs text-gray-700 truncate ... w-16">
                                      {file.name}
                                    </span>
                                    <button
                                      className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        removeChipHandler(index);
                                      }}
                                    >
                                      <XCircleIcon className="h-3 w-3" />
                                    </button>
                                  </div>
                                </div>
                              ))}
                            </div>
                          )}
                          <div className="my-1">
                            <input
                              type="file"
                              ref={fileInputRef}
                              style={{ display: "none" }}
                              accept=".jpg, .jpeg, .png"
                              multiple
                              onChange={handleFileChange}
                            />
                          </div>
                          {selectedDriverLicenseFile.length !== 0 && (
                            <ButtonComponent
                              text={`${
                                isButtonDisabled
                                  ? uploadSuccess
                                    ? "Uploaded"
                                    : "Uploading ..."
                                  : "Upload"
                              }`}
                              icon={
                                isLoadingAPI && (
                                  <Lottie
                                    className="md:w-5 w-5 h-auto"
                                    animationData={CircularLoading}
                                    loop={true}
                                  />
                                )
                              }
                              disabled={isButtonDisabled}
                              utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
                            hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full
                            ${
                              isAddingLicense
                                ? "cursor-not-allowed opacity-50"
                                : ""
                            } 
                           `}
                              onClick={uploadDriverLicenseHandler}
                            />
                          )}
                          <span className="text-xs text-gray-500 mt-5">
                            Max size: 5MB
                          </span>{" "}
                          {error && (
                            <span className="text-xs text-red-500 mt-2">
                              {error}
                            </span>
                          )}
                          {uploadSuccess && (
                            <span className="text-xs text-green-500 mt-2">
                              {uploadSuccess}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ModalComponent
              isXL={true}
              isOpen={isModalImage}
              onCloseHandler={() => {}}
              header={
                <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
                  <ArrowUpTrayIcon
                    className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
                    aria-hidden="true"
                  />
                  <span className="text-white inline md:text-base text-xs">
                    Import Image
                  </span>
                </div>
              }
              body={
                <>
                  {selectedImage && (
                    <div className="flex justify-center py-5 mx-7 my-4">
                      <img
                        src={selectedImage}
                        alt="Selected"
                        className="max-w-full h-96 relative"
                      />
                      <button
                        onClick={closeSelectedImage}
                        className="absolute top-16 right-2 px-3 py-2 text-sm bg-dealogikal-200 text-white rounded-lg cursor-pointer"
                      >
                        Close
                      </button>
                    </div>
                  )}
                </>
              }
            />
          </>
        }
      />
    </>
  );
};

export default AddDriverComp;
