// DC: add all our page components here ...
import Landing from "../pages/landing/Landing";
import Login from "../pages/login/Login";

// DC: declare our routes here...
const Routes = [
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <Landing />,
  },
];

export default Routes;
