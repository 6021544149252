import { useState, useEffect, ChangeEvent } from "react";
import {
  generateKey,
  getTokenFromLocalStorage,
  postFetch,
} from "../../../../redux/functions/function";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import BreadcrumbComponent from "../../../templates/BreadcrumbComponent/BreadcrumbComp";
import StepsComponent from "../../../templates/StepsComponent/StepsComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import RemarksComp from "../../../templates/RemarkComponent/RemarksComp";
import NearbyPartyDetailsComp from "../../../templates/NearbyPartyDetailsComponent/NearbyPartyDetailsComp";
import TransitionComponent from "../../../templates/TransitionComponent/TransitionComponent";
import ModalComp from "../../../templates/ModalComponent/ModalComponent";
import suppliersNearbyData from "./NearbySuppliersData";
import StepType from "../../../../model/types/StepType";
import { manageSteps } from "../../../../redux/functions/function";

import {
  TruckIcon,
  MapPinIcon,
  CalendarIcon,
  ArrowLeftIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getBuyCommodityDetails,
  getUtilities,
  setBuyerShippingDetails,
  setShippingMethods,
} from "../../../../redux/functions/storageSlice";
import ComboboxComponent from "../../../templates/ComboboxComponent/ComboboxComponent";
import { getShippingMethods } from "../../../../redux/functions/API";
import ShippingMethodModel from "../../../../model/interface/ShippingMethodModel";
import BuyerShippingDetailsType from "../../../../model/types/BuyerShippingDetailsType";
import BuyerSelectedItemsType from "../../../../model/types/BuyerSelectedItemsType";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";

const ShippingDetailsComp: React.FC = () => {
  // declare navigation
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  const buyCommodityDetails = useAppSelector(getBuyCommodityDetails);
  const selectedProducts = buyCommodityDetails.selectedProducts;
  const shippingDetails = buyCommodityDetails.shippingDetails;

  const [shippingMethod, setShippingMethod] = useState(
    shippingDetails.shipping_method
  );
  const [deliveryLocation, setDeliveryLocation] = useState(
    shippingDetails.delivery_location
  );
  const [completeAddress, setCompleteAddress] = useState(
    shippingDetails.delivery_location_details
  );
  const [deliveryDate, setDeliveryDate] = useState<string>(
    shippingDetails.delivery_date
  );
  const [listOfShippingMethods, setListOfShippingMethods] = useState<
    ShippingMethodModel[]
  >([]);
  const [isShippingMethodEmpty, setIsShippingMethodEmpty] =
    useState<boolean>(false);
  const [isDeliveryLocationEmpty, setIsDeliveryLocationEmpty] =
    useState<boolean>(false);
  const [isCompleteAddressEmpty, setIsCompleteAddressEmpty] =
    useState<boolean>(false);
  const [isDeliveryDateEmpty, setIsDeliveryDateEmpty] =
    useState<boolean>(false);
  const [isDeliveryDateInvalid, setIsDeliveryDateInvalid] =
    useState<boolean>(false);

  const [isSuppliersFound, setisSuppliersFound] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState<string>("All");
  const [isShowTransitionPage, setIsShowTransitionPage] = useState(false);

  // get token utilities
  const _token = getTokenFromLocalStorage() ?? "";

  // cja: this hook will be triggered to fetch the shipping methods from API
  useEffect(() => {
    //  if redux is empty, then call api and store to redux
    if (utilities.shippingMethods.length === 0) {
      getShippingMethods(_token).then((data: any) => {
        dispatch(setShippingMethods(data));
      });
    } else {
      // if not empty, get from redux and store in the local state
      let updatedShippingMethods: ShippingMethodModel[] = [];
      utilities.shippingMethods.length !== 0 &&
        utilities.shippingMethods.forEach((shipping_method: any) => {
          updatedShippingMethods = [...updatedShippingMethods, shipping_method];
        });
      setListOfShippingMethods(updatedShippingMethods);
    }
  }, [utilities.shippingMethods, dispatch, _token]);

  // cja: get the steps saved in session, to be used in updating the steps component
  const [stepsFromSession, setStepsFromSession] = useState<StepType[]>([]);
  useEffect(() => {
    const strSteps = sessionStorage.getItem("steps");
    strSteps != null && setStepsFromSession(JSON.parse(strSteps));
  }, []);

  // cja: update the step component
  const [updatedSteps, setUpdatedSteps] = useState<StepType[]>([]);
  useEffect(() => {
    if (stepsFromSession.length !== 0) {
      manageSteps(
        {
          name: "Shipping Details",
          href: "/shipping_details",
          status: "current",
        },
        stepsFromSession
      ).then((updated_steps: any) => {
        setUpdatedSteps(updated_steps);
      });
    }
  }, [stepsFromSession]);

  const foundSupplierHandler = () => {
    setIsLoading(false);
    setisSuppliersFound(true);
  };

  const findingSupplier = () => {
    setIsLoading(true);
    setisSuppliersFound(false);
    setTimeout(() => {
      foundSupplierHandler();
    }, 3000);
  };

  const proceedToQuotationHandler = () => {
    // clear all error states
    resetErrorStates();
    // check empty fields
    if (
      shippingMethod.id === 0 ||
      deliveryLocation.id === 0 ||
      completeAddress === "" ||
      deliveryDate === ""
    ) {
      // set invalid fields
      setIsShippingMethodEmpty(shippingMethod.id === 0 ? true : false);
      setIsDeliveryLocationEmpty(deliveryLocation.id === 0 ? true : false);
      setIsCompleteAddressEmpty(completeAddress === "" ? true : false);
      setIsDeliveryDateEmpty(deliveryDate === "" ? true : false);
    } else {
      // check if date is valid
      const currentDate = new Date();
      if (new Date(deliveryDate) < currentDate) {
        setIsDeliveryDateInvalid(true);
      } else {
        // construct the data to be pass to the API call (add_qoutation)
        let selectedCommodities: any = [];
        selectedProducts.forEach((product: BuyerSelectedItemsType) => {
          selectedCommodities = [
            ...selectedCommodities,
            {
              commodity_id: product.product.id,
              unit_id: product.unit.id,
              volume: product.volume,
            },
          ];
        });

        // get and decode the token from local storage
        const _token = getTokenFromLocalStorage() ?? "";
        const userData: TokenModel = jwt_decode(_token);
        const buyer_id: string = userData._id.toString();

        let newQuotation = {
          commodities: selectedCommodities,
          shipping_method_id: shippingMethod.id,
          delivery_location_id: deliveryLocation.id,
          complete_address: completeAddress,
          delivery_date: deliveryDate,
          buyer_id: buyer_id,
        };

        // save to redux
        setIsShowTransitionPage(true);
        saveShippingDetails().then(() => {
          postFetch(
            `${process.env.REACT_APP_API_URL}/quotation/add`,
            newQuotation,
            _token
          ).then((response) => {
            if (response.statusCode === 200) {
              setTimeout(() => {
                setIsShowTransitionPage(false);
                navigate(`/quotation/${response.data.items[0].quotation_id}`);
              }, 3000);
            }
          });
        });
      }
    }
  };

  const resetErrorStates = () => {
    setIsShippingMethodEmpty(false);
    setIsDeliveryLocationEmpty(false);
    setIsDeliveryDateEmpty(false);
    setIsDeliveryDateInvalid(false);
  };

  const saveShippingDetails = () => {
    return new Promise((resolve, reject) => {
      let updatedShippingDetails: BuyerShippingDetailsType = {
        shipping_method: shippingMethod,
        delivery_location: deliveryLocation,
        delivery_location_details: completeAddress,
        delivery_date: deliveryDate,
      };

      dispatch(setBuyerShippingDetails(updatedShippingDetails));
      resolve(true);
    });
  };

  return (
    <>
      {isShowTransitionPage === true ? (
        <TransitionComponent
          key={Math.random()}
          transition_text="Proceeding to Quotation..."
        />
      ) : (
        <>
          <MainConsoleLayoutComponent
            content={
              <>
                {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                <BreadcrumbContainerComponent
                  key={generateKey(Math.random().toString())}
                  breadcrumb={<BreadcrumbComponent />}
                  subtitle="Shipping Details"
                  steps={<StepsComponent steps={updatedSteps} />}
                />
                {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
                <div className="flex flex-wrap justify-center mt-2">
                  {/* single card (start) */}
                  <div className="flex flex-col w-full md:pt-10 md:pl-8 md:w-2/5">
                    <RemarksComp
                      customClasses="md:hidden block mt-6"
                      main_message="8 suppliers nearby."
                      isLoading={isLoading}
                      isFound={isSuppliersFound}
                      isViewDetailsClicked={() => setIsModalOpen(true)}
                    />

                    <label
                      htmlFor="txtshippingmethod"
                      className="block text-sm font-medium leading-6 text-gray-900 md:mt-0 mt-6"
                    >
                      <div className="flex gap-2">
                        <TruckIcon className="-ml-0.5 h-5 w-5" />
                        <span>Shipping Method</span>
                      </div>
                    </label>
                    <div className="mt-2">
                      <ComboboxComponent
                        hasError={isShippingMethodEmpty}
                        items={listOfShippingMethods}
                        placeholder="Select shipping method"
                        selectedItem={shippingMethod}
                        setSelectedItem={setShippingMethod}
                      />
                    </div>
                    {isShippingMethodEmpty && (
                      <div className="ml-6 flex mt-3">
                        <p className="text-red-600 text-xs md:text-sm">
                          Shipping method field is required
                        </p>
                      </div>
                    )}

                    <label
                      htmlFor="txtlocation"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                    >
                      <div className="flex gap-2">
                        <MapPinIcon className="-ml-0.5 h-5 w-5" />
                        <span>Set Delivery Location</span>
                      </div>
                    </label>
                    <div className="mt-2">
                      <ComboboxComponent
                        hasError={isDeliveryLocationEmpty}
                        items={[]}
                        placeholder="Select Delivery Location"
                        selectedItem={deliveryLocation}
                        setSelectedItem={setDeliveryLocation}
                        onBlur={findingSupplier}
                      />
                    </div>
                    {isDeliveryLocationEmpty && (
                      <div className="ml-6 flex mt-3">
                        <p className="text-red-600 text-xs md:text-sm">
                          Delivery location field is required
                        </p>
                      </div>
                    )}
                    <label
                      htmlFor="txtlocation"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                    >
                      <div className="flex gap-2">
                        <MapPinIcon className="-ml-0.5 h-5 w-5" />
                        <span>Complete Address</span>
                      </div>
                    </label>
                    <div className="mt-2">
                      <input
                        value={completeAddress}
                        type="text"
                        name="txtlocation"
                        id="txtlocation"
                        placeholder="Set complete delivery location"
                        className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                          isCompleteAddressEmpty ? "red-600" : "gray-300"
                        } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setCompleteAddress(e.target.value)
                        }
                      />
                    </div>
                    {isCompleteAddressEmpty && (
                      <div className="ml-6 flex mt-3">
                        <p className="text-red-600 text-xs md:text-sm">
                          Complete address field is required
                        </p>
                      </div>
                    )}

                    <label
                      htmlFor="txtdate"
                      className="block text-sm font-medium leading-6 text-gray-900 mt-6"
                    >
                      <div className="flex gap-2">
                        <CalendarIcon className="-ml-0.5 h-5 w-5" />
                        <span>Set Delivery Date</span>
                      </div>
                    </label>
                    <div className="mt-2">
                      <input
                        type="date"
                        value={deliveryDate}
                        name="txtdate"
                        id="txtdate"
                        className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
                          isDeliveryDateEmpty || isDeliveryDateInvalid
                            ? "red-600"
                            : "gray-300"
                        } placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          setDeliveryDate(e.target.value)
                        }
                      />
                    </div>
                    {isDeliveryDateEmpty && (
                      <div className="ml-6 flex mt-3">
                        <p className="text-red-600 text-xs md:text-sm">
                          Delivery date field is required
                        </p>
                      </div>
                    )}
                    {isDeliveryDateInvalid && (
                      <div className="ml-6 flex mt-3">
                        <p className="text-red-600 text-xs md:text-sm">
                          Please make sure that delivery date is ahead of the
                          current date.
                        </p>
                      </div>
                    )}

                    <ButtonComponent
                      text="PROCEED TO QUOTATION"
                      utils="bg-dealogikal-100 text-white text-sm shadow-sm py-4 sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal w-full mt-10"
                      onClick={proceedToQuotationHandler}
                    />
                  </div>
                  <div className="flex flex-col w-full md:pt-10 md:pl-8 md:w-3/5">
                    <RemarksComp
                      customClasses="md:block hidden"
                      main_message="8 suppliers nearby."
                      isLoading={isLoading}
                      isFound={isSuppliersFound}
                      isViewDetailsClicked={() => setIsModalOpen(true)}
                    />
                  </div>
                  {/* single card (end) */}
                </div>

                <ModalComp
                  isOpen={isModalOpen}
                  onCloseHandler={() => setIsModalOpen(false)}
                  header={
                    <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-2 px-4 text-center">
                      <ArrowLeftIcon
                        className="-ml-0.5 md:h-5 md:w-5 h-4 w-4 text-white inline float-left"
                        onClick={() => setIsModalOpen(false)}
                      />
                      <span className="text-white inline md:text-base text-sm">
                        Suppliers Nearby
                      </span>
                    </div>
                  }
                  body={
                    <div className="md:p-4">
                      <div className="flex flex-wrap justify-center">
                        {/* single card (start) */}
                        <div className="flex flex-col w-full md:py-2 md:px-4 md:w-full">
                          <div>
                            <div className="relative rounded-md shadow-sm md:py-0 py-2 md:px-0 px-2">
                              <input
                                type="text"
                                name="account-number"
                                id="account-number"
                                className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6"
                                placeholder="Search suppliers"
                              />
                              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
                                <MagnifyingGlassIcon
                                  className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-col w-full md:py-2 md:px-4 md:w-full">
                          <div className="table w-full px-2 pt-3 pb-6 border-b-2 border-gray-100">
                            <div className="table-row-group">
                              <div className="table-row">
                                <div className="table-cell align-middle w-1/2 md:px-0 px-2">
                                  <span
                                    className={`${
                                      activeTab === "All"
                                        ? "border-b-2 border-dealogikal-100 text-dealogikal-100"
                                        : "text-gray-300"
                                    } py-1 cursor-pointer md:text-sm text-xs duration-50`}
                                    onClick={() => setActiveTab("All")}
                                  >
                                    All
                                  </span>
                                  <span
                                    className={`${
                                      activeTab === "Rating"
                                        ? "border-b-2 border-dealogikal-100 text-dealogikal-100"
                                        : "text-gray-300"
                                    } mx-3 py-1 cursor-pointer md:text-sm text-xs duration-50`}
                                    onClick={() => setActiveTab("Rating")}
                                  >
                                    Rating
                                  </span>
                                  <span
                                    className={`${
                                      activeTab === "Nearest"
                                        ? "border-b-2 border-dealogikal-100 text-dealogikal-100"
                                        : "text-gray-300"
                                    } py-1 cursor-pointer md:text-sm text-xs duration-50`}
                                    onClick={() => setActiveTab("Nearest")}
                                  >
                                    Nearest
                                  </span>
                                </div>
                                <div className="table-cell align-middle text-right w-1/2 md:px-0 px-2">
                                  <span className="text-dealogikal-100 cursor-pointer md:text-sm text-xs duration-50">
                                    Deselect All
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="mt-4 overflow-y-auto h-96 pb-4">
                            {activeTab === "All" &&
                              suppliersNearbyData.map((supplier) => (
                                <NearbyPartyDetailsComp
                                  details={supplier}
                                  key={supplier.id}
                                />
                              ))}
                          </div>
                        </div>
                        {/* single card (end) */}
                      </div>
                    </div>
                  }
                />
              </>
            }
          />
        </>
      )}
    </>
  );
};

export default ShippingDetailsComp;
