import React from "react";
import InvoiceDetailType from "../../../../model/types/InvoiceDetailType";
import logo from "../dealogikal-app-dark_p4svpl.png"

interface InvoicePDFProps {
  selectedInvoiceListDetails: InvoiceDetailType[];
}

const InvoicePDF: React.FC<InvoicePDFProps> = ({
  selectedInvoiceListDetails,
}) => {

  const total = selectedInvoiceListDetails.reduce((sum, invoice) => {
    // Sum up all line_total for each invoice
    const invoiceTotal = invoice.items.invoiceItems.reduce((invoiceSum, invoiceItem) => {
      return invoiceSum + invoiceItem.line_total;
    }, 0);
  
    // Add the total of the current invoice to the overall sum
    return sum + invoiceTotal;
  }, 0);

  return (
    <>
      {selectedInvoiceListDetails.map((detail) => (
        <div key={detail.items.invoice_number}>
          <div className="w-full bg-gray-50 shadow-md rounded-lg px-20 py-5 h-[90rem]">
            <div className="flex justify-between items-center gap-28 ">
              <img src={logo} alt="logo" className="h-40 w-52" />

              <h1 className="text-dealogikal-100 font-bold text-xl mb-2">
                SALES INVOICE
              </h1>
            </div>
            <div className="flex justify-between">
              <div className="flex flex-col text-gray-600 ml-3 text-base">
                <span>Dealogikal Corp</span>
                <span>Kiener Energy Centre Bldg. Kiener Hills, Matumbo</span>
                <span>Road, Pusok, Lapulapu City, Cebu</span>
                <span>(032) 411-0303</span>
                <span>support@dealogikal.com</span>
                <span>https://www.dealogikal.com</span>
              </div>

              <div className="flex flex-col text-end text-sm gap-2">
                <div className="text-center">
                  <div className="text-white flex pb-2 pt-1 bg-dealogikal-200 justify-center text-center w-80 h-auto">
                    <span>Date</span>
                  </div>
                  <span className=" text-gray-500 my-2">
                    {new Date(detail.items.invoice_date).toLocaleString(
                      "en-US",
                      {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                        second: "2-digit",
                        hour12: true,
                      }
                    )}
                  </span>
                </div>

                <div className="text-center">
                  <div className="text-white flex pb-2 pt-1 bg-dealogikal-200 justify-center text-center w-80 h-auto">
                    <p> Invoice #</p>
                  </div>
                  <span className=" text-gray-500 my-2">
                    {detail.items.invoice_number}
                  </span>
                </div>

                <div className="text-center">
                  <div className="text-white flex pb-2 pt-1 bg-dealogikal-200 justify-center text-center w-80 h-auto">
                    <p>Tax Identification Number</p>
                  </div>
                  <span className=" text-gray-500 my-2">010-259-943-000</span>
                </div>
              </div>
            </div>

            <table className="min-w-full divide-y divide-gray-500 border border-gray-500 text-center my-10">
              <thead>
                <tr className="divide-x divide-gray-500">
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-4 text-center text-sm font-semibold text-dealogikal-200 sm:pl-0 "
                  >
                    Purchase Order #
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-3.5 text-center text-sm font-semibold text-dealogikal-200"
                  >
                    Customer Representive
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-500 bg-white">
                <tr className="divide-x divide-gray-500">
                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                    {detail.items.reference_number}
                  </td>
                  <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-medium text-gray-900 sm:pl-0">
                    {detail.items.sold_to}
                  </td>
                </tr>
              </tbody>
            </table>

            <div className="overflow-x-auto">
              <table className="min-w-full bg-white border border-gray-500 text-center my-10">
                <thead className="bg-gray-50">
                  <tr className="divide-x divide-gray-500">
                    <th
                      scope="col"
                      className="py-2 px-4 border-b border-gray-800 font-bold"
                      style={{ width: "258.21px", height: "39.96px" }}
                    >
                      Item
                    </th>
                    <th
                      scope="col"
                      className="py-2 px-4 border-b border-gray-800 font-bold"
                      style={{ width: "258.21px", height: "39.96px" }}
                    >
                      Quantity
                    </th>
                    <th
                      scope="col"
                      className="py-2 px-4 border-b border-gray-800 font-bold"
                      style={{ width: "258.21px", height: "39.96px" }}
                    >
                      Unit Price
                    </th>
                    <th
                      scope="col"
                      className="py-2 px-4 border-b border-gray-800 font-bold"
                      style={{ width: "258.21px", height: "39.96px" }}
                    >
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-500">
                  {detail.items.invoiceItems.map((item) => (
                    <tr
                      key={item.invoice_item_id}
                      className="divide-x divide-gray-500"
                    >
                      <td
                        className="py-2 px-4"
                        style={{ width: "258.21px", height: "39.96px" }}
                      >
                        {item.item_description || "N/A"}
                      </td>
                      <td
                        className="py-2 px-4"
                        style={{ width: "258.21px", height: "39.96px" }}
                      >
                        {item.quantity ? item.quantity.toLocaleString() : "N/A"}{" "}
                        Liters
                      </td>
                      <td
                        className="py-2 px-4"
                        style={{ width: "258.21px", height: "39.96px" }}
                      >
                        {item.unit_price
                          ? `PHP ${item.unit_price.toFixed(4)}`
                          : "N/A"}
                      </td>
                      <td
                        className="py-2 px-4"
                        style={{ width: "258.21px", height: "39.96px" }}
                      >
                        {item.line_total
                          ? `PHP ${item.line_total.toFixed(4)}`
                          : "N/A"}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              <div className="flex bg-white  border-t border-b border-x border-gray-500 divide-x divide-gray-500">
                <div className="py-2 px-4 divide-x font-bold w-32 text-dealogikal-theme-1 border-l whitespace-nowrap">
                  Ship Via
                </div>
                <div className="py-2 px-4 flex-grow text-center">Deliver</div>
                <div className="py-2 px-4 font-bold divide-x text-center text-dealogikal-theme-1 w-32">
                  Ship Date
                </div>
                <div className="py-2 px-4 border-r flex-grow text-center">
                  07/14/2024
                </div>
              </div>

              <div className="grid grid-cols-1 bg-white border-x border-gray-500">
                <div className="flex border-b border-gray-500 divide-x divide-gray-500">
                  <div className="py-2 px-4 w-32 text-dealogikal-theme-1 font-bold">
                    Address
                  </div>
                  <div className="py-2 px-4 col-span-5">
                    {detail.items.company_address}
                  </div>
                </div>

                <div className="flex border-b border-gray-500 divide-x divide-gray-500">
                  <div className="py-2 px-4 w-32 text-dealogikal-theme-1 font-bold">
                    Notes
                  </div>
                  <div className="py-2 px-4 col-span-5 border-r">
                   {detail.items.notes}
                  </div>
                </div>
              </div>
            </div>

            <div className="flex  content-center items-center justify-between mt-8 gap-20">
              <p className="text-gray-500">Thank you for trusting Dealogikal</p>
              <div className="max-w-2xl">
                <table className="w-full border-collapse border border-gray-500">
                  <tbody>
                    {[
                      {
                        label: "Total Sales (VAT Inc.)",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        }).format(total)}`,
                      },
                      {
                        label: `Less: ${detail.items.vat_rate}% VAT`,
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 3,
                          maximumFractionDigits: 3,
                        }).format(total-(total / (1.0+(detail.items.vat_rate/100))))}`,
                      },
                      {
                        label: "Net of VAT/TOTAL",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total / (1.0+(detail.items.vat_rate/100)))}`,
                      },
                      {
                        label: "Less: SC/PWD Disc",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(0.0)}`,
                      },
                      {
                        label: "Total Due",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}`,
                      }, //{ label: "Add VAT", value: "PHP 24,593.7686" },
                      {
                        label: "Less: WithHolding",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(detail.items.withholding)}`,
                      }, //{ label: "Total Amount Due", value: "PHP 229,541.8400" },
                      {
                        label: "Total Amount Due",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}`,
                      }, //{ label: "VATable Sales", value: "PHP 204,948.0714" },
                      {
                        label: "VATable (V)",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total / (1.0+(detail.items.vat_rate/100)))}`,
                      }, //{ label: "VAT-Exempt Sales", value: "PHP 0.0000" },
                      {
                        label: "VAT-Exempt (E)",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(detail.items.vat_exempt)}`,
                      }, //{ label: "Zero-Rated Sales", value: "PHP 0.0000" },
                      {
                        label: "Zero-Rated (Z)",
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(detail.items.zero_rated)}`,
                      }, //{ label: "VAT Amount", value: "PHP 24,593.7686" },
                      {
                        label: `VAT (${detail.items.vat_rate}%)`,
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total-(total / (1.0+(detail.items.vat_rate/100))))}`,
                      },
                      {
                        label: `TOTAL`,
                        value: `${Intl.NumberFormat("en-PH", {
                          style: "currency",
                          currency: "PHP",
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        }).format(total)}`,
                      },
                    ].map((item, index) => (
                      <tr
                        key={index}
                        className="border-b divide-x divide-gray-500 border-gray-500"
                      >
                        <td className="py-2 px-4 text-left text-blue-600 font-medium">
                          {item.label}
                        </td>
                        <td className="py-2 px-4 text-right">{item.value}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div className="w-full bg-gray-200 text-center py-2 items-center text-gray-400 mt-5">
              <p>
                For questions concerning this invoice, please feel free to
                contact us.
              </p>
            </div>

            <div className="w-auto text-center">
              <p className="text-gray-300 mt-10 mx-auto w-96">
                Copyright @ 2021 Dealogikal. All rights reserved. Confidential.
                Terms & Conditions may apply
              </p>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default InvoicePDF;
