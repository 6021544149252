import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";

interface AuthContextProps {
  children: ReactNode;
}

interface AuthContextValue {
  userRole: number | null;
  setUserRoleContext: Dispatch<SetStateAction<number | null>>;
}

const AuthContext = createContext<AuthContextValue | undefined>(undefined);

export const AuthProvider: React.FC<AuthContextProps> = ({ children }) => {
  const [userRole, setUserRole] = useState<number | null>(() => {
    // Initialize userRole from localStorage or use null if it doesn't exist
    const storedUserRole = localStorage.getItem("userRole");
    return storedUserRole ? parseInt(storedUserRole, 10) : null;
  });

  const setUserRoleContext: AuthContextValue["setUserRoleContext"] = (role) => {
    setUserRole(role);
  };

  useEffect(() => {
    // Update localStorage whenever userRole changes
    localStorage.setItem("userRole", userRole?.toString() || "");
  }, [userRole]);

  const contextValue: AuthContextValue = {
    userRole,
    setUserRoleContext,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useUser = (): AuthContextValue => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("authContext must be used within a AuthProvider");
  }
  return context;
};
