// cja: enhancement soon
// - this calendar component must accept month property
// - has next onclick event trigger
// - re-calculate the dates of the month below

import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import EnabledDatesModel from "../../../model/interface/EnabledDatesModel";
import {
	generateKey,
	getTotalNumberOfDaysInMonth,
} from "../../../redux/functions/function";
import { useEffect, useState } from "react";

interface CalendarComponentModel {
	dateOnClickHandler: (
		month: number,
		date: number,
		year: number,
		enabled: boolean
	) => void;
	selectedDate: { month: number; day: number; year: number };
	datesEnabled?: EnabledDatesModel[]; // dates that are enabled starting from the current date
}

const CalendarComponent: React.FC<CalendarComponentModel> = (props) => {
	/**
	 *  janm: use this code below on testing in the last days for choosing next days in month
	 * const currentDate: Date = new Date(2024, 4, 29);
	 */

	const currentDate: Date = new Date();
	// const currentDate: Date = new Date(2024, 4, 29);
	// derive date breakdown from currentDate state
	const dayOfMonth: number = currentDate.getDate(); // 0 based values
	const [currentMonth, setCurrentMonth] = useState<number>(
		currentDate.getMonth()
	); // 0 based values

	const [currentYear, setCurrentYear] = useState<number>(
		currentDate.getFullYear()
	); // 0 based values

	// Calculate the number of days in the current month
	const [daysInMonth, setDaysInMonth] = useState<number>(0);
	const [activeMonth, setActiveMonth] = useState<number>(currentMonth);

	useEffect(() => {
		// janmm: add plus 1 for currentMonth to match the daysInMonth and currentMonth
		setDaysInMonth(getTotalNumberOfDaysInMonth(currentMonth + 1, currentYear));
	}, [currentMonth, currentYear]);

	// Calculate the first day of the month
	const firstDay = new Date(currentYear, currentMonth, 1).getDay();

	const weeks = [];
	let daysCounter = 1;

	// let's populate the weeks
	const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

	weeks.push(
		<div
			key={Math.random()}
			className="grid grid-cols-7 gap-2 px-0.5 py-2 border bg-gray-100 text-black"
		>
			{days.map((day: string, i) => (
				<div key={i} className="text-center font-medium p-2">
					{day}
				</div>
			))}
		</div>
	);

	// create an array of weeks with empty slots for the days before the first day of the month
	for (let singleWeek = 0; singleWeek < 6; singleWeek++) {
		const week = [];

		for (let singleDay = 0; singleDay < 7; singleDay++) {
			if (singleWeek === 0 && singleDay < firstDay) {
				week.push(
					<div
						key={generateKey(Math.random().toString() + singleDay.toString())}
						className="text-center p-2"
					></div>
				);
			} else if (daysCounter <= daysInMonth) {
				const day = daysCounter; // get day
				let isEnabled = false;
				// cja: loop the enabled dates from the props and identify the enabled dates through this loop block
				props.datesEnabled?.forEach((date: EnabledDatesModel) => {
					if (
						date.month === currentMonth + 1 &&
						date.date === day &&
						date.year === currentYear
					) {
						isEnabled = true;
					}
				});

				let dateDivStyleFormat = "";
				if (isEnabled) {
					dateDivStyleFormat = `bg-white ${
						props.selectedDate.day === daysCounter
							? "border-dealogikal-100"
							: "border-gray-300"
					}  text-black font-normal cursor-pointer`;
				} else {
					// janm: add another condition to check the currentMonth if user click the nextmonth it will remove the back to gray
					if (day === dayOfMonth && currentMonth === activeMonth) {
						dateDivStyleFormat = "bg-dealogikal-50 text-white cursor-default";
					} else {
						dateDivStyleFormat = "bg-gray-100 text-gray-300 cursor-default";
					}
				}

				const selectionBehavour =
					props.selectedDate.day === daysCounter && isEnabled
						? "border-dealogikal-100"
						: "";

				week.push(
					<div
						key={generateKey(Math.random().toString() + daysCounter.toString())}
						className={`text-center p-3 border ${dateDivStyleFormat} ${selectionBehavour}`}
						onClick={
							() =>
								props.dateOnClickHandler(
									currentMonth + 1,
									day,
									currentYear,
									isEnabled!
								) // we must add 1, date is 0 base
						}
					>
						{day}
					</div>
				);
				daysCounter++;
			}
		}

		weeks.push(
			<div key={singleWeek} className="grid grid-cols-7 gap-2 mt-2">
				{week}
			</div>
		);
	}

	// month manipulation blocks
	const nextMonthClickHandler = (year: number, month: number) => {
		// Adjust the year if the month is 12 (December)
		if (month === 11) {
			setActiveMonth(0);
			setCurrentMonth(0); // Reset the month to January
			let newYear = year + 1; // Increment the year
			setCurrentYear(newYear);
		} else {
			// Increment the month by 1
			let newMonth = month + 1;
			setCurrentMonth(newMonth);
		}
	};

	const previousMonthClickHandler = (year: number, month: number) => {
		// Adjust the year if the month is 1 (January)
		if (month === 0) {
			setActiveMonth(11);
			setCurrentMonth(11); // Set the month to December
			let newYear = year - 1; // Decrement the year
			setCurrentYear(newYear);
		} else {
			// Decrement the month by 1
			let newMonth = month - 1;
			setCurrentMonth(newMonth);
		}
	};

	const renderCurrentMonthAndYear = () => {
		const dateString = `${currentYear}-${
			currentMonth === -1 ? 12 : currentMonth + 1
		}-${1}`;
		const convertedDate = new Date(dateString.toString());
		return `${convertedDate.toLocaleString("default", {
			month: "long",
		})} ${currentYear}`;
	};

	return (
		<>
			<div className="mx-2 p-4 border rounded-lg">
				<div className="flex justify-center my-2 mb-6">
					{/* cja: PREVIOUS month event handler (start) */}
					<div className="flex flex-col w-full md:w-1/5">
						<h2
							className="text-center text-sm cursor-pointer hover:text-dealogikal-100"
							onClick={() =>
								previousMonthClickHandler(currentYear, currentMonth)
							}
						>
							<ChevronLeftIcon
								className="-md:ml-0.5 sm:h-5 sm:w-5 h-4 w-4 text-gray-700 inline mx-4"
								aria-hidden="true"
							/>
						</h2>
					</div>
					{/* cja: PREVIOUS month event handler (end) */}
					<div className="flex flex-col w-full md:w-3/5">
						<h2 className="text-center text-base text-gray-700">
							{renderCurrentMonthAndYear()}
						</h2>
					</div>
					{/* cja: NEXT month event handler (start) */}
					<div className="flex flex-col w-full md:w-1/5">
						<h2
							className="text-center text-sm cursor-pointer hover:text-dealogikal-100"
							onClick={() => nextMonthClickHandler(currentYear, currentMonth)}
						>
							<ChevronRightIcon
								className="-md:ml-0.5 sm:h-5 sm:w-5 h-4 w-4 text-gray-700 inline mx-4"
								aria-hidden="true"
							/>
						</h2>
					</div>
					{/* cja: NEXT month event handler (end) */}
				</div>
				{weeks}
			</div>
		</>
	);
};

export default CalendarComponent;
