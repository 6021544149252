import styles from "./LandingComp.module.css";
import PageContentComponent from "../templates/PageContentComponent/PageContentComponent";
import NavbarComponent from "../templates/NavbarComponent/NavbarComponent";
import ButtonComponent from "../templates/ButtonComponent/ButtonComponent";
import Testimonials from "./Testimonials";
import Footer from "./Footer";
import { ReactComponent as ChooseItemIcon } from "../templates/svg/choose-icon.svg";
import { ReactComponent as DeliveryIcon } from "../templates/svg/delivery.svg";
import { ReactComponent as ReceiveIcon } from "../templates/svg/receive.svg";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import topCompaniesData from "./Top_Companies";
import {
  destoryTokenFromLocalStorage,
  getTokenFromLocalStorage,
} from "../../redux/functions/function";
import { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../model/interface/TokenModel";

const LandingComp = () => {
  // declare navigate
  const navigate = useNavigate();

  // DC: get the current token
  const _token = getTokenFromLocalStorage() ?? "";
  const [userData, setUserData] = useState<TokenModel>({
    _id: 0,
    name: "",
    username: "",
    role: 0,
    exp: 0,
    iat: 0,
    company: "",
  });

  // cja: useEffect hook to check & decode if _token exist in local storage.
  useEffect(() => {
    if (_token !== "") {
      setUserData(jwt_decode(_token));
    }
  }, [_token]);

  // observe intersect triggers
  const { ref: mainSectionRef, inView: isMainSectionIntersect } = useInView({
    triggerOnce: true,
  });

  const {
    ref: topCompaniesSectionRef,
    inView: isTopCompaniesSectionIntersect,
  } = useInView({ triggerOnce: true });

  const { ref: useCaseSectionRef, inView: isUseCaseSectionIntersect } =
    useInView({ triggerOnce: true });

  const {
    ref: platformSnippetSectionRef,
    inView: isPlatformSnippetSectionIntersect,
  } = useInView({ triggerOnce: true });

  const {
    ref: sellerBenefitSectionRef,
    inView: isSellerBenefitSectionIntersect,
  } = useInView({ triggerOnce: true });

  const {
    ref: logisticsBenefitSectionRef,
    inView: isLogisticsBenefitSectionIntersect,
  } = useInView({ triggerOnce: true });

  // useStates if the intersect observer status
  const [isMainSectionTriggered, setIsMainSectionTriggered] =
    useState<boolean>(false);
  const [isTopCompaniesSectionTriggered, setIsTopCompaniesSectionTriggered] =
    useState<boolean>(false);
  const [isUseCaseSectionTriggered, setIsUseCaseSectionTriggered] =
    useState<boolean>(false);
  const [
    isPlatformSnippetSectionTriggered,
    setIsPlatformSnippetSectionTriggered,
  ] = useState<boolean>(false);
  const [isSellerBenefitSectionTriggered, setIsSellerBenefitSectionTriggered] =
    useState<boolean>(false);
  const [
    isLogisticsBenefitSectionTriggered,
    setIsLogisticsBenefitSectionTriggered,
  ] = useState<boolean>(false);
  // useEffects serves as callbacks of the intersect observer
  useEffect(() => {
    setIsMainSectionTriggered(isMainSectionIntersect);
  }, [isMainSectionIntersect]);
  useEffect(() => {
    setIsTopCompaniesSectionTriggered(isTopCompaniesSectionIntersect);
  }, [isTopCompaniesSectionIntersect]);
  useEffect(() => {
    setIsUseCaseSectionTriggered(isUseCaseSectionIntersect);
  }, [isUseCaseSectionIntersect]);
  useEffect(() => {
    setIsPlatformSnippetSectionTriggered(isPlatformSnippetSectionIntersect);
  }, [isPlatformSnippetSectionIntersect]);
  useEffect(() => {
    setIsSellerBenefitSectionTriggered(isSellerBenefitSectionIntersect);
  }, [isSellerBenefitSectionIntersect]);
  useEffect(() => {
    setIsLogisticsBenefitSectionTriggered(isLogisticsBenefitSectionIntersect);
  }, [isLogisticsBenefitSectionIntersect]);

  let navItems = [
    { name: "Partners", link: "/" },
    { name: "About us", link: "/" },
  ];

  // cja: button in navigating to Login/Dashboard
  const navigateToLoginClickHandler = () => {
    if (userData.role === 0) {
      navigate("/login");
    } else {
      // identify the last/existing user role logged in
      let dashboardToNavigate = "";
      switch (userData.role) {
        case 1:
          dashboardToNavigate = "b";
          break;
        case 2:
          dashboardToNavigate = "s";
          break;
        case 6:
          dashboardToNavigate = "l";
          break;
        default:
          destoryTokenFromLocalStorage();
          break;
      }

      navigate(`/${dashboardToNavigate}/dashboard`);
    }
  };

  return (
    <>
      <PageContentComponent
        content={{
          contentUtils: "mb-40",
          header: (
            <>
              <NavbarComponent
                brand_logo={
                  <div className="font-bold text-2xl cursor-pointer flex items-center text-gray-800">
                    <img
                      src="https://res.cloudinary.com/doehyebmw/image/upload/v1681709063/dealogikal_2.0/resources/images/logo/app-logo-dark_wpiwgj.png"
                      className="h-9 sm:mx-0 ml-3"
                      alt="Dealogikal Logo"
                    />
                  </div>
                }
                nav_items={navItems}
                right_buttons={
                  <ButtonComponent
                    text={`${_token ? "Dashboard" : "Login"}`}
                    utils="bg-dealogikal-100 text-white shadow-lg sm:py-4 py-3 px-12 rounded md:ml-8 sm:hover:bg-dealogikal-200 duration-500 rounded-full sm:font-semibold"
                    onClick={navigateToLoginClickHandler}
                  />
                }
              />
            </>
          ),
          content: (
            <>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div className="flex flex-wrap justify-center md:px-44 md:mt-56 mt-32 md:mb-0 mb-36">
                <div className="flex flex-col w-full md:w-1/2 md:pt-12 px-4">
                  <span
                    className={`sm:text-5xl text-3xl font-black ${
                      !isMainSectionTriggered ? styles.opacity_0 : ""
                    } ${isMainSectionIntersect ? styles.bottom2top_3s : ""}`}
                  >
                    Your Complete
                  </span>
                  <span
                    className={`sm:text-5xl text-3xl font-black mt-3 ${
                      !isMainSectionTriggered ? styles.opacity_0 : ""
                    } ${isMainSectionIntersect ? styles.bottom2top_3s : ""}`}
                  >
                    Supply Chain Solution.
                  </span>
                  <br />
                  {/* cja: lineUp class is for animating the text insisde */}
                  <span
                    className={`${
                      !isMainSectionTriggered ? styles.opacity_0 : ""
                    } ${isMainSectionIntersect ? styles.bottom2top_4s : ""}`}
                    ref={mainSectionRef}
                  >
                    <span className={`sm:text-2xl text-lg  font-medium mt-2`}>
                      Procurement. Logistics. Trade Finance.
                    </span>
                    <br />
                    <span className="sm:text-2xl text-lg  font-medium">
                      All in one place.
                    </span>
                  </span>

                  <ButtonComponent
                    text="Get Started"
                    textUtils="sm:text-xl text-sm"
                    utils={`bg-dealogikal-100 text-white shadow-lg sm:py-6 py-4 sm:w-1/4 w-1/3 sm:hover:bg-dealogikal-200 duration-500 rounded-full sm:font-semibold md:mt-40 mt-5 ${
                      !isMainSectionTriggered ? styles.opacity_0 : ""
                    } ${isMainSectionIntersect ? styles.bottom2top_4s : ""}`}
                    onClick={() => console.log("Ready to Start")}
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 md:p-0 py-10">
                  <img
                    className={`h-full w-full object-cover object-center ${
                      !isMainSectionTriggered ? styles.opacity_0 : ""
                    } ${isMainSectionIntersect ? styles.bottom2top_4s : ""}`}
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1685501923/dealogikal_2.0/resources/images/big_img/landing_page_supply_chain-min_kl9aca.png"
                    alt="Landing Page Supply Chain"
                  />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div className="md:mt-72 md:pt-56 md:pb-56 bg-gray-100">
                <div className="flex flex-wrap justify-center md:px-4">
                  <div className="flex flex-col w-full p-2 md:w-full">
                    <h2
                      className={`text-center text-gray-900 md:text-lg md:mt-0 mt-4 ${
                        !isTopCompaniesSectionTriggered ? styles.opacity_0 : ""
                      } ${
                        isTopCompaniesSectionIntersect
                          ? styles.animate_top_companies
                          : ""
                      }`}
                    >
                      Trusted by 300+ Companies
                    </h2>
                  </div>
                </div>
                <div
                  className="flex flex-wrap md:px-44 md:mt-32 pb-4"
                  ref={topCompaniesSectionRef}
                >
                  {topCompaniesData.map((company) => {
                    return (
                      <div
                        className={`flex flex-col w-full md:p-2 md:w-1/5 ${
                          !isTopCompaniesSectionTriggered
                            ? styles.opacity_0
                            : ""
                        } ${
                          isTopCompaniesSectionIntersect &&
                          !isUseCaseSectionIntersect
                            ? styles.animate_top_companies
                            : ""
                        }`}
                        key={Math.random()}
                      >
                        <img
                          className="mx-auto md:h-48 h-28 w-auto flex-shrink-0"
                          src={company.logoURL}
                          alt={company.name + ` Logo`}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div
                className={`flex flex-wrap md:px-44 md:pt-44 md:pb-28 ${
                  !isUseCaseSectionTriggered ? styles.opacity_0 : ""
                }`}
                ref={useCaseSectionRef}
              >
                <div className="flex flex-col w-full sm:w-1/2 md:px-0 px-3 md:py-0 py-3">
                  <span
                    className={`sm:text-4xl text-3xl font-medium ${
                      !isUseCaseSectionTriggered ? styles.opacity_0 : ""
                    } ${isUseCaseSectionIntersect ? styles.left2right_2s : ""}`}
                  >
                    Buy your Commodity needs.
                  </span>
                  <span
                    className={`md:text-4xl text-3xl text-dealogikal-100 font-black sm:mt-5 ${
                      !isUseCaseSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isUseCaseSectionIntersect ? styles.left2right_2_5s : ""
                    }`}
                  >
                    Effortlessly.
                  </span>
                  <span
                    className={`md:text-lg md:mt-20 mt-5 ${
                      !isUseCaseSectionTriggered ? styles.opacity_0 : ""
                    } ${isUseCaseSectionIntersect ? styles.left2right_3s : ""}`}
                  >
                    Seamlessly access{" "}
                    <span className="font-black">Suppliers</span>,{" "}
                    <span className="font-black">Logistics Providers</span> and{" "}
                    <br />
                    <span className="font-black">
                      Trade Finance Solutions
                    </span>{" "}
                    for your commodity requirements <br /> in one{" "}
                    <span className="font-black">Platform</span>.
                  </span>
                </div>
                <div
                  className={`flex flex-col w-full md:w-1/2 md:px-0 px-3 md:py-0 py-3 ${
                    !isUseCaseSectionTriggered ? styles.opacity_0 : ""
                  } ${isUseCaseSectionIntersect ? styles.bottom2top_2s : ""}`}
                >
                  <div className="md:flex md:flex-wrap justify-center">
                    <div className="flex flex-col w-full p-2 md:w-1/3">
                      <ChooseItemIcon className="w-12 h-12 text-dealogikal-100 mx-auto" />
                      <span className="mx-auto mt-5">Choose Item</span>
                    </div>
                    <div className="flex flex-col w-full p-2 md:w-1/3">
                      <DeliveryIcon className="w-12 h-12 text-dealogikal-100 mx-auto" />
                      <span className="mx-auto mt-5">
                        Set Delivery/Pickup Location
                      </span>
                    </div>
                    <div className="flex flex-col w-full p-2 md:w-1/3">
                      <ReceiveIcon className="w-12 h-12 text-dealogikal-100 mx-auto" />
                      <span className="mx-auto mt-5">Receive Order</span>
                    </div>
                  </div>
                  <div className="flex flex-wrap justify-center mt-10">
                    <div className="flex flex-col w-full md:w-full">
                      <ButtonComponent
                        text="Buyer Account Registration"
                        utils={`bg-dealogikal-100 text-white shadow-lg md:py-6 py-4 md:w-1/2 md:px-0 px-8 sm:hover:bg-dealogikal-200 duration-500 rounded-full md:font-semibold md:mt-10 mt-1 mx-auto`}
                        onClick={{
                          click: () => console.log("Ready to Start"),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div
                className="flex flex-wrap md:pb-44 md:pt-20"
                ref={platformSnippetSectionRef}
              >
                <div
                  className={`flex flex-col mx-auto md:w-fit border-b-4 border-gray-300 ${
                    !isPlatformSnippetSectionTriggered ? styles.opacity_0 : ""
                  } ${
                    isPlatformSnippetSectionIntersect
                      ? styles.bottom2top_3s
                      : ""
                  }`}
                >
                  <img
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1688456720/dealogikal_2.0/resources/images/big_img/Sample_Feature_lecrjf-min_ss1cdi.png"
                    className={`h-auto`}
                    alt="Dealogikal Sample Feature"
                  />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div
                className={`flex flex-wrap justify-center md:px-44 md:py-24 bg-gray-100 ${
                  !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                }`}
                ref={sellerBenefitSectionRef}
              >
                <div className="flex flex-col w-full p-2 md:w-full">
                  <h2
                    className={`text-start text-gray-400 md:text-lg md:mt-0 mt-8 md:ml-0 ml-2 ${
                      !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isSellerBenefitSectionIntersect
                        ? styles.left2right_2s
                        : ""
                    }`}
                  >
                    Seller Benefit
                  </h2>
                </div>
              </div>
              <div
                className={`flex flex-wrap justify-center md:px-44 px-4 bg-gray-100 ${
                  !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                }`}
              >
                <div className="flex flex-col w-full md:w-1/2 md:pt-12 text-start">
                  <span
                    className={`${
                      !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isSellerBenefitSectionIntersect
                        ? styles.left2right_2s
                        : ""
                    }`}
                  >
                    <span
                      className={`sm:text-4xl text-3xl text-black font-medium`}
                    >
                      Lorem <span className="text-dealogikal-100">ipsum </span>
                      dolor sit amet,
                    </span>
                    <br />
                    <span
                      className={`sm:text-4xl text-3xl text-black font-medium mt-3`}
                    >
                      autem iste facilis
                    </span>
                  </span>
                  <br />
                  <span
                    className={` ${
                      !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isSellerBenefitSectionIntersect
                        ? styles.left2right_2s
                        : ""
                    }`}
                  >
                    <span
                      className={`md:text-xl text-lg font-thin text-gray-400 mt-2`}
                    >
                      H2 text here, h2 here, h2,,,
                    </span>
                    <br />
                    <span className="md:text-xl text-lg font-thin text-gray-400">
                      All in one place.
                    </span>
                  </span>

                  <ButtonComponent
                    text="Call to action"
                    utils={`bg-dealogikal-100 text-white shadow-lg md:py-6 py-4 md:w-1/4 w-1/2 sm:hover:bg-dealogikal-200 self-start duration-500 rounded-full md:font-semibold md:mt-40 mt-8  ${
                      !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isSellerBenefitSectionIntersect
                        ? styles.bottom2top_3s
                        : ""
                    }`}
                    onClick={() => console.log("Ready to Start")}
                  />
                </div>
                <div className="flex flex-col md:w-1/2 w-64 md:p-6 md:mt-0 my-10">
                  <img
                    className={`h-auto md:w-8/12 object-full rounded-full self-end ${
                      !isSellerBenefitSectionTriggered ? styles.opacity_0 : ""
                    } ${
                      isSellerBenefitSectionIntersect
                        ? styles.bottom2top_3s
                        : ""
                    }`}
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1688454605/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/beautiful-smart-asian-min_zjsyd5.jpg"
                    alt="Seller Benefit"
                  />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div
                className={`flex flex-wrap justify-center md:px-44 md:py-32 bg-gray-100 ${
                  !isLogisticsBenefitSectionTriggered ? styles.opacity_0 : ""
                }`}
                ref={logisticsBenefitSectionRef}
              >
                <div className="flex flex-col w-full p-2 md:w-full">
                  <h2
                    className={`md:text-end text-start text-gray-400 md:text-lg md:mt-0 mt-8 md:ml-0 ml-2 ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.right2left_2s
                        : ""
                    }`}
                  >
                    Logistics Benefit
                  </h2>
                </div>
              </div>
              <div
                className={`md:flex md:flex-wrap justify-center md:px-44 md:pb-36 pb-2 bg-gray-100 ${
                  !isLogisticsBenefitSectionTriggered ? styles.opacity_0 : ""
                }`}
              >
                <div className="md:block hidden flex-col w-full md:w-1/2 md:p-6">
                  <img
                    className={`h-auto w-8/12 object-full rounded-full self-start ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.bottom2top_3s
                        : ""
                    }`}
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1688454605/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/beautiful-smart-asian-min_zjsyd5.jpg"
                    alt="Logistics Benefit"
                  />
                </div>
                <div className="flex flex-col w-full md:w-1/2 md:pt-12 md:px-0 px-4 md:text-end text-start">
                  <span
                    className={`sm:text-4xl text-3xl text-black font-medium ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.right2left_2s
                        : ""
                    }`}
                  >
                    Lorem{" "}
                    <span className="sm:text-4xl text-3xl text-dealogikal-100">
                      ipsum
                    </span>{" "}
                    dolor sit amet,
                  </span>
                  <span
                    className={`sm:text-4xl text-3xl text-black font-medium md:mt-3 ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.right2left_2s
                        : ""
                    }`}
                  >
                    autem iste facilis
                  </span>
                  <br />
                  <span
                    className={`${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.right2left_2s
                        : ""
                    }`}
                  >
                    <span
                      className={`md:text-xl text-lg font-thin text-gray-400 mt-2`}
                    >
                      H2 text here, h2 here, h2s,,,
                    </span>
                    <br />
                    <span className="md:text-xl text-lg font-thin text-gray-400">
                      All in one place.
                    </span>
                  </span>

                  <ButtonComponent
                    text="Call to action"
                    utils={`bg-dealogikal-100 text-white shadow-lg md:py-6 py-4 md:w-1/4 w-1/2 sm:hover:bg-dealogikal-200 md:self-end self-start duration-500 rounded-full md:font-semibold md:mt-40 mt-8 ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.bottom2top_3s
                        : ""
                    }`}
                    onClick={() => console.log("Ready to Start")}
                  />
                </div>
                <div className="md:hidden block flex-col md:w-1/2 w-64 md:p-6 md:my-0 my-10 mx-auto">
                  <img
                    className={`h-auto md:w-8/12 object-full rounded-full md:self-start self-center ${
                      !isLogisticsBenefitSectionTriggered
                        ? styles.opacity_0
                        : ""
                    } ${
                      isLogisticsBenefitSectionIntersect
                        ? styles.bottom2top_3s
                        : ""
                    }`}
                    src="https://res.cloudinary.com/doehyebmw/image/upload/v1688454605/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/beautiful-smart-asian-min_zjsyd5.jpg"
                    alt="Logistics Benefit"
                  />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div className="flex flex-wrap justify-center md:px-44 md:pb-3">
                <div className="flex flex-col w-full md:w-full md:p-6">
                  <Testimonials />
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <div className="md:mt-24 md:pt-24 pt-12 md:pb-56 pb-12  bg-gray-100">
                <div className="flex flex-wrap justify-center md:px-4">
                  <div className="flex flex-col w-full p-2 md:w-full">
                    <h2 className="text-center text-gray-900 text-lg">
                      Awards
                    </h2>
                  </div>
                </div>
                <div className="flex flex-wrap md:px-44 md:mt-32">
                  <div className="flex flex-col w-full md:p-2 md:w-1/4">
                    <img
                      className={`h-auto md:w-10/12 w-52 md:my-0 my-4 object-full rounded-full self-center`}
                      src="https://res.cloudinary.com/doehyebmw/image/upload/v1685931723/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/mockup_awards_iwrvht.png"
                      alt="Award"
                    />
                  </div>
                  <div className="flex flex-col w-full md:p-2 md:w-1/4">
                    <img
                      className={`h-auto md:w-10/12 w-52 md:my-0 my-4 object-full rounded-full self-center`}
                      src="https://res.cloudinary.com/doehyebmw/image/upload/v1685931723/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/mockup_awards_iwrvht.png"
                      alt="Award"
                    />
                  </div>
                  <div className="flex flex-col w-full md:p-2 md:w-1/4">
                    <img
                      className={`h-auto md:w-10/12 w-52 md:my-0 my-4 object-full rounded-full self-center`}
                      src="https://res.cloudinary.com/doehyebmw/image/upload/v1685931723/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/mockup_awards_iwrvht.png"
                      alt="Award"
                    />
                  </div>
                  <div className="flex flex-col w-full md:p-2 md:w-1/4">
                    <img
                      className={`h-auto md:w-10/12 w-52 md:my-0 my-4 object-full rounded-full self-center`}
                      src="https://res.cloudinary.com/doehyebmw/image/upload/v1685931723/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/mockup_awards_iwrvht.png"
                      alt="Award"
                    />
                  </div>
                </div>
              </div>
              {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
              <Footer />
            </>
          ),
        }}
      />
    </>
  );
};

export default LandingComp;
