import { Switch } from "@headlessui/react";
import { classNames } from "../../../redux/functions/function";
import { ToogleModel } from "../../../model/interface/ToogleModel";

export default function ToggleComponent(props: ToogleModel) {
  return (
    <Switch
      checked={Boolean(props.toggleEnabled)}
      onChange={props.setToggleEnabled}
      className={classNames(
        Boolean(props.toggleEnabled) ? "bg-dealogikal-200" : "bg-gray-200",
        "relative inline-flex md:h-6 md:w-11 h-4 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-1 focus:ring-dealogikal-200 focus:ring-offset-2"
      )}
    >
      {/* <span className="sr-only">Use setting</span> */}
      <span
        aria-hidden="true"
        className={classNames(
          Boolean(props.toggleEnabled) ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block md:h-5 md:w-5 h-3 w-3 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
        )}
      />
    </Switch>
  );
}
