import ReverseAuctionTimerComponentModel from "../../../model/interface/ReverseAuctionTimerComponentModel";

const ReverseAuctionTimerComponent: React.FC<
  ReverseAuctionTimerComponentModel
> = (props) => {
  const hours = Math.floor(props.seconds / 3600);
  const minutes = Math.floor((props.seconds % 3600) / 60);
  const remainingSeconds = Math.round(props.seconds % 60);

  const renderTimerColor = (classType: string) => {
    if (props.auction_status === undefined) {
      return classType === "border"
        ? "border-red-950"
        : classType === "text"
        ? "text-red-950"
        : "";
    } else {
      if (props.auction_status === 1) {
        return classType === "border"
          ? "border-red-950"
          : classType === "text"
          ? "text-red-950"
          : "";
      } else if (props.auction_status === 2) {
        return classType === "border"
          ? "border-white"
          : classType === "text"
          ? "text-white"
          : "";
      } else {
        return classType === "border"
          ? "border-white"
          : classType === "text"
          ? "text-white"
          : "";
      }
    }
  };

  const renderReadableTime = () => {
    if (hours <= 24) {
      return (
        <>
          <span className={`border ${renderTimerColor("border")} px-4 py-2`}>
            {props.isTimeLoading ? "--" : hours.toString().padStart(2, "0")}
            <span className="text-xs ml-2">{` hrs`}</span>
          </span>
          <span className={`mx-2`}>:</span>
          <span className={`border ${renderTimerColor("border")} px-4 py-2`}>
            {props.isTimeLoading ? "--" : minutes.toString().padStart(2, "0")}
            <span className="text-xs ml-2">{` min`}</span>
          </span>
          <span className={`mx-2`}>:</span>
          <span className={`border ${renderTimerColor("border")} px-4 py-2`}>
            {props.isTimeLoading
              ? "--"
              : remainingSeconds.toString().padStart(2, "0")}
            <span className="text-xs ml-2">{` sec`}</span>
          </span>
        </>
      );
    } else if (hours > 24) {
      let days = hours / 24;
      let dayToWholeNumber = Math.floor(days);
      return (
        <>
          <span>{`${dayToWholeNumber} `}</span>
          <span className="text-xs">{`day${
            dayToWholeNumber > 1 ? "s" : ""
          }`}</span>
        </>
      );
    } else {
      return <></>;
    }
  };

  return (
    <div
      className={`${renderTimerColor(
        "text"
      )} flex items-center text-sm md:text-base lg:text-xl
          font-extrabold mt-6 sm:mt-4 ${
            props.auction_status === undefined ? "mb-5" : "mb-2"
          }`}
    >
      {renderReadableTime()}
    </div>
  );
};

export default ReverseAuctionTimerComponent;
