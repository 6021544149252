import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/20/solid";

interface Props {
  headerText: string;
  body: React.ReactNode;
}

export default function DisclosureComponent(props: Props) {
  return (
    <div className="w-full">
      <div className="mx-auto mt-3 w-full rounded-2xl bg-white">
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex w-full justify-between rounded-lg bg-dealogikal-100 px-4 py-3 text-left text-sm font-medium text-white sm:hover:bg-dealogikal-50 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                <span>{props.headerText}</span>
                <ChevronUpIcon
                  className={`${
                    open ? "rotate-180 transform" : ""
                  } h-5 w-5 text-white`}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="p-2 text-sm text-gray-500">
                {props.body}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
