const topCompaniesData = [
  {
    name: "KPEC",
    logoURL:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1688456915/dealogikal_2.0/resources/images/big_img/kpec_logo_zsxzhr-min_1_oj2iio.png",
  },
  {
    name: "Pheonix",
    logoURL:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1685513358/dealogikal_2.0/resources/images/big_img/pheonix_logo_svkoz4.png",
  },
  {
    name: "Chevron",
    logoURL:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1685513358/dealogikal_2.0/resources/images/big_img/chevron_logo_nuzqkf.png",
  },
  {
    name: "PPT",
    logoURL:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1685513358/dealogikal_2.0/resources/images/big_img/ppt_logo_onnm69.png",
  },
  {
    name: "Petron",
    logoURL:
      "https://res.cloudinary.com/doehyebmw/image/upload/v1685513358/dealogikal_2.0/resources/images/big_img/petron_logo_quujih.png",
  },
];

export default topCompaniesData;
