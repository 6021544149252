interface Props {
  badge_text: string;
  isActive: boolean;
}

const OnlineBadgeComponent: React.FC<Props> = (props) => {
  return (
    <>
      <span className="inline-flex items-center gap-x-1.5 rounded-full px-3 py-1 text-xs font-medium text-gray-700 ring-1 ring-inset ring-gray-200 ml-1">
        <svg
          className={`h-1.5 w-1.5 ${
            props.isActive ? "fill-green-500" : "fill-gray-400"
          }`}
          viewBox="0 0 6 6"
          aria-hidden="true"
        >
          <circle cx={3} cy={3} r={3} />
        </svg>
        {props.badge_text}
      </span>
    </>
  );
};

export default OnlineBadgeComponent;
