import { Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
} from "@heroicons/react/20/solid";
import { Bars3Icon, BellIcon } from "@heroicons/react/24/outline";
import { Fragment, useCallback, useEffect, useState } from "react";
import {
  classNames,
  getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import { useNavigate } from "react-router-dom";
import { TopNavigationModel } from "../../../../model/interface/TopNavigationModel";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../../../model/interface/TokenModel";
import { useAppDispatch, useAppSelector } from "../../../../redux/app/hooks";
import {
  getNotificationList,
  setNotificationList,
  clearNotificationList,
  clearAllInRedux,
} from "../../../../redux/functions/storageSlice";
import NotificationModalComponent from "../../../templates/NotificationModalComponent/NotificationModalComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import { UserNavigationType } from "../../../../model/types/UserNavigationType";
import { useWebSocket } from "../../../../services/ws/WebSocketService";
import {
  getNotificationsAPI,
  updateNotificationsAPI,
} from "../../../../redux/functions/API";
import NotificationType from "../../../../model/types/NotificationType";

const TopNavigationComponent = (props: TopNavigationModel) => {
  // decode the token
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  // declare navigation
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  // declare states here...
  const [fullname, setFullname] = useState<string>("");
  const [terminal, setTerminal] = useState<string>("");
  const [accountLabel, setAccountLabel] = useState<string>("");
  const notificationList = useAppSelector(getNotificationList);

  // initialize websocket custom hook
  const { socket, sendWebSocketMessage } = useWebSocket();

  useEffect(() => {
    setFullname(userData.name);
    switch (userData.role) {
      case 1:
        setTerminal(`text-buyer-10`);
        setAccountLabel("Buyer Account");
        break;
      case 2:
        setTerminal(`text-supplier-10`);
        setAccountLabel("Supplier Account");
        break;
      case 3:
        setTerminal(`text-mm-10`);
        setAccountLabel("Market Maker Account");
        break;
      case 4:
        setTerminal(`text-sales-10`);
        setAccountLabel("Sales Account");
        break;
      case 5:
        setTerminal(`text-admin-10`);
        setAccountLabel("Administrator Account");
        break;
      case 6:
        setTerminal(`text-logistic-10`);
        setAccountLabel("Logistic Account");
        break;
      case 7:
        setTerminal(`text-administrator-10`);
        setAccountLabel("Administrator Account");
        break;
      default:
        setTerminal(`text-unknown-10`);
        setAccountLabel("Unknown Account");
        break;
    }
  }, [userData.name, userData.role]);

  // notififications start ===========================================================================>

  // janm: flag for the active notifications
  const [activeNotifications, setActiveNotifications] =
    useState<string>("Unread");

  const selectedNotification = [
    {
      text: "Unread", //text: "All",
    },
    {
      text: "Read", //text: "Unread",
    },
  ];

  const notificationsClickHandler = (notificationId: any) => {
    if (props.notificationsList !== undefined) {
      // Store the clicked notification ID

      // Check if the clicked notification is already read
      const clickedNotification = props.notificationsList.find(
        (notification) =>
          notification.notification_id.toString() === notificationId.toString()
      );

      if (clickedNotification) {
        // You can do further actions here based on whether the notification is read or not
      }
    }
  };

  const [notificationCount, setNotificationCount] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (props.notificationsList && props.notificationsList.length > 0) {
      // Count unread notifications
      const unreadCount = props.notificationsList.filter(
        (notification) => !notification.read_at
      ).length;
      setNotificationCount(unreadCount);
    } else {
      setNotificationCount(0);
    }
  }, [props.notificationsList]);

  // cja: click handler for the right user navigation menu
  const userNavigationMenuClickHandler = (item: UserNavigationType) => {
    // if signout is being clicked, lets clear the sessionData slice
    if (item.name === "Sign out") {
      // clearing of sessions (start)
      // janm: clearing redux data
      dispatch(clearAllInRedux());

      localStorage.removeItem("activeMenu");

      // close the connection upon logout
      if (socket?.readyState === WebSocket.OPEN) {
        try {
          const socketPayload = {
            user_id: userData._id,
          };
          sendWebSocketMessage("destroySocketConnection", socketPayload);
        } catch (error) {
          console.error("Error sending closing message:", error);
        }
      }

      // clearing of sessions (end)
    }

    // do the callback
    item.callback();
    // then redirect
    navigate(item.href);
  };

  const [notificationsStatus, setNotificationsStatus] = useState<{
    [key: number]: string;
  }>({});
  const handleClick = (notificationId: number, notifyStatus: string) => {
    const data = {
      notification_id: notificationId,
      status: notifyStatus,
    };

    updateNotificationsAPI(_token, data).then((resp: any) => {
      if (resp.statusCode === 200) {
        setNotificationsStatus((prevStatuses: any) => ({
          ...prevStatuses,
          [notificationId]: "read",
        }));
      }
      fetchNotifications();
    });
  };

  const [notificationListLoading, setNotificationListLoading] =
    useState<boolean>(true);

  const fetchNotifications = useCallback(() => {
    setNotificationListLoading(true);
    const user_id: string = userData._id.toString();
    dispatch(clearNotificationList());
    getNotificationsAPI(_token, user_id)
      .then((response: any) => {
        const { statusCode, data } = response;
        if (statusCode === 200) {
          const newNotifications: NotificationType[] = data.items;
          dispatch(setNotificationList(newNotifications));
        }
        setNotificationListLoading(false);
      })
      .catch(() => {
        console.error("Error fetching notifications");
        setNotificationListLoading(false);
      });
  }, [userData._id, dispatch, _token]);

  useEffect(() => {
    if (notificationListLoading) {
      fetchNotifications(); // Only call fetch when loading is true
    }
  }, [fetchNotifications, notificationListLoading]);

  return (
    <div className="sticky top-0 z-10 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
      <button
        type="button"
        className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
        onClick={() => props.setSidebarOpen?.(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <Bars3Icon className="h-6 w-6" aria-hidden="true" />
      </button>
      {/* Separator */}
      <div className="h-6 w-px bg-gray-200 lg:hidden" aria-hidden="true" />

      <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
        <form className="relative flex flex-1" action="#" method="GET">
          <label htmlFor="search-field" className="sr-only">
            Search
          </label>
          <MagnifyingGlassIcon
            className="pointer-events-none absolute inset-y-0 left-0 h-full md:w-5 w-4 text-gray-400"
            aria-hidden="true"
          />
          <input
            id="search-field"
            className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 text-sm"
            placeholder="Search..."
            type="search"
            name="search"
          />
        </form>
        <div className="flex items-center gap-x-4 lg:gap-x-6">
          <NotificationModalComponent
            button={
              <>
                {notificationCount !== null && notificationCount !== 0 && (
                  <span className="bg-red-600 rounded-full  text-white font-bold relative text-xxs -top-2 translate-x-3 z-10 h-3.5 w-3.5 flex items-center justify-center ">
                    {notificationCount}
                  </span>
                )}
                <BellIcon className="h-6 w-6 absolute" aria-hidden="true" />
              </>
            }
            header={
              <>
                <div className="bg-dealogikal-100 md:py-2 md:px-4 pt-3 pb-3 px-4 text-center">
                  <BellIcon
                    className="md:ml-0.5 md:h-5 md:w-5 h-5 w-5 text-white inline mr-1.5"
                    aria-hidden="true"
                  />
                  <span className="text-white inline md:text-sm text-xs">
                    Notifications
                  </span>
                </div>
              </>
            }
            body={
              <>
                <div className="md:mx-0 mx-3.5">
                  <NavTabComponent
                    key={Math.random()}
                    nav_type="secondary"
                    active_tab={activeNotifications}
                    nav_items={selectedNotification}
                    setActiveNavHandler={setActiveNotifications}
                  />
                </div>
                <div className="flex flex-wrap justify-center">
                  {/* single Card (start) */}
                  <div className="flex flex-col space-y-2 w-full md:pb-10 pb-8 md:w-full md:px-0">
                    {activeNotifications.includes("Unread") && (
                      <>
                        <div className="md:px-2.5 px-3 justify-center">
                          <div className="table w-full pt-6">
                            <div className="table-row-group">
                              {props.notificationsList!.length > 0 &&
                                // Filter to show only unread notifications
                                props
                                  .notificationsList!.filter(
                                    (notifyList) => !notifyList.read_at
                                  ) // or use `notificationsStatus[notifyList.notification_id] !== 'read'`
                                  .map((notifyList) => {
                                    const notificationId =
                                      notifyList.notification_id;
                                    const notifyStatus = notifyList.read_at
                                      ? "read"
                                      : "unread";

                                    //const isUnread = notifyList.read_at === null; // isUnread is true
                                    return (notificationsStatus[
                                      notifyList.notification_id
                                    ] || "unread") === "unread" ? (
                                      <div
                                        key={notificationId}
                                        className={`${
                                          notifyStatus === "read"
                                            ? "bg-gray-300"
                                            : "bg-dealogikal-300"
                                        } shadow-md py-2 px-3 w-full rounded-lg bg-opacity-25 mb-2 cursor-pointer hover:bg-gray-300`}
                                        onClick={() =>
                                          notificationsClickHandler(
                                            notificationId.toString()
                                          )
                                        }
                                      >
                                        <div className="table-cell md:w-10/12">
                                          <span className="font-normal items-center flex gap-5">
                                            <span className="flex flex-col">
                                              <span className="text-gray-800 font-semibold text-sm">
                                                {` ${notifyList.title}`}
                                              </span>
                                              <span className="text-gray-500 text-sm mt-1.5">
                                                {` ${notifyList.message}`}
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div className="table-cell md:w-2/12">
                                          {/* Content for the new column */}
                                          <div className="group inline-block">
                                            <button className="outline-none focus:outline-none px-2 py-1 bg-dealogikal-400 rounded-sm flex items-center w-10 h-10">
                                              <span className="pr-1 flex-1">
                                                <svg
                                                  aria-hidden="true"
                                                  focusable="false"
                                                  data-prefix="fas"
                                                  data-icon="ellipsis-h"
                                                  role="img"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 512 512"
                                                  className="svg-inline--fa fa-ellipsis-h fa-w-16 fa-3x"
                                                >
                                                  <path
                                                    fill="currentColor"
                                                    d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                                    className=""
                                                  ></path>
                                                </svg>
                                              </span>
                                            </button>
                                            <ul className="bg-white border rounded-sm transform scale-0 group-hover:scale-100 absolute transition duration-150 ease-in-out origin-top min-w-32">
                                              <li className="rounded-sm px-3 py-1 hover:bg-gray-100">
                                                <button
                                                  className="w-full text-left"
                                                  onClick={() =>
                                                    handleClick(
                                                      notificationId,
                                                      notifyStatus === "read"
                                                        ? "unread"
                                                        : "read"
                                                    )
                                                  }
                                                >
                                                  {notifyStatus === "read"
                                                    ? "Unread"
                                                    : `Read`}
                                                </button>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    );
                                  })}
                              <ButtonComponent
                                text="See All"
                                utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4"
                              />
                            </div>
                          </div>
                          <br />
                        </div>
                      </>
                    )}

                    {activeNotifications.includes("Read") && (
                      <>
                        <div className="md:px-2.5 px-3 justify-center">
                          <div className="table w-full pt-6">
                            <div className="table-row-group">
                              {notificationList!.length > 0 &&
                                notificationList!
                                  .filter((notifyList) => notifyList.read_at)
                                  .map((notifyList) => {
                                    const notificationId =
                                      notifyList.notification_id;
                                    return (
                                      <div
                                        key={notificationId}
                                        className={`bg-gray-300 shadow-md py-2 px-3 w-full rounded-lg bg-opacity-25 mb-2 cursor-pointer hover:bg-gray-300`}
                                        onClick={() =>
                                          notificationsClickHandler(
                                            notificationId.toString()
                                          )
                                        }
                                      >
                                        <div className="table-cell md:w-10/12">
                                          <span className="font-normal items-center flex gap-5">
                                            <span className="flex flex-col">
                                              <span className="text-gray-800 font-semibold text-sm">
                                                {` ${notifyList.title}`}
                                              </span>
                                              <span className="text-gray-500 text-sm mt-1.5">
                                                {` ${notifyList.message}`}
                                              </span>
                                            </span>
                                          </span>
                                        </div>
                                        <div className="table-cell md:w-2/12">
                                          {/* Content for the new column */}
                                          <div className="group inline-block">
                                            <button className="outline-none focus:outline-none px-2 py-1 bg-dealogikal-400 rounded-sm flex items-center w-10 h-10">
                                              <span className="pr-1 flex-1">
                                                <svg
                                                  aria-hidden="true"
                                                  focusable="false"
                                                  data-prefix="fas"
                                                  data-icon="ellipsis-h"
                                                  role="img"
                                                  xmlns="http://www.w3.org/2000/svg"
                                                  viewBox="0 0 512 512"
                                                  className="svg-inline--fa fa-ellipsis-h fa-w-16 fa-3x"
                                                >
                                                  <path
                                                    fill="currentColor"
                                                    d="M328 256c0 39.8-32.2 72-72 72s-72-32.2-72-72 32.2-72 72-72 72 32.2 72 72zm104-72c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72zm-352 0c-39.8 0-72 32.2-72 72s32.2 72 72 72 72-32.2 72-72-32.2-72-72-72z"
                                                    className=""
                                                  ></path>
                                                </svg>
                                              </span>
                                            </button>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              <ButtonComponent
                                text="See All"
                                utils="bg-white text-dealogikal-200 ring-1 ring-inset ring-dealogikal-200 text-sm shadow-sm py-3 sm:hover:bg-slate-50 duration-500 rounded-full font-normal w-full mt-4"
                              />
                            </div>
                          </div>
                          <br />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </>
            }
          />
          {/* Separator */}
          <div
            className="hidden lg:block md:h-6 md:w-px md:bg-gray-200"
            aria-hidden="true"
          />
          <span
            className={`${terminal} font-extrabold text-sm cursor-pointer`}
            title={`Your account is ${
              accountLabel !== "Administrator Account" ? `a` : `an`
            } ${accountLabel}`}
          >
            {userData.company}
          </span>

          {/* Separator */}
          <div
            className="hidden lg:block md:h-6 md:w-px md:bg-gray-200"
            aria-hidden="true"
          />

          {/* Profile dropdown */}
          <Menu as="div" className="relative">
            <Menu.Button className="-m-1.5 flex items-center p-1.5">
              <span className="sr-only">Open user menu</span>
              <img
                className="h-8 w-8 rounded-full bg-gray-50"
                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                alt=""
              />
              <span className="hidden lg:flex lg:items-center">
                <span
                  className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                  aria-hidden="true"
                >
                  {fullname}
                </span>
                <ChevronDownIcon
                  className="ml-2 h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Menu.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                {props.userNavigation.map((item) => (
                  <Menu.Item key={item.name}>
                    {({ active }) => (
                      <span
                        onClick={() => userNavigationMenuClickHandler(item)}
                        className={classNames(
                          active ? "bg-gray-50" : "",
                          "block px-3 py-1 text-sm leading-6 text-gray-900 cursor-pointer"
                        )}
                      >
                        {item.name}
                      </span>
                    )}
                  </Menu.Item>
                ))}
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </div>
  );
};

export default TopNavigationComponent;
