import React, { useEffect } from "react";
import ReverseAuctionComp from "../../../../../components/buyer/auction_templates/add_template/reverse_auction/ReverseAuctionTemplateComp";
import { useValidateAuth } from "../../../../../hooks/useValidateAuth";
import { useLocation, useNavigate } from "react-router-dom";
import { useCheckWebSocketConnection } from "../../../../../hooks/useCheckWebSocketConnection";

const ReverseAuctionTemplate: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const validateAuth = useValidateAuth(location.pathname);

  /*
    cja: [Hook Purpose]
    - if not refresh, every navigate of the pages does not resend the message by checking the redux state
    - if refresh, reestablish the connection
  */
  useCheckWebSocketConnection(!validateAuth);

  /*
    cja: hook that will handle in validating
    the expiration of authentication token
  */
  useEffect(() => {
    if (validateAuth) {
      navigate("/login");
    }
  }, [navigate, validateAuth]);

  return <ReverseAuctionComp />;
};
export default ReverseAuctionTemplate;
