import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { classNames, generateKey } from "../../../redux/functions/function";
import DropdownItemType from "../../../model/types/DropdownItemType";
import DropdownModel from "../../../model/interface/DropdownModel";

const DropdownComponent: React.FC<DropdownModel> = (props) => {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left float-right md:w-48 w-full md:mt-0 mt-4 md:mb-0 mb-4"
    >
      <div>
        <Menu.Button className="inline-flex w-full justify-center gap-x-2 rounded-full bg-dealogikal-100 md:py-4 py-3 text-sm text-medium text-white shadow-md hover:bg-dealogikal-200">
          {props.main_text}
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-white"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-50 mt-2 md:w-80 w-full origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.items.map((item: DropdownItemType) => (
              <Menu.Item key={generateKey(item.item_text + Math.random())}>
                {({ active }) => (
                  <span
                    className={classNames(
                      active
                        ? "bg-gray-100 text-dealogikal-200"
                        : "text-dealogikal-200",
                      "group flex items-center px-4 py-4 text-sm cursor-pointer"
                    )}
                    onClick={item.onClick}
                  >
                    {item.icon}
                    {item.item_text}
                  </span>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default DropdownComponent;
