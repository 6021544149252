import {
  InboxStackIcon,
  MagnifyingGlassIcon,
  PlusCircleIcon,
  RectangleStackIcon,
  SquaresPlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import VerticalListComponent from "../../../templates/VerticalListComponent/VerticalListComponent";
import { ChangeEvent, useEffect, useState } from "react";
import VariationFieldComponent from "../../../templates/VariationComponent/VariationFieldComponent";
import {
  countDecimalPoints,
  generateKey,
} from "../../../../redux/functions/function";
import { useAppSelector } from "../../../../redux/app/hooks";
import { getUtilities } from "../../../../redux/functions/storageSlice";
import SelectedProductsType from "../../../../model/types/SelectedProductsType";
import ModalComponent from "../../../templates/ModalComponent/ModalComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import ProductType from "../../../../model/types/ProductType";
import DropdownLitstComponent from "../../../templates/DropdownLitstComponent/DropdownLitstComponent";

interface OfferVariationTabContentCompInterface {
  selectedProducts: SelectedProductsType[];
  setSelectedProducts: (val: SelectedProductsType[]) => void;
  offerVariationsHasChanges: boolean;
  editClickHandler: () => void;
  cancelEditClickHandler: () => void;
  mainVariationFieldArray: [number, [number, [string, string, string]][]][];
  setMainVariationFieldArray: (
    val: [number, [number, [string, string, string]][]][]
  ) => void;
  setIsOfferDetailsLoading: (val: boolean) => void;
}

const OfferVariationTabContentComp: React.FC<
  OfferVariationTabContentCompInterface
> = (props) => {
  // flags
  // cja: validation 1
  // flag state if the fields are empty when the add variation has been clicked
  const [hasEmptyFields, setHasEmptyFields] = useState<boolean>(false);
  // local state in storing the empty field
  // state data guide-->[product_id, [variation_id, [min, max, price]], error_message]
  const [emptyVariationField, setEmptyVariationField] = useState<
    [number, [number, [string, string, string]], string]
  >([-1, [-1, ["", "", ""]], ""]);

  // cja: validation 2
  // flag state if variation fields faild to meet the following validaion
  // minimum volume must be less than maximum volume; or vice versa
  // state data guide-->[product_id, [variation_id, [min, max, price]], error_message]
  const [hasComparisonError, setHasComparisonError] = useState<boolean>(false);
  const [faildComparedVariationFields, setFaildComparedVariationFields] =
    useState<[number, [number, [string, string, string]], string]>([
      -1,
      [-1, ["", "", ""]],
      "",
    ]);

  // cja: validation 3
  // flag state if minimum or maximum volumes exceeds product stock
  // state data guide-->[product_id, [variation_id, [min, max, price]], error_message, index]
  // index is either minimum or maximum volume field
  const [isStockExceedError, setIsStockExceedError] = useState<boolean>(false);
  const [exceedToStockField, setExceedToStockField] = useState<
    [number, [number, [string, string, string]], string, number]
  >([-1, [-1, ["", "", ""]], "", -1]);

  // local states
  //const dispatch = useAppDispatch();
  const utilities = useAppSelector(getUtilities);
  const [listOfProducts, setListOfProducts] = useState<any>([]);
  const [currentProduct, setCurrentProduct] = useState<number>(0);
  const [stockData, setStockData] = useState<string>("");
  // cja: state data guide-->[product_id, variation_id, field_index(min, max, price)]
  const [focusedField, setFocusedField] = useState<[number, number, number]>([
    -1, -1, -1,
  ]);
  const [productsInModal, setProductsInModal] = useState<
    SelectedProductsType[]
  >([]);

  // this useEffect hook will extract the products to be display inside the modal
  useEffect(() => {
    let updatedProductsInModal: SelectedProductsType[] = [];
    if (props.selectedProducts.length !== 0) {
      // add the selected product first
      props.selectedProducts.forEach((selected: SelectedProductsType) => {
        updatedProductsInModal = [...updatedProductsInModal, selected];
      });

      // then add the rest of the products
      utilities.productList.forEach((product: ProductType) => {
        // get the products that are not selected
        let productID = product.product_id;
        const productDetails = props.selectedProducts.map(
          (selected: SelectedProductsType) => {
            if (productID !== selected.product.product_id) {
              return product;
            }

            return null;
          }
        );

        if (productDetails !== null) {
          updatedProductsInModal = [
            ...updatedProductsInModal,
            {
              fields: "",
              product: {
                product_id: product.product_id!,
                commodity_name: product.commodity_name,
                commodity_type: product.commodity_type,
                stocks: product.stocks,
              },
            },
          ];
        }
      });

      setProductsInModal(updatedProductsInModal);
    }
  }, [
    props.selectedProducts.length,
    utilities.productList,
    props.selectedProducts,
  ]);

  // cja: this useEffect hook will extract the list of item to be pass in the vertical list component
  useEffect(() => {
    if (props.mainVariationFieldArray.length > 0) {
      let updatedListOfProducts: {
        item_id: number;
        name: string;
        current: boolean;
      }[] = [];

      props.mainVariationFieldArray.forEach(
        (
          mainVariationField: [number, [number, [string, string, string]][]]
        ) => {
          // extract product name
          let productName = "";
          utilities.productList.length !== 0 &&
            utilities.productList.forEach((product: any) => {
              if (
                product.product_id.toString() ===
                mainVariationField[0].toString()
              ) {
                productName = product.commodity_name;
              }
            });

          // extract the list of item to be pass in the vertical list component
          updatedListOfProducts = [
            ...updatedListOfProducts,
            {
              item_id: mainVariationField[0],
              name: productName,
              current: mainVariationField[0] === currentProduct ? true : false,
            },
          ];
        }
      );
      setListOfProducts(updatedListOfProducts);
      if (currentProduct === 0) {
        if (props.selectedProducts.length !== 0) {
          let new_currentProduct = props.selectedProducts[0].product.product_id;
          setCurrentProduct(new_currentProduct);
        }
      }
    }
  }, [
    props.mainVariationFieldArray,
    utilities.productList,
    currentProduct,
    props.selectedProducts,
  ]);
  // ===================================================================================================>

  // cja: function handler if the products are clicked
  const itemOnClickHandler = (item_id: number) => {
    // remove the error first
    // validation 1: empty field validation
    setHasEmptyFields(false);
    setEmptyVariationField([-1, [-1, ["", "", ""]], ""]);
    // validation 2: comparison validation
    setHasComparisonError(false);
    setFaildComparedVariationFields([-1, [-1, ["", "", ""]], ""]);
    // validation 3: stock validation
    setIsStockExceedError(false);
    setExceedToStockField([-1, [-1, ["", "", ""]], "", -1]);
    // set the current product into active
    setCurrentProduct(item_id);
    setStockData(ExtractStock(item_id));
    setHasEmptyFields(false);
  };

  // cja: function that will assign the stock in a specific product
  useEffect(() => {
    if (stockData === "" && props.selectedProducts.length !== 0) {
      setStockData(props.selectedProducts[0].product.stocks);
    }
  }, [props.selectedProducts, stockData]);

  const ExtractStock = (item_id: number) => {
    let itemStock = "";
    props.selectedProducts.length !== 0 &&
      props.selectedProducts.forEach(
        (selected_product: SelectedProductsType) => {
          if (selected_product.product.product_id === item_id) {
            itemStock = selected_product.product.stocks;
          }
        }
      );

    return itemStock;
  };

  // cja: dynamic variation field function implementations (start)
  // cja: delete variation field handler function
  const deleteVariationFieldHandler = (
    product_id: number,
    variation_id: number
  ) => {
    removeVariationField(variation_id, product_id).then((res: any) => {
      props.setMainVariationFieldArray(res);
    });
  };

  // cja: validation handler region (start)
  // cja: function handler to check the empty variation fields before adding new field
  const checkEmptyFields = (product_id: number) => {
    return new Promise((resolve, reject) => {
      let hasEmpty = null; // boolean flag variable to be return from the caller
      // locate product variation array through product_id parameter
      props.mainVariationFieldArray.forEach(
        (
          main_variation_field: [number, [number, [string, string, string]][]]
        ) => {
          if (main_variation_field[0].toString() === product_id.toString()) {
            let variation_field_arr: [number, [string, string, string]][] =
              main_variation_field[1]; // extract the variation field of the matched product
            // loop over the product variation array
            variation_field_arr.forEach(
              (variation_field: [number, [string, string, string]]) => {
                // then check if there are empty fields
                if (
                  variation_field[1][0] === "" ||
                  variation_field[1][1] === "" ||
                  variation_field[1][2] === ""
                ) {
                  // set flag to true if there is empty field
                  hasEmpty = true;
                  // save the product_id, variation field with empty fields, and error message to local state
                  setEmptyVariationField([
                    product_id,
                    variation_field,
                    "Please make sure to fill in the fields completely before adding another variation.",
                  ]);
                } else {
                  hasEmpty = false;
                }
              }
            );
          }
        }
      );
      resolve(hasEmpty);
    });
  };

  // cja: function handler to check if the minimum volume field is always less than or
  // not equal to the maximum volume field before adding new variation field
  const checkComparison = (product_id: number) => {
    return new Promise((resolve, reject) => {
      let isInvalid = null; // boolean flag if variation field is invalid.
      // locate product variation array through product_id parameter
      props.mainVariationFieldArray.forEach(
        (
          main_variation_field: [number, [number, [string, string, string]][]]
        ) => {
          if (main_variation_field[0].toString() === product_id.toString()) {
            let variation_field_arr: [number, [string, string, string]][] =
              main_variation_field[1]; // extract the variation field of the matched product
            // loop over the product variation array
            variation_field_arr.forEach(
              (variation_field: [number, [string, string, string]]) => {
                // then compare fields
                let minimumField = parseInt(variation_field[1][0]);
                let maximumField = parseInt(variation_field[1][1]);
                if (minimumField >= maximumField) {
                  // set flag to true if there is comparison error
                  isInvalid = true;
                  // save the product_id, variation field, and error message to local state
                  setFaildComparedVariationFields([
                    product_id,
                    variation_field,
                    "Please make sure that minimum volume is less than or equal to the maximum volume.",
                  ]);
                } else {
                  isInvalid = false;
                }
              }
            );
          }
        }
      );

      resolve(isInvalid);
    });
  };

  // cja: function handler to check if minimum and maximum volume fields will not exceed the product stock
  const checkStockValidation = (product_id: number) => {
    return new Promise((resolve, reject) => {
      let isInvalid = null; // boolean flag if variation field is invalid.
      // locate product variation array through product_id parameter
      props.mainVariationFieldArray.forEach(
        (
          main_variation_field: [number, [number, [string, string, string]][]]
        ) => {
          if (main_variation_field[0].toString() === product_id.toString()) {
            let variationFieldArray: [number, [string, string, string]][] =
              main_variation_field[1]; // extract the variation field of the matched product
            // loop over the product variation array
            variationFieldArray.forEach(
              (variation_field: [number, [string, string, string]]) => {
                let stringStock = ExtractStock(product_id)
                  .toString()
                  .replaceAll(" ltrs", "")
                  .replaceAll(",", "");

                let productStock = parseInt(stringStock);
                let minimumField = parseInt(variation_field[1][0]);
                let maximumField = parseInt(variation_field[1][1]);

                if (
                  minimumField >= productStock ||
                  maximumField > productStock
                ) {
                  let index = -1;
                  if (minimumField >= productStock) {
                    index = 0;
                  } else if (maximumField > productStock) {
                    index = 1;
                  }
                  // set flag to true if the minimum or maximum volume fields exceed product stock
                  isInvalid = true;
                  // save the product_id, variation field, and error message to local state
                  setExceedToStockField([
                    product_id,
                    variation_field,
                    `Please make sure that ${
                      index === 0 ? "minimum" : "maximum"
                    } volume does not exceed ${
                      index === 0 ? " or equal" : ""
                    } to the product stock.`,
                    index,
                  ]);
                } else {
                  isInvalid = false;
                }
              }
            );
          }
        }
      );
      resolve(isInvalid);
    });
  };

  // cja: validation handler region (end)

  const clearErrorFlags = () => {
    // validation 1: empty field states
    setHasEmptyFields(false);
    setEmptyVariationField([-1, [-1, ["", "", ""]], ""]);
    // validation 2: comparing minimum and maximum volume field states
    setHasComparisonError(false);
    setFaildComparedVariationFields([-1, [-1, ["", "", ""]], ""]);
    // validation 3: minimum or maximum field must not exceed to the product stock
    setIsStockExceedError(false);
    setExceedToStockField([-1, [-1, ["", "", ""]], "", -1]);
  };

  // cja: function click add variaiton handler
  const addVariationHandler = (product_id: number) => {
    // remove the existing errors in publishing event
    // setPublishEmptyValidation([]); -- temporary

    // initially, let's clear all the validation states related to this function (adding of new variation functionality)
    clearErrorFlags();
    // validation 1: let's check if there is an empty field
    checkEmptyFields(product_id).then((res: any) => {
      if (res === true) {
        setHasEmptyFields(res);
      } else {
        // validation 2: let's check if minimum is less than maximum volume
        checkComparison(product_id).then((res: any) => {
          if (res === true) {
            setHasComparisonError(res);
          } else {
            // validation 3: let's check if minimum or maximum fields does not exceed the product stock
            checkStockValidation(product_id).then((res: any) => {
              if (res === true) {
                setIsStockExceedError(res);
              } else {
                let updatedMainVariationFieldArray: [
                  number,
                  [number, [string, string, string]][]
                ][] = [];
                props.mainVariationFieldArray.forEach(
                  (
                    variation_field: [
                      number,
                      [number, [string, string, string]][]
                    ]
                  ) => {
                    if (variation_field[0] === product_id) {
                      let productVariationFields: [
                        number,
                        [string, string, string]
                      ][] = variation_field[1];
                      let productVariationIDFields: number[] = [];
                      productVariationFields.forEach(
                        (field: [number, [string, string, string]]) => {
                          productVariationIDFields = [
                            ...productVariationIDFields,
                            field[0],
                          ];
                        }
                      );
                      // sort product_variation_id_fields in ascending order
                      productVariationIDFields.sort(function (
                        a: number,
                        b: number
                      ) {
                        return b - a;
                      });
                      // increment the last variation_id to be the next variation_id
                      let newProductVariationID =
                        productVariationIDFields[0] + 1;
                      const newProductVariationFields: [
                        number,
                        [string, string, string]
                      ][] = [
                        ...productVariationFields,
                        [newProductVariationID, ["", "", ""]],
                      ];
                      // update variation_field
                      updatedMainVariationFieldArray = [
                        ...updatedMainVariationFieldArray,
                        [product_id, newProductVariationFields],
                      ];
                    } else {
                      updatedMainVariationFieldArray = [
                        ...updatedMainVariationFieldArray,
                        variation_field,
                      ];
                    }
                    props.setMainVariationFieldArray(
                      updatedMainVariationFieldArray
                    );
                  }
                );
              }
            });
          }
        });
      }
    });
  };

  // cja: create another array of variation field with variation field already deleted
  const removeVariationField = (variation_id: number, product_id: number) => {
    return new Promise((resolve, reject) => {
      let updatedMainVariationFieldArray: [
        number,
        [number, [string, string, string]][]
      ][] = [];
      let newProductVariationFields: [number, [string, string, string]][] = [];

      props.mainVariationFieldArray.forEach(
        (variation_field: [number, [number, [string, string, string]][]]) => {
          if (variation_field[0].toString() === product_id.toString()) {
            let productVariationFields: [number, [string, string, string]][] =
              variation_field[1];
            productVariationFields.forEach(
              (product_variation_field: [number, [string, string, string]]) => {
                if (
                  variation_id.toString() !==
                  product_variation_field[0].toString()
                ) {
                  newProductVariationFields = [
                    ...newProductVariationFields,
                    product_variation_field,
                  ];
                }
              }
            );
            updatedMainVariationFieldArray = [
              ...updatedMainVariationFieldArray,
              [product_id, newProductVariationFields],
            ];
          } else {
            updatedMainVariationFieldArray = [
              ...updatedMainVariationFieldArray,
              variation_field,
            ];
          }
        }
      );
      resolve(updatedMainVariationFieldArray);
    });
  };
  // cja: dynamic variation field function implementations (end)

  // cja: This is the handler that will be triggered every
  // onChange event of all the variation fields in different products.
  const handleChange = (
    event: ChangeEvent<HTMLInputElement>,
    product_id: number,
    variation_id: number,
    index: number
  ) => {
    setFocusedField([product_id, variation_id, index]); // set the focus field
    // CJA: validate the use of Decimal Point
    /*
        Decimal Validation:

        (1) decimal point MUST ONLY be applicable in the price input
        (2) decimal point must only be one
        (3) no decimal point should be at the first index of the input

      */

    // Decimal Validation 1: Identify the input. Decimal MUST ONLY be applicable in the price input
    let accepted_characters = null;
    /*
      [index] Legend:

      0 - Minimum Volume Input
      1 - Maximum Volume Input
      2 - Price per unit Input

    */
    if (index === 2) {
      // check the value if not blank/alphabet/character
      // include decimal
      accepted_characters = /^[0-9,.\b]+$/;
    } else {
      // check the value if not blank/alphabet/character
      accepted_characters = /^[0-9,\b]+$/;
    }

    if (
      event.target.value === "" ||
      accepted_characters.test(event.target.value)
    ) {
      // Decimal Validation 2 & 3: Decimal point must only be one & no decimal point should be at the first index of the input
      if (
        countDecimalPoints(event.target.value) < 2 &&
        event.target.value.charAt(0) !== "."
      ) {
        updateField(
          event,
          product_id.toString(),
          variation_id.toString(),
          index
        ).then((new_product_variation_fields: any) => {
          let updatedMainVariationFieldArray: [
            number,
            [number, [string, string, string]][]
          ][] = [];
          props.mainVariationFieldArray.forEach(
            (
              main_variation_field: [
                number,
                [number, [string, string, string]][]
              ]
            ) => {
              let mainVariationFieldID = main_variation_field[0].toString();
              if (product_id.toString() === mainVariationFieldID) {
                updatedMainVariationFieldArray = [
                  ...updatedMainVariationFieldArray,
                  [product_id, new_product_variation_fields],
                ];
              } else {
                updatedMainVariationFieldArray = [
                  ...updatedMainVariationFieldArray,
                  main_variation_field,
                ];
              }
            }
          );
          props.setMainVariationFieldArray(updatedMainVariationFieldArray);
        });
      }
    }
  };

  // cja: this is the main function handler in updating the variation field value.
  // This will only return a Promise response containing the array of variation fields on a specific product.
  const updateField = (
    event: ChangeEvent<HTMLInputElement>,
    product_id: string,
    variation_id: string,
    index: number
  ) => {
    return new Promise((resolve, reject) => {
      // find the product through product id parameter
      props.mainVariationFieldArray.forEach(
        (
          main_variation_field: [number, [number, [string, string, string]][]]
        ) => {
          let currentProductID = main_variation_field[0].toString(); // get product id from the current field in the loop
          let productVariationFields: [number, [string, string, string]][] =
            main_variation_field[1]; // get the variation field array of the product
          let newProductVariationFields: [number, [string, string, string]][] =
            []; // initialize new version of product variation field array
          if (currentProductID === product_id) {
            // loop the fields of the current product, then find the specific variation
            // by using the variation id parameter
            productVariationFields.forEach(
              (product_variation_field: [number, [string, string, string]]) => {
                let currentVariationID = product_variation_field[0].toString(); // get the current product variation id
                if (currentVariationID === variation_id) {
                  let fields: [string, string, string] =
                    product_variation_field[1]; // get the array of fields ([min, max, price]) of the current variation
                  fields[index] = event.target.value; // locate and update the field by the use of index parameter

                  // add to new array of product variation array
                  newProductVariationFields = [
                    ...newProductVariationFields,
                    [parseInt(currentVariationID), fields],
                  ];
                } else {
                  // simply add to new array of product variation array
                  newProductVariationFields = [
                    ...newProductVariationFields,
                    [parseInt(currentVariationID), product_variation_field[1]],
                  ];
                }
              }
            );
            resolve(newProductVariationFields);
          }
        }
      );
    });
  };

  // cja: select product modal blocks (start)
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedProductID, setSelectedProductID] = useState<number[]>([]);
  const [isDisplaySelectionError, setIsDisplaySelectionError] =
    useState<boolean>(false);
  const [activeSelectProductNav, setActiveSelectProductNav] =
    useState<string>("Item");
  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const selectProductNavItems = [
    {
      text: `Item(${props.selectedProducts.length})`,
    },
    {
      text: "Unlisted(0)",
    },
  ];

  // cja: product list inside modal checkbox handler
  const selectionHandler = (product_id: number) => {
    // get the entire product details
    //let toSelectProduct: any = [];
    // listOfProducts.forEach((prod: ProductType) => {
    //   if (prod.product_id === product_id) {
    //     toSelectProduct = [...toSelectProduct, prod];
    //   }
    // });

    // props.selectedProducts.forEach((selected: SelectedProductsType) => {
    //   toSelectProduct = [...toSelectProduct, selected];
    // });

    // check if product_id exist in the selectedProduct
    checkProductExist(product_id).then((res) => {
      if (!res && props.selectedProducts.length < 3) {
        // if selected product does not exist, let's update productsInModal local state

        // extract the product data structure to be added in the selected products
        let productToAdd: ProductType = {
          product_id: 0,
          commodity_type: "",
          commodity_name: "",
          stocks: "",
        };
        utilities.productList.forEach((product: ProductType) => {
          if (product.product_id.toString() === product_id.toString()) {
            productToAdd = {
              commodity_name: product.commodity_name,
              commodity_type: product.commodity_type,
              product_id: product_id,
              stocks: product.stocks,
            };
          }
        });

        // add to main selectedProducts parent local state
        let newSelectedProducts: SelectedProductsType[] = [];
        props.selectedProducts.forEach((selected: SelectedProductsType) => {
          newSelectedProducts = [...newSelectedProducts, selected];
        });
        newSelectedProducts = [
          ...newSelectedProducts,
          {
            product: productToAdd,
            fields: "",
          },
        ];

        props.setSelectedProducts(newSelectedProducts);

        // let updatedMainVariationFieldArray: [
        //   number,
        //   [number, [string, string, string]][]
        // ][] = [];
        // utilities.productList.forEach((product: ProductType) => {
        //   if (product.product_id.toString() === product_id.toString()) {
        //     updatedMainVariationFieldArray = [
        //       ...updatedMainVariationFieldArray,
        //       [product_id, [[product_id, ["", "", ""]]]],
        //     ];
        //   } else {
        //   }
        // });

        // props.setMainVariationFieldArray(updatedMainVariationFieldArray);

        // let newSelectedProducts: SelectedProductsType[] = [];
        // newSelectedProducts = [
        //   ...props.selectedProducts,
        //   {
        //     product: toSelectProduct[0],
        //     fields: "",
        //   },
        // ];
        // props.setSelectedProducts(newSelectedProducts);
      } else if (res && props.selectedProducts.length <= 3) {
        // update the mainVariationArray local state
        // if selected, then add product name to selected array
        let newSelectedProducts: SelectedProductsType[] = [];
        props.selectedProducts.forEach(
          (selected_product: SelectedProductsType) => {
            if (selected_product.product.product_id !== product_id) {
              newSelectedProducts = [...newSelectedProducts, selected_product];
            }
          }
        );
        props.setSelectedProducts(newSelectedProducts);
      } else {
        selectionErrorHandler();
      }
    });
  };

  // cja: check if to_select product already exist
  const checkProductExist = (product_id: number) => {
    return new Promise((resolve, reject) => {
      resolve(selectedProductID.includes(product_id));
    });
  };

  // cja: useEffect hook that extracts the product ids
  // and store to an array if changes detected
  useEffect(() => {
    let productIDArray: number[] = [];
    props.selectedProducts.forEach((selected_product: SelectedProductsType) => {
      productIDArray = [...productIDArray, selected_product.product.product_id];
    });
    setSelectedProductID(productIDArray);
  }, [props.selectedProducts]);

  // cja: function that handles the display of the error message in
  // in selecting products beyond 3
  const selectionErrorHandler = () => {
    setIsDisplaySelectionError(true);
    setTimeout(() => {
      setIsDisplaySelectionError(false);
    }, 3000);
  };

  // cja: confirm selection handler function
  const confirmSelectionHandler = () => {
    // declare the base property which will be the basis in sorting the array in alphabet order
    sortSelectedProducts(props.selectedProducts, "alphabet");
    // dispatch(setSelected_Products(JSON.stringify(selectedProducts)));
    // navigate("/s/add_product_offer");
  };

  const sortSelectedProducts = (
    arr: SelectedProductsType[],
    base_type: string
  ) => {
    arr.sort(function (a: any, b: any) {
      var result = 0;
      if (base_type === "alphabet") {
        var textA = a.product.commodity_name.toString().toLowerCase();
        var textB = b.product.commodity_name.toString().toLowerCase();
        result = textA < textB ? -1 : textA > textB ? 1 : 0;
      } else if (base_type === "numeric") {
        // to be enchance
      }
      return result;
    });
  };
  // cja: select product modal blocks (end)

  // cja: function that will handle in checking the active product in the list
  // and display its fields
  const populateVariationFields = (
    active_product: string,
    product_variation_field: [number, [string, string, string]][]
  ) => {
    // cja: if variation_field[0] === currentProduct
    return active_product === currentProduct.toString() ? (
      <>
        {product_variation_field.map(
          (variation_field: [number, [string, string, string]]) => {
            let variationFieldID: number = variation_field[0];
            // cja: this function will identify if the field is the last focus
            function IsFocused(index: number) {
              return (
                focusedField[0].toString() === active_product.toString() &&
                variation_field[0].toString() === focusedField[1].toString() &&
                focusedField[2] === index
              );
            }

            // cja: this function will identify if the field is valid or invalid to apply border color
            function IsInvalid(index: number, validation: string) {
              if (validation === "empty") {
                return (
                  emptyVariationField[0].toString() === active_product &&
                  emptyVariationField[1][0].toString() ===
                    variationFieldID.toString() &&
                  emptyVariationField[1][1][index] === ""
                );
              } else if (validation === "comparison") {
                return (
                  variationFieldID.toString() ===
                  faildComparedVariationFields[1][0].toString()
                );
              } else if (validation === "stock") {
                return (
                  variationFieldID.toString() ===
                    exceedToStockField[1][0].toString() &&
                  index === exceedToStockField[3]
                );
              } else {
                return false;
              }
            }

            return (
              <div
                className="flex flex-wrap justify-center mt-4"
                key={generateKey(Math.random() + variation_field[0].toString())}
              >
                <div
                  className={`flex flex-col ${
                    product_variation_field.length === 1
                      ? "md:w-full"
                      : "md:w-11/12"
                  }`}
                >
                  <div className="flex flex-wrap justify-center rounded-lg bg-gray-100 md:bg-white px-2 md:px-0 pb-2 md:pb-0">
                    <VariationFieldComponent
                      key={generateKey(
                        Math.random() + "minimum" + variationFieldID
                      )}
                      width="md:w-1/3"
                      padding_alignment="md:pr-2"
                      input_value={variation_field[1][0].toString()}
                      input_type="text"
                      input_name="txtminimum_volume"
                      input_id="txtminimum_volume"
                      placeholder_text="Minimum Volume"
                      variation_id={variationFieldID}
                      OnchangeHandler={(e) =>
                        handleChange(
                          e,
                          parseInt(active_product),
                          variationFieldID,
                          0
                        )
                      }
                      isInvalid={
                        IsInvalid(0, "empty") ||
                        IsInvalid(0, "comparison") ||
                        IsInvalid(0, "stock")
                      }
                      isFocused={IsFocused(0)}
                      isDisabled={!props.offerVariationsHasChanges}
                    />
                    <VariationFieldComponent
                      key={generateKey(
                        Math.random() + "maximum" + variationFieldID
                      )}
                      width="md:w-1/3"
                      padding_alignment="md:px-2"
                      input_value={variation_field[1][1].toString()}
                      input_type="text"
                      input_name="txtmaximum_volume"
                      input_id="txtmaximum_volume"
                      placeholder_text="Maximum Volume"
                      variation_id={variationFieldID}
                      OnchangeHandler={(e) =>
                        handleChange(
                          e,
                          parseInt(active_product),
                          variationFieldID,
                          1
                        )
                      }
                      isInvalid={
                        IsInvalid(1, "empty") ||
                        IsInvalid(1, "comparison") ||
                        IsInvalid(1, "stock")
                      }
                      isFocused={IsFocused(1)}
                      isDisabled={!props.offerVariationsHasChanges}
                    />
                    <VariationFieldComponent
                      key={generateKey(
                        Math.random() + "price" + variationFieldID
                      )}
                      width="md:w-1/3"
                      padding_alignment="md:pl-2"
                      input_value={variation_field[1][2].toString()}
                      input_type="text"
                      input_name="txtprice_per_unit"
                      input_id="txtprice_per_unit"
                      placeholder_text="Price per unit"
                      variation_id={variationFieldID}
                      OnchangeHandler={(e) =>
                        handleChange(
                          e,
                          parseInt(active_product),
                          variationFieldID,
                          2
                        )
                      }
                      isInvalid={IsInvalid(2, "empty")}
                      isFocused={IsFocused(2)}
                      isDisabled={!props.offerVariationsHasChanges}
                    />
                    {product_variation_field.length > 1 &&
                      props.offerVariationsHasChanges && (
                        <span
                          className="block md:hidden text-sm text-gray-900 text-end w-full mt-2"
                          onClick={() =>
                            deleteVariationFieldHandler(
                              parseInt(active_product),
                              variationFieldID
                            )
                          }
                        >
                          Remove
                        </span>
                      )}
                  </div>
                </div>
                <div
                  className={`flex flex-col my-auto ${
                    product_variation_field.length === 1
                      ? "md:w-0"
                      : "md:w-1/12"
                  }`}
                >
                  <div className="flex flex-wrap">
                    <TrashIcon
                      className="hidden md:block h-6 w-6 mx-auto text-gray-400 cursor-pointer"
                      onClick={() =>
                        deleteVariationFieldHandler(
                          parseInt(active_product),
                          variationFieldID
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            );
          }
        )}
      </>
    ) : (
      <></>
    );
  };

  const renderSingleValidationErrorMessage = (error_text: string) => {
    return (
      <div className={`flex flex-wrap justify-center mt-5`}>
        <div className="flex flex-col w-full md:w-full">
          <span className="text-red-600 text-sm text-center">{error_text}</span>
        </div>
      </div>
    );
  };

  return (
    <>
      <div className="flex flex-wrap justify-center mt-2">
        <div className="flex flex-col w-full md:w-full pr-4 items-end">
          {props.offerVariationsHasChanges ? (
            <span
              className="text-gray-700 font-medium underline cursor-pointer"
              onClick={props.cancelEditClickHandler}
            >
              Cancel
            </span>
          ) : (
            <span className="text-dealogikal-100 font-medium">
              <span
                className="underline cursor-pointer"
                onClick={props.editClickHandler}
              >
                Edit
              </span>
            </span>
          )}
        </div>
      </div>
      <div className="flex flex-wrap justify-center mt-5 md:mt-6">
        {/* single card (start) */}
        <div className="flex flex-col w-full md:w-2/5 pl-0 md:pl-6 pr-0 md:pr-24">
          <span className="-mt-11 mb-4 text-dealogikal-100 sm:text-end w-1/2 md:w-full">
            <span
              className="cursor-pointer underline"
              onClick={() => setIsModalOpen(true)}
            >
              Edit products
            </span>
          </span>
          {/* cja: show on small upto large screens --> hidden to all screens by default */}
          <div className="hidden md:block">
            <VerticalListComponent
              list={listOfProducts}
              itemOnClickHandler={itemOnClickHandler}
            />
          </div>
          {/* cja: show on all screens --> hidden to small upto larger screens by default */}
          <div className="block md:hidden -mt-6 mb-2">
            <DropdownLitstComponent
              list={listOfProducts}
              itemOnClickHandler={itemOnClickHandler}
            />
          </div>
        </div>
        <div className="flex flex-col w-full md:w-3/5">
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-full md:w-2/3">
              <label
                htmlFor="txtlocation"
                className="text-sm font-medium leading-6 text-gray-900"
              >
                <div className="flex gap-2">
                  <SquaresPlusIcon className="-ml-0.5 h-5 w-5" />
                  <span>Variation(s)</span>
                </div>
              </label>
            </div>
            <div className="flex flex-col w-full md:w-1/3">
              <div className="selft-start md:self-end mt-2 md:mt-0">
                <span className="text-center rounded-full bg-red-100 px-6 py-2 text-xs font-medium text-red-700 flex-wrap">
                  <InboxStackIcon className="-ml-0.5 h-5 w-5 inline" />
                  <span className="inline ml-2">{stockData}</span>
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-wrap justify-center mt-4">
            <div className="flex flex-col w-full md:w-full">
              {props.mainVariationFieldArray.map(
                (main_variation_field: any) => {
                  // cja: guide
                  // main_variation_field[0] = product_id
                  // main_variation_field[1] = variation_field_array

                  return (
                    <div
                      key={
                        Math.random().toString() +
                        main_variation_field[0].toString()
                      }
                    >
                      {populateVariationFields(
                        main_variation_field[0].toString(),
                        main_variation_field[1]
                      )}
                    </div>
                  );
                }
              )}
            </div>
            {hasEmptyFields === true &&
              renderSingleValidationErrorMessage(emptyVariationField[2])}
            {hasComparisonError === true &&
              renderSingleValidationErrorMessage(
                faildComparedVariationFields[2]
              )}
            {isStockExceedError === true &&
              renderSingleValidationErrorMessage(exceedToStockField[2])}
            <ButtonComponent
              icon={<PlusCircleIcon className="h-5 w-5" aria-hidden="true" />}
              text="Add Variation"
              utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:mt-6 mt-6 mb-5 float-right ${
                props.offerVariationsHasChanges
                  ? "sm:hover:bg-dealogikal-200"
                  : "cursor-not-allowed opacity-50"
              } duration-500 rounded-full font-normal w-full`}
              onClick={() => addVariationHandler(currentProduct)}
              disabled={!props.offerVariationsHasChanges}
            />
          </div>
        </div>
        {/* single card (end) */}
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        onCloseHandler={() => setIsModalOpen(false)}
        header={
          <div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
            <RectangleStackIcon
              className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
              aria-hidden="true"
            />
            <span className="text-white inline md:text-base text-xs">
              Select Product from My Products
            </span>
          </div>
        }
        body={
          <>
            <div className="relative rounded-md md:py-0 md:px-0 px-2 md:mt-7 mt-5 md:mx-7 mx-3">
              <input
                type="text"
                name="account-number"
                id="account-number"
                className="block w-full rounded-md border-0 md:py-4 py-2.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                placeholder="Search product"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-3 pr-5">
                <MagnifyingGlassIcon
                  className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
            <div className="md:mx-0 mx-3.5">
              <NavTabComponent
                key={Math.random()}
                nav_type="secondary"
                active_tab={activeSelectProductNav}
                nav_items={selectProductNavItems}
                setActiveNavHandler={setActiveSelectProductNav}
              />
            </div>
            {isDisplaySelectionError && (
              <div className="md:px-6 px-5 md:mt-5">
                <span className="text-sm text-red-600">
                  You can only select up to 3 products
                </span>
              </div>
            )}
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
                {activeSelectProductNav.includes("Item") && (
                  <>
                    {productsInModal.length !== 0 && (
                      <>
                        <div className="md:px-6 px-5 justify-center">
                          <div className="table w-full pt-6">
                            <div className="table-row-group">
                              {productsInModal.map(
                                (selected: SelectedProductsType) => {
                                  return (
                                    <div
                                      key={generateKey(
                                        selected.product.product_id.toString() +
                                          Math.random().toString()
                                      )}
                                    >
                                      <div className="table-row">
                                        <div className="table-cell text-center md:w-1/12">
                                          <input
                                            id={
                                              `cb` + selected.product.product_id
                                            }
                                            name={
                                              `cb` + selected.product.product_id
                                            }
                                            type="checkbox"
                                            checked={selectedProductID.includes(
                                              selected.product.product_id
                                            )}
                                            className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
                                            value={selected.product.product_id}
                                            onChange={(event) =>
                                              selectionHandler(
                                                parseInt(event.target.value)
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="table-cell md:w-10/12">
                                          <span className="font-normal md:text-base text-xs ml-4">
                                            {selected.product.commodity_name}
                                          </span>
                                        </div>
                                      </div>
                                      <br />
                                    </div>
                                  );
                                }
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="text-center md:mt-4 md:mx-0 mx-5">
                          <ButtonComponent
                            text="Confirm"
                            utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 ${
                              props.selectedProducts.length === 0
                                ? "disabled:opacity-50 cursor-arrow"
                                : "hover:bg-dealogikal-200 cursor-pointer"
                            } duration-500 rounded-full font-normal md:w-11/12 w-full`}
                            onClick={confirmSelectionHandler}
                            disabled={
                              props.selectedProducts.length === 0 ? true : false
                            }
                          />
                        </div>
                      </>
                    )}
                  </>
                )}
                {activeSelectProductNav.includes("Unlisted") && (
                  <>
                    <div className="mx-auto text-gray-400">
                      <span className="md:text-sm text-xs">Coming Soon...</span>
                    </div>
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};

export default OfferVariationTabContentComp;
