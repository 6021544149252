import { useState } from "react";
interface DropdownLitstComponentInterface {
  list: any[];
  itemOnClickHandler: (item_id: number) => void;
}

const DropdownLitstComponent: React.FC<DropdownLitstComponentInterface> = (
  props
) => {
  const [selectedItem, setSelectedItem] = useState<number>(0);

  return (
    <div className="w-full py-4 items-center">
      <select
        id="dropdownlist"
        name="dropdownlist"
        className="mt-2 block w-full rounded-2xl border-0 py-2.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-dealogikal-100 sm:text-sm sm:leading-6"
        onChange={(e) => {
          setSelectedItem(Number(e.target.value));
          props.itemOnClickHandler(Number(e.target.value));
        }}
        value={selectedItem}
      >
        {props.list.map((item: any) => (
          <option
            key={Math.random().toString() + item.item_id.toString()}
            value={item.item_id}
          >
            {item.name}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropdownLitstComponent;
