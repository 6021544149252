import { ReactNode } from "react";

interface Props {
  owner_name: string;
  badge_status: ReactNode;
  chat_type: string;
  message: string;
}

const SingleChatComp: React.FC<Props> = (props) => {
  return (
    <>
      {props.chat_type === "receive" && (
        <div className="flex items-start mt-5">
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-full"
              src="https://res.cloudinary.com/doehyebmw/image/upload/v1688455561/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/Testimonial/featured_testimonial_pp-min_1_ztj6jl.jpg"
              alt="Avatar"
            />
          </div>
          <div className="ml-3">
            <p className="text-gray-800 text-xs">
              {props.owner_name} {props.badge_status}
            </p>
            {/* Message */}
            <div className="bg-white rounded-lg p-2 mt-1">
              <p className="text-gray-600 text-sm">{props.message}</p>
            </div>
            {/* Message */}
          </div>
        </div>
      )}

      {props.chat_type === "send" && (
        <div className="flex items-start fixed right-0 mt-5">
          <div className="mr-2">
            <p className="text-gray-800 text-xs">
              {props.badge_status} &nbsp;&nbsp;{props.owner_name}
            </p>
            {/* Message */}
            <div className="bg-white rounded-lg p-2 mt-1">
              <p className="text-gray-600 text-sm">{props.message}</p>
            </div>
            {/* Message */}
          </div>
          <div className="flex-shrink-0">
            <img
              className="w-8 h-8 rounded-full mr-3"
              src="https://res.cloudinary.com/doehyebmw/image/upload/v1688455561/dealogikal_2.0/resources/images/big_img/Landing%20Page%20Images/Testimonial/featured_testimonial_pp-min_1_ztj6jl.jpg"
              alt="Avatar"
            />
          </div>
        </div>
      )}
    </>
  );
};
export default SingleChatComp;
