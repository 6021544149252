import { ProgressBarModel } from "../../../model/interface/ProgressBarModel";
import styles from "./ProgressBarComponent.module.css";

const ProgressBarComponent: React.FC<ProgressBarModel> = (props) => {
  return (
    <>
      <div
        className={`h-2 sm:h-5 rounded-full text-center bg-dealogikal-50 w-72 md:w-full mx-auto md:mt-36 mt-20`}
      >
        <div
          className={`${styles.playProgressAnimation} h-full w-8 rounded-full bg-dealogikal-100`}
        ></div>
        <div className="mt-4 text-sm lg:text-md">{props.text}</div>
      </div>
    </>
  );
};

export default ProgressBarComponent;
