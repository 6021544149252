import { classNames } from "../../../redux/functions/function";

import { CheckIcon } from "@heroicons/react/20/solid";

type step = {
  name: string;
  href: string;
  status: string;
};

interface Props {
  steps?: step[];
}

const StepsComponent: React.FC<Props> = (props) => {
  return (
    <nav aria-label="Progress" className="mx-auto mt-3 md:mt-8">
      <ol className="flex items-center">
        {props.steps?.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              props.steps?.length !== undefined &&
                stepIdx !== props.steps?.length - 1
                ? "pr-8 sm:pr-20"
                : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-dealogikal-100" />
                </div>
                <a
                  href={step.href}
                  className="relative flex h-7 w-7 items-center justify-center rounded-full bg-dealogikal-100 sm:hover:bg-dealogikal-100"
                >
                  <CheckIcon
                    className="h-5 w-5 text-white"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : step.status === "current" ? (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href={step.href}
                  className="relative flex h-7 w-7 items-center justify-center rounded-full border-2 border-dealogikal-100 bg-white"
                  aria-current="step"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-dealogikal-100"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            ) : (
              <>
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-gray-200" />
                </div>
                <a
                  href={step.href}
                  className="group relative flex h-7 w-7 items-center justify-center rounded-full border-2 border-gray-300 bg-white sm:hover:border-gray-400"
                >
                  <span
                    className="h-2.5 w-2.5 rounded-full bg-transparent group-sm:hover:bg-gray-300"
                    aria-hidden="true"
                  />
                  <span className="sr-only">{step.name}</span>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
      {/* step labels */}
      <ol className="flex items-center mt-2">
        {props.steps?.map((step, stepIdx) => (
          <li
            key={step.name}
            className={classNames(
              props.steps?.length !== undefined &&
                stepIdx !== props.steps?.length - 1
                ? "pr-8 sm:pr-20"
                : "",
              "relative"
            )}
          >
            {step.status === "complete" ? (
              <>
                <a
                  href="/"
                  className="relative flex h-7 w-7 items-center justify-center"
                >
                  <small className="md:text-xs text-xxs text-gray-400">
                    {step.name}
                  </small>
                </a>
              </>
            ) : step.status === "current" ? (
              <>
                <a
                  href="/"
                  className="relative flex h-7 w-7 items-center justify-center"
                  aria-current="step"
                >
                  <small className="md:text-xs text-xxs text-dealogikal-100">
                    {step.name}
                  </small>
                </a>
              </>
            ) : (
              <>
                <a
                  href="/"
                  className="group relative flex h-7 w-7 items-center justify-center"
                >
                  <small className="md:text-xs text-xxs text-gray-400">
                    {step.name}
                  </small>
                </a>
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default StepsComponent;
