import React, { useCallback, useEffect, useState } from "react";
import { ButtonWithTimerModel } from "../../../model/interface/ButtonWithTimerModel";

const ButtonWithTimerComponent: React.FC<ButtonWithTimerModel> = (props) => {
  const [remainingTimeInSeconds, setRemainingTimeInSeconds] = useState<number>(
    props.time
  );

  // destructure props --> get updateBookedStatus() function only
  const { updateBookedStatus } = props;

  // cja: useEffect hook that handles the running timer
  useEffect(() => {
    if (remainingTimeInSeconds !== undefined) {
      const intervalId = setInterval(() => {
        setRemainingTimeInSeconds((prevRemainingTimeInSeconds) => {
          if (prevRemainingTimeInSeconds > 0) {
            return prevRemainingTimeInSeconds - 1;
          } else if (prevRemainingTimeInSeconds === 0) {
            clearInterval(intervalId);
            return 0;
          } else {
            return 0;
          }
        });

        // if remaining time in seconds is equal to 0, then call updateBookedStatus to send request to web socket connection
        if (remainingTimeInSeconds === 0) {
          updateBookedStatus(2);
        }
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [remainingTimeInSeconds, updateBookedStatus]);

  // rendering of readable timer
  const renderTimer = useCallback(() => {
    const minutes = Math.floor((remainingTimeInSeconds % 3600) / 60);
    const remainingSeconds = Math.round(remainingTimeInSeconds % 60);

    if (minutes === 0 && remainingSeconds === 0) {
      return ``;
    } else {
      return `[ ${String(minutes).padStart(2, "0")} : ${String(
        remainingSeconds
      ).padStart(2, "0")} ]`;
    }
  }, [remainingTimeInSeconds]);

  return (
    <>
      <button
        type="button"
        className="w-full rounded-md bg-dealogikal-100 px-3.5 py-4 text-sm font-semibold text-white shadow-sm sm:hover:bg-dealogikal-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-2/3"
        onClick={props.confirmClickHandler}
      >
        {`Confirm Order ${renderTimer()}`}
      </button>
    </>
  );
};

export default ButtonWithTimerComponent;
