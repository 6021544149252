import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
  CubeIcon,
  RectangleGroupIcon,
  Square3Stack3DIcon,
} from "@heroicons/react/24/outline";
import ButtonComponent from "../../../templates/ButtonComponent/ButtonComponent";
import ProductType from "../../../../model/types/ProductType";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import {
  formatNumberWithComma,
  getTokenFromLocalStorage,
} from "../../../../redux/functions/function";
import {
  getProductDetails,
  getProductList,
} from "../../../../redux/functions/API";
import { useAppSelector, useAppDispatch } from "../../../../redux/app/hooks";
import {
  getUtilities,
  setUtilityProductList,
  setProductDetails,
  setProductPageAction,
} from "../../../../redux/functions/storageSlice";
import { TokenModel } from "../../../../model/interface/TokenModel";
import jwt_decode from "jwt-decode";
import Skeleton from "react-loading-skeleton";
import 'react-loading-skeleton/dist/skeleton.css'

const ProductListComp: React.FC = () => {
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const navigate = useNavigate();
  const utilities = useAppSelector(getUtilities);
  const dispatch = useAppDispatch();

  // cja: flag for the active nav item
  const [activeNav, setActiveNav] = useState<string>("Petroleum");
  const [productList, setProductList] = useState<ProductType[]>(
    utilities.productList
  );
  const [isProductListLoading, setIsProductListLoading] = useState(true);

  // DC: setup table header columns
  const tableHeader = [
    {
      name: "commodity_name",
      value: "Name",
      icon: CubeIcon,
    },
    {
      name: "stocksWithUnits",
      value: "Stocks",
      icon: Square3Stack3DIcon,
    },
    {
      name: "commodity_type",
      value: "Category",
      icon: RectangleGroupIcon,
    },
  ];

  const setActiveNavTab = (active_item: string) => {
    setActiveNav(active_item);
  };
  // cja: this array of objects will be passed in the
  // NavTab component for populating the nav items
  const nav_items = [
    {
      text: "Petroleum",
    },
    {
      text: "Non-Petroleum",
    },
  ];

  // check if utilities did not exist from redux
  useEffect(() => {
    // if commodity types is empty
    if (productList.length === 0 && isProductListLoading) {
      const supplier_id: string = userData._id.toString();
      getProductList(_token, 2, supplier_id).then((data: any) => {
        setIsProductListLoading(false);

        // add stocksWithUnits field to an array
        const finalData = data.map((i: ProductType) => ({
          ...i,
          stocksWithUnits: `${formatNumberWithComma(i.stocks)} ${i.unit_name}`,
        }));
        setProductList(finalData);
        dispatch(setUtilityProductList(finalData));
      });
    } else {
      // removed the loading
      setIsProductListLoading(false);
    }
  }, [dispatch, productList, userData._id, _token, isProductListLoading]);

  const rowClickHandler = (product_id: any) => {
    const supplier_id: string = userData._id.toString();
    getProductDetails(_token, supplier_id, product_id)
      .then((response: any) => {
        if (response.statusCode === 200) {
          let productDetails: ProductType = {
            product_id: response.data.items[0].product_id,
            commodity_id: response.data.items[0].commodity_id,
            commodity_type_id: response.data.items[0].commodity_type_id,
            commodity_type: response.data.items[0].commodity_type,
            commodity_name: response.data.items[0].commodity_name,
            unit_name: response.data.items[0].unit_name,
            stocks: response.data.items[0].stocks,
          };

          dispatch(setProductDetails(productDetails));
          dispatch(setProductPageAction("edit"));
          navigate(`/s/add_single_product`);
        }
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <BreadcrumbContainerComponent
              key={Math.random()}
              subtitle="My Products"
            />
            {/* ------------------------------------------------------------------------------------------------------------------------------------------------------------------ */}
            <NavTabComponent
              key={Math.random()}
              nav_type="primary"
              active_tab={activeNav}
              nav_items={nav_items}
              setActiveNavHandler={setActiveNavTab}
              left_content={
                <></>
                // <div className="relative rounded-md md:py-0 py-2 md:mt-0 mt-2 w-full">
                //   <input
                //     type="text"
                //     name="account-number"
                //     id="account-number"
                //     className="block w-full rounded-3xl border-0 md:px-6 px-4 md:py-4 py-3.5 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 md:text-sm text-xs sm:leading-6"
                //     placeholder="Search products"
                //   />
                //   <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center md:pr-4 pr-5">
                //     <MagnifyingGlassIcon
                //       className="md:h-5 md:w-5 h-4 w-4 text-gray-400"
                //       aria-hidden="true"
                //     />
                //   </div>
                // </div>
              }
              right_content={
                <>
                  {productList.length > 0 && (
                    <ButtonComponent
                      text="Add Product"
                      utils="bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-3.5 md:my-0 mb-5 float-right sm:hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-1/3 w-full"
                      onClick={() => {
                        dispatch(
                          setProductDetails({
                            commodity_name: "",
                            commodity_type: "",
                            product_id: 0,
                            stocks: "",
                            commodity_id: 0,
                            commodity_type_id: 0,
                            unit_name: "",
                          })
                        );
                        dispatch(setProductPageAction("add"));
                        navigate("/s/choose_commodity");
                      }}
                    />
                  )}
                </>
              }
            />
            <div className="flex flex-wrap justify-center">
              {/* single card (start) */}
              <div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
                {activeNav === "Petroleum" && (
                  <>
                    {isProductListLoading ? (
                      <div className="-my-2">
                        <Skeleton height={40} count={1} />
                        <Skeleton height={40} count={5} />
                      </div>
                    ) : (
                      <TableComponent
                        tableHeader={tableHeader}
                        tableData={productList}
                        currentPage={1} // DC: static for now, lets make it dynamic soon
                        rowsPerPage={10} // DC: static for now, lets make it dynamic soon
                        hasRowClick={true}
                        idStringProperty="product_id"
                        handleRowClick={rowClickHandler}
                        emptyState={{
                          primaryMessage: "No products available.",
                          secondaryMessage:
                            "Get started by creating a new product.",
                          buttonText: "Add New Product",
                          buttonOnClick() {
                            navigate("/s/choose_commodity");
                          },
                        }}
                      />
                    )}
                  </>
                )}
                {activeNav === "Non-Petroleum" && (
                  <>
                    <div className="mx-auto md:mt-10 mt-8 text-gray-400">
                      <span className="md:text-sm text-xs">Coming Soon...</span>
                    </div>
                  </>
                )}
              </div>
              {/* single card (end) */}
            </div>
          </>
        }
      />
    </>
  );
};
export default ProductListComp;
