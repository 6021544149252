import { TokenModel } from "../../../model/interface/TokenModel";
import {
  getDefaultBankCutOffMessage,
  getDefaultOperationCutOffMessage,
  getTokenFromLocalStorage,
} from "../../../redux/functions/function";
import jwt_decode from "jwt-decode";
import ReverseAuctionTimerComponent from "../TimerComponent/ReverseAuctionTimerComponent";

interface ReverseAuctionBannerComponentModel {
  margin_top?: string;
  auction_status?: number;
  remaining_time_in_seconds: number;
  is_remaining_time_loading: boolean;
  remaining_opening_time?: number;
  is_remaining_opening_time_loading?: boolean;
  setDeliveryDateModalOpen: (val: boolean) => void;
}

const ReverseAuctionBannerComponent: React.FC<
  ReverseAuctionBannerComponentModel
> = (props) => {
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);
  const renderLeftSideBannerContent = () => {
    if (props.auction_status === 1 || props.auction_status === undefined) {
      return (
        <>
          <div
            className={`flex flex-col w-full ${
              props.auction_status === undefined ? "md:w-full" : ""
            } items-center md:items-start `}
          >
            <span
              className={`${
                props.remaining_time_in_seconds === 0 &&
                props.auction_status === undefined
                  ? "text-white"
                  : "text-black"
              } font-bold ${
                props.auction_status === undefined ? "text-sm" : "sm:text-base"
              }`}
            >
              {getDefaultOperationCutOffMessage("2:00 PM")}
            </span>
          </div>
          <div
            className={`flex flex-col w-full  ${
              props.auction_status === undefined ? "md:w-full" : ""
            } items-center md:items-start lg:items-center`}
          >
            <span
              className={`${
                props.remaining_time_in_seconds === 0 &&
                props.auction_status === undefined
                  ? "text-white"
                  : "text-black"
              } font-bold ${
                props.auction_status === undefined ? "text-sm" : "sm:text-base"
              }`}
            >
              {getDefaultBankCutOffMessage("3:00 PM")}
            </span>
          </div>
        </>
      );
    } else if (props.auction_status === 2) {
      return (
        <>
          <div className="flex flex-col w-full md:w-1/3 items-center"></div>
          <div className="flex flex-col w-full md:w-3/3 items-center">
            <span className="text-xl text-white font-semibold mb-2">
              {`Active Quotation will resume in `}
            </span>
            <ReverseAuctionTimerComponent
              auction_status={props.auction_status}
              seconds={props.remaining_time_in_seconds}
              isTimeLoading={props.is_remaining_time_loading}
            />
          </div>
        </>
      );
    } else if (props.auction_status === 3 && userData.role !== 7) {
      return (
        <>
          <div className="flex flex-col w-full md:w-1/3 items-center"></div>
          <div className="flex flex-col w-full md:w-3/3 items-center">
            <span className="text-2xl md:text-xl lg:text-xl text-white font-semibold">
              Active Quotation Closed
            </span>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="flex flex-col w-full md:w-1/3 items-center"></div>
          <div className="flex flex-col w-full md:w-1/3 items-center">
            <span>{userData.role === 7 ? 'Currently Expired Quotation' : 'Loading...'}</span>
          </div>
        </>
      );
    }
  };

  const renderRightSideBannerContent = () => {
    if (props.auction_status !== undefined) {
      if (props.auction_status === 1) {
        return (
          <div className="inline-flex w-full md:w-1/3 justify-center ">
            <ReverseAuctionTimerComponent
              seconds={props.remaining_time_in_seconds}
              isTimeLoading={props.is_remaining_time_loading}
              auction_status={props.auction_status}
            />
          </div>
        );
      } else if (props.auction_status === 2) {
        return (
          <div className="inline-flex w-full md:w-1/3 justify-end pr-24"></div>
        );
      } else if (props.auction_status === 3) {
        return ( (userData.role === 1 || userData.role === 7) &&
          <div className="inline-flex w-full md:w-1/3 justify-center">
            <button
              type="button"
              className="rounded-full bg-red-700 text-white px-6 py-2 text-sm font-semibold shadow-sm 
                         sm:hover:bg-red-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 "
              onClick={() => props.setDeliveryDateModalOpen(true)}
            >
              Revive Auction
            </button>
          </div>
        );
      } else {
        return (
          <div className="inline-flex w-full md:w-1/3 justify-end pr-24">
            <span>Loading...</span>
          </div>
        );
      }
    } else {
      switch (props.remaining_time_in_seconds) {
        case 0:
          return (
            <div
              className={`inline-flex w-full ${
                props.auction_status === undefined
                  ? "md:w-full justify-center mt-2"
                  : "md:w-1/3 justify-end pr-24"
              }`}
            >
              <span className="bg-red-700 text-white font-semibold text-sm rounded-full px-6 py-2">
                Auction Close
              </span>
            </div>
          );
        default:
          return (
            <div
              className={`inline-flex w-full ${
                props.auction_status === undefined
                  ? "md:w-full justify-center mt-3"
                  : "md:w-1/3 justify-end pr-24"
              }`}
            >
              <ReverseAuctionTimerComponent
                seconds={props.remaining_time_in_seconds}
                isTimeLoading={props.is_remaining_time_loading}
              />
            </div>
          );
      }
    }
  };

  const renderBackgroundColor = (seconds: number) => {
    // if done fetching the remaining time
    /*
      Active Quotation Status ID Legend:
        1 - In Progress
        2 - Waiting
        3 - Expired
        4 - Completed
    */

    /*
      Timer Background (bg-color) Legend:
        bg-gray-100 - 8:00 AM - 10:59 AM; Auction is On-going
        bg-orange-50 - 11:00 AM - 12:59 PM; Auction is On-going
        bg-orange-100 - 01:00 PM - 01:59 PM; Auction is On-going
        bg-red-400 - 02:00 PM; Auction is expired; Auction is being revived & In waiting status
    */

    if (!props.is_remaining_time_loading) {
      if (props.auction_status !== undefined) {
        if (props.auction_status === 1) {
          if (seconds >= 10800) {
            return "bg-gray-100";
          } else if (seconds >= 7200 && seconds < 10800) {
            return "bg-orange-50";
          } else if (seconds >= 3600 && seconds < 7200) {
            return "bg-orange-100";
          } else if (seconds > 0 && seconds < 3600) {
            return "bg-red-100";
          } else if (seconds === 0) {
            return "bg-red-400";
          } else {
            return "invalid";
          }
        } else if (props.auction_status === 2) {
          return "bg-red-400";
        } else if (props.auction_status === 3) {
          return "bg-red-400";
        }
      } else {
        if (seconds >= 10800) {
          return "bg-gray-100";
        } else if (seconds >= 7200 && seconds < 10800) {
          return "bg-orange-50";
        } else if (seconds >= 3600 && seconds < 7200) {
          return "bg-orange-100";
        } else if (seconds > 0 && seconds < 3600) {
          return "bg-red-100";
        } else if (seconds === 0) {
          return "bg-red-400";
        } else {
          return "invalid";
        }
      }
    }
    // if still fetching the remaining time
    else {
      return "bg-gray-100";
    }
  };

  return (
    <>
      <div
        className={`flex flex-wrap items-center content-center justify-center md:flex md:gap-10 lg:gap-0  ${
          props.margin_top
        } ${renderBackgroundColor(
          props.remaining_time_in_seconds
        )} duration-1000 ${
          props.auction_status === undefined ? "py-3" : "py-6"
        } w-full`}
      >
        <div className="md:items-start lg:flex lg:w-[45rem] pl-5">
          {renderLeftSideBannerContent()}
        </div>
        {renderRightSideBannerContent()}
      </div>
    </>
  );
};

export default ReverseAuctionBannerComponent;
