import NavTabItemType from "../../../model/types/NavTabItemType";
import NavTabPropsModel from "../../../model/interface/NavTabPropsModel";
import { Fragment } from "react";
import { generateKey } from "../../../redux/functions/function";

const NavTabComponent: React.FC<NavTabPropsModel> = (props) => {
  return (
    <>
      <div className="flex flex-wrap">
        <div className="flex flex-col w-full md:mt-0 mt-0.5 md:pl-2 md:w-1/3">
          {props.left_content}
        </div>
        <div className="flex flex-col w-full md:mt-0 mt-0.5 md:pl-2 md:w-4/6 md:items-end items-center">
          {props.right_content}
        </div>
      </div>
      <div className="flex flex-wrap justify-center md:mt-6">
        {/* single card (start) */}
        <div className="flex flex-col w-full md:mt-0 mt-0.5 md:pl-2 md:w-full">
          <div className="table w-full pb-2 border-b-2 border-gray-100">
            <div className="table-row-group">
              <div className="table-row">
                <div className="table-cell align-middle w-full">
                  {props.nav_items.length !== 0 &&
                    props.nav_items.map((item: NavTabItemType) => {
                      return (
                        <Fragment key={generateKey(item.text + Math.random())}>
                          {props.nav_type === "primary" && (
                            <span
                              key={Math.random()}
                              className={`${
                                item.text === props.active_tab
                                  ? "border-b-2 border-dealogikal-100 text-dealogikal-100"
                                  : "text-gray-300"
                              } py-3 cursor-pointer md:text-sm text-xs duration-50 px-4`}
                              onClick={() =>
                                props.setActiveNavHandler(item.text)
                              }
                            >
                              <span>{item.text}</span>
                              {item.icon}
                            </span>
                          )}
                          {props.nav_type === "secondary" && (
                            <span
                              key={Math.random()}
                              className={`${
                                item.text.includes(props.active_tab)
                                  ? "border-b-2 border-dealogikal-100 text-dealogikal-100"
                                  : "text-gray-300"
                              } py-3 cursor-pointer md:text-sm text-xs duration-50 px-4`}
                              onClick={() =>
                                props.setActiveNavHandler(item.text)
                              }
                            >
                              <span>{item.text}</span>
                            </span>
                          )}
                        </Fragment>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* single card (end) */}
      </div>
    </>
  );
};

export default NavTabComponent;
