import { useNavigate } from "react-router-dom";
import PageContentComponent from "../templates/PageContentComponent/PageContentComponent";
import { getTokenFromLocalStorage } from "../../redux/functions/function";
import jwt_decode from "jwt-decode";
import { TokenModel } from "../../model/interface/TokenModel";

const PageNotFound = () => {
  // declare navigation here
  const navigate = useNavigate();
  // get and decode the token from local storage
  const _token = getTokenFromLocalStorage() ?? "";
  const userData: TokenModel = jwt_decode(_token);

  const identifyRedirection = () => {
    switch (userData.role) {
      case 1:
        navigate("/b/dashboard");
        break;
      case 2:
        navigate("/s/dashboard");
        break;
      case 6:
        navigate("/l/dashboard");
        break;
      case 7:
        navigate("/a/dashboard");
        break;
      default:
        break;
    }
  };

  return (
    <>
      <PageContentComponent
        content={{
          content: (
            <main className="relative isolate h-screen">
              <img
                src="https://images.unsplash.com/photo-1545972154-9bb223aac798?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=3050&q=80&exp=8&con=-15&sat=-75"
                alt=""
                className="absolute inset-0 -z-10 h-full w-full object-cover object-top"
              />
              <div className="mx-auto max-w-7xl px-6 py-32 text-center sm:py-40 lg:px-8">
                <p className="text-base font-semibold leading-8 text-white">
                  404
                </p>
                <h1 className="mt-4 text-3xl font-bold tracking-tight text-white sm:text-5xl">
                  Page not found
                </h1>
                <p className="mt-4 text-base text-white/70 sm:mt-6">
                  {"Sorry, we couldn’t find the page you’re looking for."}
                </p>
                <div className="mt-10 flex justify-center">
                  <span
                    onClick={identifyRedirection}
                    className="text-sm font-semibold leading-7 cursor-pointer text-black bg-white px-6 py-4 rounded-3xl"
                  >
                    <span aria-hidden="true">&larr;</span> Back to home
                  </span>
                </div>
              </div>
            </main>
          ),
        }}
      />
    </>
  );
};
export default PageNotFound;
