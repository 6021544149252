import { generateKey } from "../../../redux/functions/function";
import MetaComponentModel from "../../../model/interface/MetaComponentModel";
import MetaType from "../../../model/types/MetaType";
const MetaComponent: React.FC<MetaComponentModel> = (props) => {
  return (
    <>
      <div
        className={`flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6 sm:self-${props.placement} self-start`}
      >
        {props.meta_data.map((meta: MetaType) => {
          return (
            <div
              key={generateKey(Math.random().toString() + meta.text)}
              className=" flex items-center text-sm mt-2 sm:mt-0 ml-0 sm:ml-2.5"
            >
              {meta.icon}
              {meta.text} {" "}
              {meta.text2}
            </div>
          );
        })}
      </div>
    </>
  );
};

export default MetaComponent;
