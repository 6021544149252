import { MapPinIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import DeliveryIslandLocationModel from '../../../../../model/interface/DeliveryIslandLocationModel';
import { getPhilippineIslandsAPI } from '../../../../../redux/functions/API';
import { getTokenFromLocalStorage } from '../../../../../redux/functions/function';

const ShippingDetailsComponent = () => {
    const _token = getTokenFromLocalStorage() ?? "";
    const [isDeliveryLocationEmpty, setIsDeliveryLocationEmpty] = useState<boolean>(false);
    const [selectedLocationIsland, setSelectedLocationIsland] = useState<DeliveryIslandLocationModel>({
        id: "",
        island: ""
    });
    const [philippineIslands, setPhilippineIslands] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            if (philippineIslands.length === 0) {
                try {
                    const islands: any = await getPhilippineIslandsAPI(_token);
                    setPhilippineIslands(islands);
                    // Assuming you want to set the selectedLocationIsland to the first island received
                    // Or implement your logic to select a specific island
                    if (islands.length > 0) {
                        setSelectedLocationIsland({
                            id: islands[0].id, // Adjust according to your data structure
                            island: islands[0].island // Adjust according to your data structure
                        });
                    }
                } catch (error: any) {
                    console.error(error);
                    setIsDeliveryLocationEmpty(true);
                }
            }
        };
        fetchData();
    }, [_token, philippineIslands.length]);

    return (
        <div>
            <label
                htmlFor='txtlocation'
                className='block text-sm font-medium leading-6 text-gray-900 mt-16'
            >
                <div className="flex gap-2">
                    <MapPinIcon className="-ml-0.5 h-5 w-5" />
                    <span>Set Delivery Location</span>
                </div>
            </label>
            <div className="inline-block mt-2">
                <input
                    id="txtLocation"
                    type="text"
                    disabled={true}
                    value={selectedLocationIsland.island || ""}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    placeholder="Set delivery zone"
                />
            </div>
            {isDeliveryLocationEmpty && (
                <div className="ml-6 flex mt-3">
                    <p className="text-red-600 text-xs md:text-sm">
                        Delivery location details field is required
                    </p>
                </div>
            )}
        </div>
    )
}

export default ShippingDetailsComponent