import {
  HandThumbUpIcon,
  ChatBubbleBottomCenterTextIcon,
} from "@heroicons/react/20/solid";
import { classNames } from "../../../redux/functions/function";

const tabs = [
  { name: "Offer Details", href: "#", icon: HandThumbUpIcon, current: true },
  {
    name: "Chat",
    href: "#",
    icon: ChatBubbleBottomCenterTextIcon,
    current: false,
  },
];

export default function TabComponent() {
  return (
    <div className="block">
      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8" aria-label="Tabs">
          {tabs.map((tab) => (
            <a
              key={tab.name}
              href={tab.href}
              className={classNames(
                tab.current
                  ? "border-dealogikal-200 text-dealogikal-200 bg-blue-50 rounded-t-lg"
                  : "border-transparent text-gray-500 sm:hover:border-gray-300 sm:hover:text-gray-700",
                "group inline-flex items-center border-b-2 py-2 md:py-3 px-8 text-xs md:text-sm font-medium"
              )}
              aria-current={tab.current ? "page" : undefined}
            >
              <tab.icon
                className={classNames(
                  tab.current
                    ? "text-dealogikal-200"
                    : "text-gray-400 group-sm:hover:text-gray-500",
                  "-ml-0.5 mr-2 h-4 w-4 md:h-5 md:w-5"
                )}
                aria-hidden="true"
              />
              <span>{tab.name}</span>
            </a>
          ))}
        </nav>
      </div>
    </div>
  );
}
