import { classNames } from "../../redux/functions/function";
import { testimonials, featuredTestimonial } from "./TestimonialsData";
import styles from "./Testimonials.module.css";
import { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";

const Testimonials = () => {
  const {
    ref: testimonialsSection1Ref,
    inView: isTestimonialsSection1Intersect,
  } = useInView({ triggerOnce: true });

  const {
    ref: testimonialsSection2Ref,
    inView: isTestimonialsSection2Intersect,
  } = useInView({ triggerOnce: true });

  // useStates if the intersect observer status
  const [isTestimonialSection1Triggered, setIsTestimonialSection1Triggered] =
    useState<boolean>(false);
  const [isTestimonialSection2Triggered, setIsTestimonialSection2Triggered] =
    useState<boolean>(false);

  // useEffects serves as callbacks of the intersect observer
  useEffect(() => {
    setIsTestimonialSection1Triggered(isTestimonialsSection1Intersect);
  }, [isTestimonialsSection1Intersect]);
  useEffect(() => {
    setIsTestimonialSection2Triggered(isTestimonialsSection2Intersect);
  }, [isTestimonialsSection2Intersect]);

  return (
    <div
      className="relative isolate bg-white pb-32 pt-24 sm:pt-32"
      ref={testimonialsSection1Ref}
    >
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-xl text-center">
          <h2
            className={`text-lg leading-8 tracking-tight text-gray-400 ${
              !isTestimonialSection1Triggered ? styles.opacity_0 : ""
            } ${isTestimonialsSection1Intersect ? styles.bottom2top_2s : ""}`}
          >
            Testimonials
          </h2>
          <p
            className={`mt-2 text-3xl font-meduim tracking-tight text-gray-900 sm:text-4xl ${
              !isTestimonialSection1Triggered ? styles.opacity_0 : ""
            } ${isTestimonialsSection1Intersect ? styles.bottom2top_2s : ""}`}
          >
            See why users love{" "}
            <span className="text-dealogikal-100">Dealogikal</span> Platform
          </p>
        </div>
        <div
          className="mx-auto mt-16 grid max-w-2xl grid-cols-1 grid-rows-1 gap-8 text-sm leading-6 text-gray-900 sm:mt-20 sm:grid-cols-2 xl:mx-0 xl:max-w-none xl:grid-flow-col xl:grid-cols-4"
          ref={testimonialsSection2Ref}
        >
          <figure
            className={`col-span-2 hidden sm:block sm:rounded-2xl sm:bg-white sm:shadow-lg sm:ring-1 sm:ring-gray-900/5 xl:col-start-2 xl:row-end-1 ${
              !isTestimonialSection2Triggered ? styles.opacity_0 : ""
            } ${isTestimonialsSection2Intersect ? styles.bottom2top_2s : ""}`}
          >
            <blockquote className="p-12 text-xl font-semibold leading-8 tracking-tight text-gray-900">
              <p>{`“${featuredTestimonial.body}”`}</p>
            </blockquote>
            <figcaption className="flex items-center gap-x-4 border-t border-gray-900/10 px-6 py-4">
              <img
                className="h-10 w-10 flex-none rounded-full bg-gray-50"
                src={featuredTestimonial.author.imageUrl}
                alt=""
              />
              <div className="flex-auto">
                <div className="font-semibold">
                  {featuredTestimonial.author.name}
                </div>
                <div className="text-gray-600">{`@${featuredTestimonial.author.handle}`}</div>
              </div>
              <img
                className="h-10 w-auto flex-none"
                src={featuredTestimonial.author.logoUrl}
                alt=""
              />
            </figcaption>
          </figure>
          {testimonials.map((columnGroup: any, columnGroupIdx: any) => (
            <div
              key={columnGroupIdx}
              className="space-y-8 xl:contents xl:space-y-0"
            >
              {columnGroup.map((column: any, columnIdx: any) => (
                <div
                  key={columnIdx}
                  className={classNames(
                    (columnGroupIdx === 0 && columnIdx === 0) ||
                      (columnGroupIdx === testimonials.length - 1 &&
                        columnIdx === columnGroup.length - 1)
                      ? "xl:row-span-2"
                      : "xl:row-start-1",
                    "space-y-8"
                  )}
                >
                  {column.map((testimonial: any) => (
                    <figure
                      key={testimonial.author.handle}
                      className={`rounded-2xl bg-white p-6 shadow-lg ring-1 ring-gray-900/5 ${
                        !isTestimonialSection2Triggered ? styles.opacity_0 : ""
                      } ${
                        isTestimonialsSection2Intersect
                          ? styles.bottom2top_3s
                          : ""
                      }`}
                    >
                      <blockquote className="text-gray-900">
                        <p>{`“${testimonial.body}”`}</p>
                      </blockquote>
                      <figcaption className="mt-6 flex items-center gap-x-4">
                        <img
                          className="h-10 w-10 rounded-full bg-gray-50"
                          src={testimonial.author.imageUrl}
                          alt=""
                        />
                        <div>
                          <div className="font-semibold">
                            {testimonial.author.name}
                          </div>
                          <div className="text-gray-600">{`@${testimonial.author.handle}`}</div>
                        </div>
                      </figcaption>
                    </figure>
                  ))}
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Testimonials;
