import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import MainConsoleLayoutComponent from "../../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import TableComponent from "../../../templates/TableComponent/TableComponent";
import NavTabComponent from "../../../templates/NavTabComponent/NavTabComponent";
import {
  ClipboardIcon,
} from "@heroicons/react/24/outline";
import LogRateListType from "../../../../model/types/LogRateListType";
import VehicleDetailsComponent from "./rate_details/VehicleDetailsComponent";
import ShippingDetailsComponent from "./rate_details/ShippingDetailsComponent";
import CVDetailsComponent from "./rate_details/CVDetailsComponent";
import { getTokenFromLocalStorage } from "../../../../redux/functions/function";
import { getRateOrderListAPI } from "../../../../redux/functions/API";

const LogisticRateComp = () => {
  const _token = getTokenFromLocalStorage() ?? "";
  const navigate = useNavigate();
  const { rate_details_id } = useParams();
  const [rateOrderList, setRateOrderList] = useState<LogRateListType[]>([]);
  const [isOrderListFetching, setIsOrderListFetching] = useState<boolean>(true);

  const nav_items = [
    {
      text: "Active",
    },
    {
      text: "Rate Details",
    },
  ];

  useEffect(() => {
    if (isOrderListFetching) {
      getRateOrderListAPI(_token, rate_details_id ?? "").then(
        (response: any) => {
          setRateOrderList(response.data.items);
          setIsOrderListFetching(false);
        }
      );
    }
  }, [isOrderListFetching, _token, rate_details_id]);

  const tableHeader = [
    {
      name: "company_name",
      value: "Company Name",
      icon: ClipboardIcon,
    },
    {
      name: "delivery_location",
      value: "Delivery Loc.",
      icon: ClipboardIcon,
    },
    {
      name: "delivery_date",
      value: "Delivery Date",
      icon: ClipboardIcon,
    },
    {
      name: "reverse_auction_status",
      value: "Reverse Auc. Stat.",
      icon: ClipboardIcon,
    },
  ];

  const [activeNav, setActiveNav] = useState<string>("Active");

  const viewReverseAuctionDetails = (reverse_auction_id: string) => {
    navigate(`/l/reverse_auction/${reverse_auction_id}`);
  };

  //-----------------------------------------------------------------------------
  const tabsData = [
    {
      name: "Vehicle Details",
      tabname: "vehicle_details",
    },
    {
      name: "Shipping Details",
      tabname: "shipping_details",
    },
    {
      name: "CV Details",
      tabname: "cv_details",
    },
  ];
 
  const [activeTab, setActiveTab] = useState(0);

  return (
    <MainConsoleLayoutComponent
      content={
        <>
          <BreadcrumbContainerComponent
            key={Math.random()}
            subtitle="Logistic Rate 1"
          />
          {/**--------------------------------------------------------------------------------------------------------------------------*/}
          <NavTabComponent
            key={Math.random()}
            nav_type="primary"
            active_tab={activeNav}
            nav_items={nav_items}
            setActiveNavHandler={setActiveNav}
           
            right_content
          />
          <div className="flex flex-wrap justify-center">
            <div className="flex flex-col w-full md-full md:pt-2 md:w-full">
              {activeNav === "Active" && (
                <>
                  <TableComponent
                    tableHeader={tableHeader}
                    tableData={rateOrderList}
                    currentPage={1}
                    rowsPerPage={10}
                    hasRowClick={true}
                    idStringProperty="reverse_auction_id" // TODO: change to reverse_auction_id
                    handleRowClick={viewReverseAuctionDetails}
                    emptyState={{
                      primaryMessage: "No Data",
                      secondaryMessage: "Get started",
                      buttonText: "Add Data",
                      buttonOnClick() {
                        navigate("/l/");
                      },
                    }}
                  />
                </>
              )}
              {activeNav === "Rate Details" && (
                <>
                  <div className="border-b border-gray-200">
                    <nav
                      className="-mb-px flex overflow-x-auto"
                      aria-label="Tabs"
                    >
                      {tabsData.map((tab, idx) => {
                        return (
                          <button
                            key={tab.name}
                            className={`
                           ${
                             idx === activeTab
                               ? "border-dealogikal-200 text-dealogikal-100 font-extrabold"
                               : "border-transparent text-gray-500 hover:border-dealogikal-theme-3 hover:text-gray-700 font-medium"
                           }"md:w-1/4 border-b-2 py-4 px-3 md:px-1 text-center text-sm"`}
                            onClick={() => setActiveTab(idx)}
                          >
                            {tab.name}
                          </button>
                        );
                      })}
                    </nav>
                  </div>
                  <div className="pt-4 px-4 md:px-6 lg:px-8">
                    {tabsData[activeTab].tabname === "vehicle_details" && (
                      <VehicleDetailsComponent />
                    )}
                    {tabsData[activeTab].tabname === "shipping_details" && (
                      <ShippingDetailsComponent />
                    )}
                    {tabsData[activeTab].tabname === "cv_details" && (
                      <CVDetailsComponent />
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </>
      }
    />
  );
};

export default LogisticRateComp;
