import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import {
	ArrowPathIcon,
	ArrowUpTrayIcon,
	CalendarDaysIcon,
	CircleStackIcon,
	CloudArrowUpIcon,
	PhotoIcon,
	TagIcon,
	TruckIcon,
	UserGroupIcon,
	UserIcon,
	XCircleIcon,
} from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import CircularLoading from "../../../lottie/circular_loading_theme_1.json";
import TableComponent from "../../templates/TableComponent/TableComponent";
import {
	generateKey,
	getTokenFromLocalStorage,
	isValidUrl,
} from "../../../redux/functions/function";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";
import ButtonComponent from "../../templates/ButtonComponent/ButtonComponent";
import {
	getPaymentListAPI,
	getSalesOrderAPI,
	getSupplierAndLogisticDetailsAPI,
	postPaymentVerification,
	updateSalesOrderAPI,
	uploadImageDisbursement,
} from "../../../redux/functions/API";
import DisbursementListType from "../../../model/types/DisbursementlistType";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
	getCommentDetails,
	getPaymentList,
	setCommentDetails,
	setDisbursementList,
	setForVerificationLists,
	setSupplierAndVendorDetails,
} from "../../../redux/functions/storageSlice";
import ForVerificationListType from "../../../model/types/ForVerificationListType";
import ToasterComponent from "../../templates/ToasterComponent/ToasterComponent";
import SupplierAndVendorType from "../../../model/types/SupplierAndVendorType";
import { useWebSocket } from "../../../services/ws/WebSocketService";
import SalesOrderListType from "../../../model/types/SalesOrderListType";
import DropdownOptionComponent from "../../templates/DropdownOptionComponent/DropdownOptioncomponent";
import DropdownItemType from "../../../model/types/DropdownItemType";
import Lottie from "lottie-react";

const PaymentComp: React.FC = () => {
	// get and decode the token from local storage
	const _token = getTokenFromLocalStorage() ?? "";
	const dispatch = useAppDispatch();
	const paymentList = useAppSelector(getPaymentList);

	const cloudName = "doehyebmw";
	const uploadPreset = "tgwshmyb";
	const commentsDetails = useAppSelector(getCommentDetails);

	const [activeNav, setActiveNav] = useState<string>("For Verification");
	const [paymentListFetching, setPaymentListFetching] = useState<boolean>(true);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [isVendorModalOpen, setIsVendorModalOpen] = useState<boolean>(false);
	const [isSalesOrderModal, setIsSalesOrderModal] = useState<boolean>(false);
	const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
	const [imageValid, setImageValid] = useState(true);

	const [isDisbursementFetching, setIsDisbursementFetching] =
		useState<boolean>(true);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [supplierError, setSupplierError] = useState<string>("");
	const [logisticError, setLogisticError] = useState<string>("");
	const [supplierSuccess, setSupplierSuccess] = useState<string>("");
	const [logisticSuccess, setLogisticSuccess] = useState<string>("");
	const [selectedSupplierFiles, setSelectedSupplierFiles] = useState<File[]>(
		[]
	);
	const [isButtonDisabled, setIsButtonDisabled] = useState(false);
	const [isAddingRate, setIsAddingRate] = useState<boolean>(false);
	const [isLoadingAPI, setIsLoadingAPI] = useState(false);
	const [selectedLogisticFiles, setSelectedLogisticFiles] = useState<File[]>(
		[]
	);
	const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);
	const [isDisbursementAPICalled, setIsDisbursementAPICalled] = useState(false);
	const [isDraggingOver, setIsDraggingOver] = useState<boolean>(false);
	const [selectedImage, setSelectedImage] = useState<string | null>(null);
	const [isModalImage, setIsModalImage] = useState<boolean>(false);
	const [isSupplierAndLogisticFetching, setIsSupplierAndLogisticFetching] =
		useState<boolean>(true);
	const [isPhotoClick, setIsPhotoClick] = useState(false);
	const [isZoomed, setIsZoomed] = useState(false);
	const [selectedOption, setSelectedOption] = useState<string>("");

	const [supplierAndVendorsDetails, setSupplierAndVendorsDetails] = useState<
		SupplierAndVendorType[]
	>([]);

	const [forVerificationList, setForVerificationList] = useState<
		ForVerificationListType[]
	>(paymentList.forVerificationList);
	const [disBursementList, setDisBursementList] = useState<
		DisbursementListType[]
	>(paymentList.disbursementList);

	const [isCommentDetailsEmpty, setIsCommentDetailsEmpty] =
		useState<boolean>(false);
	const [salesOrderList, setSalesOrderList] = useState<SalesOrderListType[]>(
		paymentList.salesOrderList
	);
	const [selectedOrder, setSelectedOrder] = useState<
		SalesOrderListType[] | null
	>(paymentList.salesOrderList);

	const [isSalesOrderListFetching, setIsSalesOrderListFetching] =
		useState<boolean>(true);
	const [isToastOpen, setIsToastOpen] = useState<boolean>(false);
	const [toastMessage, setToastMessage] = useState<string>("");
	const [toastType, setToastType] = useState<string>("");

	const { socket, sendWebSocketMessage } = useWebSocket();

	const selectedPayment = paymentList.forVerificationList.find(
		(list: any) => list.row_id === selectedRowId
	);

	const selectedDisbursement = paymentList.disbursementList.find(
		(list: any) => list.offer_booking_id === selectedRowId
	);

	const vendorAndSupplierDetails = paymentList.supplierAndVendorDetails.find(
		(list: any) => list.offer_booking_id === selectedRowId
	);

	const clearErrors = () => {
		setIsCommentDetailsEmpty(false);
	};

	console.log(clearErrors);

	const fieldChangeHandler = (value: string | any, name: string) => {
		switch (name) {
			case "txtcommentsdetails":
				dispatch(setCommentDetails(value));
				break;
			default:
				break;
		}
	};

	// fetching for verification list
	useEffect(() => {
		if (paymentListFetching) {
			if (paymentList.forVerificationList.length === 0) {
				getPaymentListAPI(_token, 4).then((response: any) => {
					if (response.statusCode === 200) {
						const itemsWithId = response.data.items.map(
							(item: any, index: number) => {
								const formattedAmount = new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "PHP", // Change currency as per your requirement
								}).format(parseFloat(item.totalAmountDue));

								return {
									...item,
									row_id: item.id ?? index,
									totalAmountDue: formattedAmount,
								};
							}
						);
						dispatch(setForVerificationLists(itemsWithId));
						setPaymentListFetching(false);
						// setForVerificationList(itemsWithId);
					} else {
						console.error("Failed to fetch payments:", response.statusMessage);
					}
				});
			} else if (paymentList.forVerificationList.length !== 0) {
				let updatedListOfForVerification: ForVerificationListType[] =
					paymentList.forVerificationList
						.filter(
							(verify: any) =>
								verify.offer_booking_id !== selectedPayment?.offer_booking_id
						)
						.map((verify: any) => ({
							order_number: verify.order_number,
							offer_booking_id: verify.offer_booking_id,
							updated_at: verify.updated_at,
							order_status_id: verify.order_status_id,
							order_status: verify.order_status,
							buyer_id: verify.buyer_id,
							payment_details_id: verify.payment_details_id,
							buyer_name: verify.buyer_name,
							supplier_name: verify.supplier_name,
							logisticName: verify.logisticName,
							buyer_shipping_method: verify.buyer_shipping_method,
							supplier_shipping_method: verify.supplier_shipping_method,
							deposit_slip_url: verify.deposit_slip_url,
							totalAmountDue: verify.totalAmountDue,
							row_id: verify.row_id,
						}));

				setForVerificationList(updatedListOfForVerification);
				setPaymentListFetching(false);
			}
		}
	}, [
		_token,
		dispatch,
		paymentList.forVerificationList,
		paymentListFetching,
		selectedPayment?.offer_booking_id,
	]);

	const nav_items = [
		{
			text: "For Verification",
		},
		{
			text: "Disbursement",
		},
		{
			text: "Confirm SO",
		},
	];

	const tableHeader = [
		{
			name: "order_number",
			value: "Order No.",
			icon: TagIcon,
		},
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "totalAmountDue",
			value: "Total Amount Due",
			icon: CircleStackIcon,
		},
		{
			name: "updated_at",
			value: "Updated Date",
			icon: CalendarDaysIcon,
		},
		{
			name: "order_status",
			value: "Status",
			icon: ArrowPathIcon,
		},
	];

	const tableDisbursementHeader = [
		{
			name: "order_number",
			value: "Order No.",
			icon: TagIcon,
		},
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "buyer_shipping_method",
			value: "Shipping Method",
			icon: TruckIcon,
		},
		{
			name: "totalAmountDue",
			value: "Total Amount",
			icon: CircleStackIcon,
		},
		{
			name: "order_status",
			value: "Supplier Status",
			icon: ArrowPathIcon,
			customRender: (item: any) => (
				<span
					className={
						item.order_status === "Disbursement"
							? "text-red-500"
							: item.order_status === "Verified"
							? "text-green-500"
							: item.order_status === "Unverified"
							? "text-orange-500"
							: ""
					}
				>
					{item.order_status}
				</span>
			),
		},
		{
			name: "logistic_status",
			value: "Logistic Status",
			icon: ArrowPathIcon,
			customRender: (item: any) => (
				<span
					className={
						item.logistic_status === "No logistic"
							? "text-gray-200"
							: item.logistic_status === "Disbursement"
							? "text-red-500"
							: item.logistic_status === "Verified"
							? "text-green-500"
							: item.logistic_status === "Unverified"
							? "text-orange-500"
							: ""
					}
				>
					{item.logistic_status}
				</span>
			),
		},
	];

	const tableSalesOrderHeader = [
		{
			name: "reference_number",
			value: "Order No.",
			icon: UserGroupIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "shipping_method",
			value: "Shipping Method",
			icon: TruckIcon,
		},
		{
			name: "amount",
			value: "Amount",
			icon: TruckIcon,
		},
		{
			name: "is_verified",
			value: "Status",
			icon: ArrowPathIcon,
			customRender: (item: any) => (
				<span className={item.status ? "text-green-500" : "text-orange-500"}>
					{item.status ? "Confirmed" : "For Confirmation"}
				</span>
			),
		},
	];

	useEffect(() => {
		if (activeNav === "Disbursement") {
			if (paymentList.disbursementList.length === 0 && isDisbursementFetching) {
				getPaymentListAPI(_token, 5).then((response: any) => {
					if (response.statusCode === 200) {
						const disbursementId = response.data.items.map(
							(item: any, index: number) => {
								const formattedAmount = new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "PHP", // Change currency as per your requirement
								}).format(parseFloat(item.totalAmountDue));

								// Check conditions and update order_status
								let updatedOrderStatus = item.order_status;
								let updatedLogisticStatus = "No Logistic";
								if (
									item.buyer_shipping_method === 1 &&
									item.supplier_shipping_method === 2
								) {
									updatedOrderStatus = "Disbursement";
									updatedLogisticStatus = "Disbursement";
									if (item.disbursement.length > 0) {
										const supplierDisbursementItem = item.disbursement.find(
											(d: any) => d.user_role_id === 2
										);
										if (supplierDisbursementItem) {
											updatedOrderStatus =
												supplierDisbursementItem.is_verified === 0
													? "Unverified"
													: "Verified";
										}

										const logisticDisbursementItem = item.disbursement.find(
											(d: any) => d.user_role_id === 6
										);
										if (logisticDisbursementItem) {
											updatedLogisticStatus =
												logisticDisbursementItem.is_verified === 0
													? "Unverified"
													: "Verified";
										}
									}
								}

								if (
									item.buyer_shipping_method === 1 &&
									item.supplier_shipping_method === 1
								) {
									updatedLogisticStatus = "No Logistic";
									updatedOrderStatus = "Disbursement";
									if (item.disbursement.length > 0) {
										const disbursementItem = item.disbursement.find(
											(d: any) => d.user_role_id === 2
										);
										if (disbursementItem) {
											updatedOrderStatus =
												disbursementItem.is_verified === 0
													? "Unverified"
													: "Verified";
										}
									}
								}

								if (
									item.buyer_shipping_method === 2 &&
									item.supplier_shipping_method === 2
								) {
									updatedLogisticStatus = "No Logistic";
									updatedOrderStatus = "Disbursement";
									if (item.disbursement.length > 0) {
										const disbursementItem = item.disbursement.find(
											(d: any) => d.user_role_id === 2
										);
										if (disbursementItem) {
											updatedOrderStatus =
												disbursementItem.is_verified === 0
													? "Unverified"
													: "Verified";
										}
									}
								}

								return {
									...item,
									row_id: item.id ?? index,
									totalAmountDue: formattedAmount,
									buyer_shipping_method:
										item.buyer_shipping_method === 2 ? "Pick-up" : "Delivery",
									order_status: updatedOrderStatus,
									logistic_status: updatedLogisticStatus,
								};
							}
						);
						// Update Redux store with new items
						dispatch(
							setDisbursementList([
								...paymentList.disbursementList,
								...disbursementId,
							])
						);
						// dispatch(setDisbursementList(disbursementId));
						setIsDisbursementFetching(false);
					} else {
						console.log("failed to fetch disbursement list");
					}
				});
			} else if (paymentList.disbursementList.length !== 0) {
				let updatedListOfDisbursement: DisbursementListType[] = [];
				// if (Array.isArray(paymentList.disbursementList)) {
				paymentList.disbursementList.forEach((disburse: any) => {
					const newDisburse: DisbursementListType = {
						order_number: disburse.order_number,
						logistic_status: disburse.logistic_status,
						offer_booking_id: disburse.offer_booking_id,
						updated_at: disburse.updated_at,
						order_status_id: disburse.order_status_id,
						order_status: disburse.order_status,
						buyer_id: disburse.buyer_id,
						buyer_name: disburse.buyer_name,
						supplier_name: disburse.supplier_name,
						logisticName: disburse.logisticName,
						buyer_shipping_method: disburse.buyer_shipping_method,
						supplier_shipping_method: disburse.supplier_shipping_method,
						deposit_slip_url: disburse.deposit_slip_url,
						totalAmountDue: disburse.totalAmountDue,
						disbursement: disburse.disbursement,
					};
					updatedListOfDisbursement = [
						...updatedListOfDisbursement,
						newDisburse,
					];
				});
				//  }
				setDisBursementList(updatedListOfDisbursement);
				setIsDisbursementFetching(false);
			}
		}
	}, [
		isDisbursementFetching,
		_token,
		dispatch,
		paymentList.disbursementList,
		activeNav,
	]);

	// fetching sales order list
	useEffect(() => {
		// if (paymentList.salesOrderList.length === 0 &&
		if (isSalesOrderListFetching) {
			getSalesOrderAPI(_token, 7).then((response: any) => {
				if (response.statusCode === 200) {
					const salesOrderData = response.data.items.map(
						(item: any, index: number) => {
							const formattedAmount = new Intl.NumberFormat("en-US", {
								style: "currency",
								currency: "PHP", // Change currency as per your requirement
							}).format(parseFloat(item.calculate_amount));

							return {
								supplier_name: item.supplier_name,
								order_details_id: item.order_details_id,
								offer_bookings_id: item.offer_bookings_id,
								shipping_method:
									item.shipping_method_id === 2 ? "Pick-up" : "Delivery",
								amount: formattedAmount,
								status: item.is_verified,
								supplier_location: item.supplier_location,
								buyer_name: item.buyer_name,
								sales_order_url: item.sales_order_url,
								reference_number: item.reference_number,
							};
						}
					);
					setSalesOrderList(salesOrderData);
					setIsSalesOrderListFetching(false);
				} else {
					console.log("failed to fetch disbursement list");
				}
			});
		}
	}, [isSalesOrderListFetching, _token]);

	// cja: flag for the active nav item
	const [activeSelectProductNav, setActiveSelectProductNav] =
		useState<string>("Supplier");
	// cja: this array of objects will be passed in the
	// NavTab component for populating the nav items
	const selectProductNavItems = [
		{
			text: `Supplier`,
		},
		{
			text: "Logistic",
		},
	];

	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	const [hasReachedTargetLength, setHasReachedTargetLength] = useState(false);

	// checking the disbursement length if it reach 2 or only 1
	useEffect(() => {
		const shippingMethod = selectedDisbursement;

		if (isVendorModalOpen && isSupplierAndLogisticFetching) {
			if (
				shippingMethod?.buyer_shipping_method === "Delivery" &&
				shippingMethod.supplier_shipping_method === 2 &&
				Array.isArray(shippingMethod.disbursement)
			) {
				if (shippingMethod.disbursement.length === 2) {
					setHasReachedTargetLength(true);
				}
			}
			if (
				shippingMethod?.buyer_shipping_method === "Delivery" &&
				shippingMethod.supplier_shipping_method === 1 &&
				Array.isArray(shippingMethod.disbursement)
			) {
				if (shippingMethod.disbursement.length === 1) {
					setHasReachedTargetLength(true);
				}
			}
		}
	}, [isVendorModalOpen, isSupplierAndLogisticFetching, selectedDisbursement]);

	useEffect(() => {
		if (isVendorModalOpen === false) {
			setHasReachedTargetLength(false);
		}
	}, [isVendorModalOpen]);

	const handleImageError = () => {
		setImageValid(false);
	};

	const submitHandler = async () => {
		if (selectedPayment !== undefined) {
			setIsSubmitLoading(true);
			setIsButtonDisabled(true);
			setIsLoadingAPI(true);
			try {
				const payload = {
					offer_booking_id: selectedPayment.offer_booking_id,
					payment_details_id: selectedPayment.payment_details_id,
				};
				postPaymentVerification(_token, payload).then((success: any) => {
					setIsSubmitLoading(false);
					setIsButtonDisabled(false);
					setIsLoadingAPI(false);

					if (success.statusCode === 500) {
						setIsModalOpen(false);
						setIsToastOpen(true);
						setToastMessage("Failed to verify payment!");
						setToastType("error");
						setTimeout(() => {
							setIsToastOpen(false);
						}, 3000);
					}
					if (success.statusCode === 200) {
						setForVerificationList((prevList) =>
							prevList.filter(
								(item) =>
									item.offer_booking_id !== selectedPayment.offer_booking_id
							)
						);

						setIsModalOpen(false);
						setIsToastOpen(true);
						setToastMessage("Successfully submit verification");
						setToastType("success");
						setTimeout(() => {
							setIsToastOpen(false);
						}, 3000);

						const newID = disBursementList.length + 1;
						const newDisbursementList: any = {
							id: newID,
							...paymentList.disbursementList,
						};
						setDisBursementList((prevVerification) => [
							...prevVerification,
							newDisbursementList,
						]);

						// Remove the offer booking ID from the Redux store
						dispatch(
							setForVerificationLists(
								paymentList.forVerificationList.filter(
									(item) =>
										item.offer_booking_id !== selectedPayment.offer_booking_id
								)
							)
						);

						// uncoment this if we need websocket for admin verification
						// if (socket && socket.readyState === WebSocket.OPEN) {
						// 	const socketPayload = {
						// 		title: "Payment Verification",
						// 		message: `Your payment has been verified`,
						// 		user_id: selectedPayment.buyer_id,
						// 		notification_type_id: 1,
						// 		source_id: selectedPayment.offer_booking_id,
						// 	};
						// 	sendWebSocketMessage("sendNotification", socketPayload);
						// }
					}
				});
			} catch (error) {
				console.log(error);
			}
		}
	};

	useEffect(() => {
		if (isVendorModalOpen) {
			setIsSupplierAndLogisticFetching(true);
		}
	}, [isVendorModalOpen]);

	// fetching logistic and supplier s
	useEffect(() => {
		const offerBookingID = selectedDisbursement?.offer_booking_id;
		if (isVendorModalOpen && offerBookingID && isSupplierAndLogisticFetching) {
			getSupplierAndLogisticDetailsAPI(_token, offerBookingID, 5).then(
				(response: any) => {
					if (response.statusCode === 200) {
						const disbursementId = response.data.items.map(
							(item: any, index: number) => {
								const formattedSupplierAmount = new Intl.NumberFormat("en-US", {
									style: "currency",
									currency: "PHP", // Change currency as per your requirement
								}).format(parseFloat(item.supplierAmount));

								return {
									...item,
									row_id: item.id ?? index,
									supplierAmount: formattedSupplierAmount,
								};
							}
						);
						setSupplierAndVendorsDetails(disbursementId);
						dispatch(setSupplierAndVendorDetails(disbursementId));
					}
				}
			);
			setIsSupplierAndLogisticFetching(false);
		}
	}, [
		isSupplierAndLogisticFetching,
		selectedDisbursement?.offer_booking_id,
		isVendorModalOpen,
		_token,
		dispatch,
	]);

	// import or upload image (start) ====================================================>

	const importImageHandler = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const handleSupplierDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);

		const files = Array.from(event.dataTransfer.files);
		handleSupplierFiles(files);
	};

	const handleLogisticDrop = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);

		const files = Array.from(event.dataTransfer.files);
		handleLogisticFiles(files);
	};

	const handleSupplierFileChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = Array.from(event.target.files || []);
		handleSupplierFiles(files);
	};

	const handleLogisticFileChange = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		const files = Array.from(event.target.files || []);
		handleLogisticFiles(files);
	};

	const handleSupplierFiles = (files: File[]) => {
		let totalSize = 0;
		files.forEach((file) => {
			totalSize += file.size;
		});

		const maxSize = 5 * 1024 * 1024; // 5MB in bytes
		if (totalSize > maxSize) {
			setSupplierError(
				"Total file size exceeds 5MB. Please upload smaller files."
			);
			return;
		}

		setSelectedSupplierFiles((prevFiles) => [...prevFiles, ...files]);
		setSupplierError(""); // Reset error if the file size is within the limit
	};

	const handleLogisticFiles = (files: File[]) => {
		let totalSize = 0;
		files.forEach((file) => {
			totalSize += file.size;
		});

		const maxSize = 5 * 1024 * 1024; // 5MB in bytes
		if (totalSize > maxSize) {
			setLogisticError(
				"Total file size exceeds 5MB. Please upload smaller files."
			);
			return;
		}

		setSelectedLogisticFiles((prevFiles) => [...prevFiles, ...files]);
		setLogisticError(""); // Reset error if the file size is within the limit
	};

	const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(true);
	};

	const handleDragLeave = (event: React.DragEvent<HTMLDivElement>) => {
		event.preventDefault();
		setIsDraggingOver(false);
	};

	// handler for remove chip
	const removeChipHandler = (index: number) => {
		const updatedFiles = [...selectedSupplierFiles];
		updatedFiles.splice(index, 1);
		setSelectedSupplierFiles(updatedFiles);
	};

	// handler for showing image
	const showImageImportHandler = (index: number) => {
		setIsVendorModalOpen(false);
		setIsModalImage(true);

		const file = selectedSupplierFiles[index];
		const reader = new FileReader();
		reader.onloadend = () => {
			setSelectedImage(reader.result as string);
		};
		reader.readAsDataURL(file);
	};

	// Function to close selectedImage
	const closeSelectedImage = () => {
		setIsVendorModalOpen(true);
		setSelectedImage(null);
		setIsModalImage(false);
	};

	const onCloseImageShowHandler = () => {
		setIsModalImage(false);
		setIsVendorModalOpen(true);
	};

	const soConfirmationHandler = async () => {
		// Ensure that selectedOrder is not null and has at least one item
		if (selectedOrder && selectedOrder.length > 0) {
			try {
				const updatePromises = selectedOrder.map((order) => {
					const data = {
						order_details_id: order.order_details_id,
						status_id: 1,
						offer_bookings_id: order.offer_bookings_id,
					};

					return updateSalesOrderAPI(_token, data).then((response: any) => {
						if (response.statusCode === 200) {
							console.log("Order updated successfully", response);
						} else {
							console.error("Failed to update order", response);
						}
					});
				});

				// Wait for all updates to complete
				await Promise.all(updatePromises);
			} catch (error) {
				console.error("Error updating sales orders", error);
			}
		} else {
			console.warn("No orders selected");
		}
	};

	// checking if the successfully upload image then if admin close the modal it should remove the successfully upload to supplier/ logistic
	useEffect(() => {
		if (isDisbursementAPICalled === true && isVendorModalOpen === false) {
			setSupplierSuccess("");
			setLogisticSuccess("");
		}
	}, [isDisbursementAPICalled, isVendorModalOpen]);

	// handle for api that upload the image
	const handleDisbursementUpload = async (
		data: any,
		setSuccessMessage: any,
		setErrorMessage: any,
		notificationUserId: any,
		statusType: "order_status" | "logistic_status"
	) => {
		try {
			const response: any = await uploadImageDisbursement(_token, data);
			if (response.statusCode === 200) {
				setIsLoadingAPI(false);
				setIsButtonDisabled(false);
				setIsAddingRate(false);
				// Update Redux store
				dispatch((dispatch) => {
					const updatedList: any = paymentList.disbursementList.map(
						(item: any) =>
							item.offer_booking_id === selectedDisbursement?.offer_booking_id
								? { ...item, [statusType]: "Unverified" }
								: item
					);
					dispatch(setDisbursementList(updatedList));
				});

				setSuccessMessage("Successfully uploaded to Supplier");
				if (socket && socket.readyState === WebSocket.OPEN) {
					const socketPayload = {
						title: "Payment Confirmation",
						message: `Delivery payment of ${selectedDisbursement?.buyer_name} has been deposited.`,
						user_id: notificationUserId,
						notification_type_id: 1,
						source_id: selectedDisbursement?.offer_booking_id,
					};
					sendWebSocketMessage("sendNotification", socketPayload);
				}
			} else if (response.statusCode === 400) {
				setErrorMessage("Error uploading to Cloudinary");
			} else if (response.statusCode === 409) {
				setErrorMessage(
					"Sorry, you have already uploaded disbursement details for this order."
				);
			}
		} catch (error) {
			setErrorMessage("Error uploading to Cloudinary");
		} finally {
			setIsDisbursementAPICalled(true);
		}
	};

	// handler for uploading to cloudinary
	const uploadSupplierReceipthandler = async () => {
		if (selectedSupplierFiles.length > 1) {
			setToastMessage("Please upload one receipt only");
			setToastType("error");
			setIsToastOpen(true);
			setTimeout(() => setIsToastOpen(false), 3000);
			return;
		}
		setIsButtonDisabled(true);
		setIsAddingRate(true);
		setIsLoadingAPI(true);
		const formData = new FormData();
		formData.append("file", selectedSupplierFiles[0]);
		formData.append("upload_preset", uploadPreset); // Your upload preset from Cloudinary

		try {
			const response = await fetch(
				`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
				{
					method: "POST",
					body: formData,
				}
			);
			const dataSupplierReceiptupload = await response.json();

			if (dataSupplierReceiptupload.error) {
				setSupplierError(dataSupplierReceiptupload.error.message);
			} else {
				// setPaymentReceiptSupplierURL(dataSupplierReceiptupload.url);
				setSelectedSupplierFiles([]); // Clear the selected files after upload

				const data = {
					offer_bookings_id: selectedDisbursement?.offer_booking_id,
					user_id: vendorAndSupplierDetails?.supplier_id,
					disbursement_slip_url: dataSupplierReceiptupload.url,
				};
				await handleDisbursementUpload(
					data,
					setSupplierSuccess,
					setSupplierError,
					vendorAndSupplierDetails?.supplier_id,
					"order_status"
				);
			}
		} catch (error) {
			setSupplierError("Error uploading to Cloudinary");
		}
	};

	//

	// handler for uploading receipt for logistic
	const uploadLogisticReceipthandler = async () => {
		if (selectedLogisticFiles.length > 1) {
			setToastMessage("Please upload one receipt only");
			setToastType("error");
			setIsToastOpen(true);
			setTimeout(() => {
				setIsToastOpen(false);
			}, 3000);
			return;
		}
		setIsButtonDisabled(true);
		setIsAddingRate(true);
		setIsLoadingAPI(true);

		const formDatas = new FormData();
		formDatas.append("file", selectedLogisticFiles[0]);
		formDatas.append("upload_preset", uploadPreset); // Your upload preset from Cloudinary

		try {
			const response = await fetch(
				`https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
				{
					method: "POST",
					body: formDatas,
				}
			);
			const dataSupplierReceiptupload = await response.json();

			if (dataSupplierReceiptupload.error) {
				console.log(dataSupplierReceiptupload.error.message);
			} else {
				// setPaymentReceiptLogisticURL(dataSupplierReceiptupload.url);
				setSelectedLogisticFiles([]); // Clear the selected files after upload

				const data: any = {
					offer_bookings_id: selectedDisbursement?.offer_booking_id,
					user_id: vendorAndSupplierDetails?.logistic_id,
					disbursement_slip_url: dataSupplierReceiptupload.url,
				};

				await handleDisbursementUpload(
					data,
					setSupplierSuccess,
					setSupplierError,
					vendorAndSupplierDetails?.logistic_id,
					"logistic_status"
				);
			}
		} catch (error) {
			console.log("Error uploading to Cloudinary");
		}
	};

	const handleImageClick = () => {
		setIsModalOpen(true);
		setIsPhotoClick(true);
	};

	const toggleZoom = () => {
		setIsZoomed((prevZoom) => !prevZoom);
	};

	const handleOrderDetails = () => {
		setIsModalOpen(true);
	};

	const dropdown_items: DropdownItemType[] = [
		{
			item_text: "Confirmed",
			onClick: () => setSelectedOption("Confirmed"),
		},
		{
			item_text: "Reconcile",
			onClick: () => setSelectedOption("Reconcile"),
		},
	];

	const verificationDropdown_items: DropdownItemType[] = [
		{
			item_text: "Verify",
			onClick: () => setSelectedOption("Verify"),
		},
		{
			item_text: "Reconcile",
			onClick: () => setSelectedOption("Reconcile"),
		},
	];
	// import or upload image (end) ====================================================>
	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<ToasterComponent
							isOpen={isToastOpen}
							label={toastMessage}
							onClose={setIsToastOpen}
							type={toastType}
						/>
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Payment"
						/>
						<NavTabComponent
							key={Math.random()}
							nav_type="secondary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
						/>
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "For Verification" && (
									<>
										{paymentListFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={tableHeader}
												tableData={forVerificationList}
												currentPage={1}
												rowsPerPage={10}
												idStringProperty="row_id"
												handleRowClick={(row_id: string) => {
													setSelectedRowId(parseInt(row_id));
													setIsModalOpen(true);
												}}
												emptyState={{
													primaryMessage: "No For Verification list",
													secondaryMessage: "",
													buttonText: "",
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Disbursement" && (
									<>
										{isDisbursementFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={tableDisbursementHeader}
												tableData={disBursementList}
												currentPage={1}
												rowsPerPage={10}
												idStringProperty="offer_booking_id"
												handleRowClick={(offer_booking_id: string) => {
													setSelectedRowId(parseInt(offer_booking_id));
													setIsVendorModalOpen(true);
												}}
											/>
										)}
									</>
								)}
								{activeNav === "Confirm SO" && (
									<>
										{isSalesOrderListFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<TableComponent
												tableHeader={tableSalesOrderHeader}
												tableData={salesOrderList}
												currentPage={1}
												rowsPerPage={10}
												idStringProperty="order_details_id"
												handleRowClick={(order_details_id: string) => {
													const selected = salesOrderList.find(
														(order) =>
															order.order_details_id ===
															parseInt(order_details_id)
													);
													setSelectedRowId(parseInt(order_details_id));
													setSelectedOrder(selected ? [selected] : null);
													setIsSalesOrderModal(true);
												}}
												emptyState={{
													primaryMessage: "No Data",
													secondaryMessage: "Get Started",
													buttonText: "Add Data",
													buttonOnClick() {},
												}}
												// handleRowClick={(offer_booking_id: string) => {
												// 	setSelectedRowId(parseInt(offer_booking_id));
												// 	setIsVendorModalOpen(true);
												// }}
											/>
										)}
									</>
								)}
							</div>
						</div>

						{/* modal for disbursement details */}
						<ModalComponent
							isOpen={isVendorModalOpen}
							onCloseHandler={() => {
								setIsVendorModalOpen(false);
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Vendor and Supplier Details
									</span>
								</div>
							}
							body={
								<>
									<div className="md:mx-0 mx-3.5">
										<NavTabComponent
											key={Math.random()}
											nav_type="secondary"
											active_tab={activeSelectProductNav}
											nav_items={selectProductNavItems}
											setActiveNavHandler={setActiveSelectProductNav}
										/>
									</div>

									{selectedDisbursement?.buyer_shipping_method === "Delivery" &&
										selectedDisbursement?.supplier_shipping_method === 2 && (
											<>
												{isSupplierAndLogisticFetching ? (
													<div className="-my-2">
														<Skeleton height={40} count={1} />
														<Skeleton height={40} count={5} />
													</div>
												) : (
													<>
														{activeSelectProductNav.includes("Supplier") && (
															<div className="py-5  mx-5 text-gray-400 ">
																{supplierAndVendorsDetails.map(
																	(supplier: SupplierAndVendorType) => (
																		<div
																			className="grid grid-cols-1 xl:grid-cols-2 gap-4"
																			key={generateKey(
																				supplier.buyer_id.toString() +
																					Math.random()
																			)}
																		>
																			<div className="flex flex-col ">
																				<span className="text-xs">
																					Supplier name
																				</span>
																				<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																					<p className="pl-3 text-gray-700 sm">
																						{supplier.supplier_name}
																					</p>
																				</div>

																				<span className="text-xs mt-10">
																					Delivery Date
																				</span>
																				<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
																					<p className="pl-3 text-gray-700 sm">
																						{/* July 7, 2024 */}
																						{supplier.requested_delivery_date}
																					</p>
																				</div>

																				<button className="mt-10 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200">
																					View Order details
																				</button>
																				{supplier.disbursement
																					.filter(
																						(disburse) =>
																							disburse.user_role_id === 2
																					)
																					.map((disburse, disubrseIndex) => {
																						return (
																							<div key={disubrseIndex}>
																								{disburse.is_verified === 1 && (
																									<>
																										<p className="ml-2 mt-5 text-sm text-green-600">
																											Supplier already verified
																										</p>
																									</>
																								)}
																								{disburse.is_verified === 0 && (
																									<>
																										<p className="ml-2 mt-5 text-sm text-gray-500">
																											Not yet verified....
																										</p>
																									</>
																								)}
																							</div>
																						);
																					})}
																			</div>
																			<div className=" flex flex-col">
																				<span className="text-xs">Amount</span>
																				<div className="bg-gray-100 rounded-md py-3 mt-2 w-full md:w-64 text-xl">
																					<p className="pl-2 text-gray-700">
																						{supplier.supplierAmount}
																					</p>
																				</div>

																				<div className="mt-10">
																					<div
																						className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5 ${
																							isDraggingOver
																								? "bg-gray-400"
																								: ""
																						}`}
																						onDrop={handleSupplierDrop}
																						onDragOver={handleDragOver}
																						onDragLeave={handleDragLeave}
																					>
																						{supplier.disbursement &&
																						supplier.disbursement.length > 0
																							? supplier.disbursement.some(
																									(disburse) =>
																										disburse.user_role_id ===
																											2 &&
																										disburse.disbursement_slip_url
																							  )
																								? supplier.disbursement.map(
																										(disburse, index) =>
																											disburse.user_role_id ===
																												2 &&
																											disburse.disbursement_slip_url ? (
																												<div key={index}>
																													<img
																														src={
																															disburse.disbursement_slip_url
																														}
																														alt="Disbursement Slip"
																														className="mt-5 h-28 w-full px-1.5"
																													/>
																												</div>
																											) : null
																								  )
																								: null
																							: null}

																						{(!supplier.disbursement ||
																							supplier.disbursement.length ===
																								0 ||
																							!supplier.disbursement.some(
																								(disburse) =>
																									disburse.user_role_id === 2 &&
																									disburse.disbursement_slip_url
																							)) &&
																							!hasReachedTargetLength && (
																								<>
																									<CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
																									<h3 className="text-sm">
																										Drag and drop
																									</h3>
																									<p className="text-xs">
																										your images here or{" "}
																										<button
																											className="text-blue-400"
																											onClick={
																												importImageHandler
																											}
																										>
																											upload image...
																										</button>
																									</p>
																									{selectedSupplierFiles.length ===
																									0 ? (
																										<span className="text-gray-400 md:text-xs text-xxs mt-5">
																											-- No file selected --
																										</span>
																									) : (
																										<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5">
																											{selectedSupplierFiles.map(
																												(file, index) => (
																													<div key={index}>
																														<div
																															className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
																															onClick={() =>
																																showImageImportHandler(
																																	index
																																)
																															}
																														>
																															<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
																															<span className="text-xs text-gray-700 truncate w-16">
																																{file.name}
																															</span>
																															<button
																																className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
																																onClick={(
																																	e
																																) => {
																																	e.stopPropagation();
																																	removeChipHandler(
																																		index
																																	);
																																}}
																															>
																																<XCircleIcon className="h-3 w-3" />
																															</button>
																														</div>
																													</div>
																												)
																											)}
																										</div>
																									)}
																									<div className="my-1">
																										<input
																											type="file"
																											ref={fileInputRef}
																											style={{
																												display: "none",
																											}}
																											accept=".jpg, .jpeg, .png"
																											multiple
																											onChange={
																												handleSupplierFileChange
																											}
																										/>
																									</div>
																									{selectedSupplierFiles.length !==
																										0 && (
																										<ButtonComponent
																											text={`${
																												isButtonDisabled
																													? "Uploading ..."
																													: "Upload"
																											}`}
																											icon={
																												isLoadingAPI && (
																													<Lottie
																														className="md:w-5 w-5 h-auto"
																														animationData={
																															CircularLoading
																														}
																														loop={true}
																													/>
																												)
																											}
																											disabled={
																												isButtonDisabled
																											}
																											utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
                    																				hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full
																														${isAddingRate ? "cursor-not-allowed opacity-50" : ""}
																														`}
																											onClick={
																												uploadSupplierReceipthandler
																											}
																										/>
																									)}
																									<span className="text-xs text-gray-500 mt-5">
																										Max size: 5MB
																									</span>
																									{supplierError && (
																										<span className="text-xs text-red-500 mt-2">
																											{supplierError}
																										</span>
																									)}
																									{supplierSuccess && (
																										<span className="text-xs text-green-500 mt-2">
																											{supplierSuccess}
																										</span>
																									)}
																								</>
																							)}
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{/* single card (start) */}
															</div>
														)}
														{activeSelectProductNav.includes("Logistic") && (
															<div className=" py-5 mx-5 text-gray-400 ">
																{supplierAndVendorsDetails.map(
																	(logistic: SupplierAndVendorType) => (
																		<div
																			className="grid grid-cols-1 lg:grid-cols-2 gap-4"
																			key={generateKey(
																				logistic.buyer_id.toString() +
																					Math.random()
																			)}
																		>
																			<div className="flex flex-col">
																				<span className="text-xs">
																					Logistic name
																				</span>
																				<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																					<p className="pl-3 text-gray-700 sm">
																						{logistic.logisticName}
																					</p>
																				</div>

																				<span className="text-xs mt-10">
																					Delivery Date
																				</span>
																				<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
																					<p className="pl-3 text-gray-700 sm">
																						{/* July 7, 2024 */}
																						{logistic.requested_delivery_date}
																					</p>
																				</div>

																				<button
																					className="mt-10 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200"
																					onClick={handleOrderDetails}
																				>
																					View Order details
																				</button>
																				{logistic.disbursement
																					.filter(
																						(disburse) =>
																							disburse.user_role_id === 6
																					)
																					.map((disburse, disubrseIndex) => {
																						return (
																							<div key={disubrseIndex}>
																								{disburse.is_verified === 1 && (
																									<>
																										<p className="ml-2 mt-5 text-sm text-green-600">
																											Logistic already verified
																										</p>
																									</>
																								)}
																								{disburse.is_verified === 0 && (
																									<>
																										<p className="ml-2 mt-5 text-sm text-gray-500">
																											Not yet verified....
																										</p>
																									</>
																								)}
																							</div>
																						);
																					})}
																			</div>
																			<div className=" flex flex-col">
																				{logistic.logisticRate.map(
																					(logisticAmount, logisticI) => {
																						return (
																							<div key={logisticI}>
																								<span className="text-xs">
																									Amount
																								</span>
																								<div className="bg-gray-100 rounded-md py-3 w-full md:w-64 text-lg">
																									<p className="pl-2 text-gray-700">
																										₱{" "}
																										{logisticAmount.frieghtCost}
																									</p>
																								</div>
																							</div>
																						);
																					}
																				)}

																				<div className="mt-10">
																					<div
																						className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5 ${
																							isDraggingOver
																								? "bg-gray-400"
																								: ""
																						}`}
																						onDrop={handleLogisticDrop}
																						onDragOver={handleDragOver}
																						onDragLeave={handleDragLeave}
																					>
																						{logistic.disbursement &&
																						logistic.disbursement.length > 0
																							? logistic.disbursement.some(
																									(disburse) =>
																										disburse.user_role_id ===
																											6 &&
																										disburse.disbursement_slip_url
																							  )
																								? logistic.disbursement.map(
																										(disburse, index) =>
																											disburse.user_role_id ===
																												6 &&
																											disburse.disbursement_slip_url ? (
																												<div key={index}>
																													<img
																														src={
																															disburse.disbursement_slip_url
																														}
																														alt="Disbursement Slip"
																														className="mt-5 h-28 w-full px-1.5"
																													/>
																												</div>
																											) : null
																								  )
																								: null
																							: null}

																						{(!logistic.disbursement ||
																							logistic.disbursement.length ===
																								0 ||
																							!logistic.disbursement.some(
																								(disburse) =>
																									disburse.user_role_id === 6 &&
																									disburse.disbursement_slip_url
																							)) &&
																							!hasReachedTargetLength && (
																								<>
																									<CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
																									<h3 className="text-sm">
																										Drag and drop
																									</h3>
																									<p className="text-xs">
																										your images here or{" "}
																										<button
																											className="text-blue-400"
																											onClick={
																												importImageHandler
																											}
																										>
																											upload image...
																										</button>
																									</p>
																									{selectedLogisticFiles.length ===
																									0 ? (
																										<span className="text-gray-400 md:text-xs text-xxs mt-5">
																											-- No file selected --
																										</span>
																									) : (
																										<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5">
																											{selectedLogisticFiles.map(
																												(file, index) => (
																													<div key={index}>
																														<div
																															className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
																															onClick={() =>
																																showImageImportHandler(
																																	index
																																)
																															}
																														>
																															<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
																															<span className="text-xs text-gray-700 truncate w-16">
																																{file.name}
																															</span>
																															<button
																																className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
																																onClick={(
																																	e
																																) => {
																																	e.stopPropagation();
																																	removeChipHandler(
																																		index
																																	);
																																}}
																															>
																																<XCircleIcon className="h-3 w-3" />
																															</button>
																														</div>
																													</div>
																												)
																											)}
																										</div>
																									)}
																									<div className="my-1">
																										<input
																											type="file"
																											ref={fileInputRef}
																											style={{
																												display: "none",
																											}}
																											accept=".jpg, .jpeg, .png"
																											multiple
																											onChange={
																												handleLogisticFileChange
																											}
																										/>
																									</div>
																									{selectedLogisticFiles.length !==
																										0 && (
																										<ButtonComponent
																											text={`${
																												isButtonDisabled
																													? "Uploading ..."
																													: "Upload"
																											}`}
																											icon={
																												isLoadingAPI && (
																													<Lottie
																														className="md:w-5 w-5 h-auto"
																														animationData={
																															CircularLoading
																														}
																														loop={true}
																													/>
																												)
																											}
																											utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
                   																	 hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full
																											${isAddingRate ? "cursor-not-allowed opacity-50" : ""} 
																										 `}
																											onClick={
																												uploadLogisticReceipthandler
																											}
																										/>
																									)}
																									<span className="text-xs text-gray-500 mt-5">
																										Max size: 5MB
																									</span>
																									{logisticError && (
																										<span className="text-xs text-red-500 mt-2">
																											{logisticError}
																										</span>
																									)}
																									{logisticSuccess && (
																										<span className="text-xs text-green-500 mt-2">
																											{logisticSuccess}
																										</span>
																									)}
																								</>
																							)}
																					</div>
																				</div>
																			</div>
																		</div>
																	)
																)}
																{/* single card (start) */}
															</div>
														)}
													</>
												)}
											</>
										)}
									{selectedDisbursement?.buyer_shipping_method === "Delivery" &&
										selectedDisbursement?.supplier_shipping_method === 1 && (
											<>
												{activeSelectProductNav.includes("Supplier") && (
													<div className="py-5  mx-5 text-gray-400 ">
														{supplierAndVendorsDetails.map(
															(supplier: SupplierAndVendorType) => (
																<div
																	className="grid grid-cols-1 xl:grid-cols-2 gap-4"
																	key={generateKey(
																		supplier.buyer_id.toString() + Math.random()
																	)}
																>
																	<div className="flex flex-col ">
																		<span className="text-xs">
																			Supplier name
																		</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																			<p className="pl-3 text-gray-700 sm">
																				{supplier.supplier_name}
																			</p>
																		</div>

																		<span className="text-xs mt-10">
																			Delivery Date
																		</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
																			<p className="pl-3 text-gray-700 sm">
																				{/* July 7, 2024 */}
																				{supplier.requested_delivery_date}
																			</p>
																		</div>

																		<button
																			className="mt-10 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200"
																			onClick={handleOrderDetails}
																		>
																			View Order details
																		</button>
																		{supplier.disbursement
																			.filter(
																				(disburse) =>
																					disburse.user_role_id === 2
																			)
																			.map((disburse, disubrseIndex) => {
																				return (
																					<div key={disubrseIndex}>
																						{disburse.is_verified === 1 && (
																							<>
																								<p className="ml-2 mt-5 text-sm text-green-600">
																									Supplier already verified
																								</p>
																							</>
																						)}
																						{disburse.is_verified === 0 && (
																							<>
																								<p className="ml-2 mt-5 text-sm text-gray-500">
																									Not yet verified....
																								</p>
																							</>
																						)}
																					</div>
																				);
																			})}
																	</div>
																	<div className=" flex flex-col">
																		<span className="text-xs">Amount</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 w-full md:w-64 text-xl">
																			<p className="pl-2 text-gray-700">
																				{supplier.supplierAmount}
																			</p>
																		</div>

																		<div className="mt-10">
																			<div
																				className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5 ${
																					isDraggingOver ? "bg-gray-400" : ""
																				}`}
																				onDrop={handleSupplierDrop}
																				onDragOver={handleDragOver}
																				onDragLeave={handleDragLeave}
																			>
																				{supplier.disbursement &&
																				supplier.disbursement.length > 0
																					? supplier.disbursement.some(
																							(disburse) =>
																								disburse.user_role_id === 2 &&
																								disburse.disbursement_slip_url
																					  )
																						? supplier.disbursement.map(
																								(disburse, index) =>
																									disburse.user_role_id === 2 &&
																									disburse.disbursement_slip_url ? (
																										<div key={index}>
																											<img
																												src={
																													disburse.disbursement_slip_url
																												}
																												alt="Disbursement Slip"
																												className="mt-5 h-28 w-full px-1.5"
																											/>
																										</div>
																									) : null
																						  )
																						: null
																					: null}

																				{(!supplier.disbursement ||
																					supplier.disbursement.length === 0 ||
																					!supplier.disbursement.some(
																						(disburse) =>
																							disburse.user_role_id === 2 &&
																							disburse.disbursement_slip_url
																					)) &&
																					!hasReachedTargetLength && (
																						<>
																							<CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
																							<h3 className="text-sm">
																								Drag and drop
																							</h3>
																							<p className="text-xs">
																								your images here or{" "}
																								<button
																									className="text-blue-400"
																									onClick={importImageHandler}
																								>
																									upload image...
																								</button>
																							</p>
																							{selectedSupplierFiles.length ===
																							0 ? (
																								<span className="text-gray-400 md:text-xs text-xxs mt-5">
																									-- No file selected --
																								</span>
																							) : (
																								<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5">
																									{selectedSupplierFiles.map(
																										(file, index) => (
																											<div key={index}>
																												<div
																													className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
																													onClick={() =>
																														showImageImportHandler(
																															index
																														)
																													}
																												>
																													<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
																													<span className="text-xs text-gray-700 truncate w-16">
																														{file.name}
																													</span>
																													<button
																														className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
																														onClick={(e) => {
																															e.stopPropagation();
																															removeChipHandler(
																																index
																															);
																														}}
																													>
																														<XCircleIcon className="h-3 w-3" />
																													</button>
																												</div>
																											</div>
																										)
																									)}
																								</div>
																							)}
																							<div className="my-1">
																								<input
																									type="file"
																									ref={fileInputRef}
																									style={{
																										display: "none",
																									}}
																									accept=".jpg, .jpeg, .png"
																									multiple
																									onChange={
																										handleSupplierFileChange
																									}
																								/>
																							</div>
																							{selectedSupplierFiles.length !==
																								0 && (
																								<ButtonComponent
																									text={`${
																										isButtonDisabled
																											? "Uploading ..."
																											: "Upload"
																									}`}
																									icon={
																										isLoadingAPI && (
																											<Lottie
																												className="md:w-5 w-5 h-auto"
																												animationData={
																													CircularLoading
																												}
																												loop={true}
																											/>
																										)
																									}
																									disabled={isButtonDisabled}
																									utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
																												hover:bg-dealogikal-200 cursor-pointer duration-500 rounded-full font-normal md:w-11/12 w-full
																												${isAddingRate ? "cursor-not-allowed opacity-50" : ""}
																												`}
																									onClick={
																										uploadSupplierReceipthandler
																									}
																								/>
																							)}
																							<span className="text-xs text-gray-500 mt-5">
																								Max size: 5MB
																							</span>
																							{supplierError && (
																								<span className="text-xs text-red-500 mt-2">
																									{supplierError}
																								</span>
																							)}
																							{supplierSuccess && (
																								<span className="text-xs text-green-500 mt-2">
																									{supplierSuccess}
																								</span>
																							)}
																						</>
																					)}
																			</div>
																		</div>
																		{/* supplier (end) */}
																	</div>
																</div>
															)
														)}
													</div>
												)}
												{activeSelectProductNav.includes("Logistic") && (
													<>
														<div className="flex justify-center my-20 text-gray-400 gap-2">
															<TruckIcon className="h-5 w-5" />
															<span>No Logistic</span> {}
														</div>
													</>
												)}
											</>
										)}

									{selectedDisbursement?.buyer_shipping_method === "Pick-up" &&
										selectedDisbursement?.supplier_shipping_method === 2 && (
											<>
												{activeSelectProductNav.includes("Supplier") && (
													<div className="py-5  mx-5 text-gray-400 ">
														{supplierAndVendorsDetails.map(
															(supplier: SupplierAndVendorType) => (
																<div
																	className="grid grid-cols-1 xl:grid-cols-2 gap-4"
																	key={generateKey(
																		supplier.buyer_id.toString() + Math.random()
																	)}
																>
																	<div className="flex flex-col ">
																		<span className="text-xs">
																			Supplier name
																		</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
																			<p className="pl-3 text-gray-700 sm">
																				{supplier.supplier_name}
																			</p>
																		</div>

																		<span className="text-xs mt-10">
																			Delivery Date
																		</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full">
																			<p className="pl-3 text-gray-700 sm">
																				{/* July 7, 2024 */}
																				{supplier.requested_delivery_date}
																			</p>
																		</div>

																		<button
																			className="mt-10 text-sm w-48 bg-dealogikal-40 cursor-pointe rounded-full py-2 text-dealogikal-200"
																			onClick={handleOrderDetails}
																		>
																			View Order details
																		</button>
																		{supplier.disbursement
																			.filter(
																				(disburse) =>
																					disburse.user_role_id === 2
																			)
																			.map((disburse, disubrseIndex) => {
																				return (
																					<div key={disubrseIndex}>
																						{disburse.is_verified === 1 && (
																							<>
																								<p className="ml-2 mt-5 text-sm text-green-600">
																									Supplier already verified
																								</p>
																							</>
																						)}
																						{disburse.is_verified === 0 && (
																							<>
																								<p className="ml-2 mt-5 text-sm text-gray-500">
																									Not yet verified....
																								</p>
																							</>
																						)}
																					</div>
																				);
																			})}
																	</div>
																	<div className=" flex flex-col">
																		<span className="text-xs">Amount</span>
																		<div className="bg-gray-100 rounded-md py-3 mt-2 w-full md:w-64 text-xl">
																			<p className="pl-2 text-gray-700">
																				{supplier.supplierAmount}
																			</p>
																		</div>

																		<div className="mt-10">
																			<div
																				className={`border border-dashed h-auto rounded-md border-gray-500 flex flex-col text-center items-center py-5 ${
																					isDraggingOver ? "bg-gray-400" : ""
																				}`}
																				onDrop={handleSupplierDrop}
																				onDragOver={handleDragOver}
																				onDragLeave={handleDragLeave}
																			>
																				{supplier.disbursement &&
																				supplier.disbursement.length > 0
																					? supplier.disbursement.some(
																							(disburse) =>
																								disburse.user_role_id === 2 &&
																								disburse.disbursement_slip_url
																					  )
																						? supplier.disbursement.map(
																								(disburse, index) =>
																									disburse.user_role_id === 2 &&
																									disburse.disbursement_slip_url ? (
																										<div key={index}>
																											<img
																												src={
																													disburse.disbursement_slip_url
																												}
																												alt="Disbursement Slip"
																												className="mt-5 h-28 w-full px-1.5"
																											/>
																										</div>
																									) : null
																						  )
																						: null
																					: null}

																				{(!supplier.disbursement ||
																					supplier.disbursement.length === 0 ||
																					!supplier.disbursement.some(
																						(disburse) =>
																							disburse.user_role_id === 2 &&
																							disburse.disbursement_slip_url
																					)) &&
																					!hasReachedTargetLength && (
																						<>
																							<CloudArrowUpIcon className="h-10 w-10 text-dealogikal-100" />
																							<h3 className="text-sm">
																								Drag and drop
																							</h3>
																							<p className="text-xs">
																								your images here or{" "}
																								<button
																									className="text-blue-400"
																									onClick={importImageHandler}
																								>
																									upload image...
																								</button>
																							</p>
																							{selectedSupplierFiles.length ===
																							0 ? (
																								<span className="text-gray-400 md:text-xs text-xxs mt-5">
																									-- No file selected --
																								</span>
																							) : (
																								<div className="flex gap-4 content-center items-center overflow-x-auto lg:w-52 w-44 py-5">
																									{selectedSupplierFiles.map(
																										(file, index) => (
																											<div key={index}>
																												<div
																													className="relative flex items-center mt-2 bg-gray-100 px-4 py-3 rounded-md hover:bg-dealogikal-40 cursor-pointer"
																													onClick={() =>
																														showImageImportHandler(
																															index
																														)
																													}
																												>
																													<PhotoIcon className="h-4 w-4 text-gray-700 mr-2" />
																													<span className="text-xs text-gray-700 truncate w-16">
																														{file.name}
																													</span>
																													<button
																														className="absolute -top-1 -right-1 p-1 bg-dealogikal-300 text-white rounded-full cursor-pointer"
																														onClick={(e) => {
																															e.stopPropagation();
																															removeChipHandler(
																																index
																															);
																														}}
																													>
																														<XCircleIcon className="h-3 w-3" />
																													</button>
																												</div>
																											</div>
																										)
																									)}
																								</div>
																							)}
																							<div className="my-1">
																								<input
																									type="file"
																									ref={fileInputRef}
																									style={{
																										display: "none",
																									}}
																									accept=".jpg, .jpeg, .png"
																									multiple
																									onChange={
																										handleSupplierFileChange
																									}
																								/>
																							</div>
																							{selectedSupplierFiles.length !==
																								0 && (
																								<ButtonComponent
																									text={`${
																										isButtonDisabled
																											? "Uploading ..."
																											: "Upload"
																									}`}
																									icon={
																										isLoadingAPI && (
																											<Lottie
																												className="md:w-5 w-5 h-auto"
																												animationData={
																													CircularLoading
																												}
																												loop={true}
																											/>
																										)
																									}
																									disabled={isButtonDisabled}
																									utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-3 rounded-md py-2
																												hover:bg-dealogikal-200 duration-500 rounded-full font-normal md:w-11/12 w-full
																												${isAddingRate ? "cursor-not-allowed opacity-50" : "cursor-pointer"}
																												`}
																									onClick={
																										uploadSupplierReceipthandler
																									}
																								/>
																							)}
																							<span className="text-xs text-gray-500 mt-5">
																								Max size: 5MB
																							</span>
																							{supplierError && (
																								<span className="text-xs text-red-500 mt-2">
																									{supplierError}
																								</span>
																							)}
																							{supplierSuccess && (
																								<span className="text-xs text-green-500 mt-2">
																									{supplierSuccess}
																								</span>
																							)}
																						</>
																					)}
																			</div>
																		</div>
																	</div>
																</div>
															)
														)}
														{/* single card (start) */}
													</div>
												)}
												{activeSelectProductNav.includes("Logistic") && (
													<>
														<div className="flex justify-center my-20 text-gray-400 gap-2">
															<TruckIcon className="h-5 w-5" />
															<span>No Logistic</span> {}
														</div>
													</>
												)}
											</>
										)}
								</>
							}
						/>
						<ModalComponent
							isOpen={isModalOpen}
							onCloseHandler={() => {
								setIsModalOpen(false);
								setIsPhotoClick(false);
								setIsZoomed(false);
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Verifying Payment
									</span>
								</div>
							}
							body={
								selectedPayment && selectedPayment.offer_booking_id ? (
									<div className="py-5 mx-5 text-gray-400 ">
										<div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
											{/** first column */}
											<div className="flex flex-col">
												<span className="text-xs">Buyer Name</span>
												<div className="bg-gray-100 rounded-md py-3 mt-2 w-full xl:w-64">
													<p className="pl-3 text-gray-700">
														{selectedPayment.buyer_name}
													</p>
												</div>

												<span className="text-xs mt-10 ">Deposit Slip</span>
												<div className="bg-gray-100 rounded-md py-3 px-1 mt-2 xl:w-64 w-full">
													<div className="flex justify-center items-center">
														{isValidUrl(selectedPayment.deposit_slip_url) ? (
															imageValid ? (
																<img
																	src={selectedPayment.deposit_slip_url}
																	alt="Deposit Slip"
																	//style={{ width: '400px', height: '200px', objectFit: 'cover' }}
																	className="max-w-full h-auto cursor-pointer"
																	onClick={handleImageClick}
																	onError={handleImageError}
																/>
															) : (
																<p className="text-center">
																	No valid deposit slip image available.
																</p>
															)
														) : (
															<p className="text-center">
																No valid deposit slip URL provided.
															</p>
														)}
													</div>
												</div>
											</div>

											{/** second column */}
											<div className="flex flex-col">
												<span className="text-xs">Total Amount</span>
												<div className="bg-gray-100 rounded-md py-3 mt-2 w-full md:w-64 text-lg">
													<p className="pl-2 text-gray-700">
														{selectedPayment.totalAmountDue}
													</p>
												</div>

												<div className="mt-4 mr-5 flex justify-center items-center">
													<DropdownOptionComponent
														items={verificationDropdown_items}
														main_text="Options"
													/>
													<div className="mt-20"></div>
												</div>
												<div className="w-full mt-5">
													{selectedOption === "Verify" && (
														<ButtonComponent
															text={`${
																isButtonDisabled
																	? "Loading Please wait"
																	: "Submit"
															}`}
															icon={
																isLoadingAPI && (
																	<Lottie
																		className="md:w-5 w-5 h-auto"
																		animationData={CircularLoading}
																		loop={true}
																	/>
																)
															}
															disabled={isButtonDisabled}
															utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm py-3.5 md:py-4 rounded-full font-normal w-full md:mt-7 hover:bg-dealogikal-200 duration-500
                                  ${
																		isSubmitLoading
																			? "cursor-not-allowed opacity-50"
																			: "cursor-pointer"
																	}
                                  `}
															onClick={submitHandler}
														/>
													)}
												</div>
												{selectedOption === "Reconcile" && (
													<>
														<textarea
															id="txtcommentsdetails"
															name="txtcommentsdetails"
															value={commentsDetails}
															placeholder="Please input reason"
															rows={3}
															className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																isCommentDetailsEmpty ? "red-600" : "gray-300"
															} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
															onChange={(e) =>
																fieldChangeHandler(
																	e.target.value,
																	"txtcommentsdetails"
																)
															}
														/>
														<div className="mt-5 w-full">
															<ButtonComponent
																text={`${
																	isButtonDisabled
																		? "Loading Please wait"
																		: "Submit"
																}`}
																icon={
																	isLoadingAPI && (
																		<Lottie
																			className="md:w-5 w-5 h-auto"
																			animationData={CircularLoading}
																			loop={true}
																		/>
																	)
																}
																disabled={isButtonDisabled}
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm py-3.5 md:py-4 rounded-full font-normal w-full md:mt-7 hover:bg-dealogikal-200 duration-500
                                  ${
																		isSubmitLoading
																			? "cursor-not-allowed opacity-50"
																			: "cursor-pointer"
																	}
                                  `}
																onClick={console.log("Not yeat ready")}
															/>
														</div>
													</>
												)}
												{/* <div className="relative text-right w-full flex justify-center pt-5">
													<div ref={menuRef}>
														<button
															type="button"
															className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
															id="menu-button"
															aria-expanded={menuOpen}
															aria-haspopup="true"
															onClick={toggleMenu}
														>
															Options
															<svg
																className="-mr-1 h-5 w-5 text-gray-400"
																viewBox="0 0 20 20"
																fill="currentColor"
																aria-hidden="true"
															>
																<path
																	fillRule="evenodd"
																	d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
																	clipRule="evenodd"
																/>
															</svg>
														</button>
													</div>
													{menuOpen && (
														<div
															className="absolute right-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
															role="menu"
															aria-orientation="vertical"
															aria-labelledby="menu-button"
															tabIndex={-1}
														>
															<div className="py-1" role="none">
																<button
																	type="button"
																	className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-blue-100"
																	role="menuitem"
																	tabIndex={-1}
																	onClick={handleVerifyClick}
																>
																	Verify
																</button>
																<button
																	type="button"
																	className="text-gray-700 block px-4 py-2 text-sm w-full text-left hover:bg-blue-100"
																	role="menuitem"
																	tabIndex={-1}
																	onClick={handleDenyClick}
																>
																	Deny
																</button>
															</div>
														</div>
													)}
												</div> */}

												{/* {(showButton || showTextArea) && (
													<>
														<div className="relative text-right w-full flex justify-center pt-5">
															{showTextArea && (
																<div className="w-full px-5">
																	<textarea className="w-full rounded-lg border-blue-100"></textarea>
																</div>
															)}
														</div>
														<div className="relative text-right w-full flex justify-center pt-5">
															<ButtonComponent
																text={`${
																	isButtonDisabled
																		? "Loading Please wait"
																		: "Submit"
																}`}
																icon={
																	isLoadingAPI && (
																		<Lottie
																			className="md:w-5 w-5 h-auto"
																			animationData={CircularLoading}
																			loop={true}
																		/>
																	)
																}
																disabled={isButtonDisabled}
																utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm py-3.5 md:py-4 rounded-full font-normal w-full md:mt-7 hover:bg-dealogikal-200 duration-500
                                  ${
																		isSubmitLoading
																			? "cursor-not-allowed opacity-50"
																			: "cursor-pointer"
																	}
                                  `}
																onClick={submitHandler}
															/>
														</div>
													</>
												)} */}
											</div>
										</div>

										{isPhotoClick && (
											<div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
												<div className="overflow-auto max-h-screen">
													<img
														src={selectedPayment.deposit_slip_url}
														alt="Enlarged Deposit Slip"
														className={`max-w-full h-auto transition-transform duration-300 ${
															isZoomed
																? "transform scale-150"
																: "transform scale-100"
														}`}
														onClick={toggleZoom}
													/>
												</div>
											</div>
										)}
									</div>
								) : (
									<div className="flex flex-wrap justify-center">
										<div className="flex flex-col w-full md:pb-10 pb-8 md:w-full md:px-0">
											<div className="md:px-6 px-5 justify-center">
												<p>No deposit slip available.</p>
											</div>
										</div>
									</div>
								)
							}
						/>
						<ModalComponent
							isXL={true}
							isOpen={isModalImage}
							onCloseHandler={onCloseImageShowHandler}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<ArrowUpTrayIcon
										className="-md:ml-0.5 md:h-6 md:w-6 h-5 w-5 text-white inline mx-4"
										aria-hidden="true"
									/>
									<span className="text-white inline md:text-base text-xs">
										Import Image
									</span>
								</div>
							}
							body={
								<>
									{selectedImage && (
										<div className="flex justify-center py-5 mx-7 my-4">
											<img
												src={selectedImage}
												alt="Selected"
												className="max-w-full h-96 relative"
											/>
											<button
												onClick={closeSelectedImage}
												className="absolute top-16 right-2 px-3 py-2 text-sm bg-dealogikal-200 text-white rounded-lg cursor-pointer"
											>
												Close
											</button>
										</div>
									)}
								</>
							}
						/>
						<ModalComponent
							isOpen={isSalesOrderModal}
							onCloseHandler={() => {
								setIsSalesOrderModal(false);
							}}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Confirm SO
									</span>
								</div>
							}
							body={
								selectedOrder ? (
									<div className="py-5 mx-5 text-gray-400">
										{selectedOrder.map((item, index) => (
											<div
												key={index}
												className="grid grid-cols-1 xl:grid-cols-2 gap-4 pb-10 items-center"
											>
												<div className="flex flex-col">
													<span className="text-ts">Supplier Name</span>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
														<p className="pl-3 text-gray-700 sm">
															{item.buyer_name}
														</p>
													</div>
													<span className="text-ts">Depot Location</span>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-12">
														<p className="pl-3 text-gray-700 sm">
															{item.supplier_location}
														</p>
													</div>
												</div>
												<div className="flex flex-col items-center">
													<div className="mt-5"></div>
													<div className="bg-gray-100 rounded-md py-3 mt-2 xl:w-64 w-full h-48">
														<div className="flex justify-center items-center h-full">
															{isValidUrl(item.sales_order_url) ? (
																imageValid ? (
																	<img
																		src={item.sales_order_url}
																		alt="SO Slip"
																		className="max-w-full h-full object-contain cursor-pointer"
																		onClick={handleImageClick}
																		onError={handleImageError}
																	/>
																) : (
																	<p>No valid SO slip image available.</p>
																)
															) : (
																<p>No valid SO slip URL provided.</p>
															)}
														</div>
													</div>
													{item.status !== 1 && (
														<>
															<div className="mt-4 mr-5">
																<DropdownOptionComponent
																	items={dropdown_items}
																	main_text="Options"
																/>
																<div className="mt-20"></div>
															</div>
															<div className="ml-6 w-full">
																{selectedOption === "Confirmed" && (
																	<ButtonComponent
																		text="Confirm"
																		utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer
																	 duration-500 rounded-full font-normal md:w-11/12 w-full`}
																		onClick={soConfirmationHandler}
																	/>
																)}
															</div>
															{selectedOption === "Reconcile" && (
																<>
																	<textarea
																		id="txtcommentsdetails"
																		name="txtcommentsdetails"
																		value={commentsDetails}
																		placeholder="Please input reason"
																		rows={3}
																		className={`block w-full rounded-2xl border-0 py-3 px-6 shadow-sm ring-1 ring-inset ring-${
																			isCommentDetailsEmpty
																				? "red-600"
																				: "gray-300"
																		} placeholder:text-gray-400 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 text-sm sm:leading-6`}
																		onChange={(e) =>
																			fieldChangeHandler(
																				e.target.value,
																				"txtcommentsdetails"
																			)
																		}
																	/>
																	<div className="mt-5 w-full">
																		<ButtonComponent
																			text="Send"
																			utils={`bg-dealogikal-100 text-white md:text-sm text-xs shadow-sm md:py-4 py-2.5 hover:bg-dealogikal-200 cursor-pointer"
																	 duration-500 rounded-full font-normal w-full`}
																			onClick={() => console.log("reconcile")}
																		/>
																	</div>
																</>
															)}
														</>
													)}
												</div>
											</div>
										))}
									</div>
								) : (
									<div className="py-5 mx-5 text-gray-400">
										No order selected.
									</div>
								)
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default PaymentComp;
