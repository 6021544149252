import React, { useEffect, useRef, useState } from "react";
import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import BreadcrumbContainerComponent from "../../templates/BreadcrumbContainerComponent/BreadcrumbContainerComponent";
import NavTabComponent from "../../templates/NavTabComponent/NavTabComponent";
import Skeleton from "react-loading-skeleton";
import logo from "../../buyer/my_order/dealogikal-app-dark_p4svpl.png";
import {
	ArrowDownCircleIcon,
	ArrowPathIcon,
	CalendarDaysIcon,
	CircleStackIcon,
	MapPinIcon,
	UserIcon,
} from "@heroicons/react/24/outline";
import ShipmentListType from "../../../model/types/ShipmentsListType";
import TableComponent from "../../templates/TableComponent/TableComponent";
import { useAppDispatch, useAppSelector } from "../../../redux/app/hooks";
import {
	getAdminShipmentList,
	getShipments,
	setAdminAtlList,
	setAdminShipmentList,
} from "../../../redux/functions/storageSlice";
import { getTokenFromLocalStorage } from "../../../redux/functions/function";
import {
	getATLForAdminAPI,
	getDeliveryForAdminAPI,
	getShipmentForAdminAPI,
} from "../../../redux/functions/API";
import DeliveryAdminListType from "../../../model/types/DeliveryAdminListType";

import { ATLTablelistType } from "../../../model/types/Authorization";
import ModalComponent from "../../templates/ModalComponent/ModalComponent";

const AdminShipmentsComp = () => {
	const _token = getTokenFromLocalStorage() ?? "";
	const dispatch = useAppDispatch();
	const myDelivery = useAppSelector(getAdminShipmentList);
	const contentRef = useRef<HTMLDivElement>(null);

	const [activeNav, setActiveNav] = useState<string>("All");
	const myShipment = useAppSelector(getShipments);
	const [isShipmentListFetching, setIsShipmentListFetching] =
		useState<boolean>(true);
	const [ShipmentList, setShipmentList] = useState<ShipmentListType[]>(
		myShipment.allShipmentsList
	);
	const [isAtlListFetching, setIsAtlListFetching] = useState<boolean>(true);
	const [isDeliveryListFetching, setIsDeliveryListFetching] =
		useState<boolean>(true);
	const [deliveryList, setDeliveryList] = useState<DeliveryAdminListType[]>(
		myDelivery.adminDeliveryList
	);
	const [atlList, setAtlList] = useState<ATLTablelistType[]>(
		myDelivery.adminAtlList
	);
	const [isDeliveryModalOpen, setIsDeliveryModalOpen] =
		useState<boolean>(false);
	const [deliveryID, setDeliveryID] = useState(0);

	console.log(isDeliveryModalOpen);
	const nav_items = [
		{
			text: "All",
		},
		{
			text: "Payment Verification",
		},
		{
			text: "ATL",
		},
		{
			text: "To Ship",
		},
		{
			text: "Delivery",
		},
	];
	const setActiveNavTab = (active_item: string) => {
		setActiveNav(active_item);
	};

	const shipmentHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "location",
			value: "Buyer Location",
			icon: MapPinIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "supplier_depot",
			value: "Supplier Depot",
			icon: MapPinIcon,
		},
		{
			name: "rate",
			value: "Amount",
			icon: CircleStackIcon,
		},
		{
			name: "order_status",
			value: "Status",
			icon: ArrowDownCircleIcon,
		},
	];

	const deliveryHeader = [
		{
			name: "buyer_name",
			value: "Buyer Name",
			icon: UserIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "order_status",
			value: "Order Status",
			icon: ArrowPathIcon,
		},
		{
			name: "date_delivered",
			value: "Date Delivery",
			icon: CalendarDaysIcon,
		},
		{
			name: "date_completed",
			value: "Date Completed",
			icon: CalendarDaysIcon,
		},
	];

	const ATLheader = [
		{
			name: "order_number",
			value: "DL number",
			icon: UserIcon,
		},
		{
			name: "supplier_name",
			value: "Supplier Name",
			icon: UserIcon,
		},
		{
			name: "supplier_depot",
			value: "Depot Address",
			icon: UserIcon,
		},
		{
			name: "status",
			value: "Status",
			icon: UserIcon,
			// customRender: (item: any) => (
			// 	<span
			// 		className={
			// 			item.status === "On-going"
			// 				? "text-orange-300"
			// 				: item.order_status === "Complete"
			// 				? "text-green-500"
			// 				: ""
			// 		}
			// 	>
			// 		{item.order_status}
			// 	</span>
			// ),
		},
	];

	//fetching the deliverylist api from admin view
	useEffect(() => {
		if (isDeliveryListFetching && activeNav === "Delivery") {
			if (myDelivery.adminDeliveryList.length === 0) {
				getDeliveryForAdminAPI(_token, 10).then((response: any) => {
					setIsDeliveryListFetching(false);
					if (response.statusCode === 200) {
						dispatch(setAdminShipmentList(response.data.items));
					}
				});
			} else if (myDelivery.adminDeliveryList.length !== 0) {
				let udpatedDeliveryList: DeliveryAdminListType[] = [];

				myDelivery.adminDeliveryList.forEach((delivery: any) => {
					const newDelivery: DeliveryAdminListType = {
						offer_booking_id: delivery.offer_booking_id,
						offer_id: delivery.offer_id,
						reverse_auction_id: delivery.reverse_auction_id,
						updated_at: delivery.updated_at,
						order_status_id: delivery.order_status_id,
						order_status: delivery.order_status,
						commodity_id: delivery.commodity_id,
						commodity_name: delivery.commodity_name,
						unit: delivery.unit,
						ordered_volume: delivery.ordered_volume,
						order_number: delivery.order_number,
						buyer_id: delivery.buyer_id,
						requested_delivery_date: delivery.requested_delivery_date,
						buyer_shipping_method: delivery.buyer_shipping_method,
						supplier_shipping_method: delivery.supplier_shipping_method,
						buyer_name: delivery.buyer_name,
						supplier_id: delivery.supplier_id,
						supplier_name: delivery.supplier_name,
						supplier_depot: delivery.supplier_depot,
						booking_price: delivery.booking_price,
						payment_details_id: delivery.payment_details_id,
						deposit_slip_url: delivery.deposit_slip_url,
						booked_at: delivery.booked_at,
						logistic_id: delivery.logistic_id,
						logistic_name: delivery.logistic_name,
						rate_details_id: delivery.rate_details_id,
						municipality_id: delivery.municipality_id,
						location: delivery.location,
						vehicle_id: delivery.vehicle_id,
						rate: delivery.rate,
						row_id: delivery.row_id,
					};
					udpatedDeliveryList = [...udpatedDeliveryList, newDelivery];
				});
				setDeliveryList(udpatedDeliveryList);
			}
		}
	}, [
		isDeliveryListFetching,
		_token,
		activeNav,
		dispatch,
		myDelivery.adminDeliveryList,
	]);

	//fetching the atl list api from admin view
	useEffect(() => {
		if (isAtlListFetching && activeNav === "ATL") {
			if (myDelivery.adminAtlList.length === 0) {
				getATLForAdminAPI(_token).then((response: any) => {
					setIsAtlListFetching(false);
					if (response.statusCode === 200) {
						// Ensure atl_details exists and extract license_number if available
						const newATLlist = response.data.items
							.filter((item: any) => item.atl_details) // Ensure atl_details exist
							.map((item: any) => ({
								...item,
								// Set buyer_shipping_method based on value
								buyer_shipping_method:
									item.buyer_shipping_method === 1 ? "Delivery" : "Pick-up",
								// // Extract license_number from atl_details
								// license_number: item.atl_details[0]?.license_number,
							}));
						dispatch(setAdminAtlList(newATLlist));
					}
				});
			} else if (myDelivery.adminAtlList.length > 0) {
				let udpatedDeliveryList: ATLTablelistType[] = [];

				myDelivery.adminAtlList.forEach((delivery: any) => {
					const newDelivery: ATLTablelistType = {
						...delivery,
						license_number: delivery.atl_details?.[0]?.license_number,
					};
					udpatedDeliveryList = [...udpatedDeliveryList, newDelivery];
				});
				setAtlList(udpatedDeliveryList);
			}
		}
	}, [
		isAtlListFetching,
		_token,
		activeNav,
		dispatch,
		myDelivery.adminAtlList,
		myDelivery.adminAtlList.length,
	]);

	// fetch Shitment from api and display to the admin view
	useEffect(() => {
		if (isShipmentListFetching) {
			getShipmentForAdminAPI(_token)
				.then((response: any) => {
					const newShipmentList = response.data.items.map(
						(shipmentItem: ShipmentListType) => {
							// Process each shipmentItem here if needed, for example:
							return {
								...shipmentItem,
								is_disbursement_verified:
									shipmentItem.is_disbursement_verified === 1
										? "Verified"
										: "Not Verified",
								is_payment_verified:
									shipmentItem.is_payment_verified === 1
										? "Verified"
										: "Not Verified",
							};
						}
					);
					setShipmentList(newShipmentList); // Update the state with the new shipment list.
					setIsShipmentListFetching(false); // Optionally stop fetching indicator
				})
				.catch((error) => {
					console.error("Error fetching shipments:", error);
					setIsShipmentListFetching(false); // Stop fetching on error
				});
		}
	}, [isShipmentListFetching, _token]);

	const handleDeliveryDetails = async (offer_booking_id: number) => {
		setIsDeliveryModalOpen(true);
		setDeliveryID(offer_booking_id);
	};

	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						{/**----------------------------------------------------------------- */}
						<BreadcrumbContainerComponent
							key={Math.random()}
							subtitle="Shipments"
						/>
						{/**----------------------------------------------------------------- */}
						<NavTabComponent
							key={Math.random()}
							nav_type="secondary"
							active_tab={activeNav}
							nav_items={nav_items}
							setActiveNavHandler={setActiveNavTab}
						/>
						{/**----------------------------------------------------------------- */}
						<div className="flex flex-wrap justify-center">
							<div className="flex flex-col w-full md:pt-2 md:pl-2 md:w-full">
								{activeNav === "All" && (
									<>
										{isShipmentListFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<>
												<TableComponent
													tableHeader={shipmentHeader}
													tableData={ShipmentList}
													currentPage={1}
													rowsPerPage={10}
													hasRowClick={true}
													idStringProperty="disbursement_details_id"
													handleRowClick={() => {}}
													emptyState={{
														primaryMessage: "No Orders available.",
														secondaryMessage: "",
														buttonText: "Create Order",
														buttonOnClick() {
															//createTemplateClickHandler();
														},
													}}
												/>
											</>
										)}
									</>
								)}
								{activeNav === "ATL" && (
									<>
										<TableComponent
											tableHeader={ATLheader}
											tableData={atlList}
											currentPage={1}
											rowsPerPage={10}
											hasRowClick={true}
											idStringProperty="disbursement_details_id"
											handleRowClick={() => {}}
											emptyState={{
												primaryMessage: "No Orders available.",
												secondaryMessage: "",
												buttonText: "Create Order",
												buttonOnClick() {
													//createTemplateClickHandler();
												},
											}}
										/>
									</>
								)}

								{activeNav === "Delivery" && (
									<>
										{isDeliveryListFetching ? (
											<div className="-my-2">
												<Skeleton height={40} count={1} />
												<Skeleton height={40} count={5} />
											</div>
										) : (
											<>
												<TableComponent
													tableHeader={deliveryHeader}
													tableData={deliveryList}
													currentPage={1}
													rowsPerPage={10}
													hasRowClick={true}
													idStringProperty="offer_booking_id"
													handleRowClick={(offer_booking_id: string) =>
														handleDeliveryDetails(parseInt(offer_booking_id))
													}
													emptyState={{
														primaryMessage: "No Orders available.",
														secondaryMessage: "",
														buttonText: "Create Order",
														buttonOnClick() {
															//createTemplateClickHandler();
														},
													}}
												/>
											</>
										)}
									</>
								)}
							</div>
						</div>

						{/** Modal Delivery component */}
						<ModalComponent
							isOpen={isDeliveryModalOpen}
							onCloseHandler={() => {
								setIsDeliveryModalOpen(false);
							}}
							isXL={true}
							header={
								<div className="bg-dealogikal-100 md:py-4 md:px-4 pt-3 pb-3 px-4 text-center">
									<span className="text-white inline md:text-base text-xs">
										Delivery Receipt Details
									</span>
								</div>
							}
							body={
								<>
									<div
										className="w-full bg-gray-50 shadow-md rounded-lg px-20 py-5 h-[70rem] "
										ref={contentRef}
									>
										<div className="flex justify-between items-center mb-5">
											<img src={logo} alt="logo" className="h-40 w-52" />

											<div className="text-center flex-1">
												<h1 className="text-dealogikal-100 font-bold text-xl mb-2">
													DEALOGIKAL CORP.
												</h1>
												<p className="text-sm mb-2">
													Kiener Energy Centre Bldg., Kiener Hills, Matumbo
													Road, Pusok, Lapulapu City, Cebu
												</p>
												<p className="text-sm mb-2">(032) 411-0303</p>
												<a
													href="https://www.dealogikal.com"
													className="text-blue-500 text-sm"
												>
													https://www.dealogikal.com
												</a>
											</div>

											<div className="text-right flex-shrink-0">
												<button className="font-bold text-lg">
													Deliver Receipt
												</button>
											</div>
										</div>

										<div className="mb-8 grid grid-cols-2 gap-4 text-sm lg:text-base w-full">
											<div>
												<p className="flex gap-4 items-center">
													<span className="w-30">Customer Name</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{/* {myShipment.deliveryDetails[0]?.buyer_name} */}
													</span>
												</p>
												<p className="flex gap-4 items-center">
													<span className="w-30">Address</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{/* {
															myShipment.deliveryDetails[0]
																?.initial_delivery_receipt[0].delivery_address
														} */}
														lapu-lapu
														{deliveryID}
													</span>
												</p>
											</div>
											<div>
												<p className="flex gap-4 items-center">
													<span className="w-24">Date</span>
													<span>:</span>
													<span className="font-semibold text-center border-b px-4 border-gray-600 flex-1">
														{/* {
															myShipment.deliveryDetails[0]
																?.requested_delivery_date
														} */}
													</span>
												</p>
											</div>
										</div>

										<table className="min-w-full border border-gray-500 text-center my-10">
											<thead>
												<tr className="bg-gray-100">
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
													>
														Quantity Ordered
													</th>
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200 border-r border-gray-500"
													>
														Quantity Received
													</th>
													<th
														scope="col"
														className="py-4 px-6 text-sm font-semibold text-dealogikal-200"
													>
														Description
													</th>
												</tr>
											</thead>

											{/* {myShipment.deliveryDetails[0]?.items.map((item: any) => (
												<tbody className="bg-white" key={item.commodity_id}>
													<tr className="border-t border-gray-500">
														<td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500">
															{item.ordered_volume}
														</td>
														<td className="py-4 px-6 text-sm text-gray-900 border-r border-gray-500"></td>
														<td className="py-4 px-6 text-sm text-gray-900">
															{item.commodity_name}
														</td>
													</tr>
												</tbody>
											))} */}
										</table>

										<div className="mt-20 border-t border-gray-500 pt-10">
											<span>Received the above good order & condition</span>
										</div>
										<div className="mt-20 border-t border-gray-500 pt-10">
											<div className="grid grid-cols-2 gap-10">
												<div className="flex flex-col items-start">
													<p className="text-center border-b w-80 border-gray-800 inline-block font-bold">
														KLYNEA MARTINI S. YAUN
													</p>
													<span className="text-center text-sm mt-1 ml-5">
														(Customer Signature over Printed Name)
													</span>
												</div>

												<div className="flex flex-col items-start">
													<div className="flex items-center">
														<span className="w-36 font-semibold">
															Date Received:
														</span>
														<span className="flex-1 border-b border-gray-600 ml-4"></span>
													</div>
													<div className="flex items-center mt-2">
														<span className="w-36 font-semibold">
															Time Received:
														</span>
														<span className="flex-1 border-b border-gray-600 ml-4"></span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</>
							}
						/>
					</>
				}
			/>
		</>
	);
};

export default AdminShipmentsComp;
