import Lottie from "lottie-react";
import loading from "../../../lottie/loading.json";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import ButtonComponent from "../ButtonComponent/ButtonComponent";
import RemarkModel from "../../../model/interface/RemarkModel";

const RemarksComp: React.FC<RemarkModel> = (props) => {
  return (
    <div className={props.customClasses}>
      {props.isLoading === true && (
        <Lottie
          className="md:w-20 w-14 h-auto mx-auto"
          animationData={loading}
          loop={true}
        />
      )}
      {props.isFound === true && (
        <>
          <span className="text-gray-900 md:pt-0 pt-6 text-sm">Remarks</span>
          <div className="bg-green-50 sm:p-8 p-6 rounded-xl mt-2">
            <div className="flex">
              <div className="flex-shrink-0">
                <InformationCircleIcon
                  className="h-5 w-5 text-green-800"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 sm:mt-0 mt-1">
                <p className="sm:text-sm text-xs font-medium text-green-800">
                  {props.main_message}
                </p>
              </div>
              <div className="ml-auto pl-3">
                <div className="-mx-1.5 -my-1.5">
                  <ButtonComponent
                    text="Details"
                    textUtils="sm:text-sm text-xs"
                    utils="bg-green-500 text-white text-sm shadow-sm py-2 px-4 hover:bg-green-600 duration-500 rounded-full font-normal"
                    onClick={props.isViewDetailsClicked}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default RemarksComp;
