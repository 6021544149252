import { useState, useMemo, useEffect, useRef } from "react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Combobox } from "@headlessui/react";
import { classNames } from "../../../redux/functions/function";
import { ComboBoxItemModel } from "../../../model/interface/ComboBoxItemModel";
import { createPortal } from "react-dom";

const ComboboxComponent: React.FC<ComboBoxItemModel> = (props) => {
  const [query, setQuery] = useState("");
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownPosition, setDropdownPosition] = useState({ top: 0, left: 0, width: 0 });
  const inputRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const filteredPeople = useMemo(() => {
    return query === ""
      ? props.items
      : props.items.filter((item) =>
          item.name.toLowerCase().includes(query.toLowerCase())
        );
  }, [query, props.items]);

  useEffect(() => {
    if (inputRef.current) {
      const rect = inputRef.current.getBoundingClientRect();
      setDropdownPosition({
        top: rect.bottom + window.scrollY,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node) &&
        inputRef.current && 
        !inputRef.current.contains(event.target as Node) // Check inputRef.current
      ) {
        setDropdownOpen(false);
      }
    };

    if (dropdownOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [dropdownOpen]);

  const renderDropdown = () => (
    createPortal(
      <div
        ref={dropdownRef}
        className="absolute z-[1050] max-h-60 overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm"
        style={{
          position: 'absolute',
          top: dropdownPosition.top,
          left: dropdownPosition.left,
          width: dropdownPosition.width,
          zIndex: 1050,
        }}
      >
        {filteredPeople.map((item) => (
          <Combobox.Option
            key={item.id}
            value={item}
            className={({ active }) =>
              classNames(
                "relative cursor-default select-none py-2 pl-3 pr-9",
                active ? "bg-dealogikal-100 text-white" : "text-gray-900"
              )
            }
          >
            {({ active, selected }) => (
              <>
                <span
                  className={classNames(
                    "block truncate",
                    selected && "font-semibold"
                  )}
                >
                  {item.name}
                </span>

                {selected && (
                  <span
                    className={classNames(
                      "absolute inset-y-0 right-0 flex items-center pr-4",
                      active ? "text-white" : "text-dealogikal-100"
                    )}
                  >
                    <CheckIcon className="h-5 w-5" aria-hidden="true" />
                  </span>
                )}
              </>
            )}
          </Combobox.Option>
        ))}
      </div>,
      document.body
    )
  );

  return (
    <Combobox
      as="div"
      value={props.selectedItem}
      onChange={(selectedItem) => {
        props.setSelectedItem(selectedItem);
        setDropdownOpen(false);
      }}
      disabled={props.isDisabled}
    >
      <div className="relative mt-2" ref={inputRef}>
        <Combobox.Input
          className={classNames(
            "text-sm block w-full rounded-2xl py-3 px-6 shadow-sm border-0 focus:ring-1 focus:ring-inset focus:ring-dealogikal-200 sm:text-sm sm:leading-6",
            props.hasError ? "ring-1 ring-inset ring-red-600" : "ring-1 ring-inset ring-gray-300",
            props.isDisabled ? "disabled:cursor-not-allowed disabled:bg-gray-50 disabled:text-gray-500 disabled:ring-gray-200" : "",
            props.utils
          )}
          onChange={(event) => {
            setQuery(event.target.value);
            setDropdownOpen(true);
          }}
          displayValue={(item: any) => item?.name}
          placeholder={props.placeholder}
          aria-label={props.placeholder}
        />
        <Combobox.Button
          className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none"
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {dropdownOpen && filteredPeople.length > 0 && renderDropdown()}
      </div>
    </Combobox>
  );
};

export default ComboboxComponent;
