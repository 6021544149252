import { ShieldCheckIcon } from "@heroicons/react/24/outline";
import NearbyPartyDetailsModel from "../../../model/interface/NearbyPartyDetailsModel";

const NearbyPartyDetailsComp: React.FC<NearbyPartyDetailsModel> = (props) => {
  return (
    <>
      <div className="table w-full px-2 py-3 border-b-2 border-gray-100">
        <div className="table-row-group">
          <div className="table-row">
            <div className="table-cell align-middle md:w-1/12 md:px-0 px-1">
              <img
                className="md:h-12 md:w-12 h-9 w-9 rounded-full mx-auto"
                src={props.details.logo_url}
                alt=""
              />
            </div>
            <div className="table-cell text-left md:w-10/12 pl-4">
              <span className="font-extrabold md:text-lg text-sm inline">
                {props.details.name}
              </span>{" "}
              <ShieldCheckIcon className="ml-1 mb-1 h-4 w-4 inline text-green-600" />
              <br />
              <span className="text-gray-600 md:text-sm text-xs">
                {props.details.location}
              </span>{" "}
              <br />
              <span className="text-gray-600 md:text-sm text-xs">
                {props.details.ratings}
              </span>
            </div>
            <div className="table-cell text-right align-middle md:w-1/12 pr-4">
              <input
                id={`item-${props.details.id}`}
                name={`item-${props.details.id}`}
                type="checkbox"
                defaultChecked={true}
                className="h-5 w-5 rounded border-gray-300 text-dealogikal-100 focus:ring-gray-50"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NearbyPartyDetailsComp;
