import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import FeaturesComponent from "./features/FeaturesComponent";
//import GettingStartedComponent from "./getting_started/GettingStartedComponent";
import ShipmentStatusComponent from "./shipment_status/ShipmentStatusComponent";

const DashboardComp = () => {
	return (
		<>
			<MainConsoleLayoutComponent
				content={
					<>
						<FeaturesComponent />
						<ShipmentStatusComponent />
						{/* <GettingStartedComponent /> */}
					</>
				}
			/>
		</>
	);
};

export default DashboardComp;
