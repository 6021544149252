import MainConsoleLayoutComponent from "../../templates/MainConsoleLayoutComponent/MainConsoleLayoutComponent";
import OrderStatusComponent from "./order-status/OrderStatusComponent";

const DashboardComponent = () => {

  return (
    <>
      <MainConsoleLayoutComponent
        content={
          <>

            {/* Order status component */}
            <OrderStatusComponent />

          </>
        }
      />
    </>
  );
};

export default DashboardComponent;
